// XP / Entry / Place
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=5105%3A0&viewport=-26012%2C15248%2C9.516730308532715

import './index.scss';
import React from 'react';

import ImgTag from 'component-lib/components/img-tag';

import { competitionPlaceOptions } from 'components/v2/activities/categories/competition/variants/hackathon/options/competitionPlaceOptions';

const XpEntryPlace = ({ place }) => {
  if (place === null) return null;

  const getIconSrc = () => {
    switch (place) {
      case 0:  return require('component-lib/icons/award--participant.svg');
      case 1:  return require('component-lib/icons/award--1st-place.svg');
      case 2:  return require('component-lib/icons/award--2nd-place.svg');
      case 3:  return require('component-lib/icons/award--3rd-place.svg');
      default: throw Error('Unexpected place');
    }
  };

  const getPlaceTitleClassName = () => {
    const placeTitleElementClassName = 'xp-entry-place__place-title';

    switch (place) {
      case 0:  return `${placeTitleElementClassName} ${placeTitleElementClassName}--participant`;
      case 1:  return `${placeTitleElementClassName} ${placeTitleElementClassName}--first-place`;
      case 2:  return `${placeTitleElementClassName} ${placeTitleElementClassName}--second-place`;
      case 3:  return `${placeTitleElementClassName} ${placeTitleElementClassName}--third-place`;
      default: throw Error('Unexpected place');
    }
  };

  const placeTitle = competitionPlaceOptions
    .find((e) => (e.value === place))
    .title;

  return (
    <div className = "xp-entry-place">
      <ImgTag width = '16px' src = { getIconSrc() } />
      <div className = { getPlaceTitleClassName() } >
        { placeTitle }
      </div>
    </div>
  );
};

export default XpEntryPlace;
