export const actionTypes = {
  REFRESH: 'v2/featureFlags/REFRESH',
};

export const actionCreators = {
  refresh: (data) => ({
    type: actionTypes.REFRESH,
    data
  }),
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REFRESH:
      return action.data;
    default:
      return state;
  }
};
