import { defined } from 'lib/helpers';
import React       from 'react';

import PanelFormHeadline    from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown    from 'components/v2/input-fields/input-text-dropdown';
import Select               from 'components/v2/input-fields/select';
import InputTextSingleLine  from 'components/v2/input-fields/input-text-single-line';
import InputTextMultiline   from 'components/v2/input-fields/input-text-multiline';
import { LOCATION_OPTIONS } from 'components/v2/activities/generics/location/country-flags';

class HighSchoolDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForHighSchool: true,
      isValueAcceptedForCampus:     true,
    };

    this.isValueAcceptedForHighSchool = () => {
      return this.state.isValueAcceptedForHighSchool;
    };

    this.isValueAcceptedForCampus = () => {
      return this.state.isValueAcceptedForCampus;
    };

    this.setValueAcceptedForHighSchool = (value) => {
      return this.setState({ isValueAcceptedForHighSchool: value });
    };

    this.setValueAcceptedForCampus = (value) => {
      return this.setState({ isValueAcceptedForCampus: value });
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const renderHighSchoolInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'school',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForHighSchool(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'school',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'school',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForHighSchool(true);
      };

      const onAcceptSuggestion = (highSchool) => {
        detailsData.setField({
          attrName: 'school',
          data:     highSchool,
        });
        this.setValueAcceptedForHighSchool(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { true }
          label         = { detailsData.getFieldLabel('school') }
          placeholder   = { detailsData.getFieldPlaceholder('school') }
          value         = { detailsData.getField('school').name }
          options       = { this.isValueAcceptedForHighSchool() ? null : detailsData.getSuggestions('school') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCampusInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'campus',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCampus(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'campus',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'campus',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCampus(true);
      };

      const onAcceptSuggestion = (campus) => {
        detailsData.setField({
          attrName: 'campus',
          data:     campus,
        });
        this.setValueAcceptedForCampus(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { false }
          label         = { detailsData.getFieldLabel('campus') }
          placeholder   = { detailsData.getFieldPlaceholder('campus') }
          value         = { detailsData.getField('campus').name }
          options       = { this.isValueAcceptedForCampus() ? null : detailsData.getSuggestions('campus') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderLocation = () => {
      return (
        <Select
          name        = "location"
          label       = { detailsData.getFieldLabel('location') }
          placeholder = { detailsData.getFieldPlaceholder('location') }
          value       = { detailsData.getField('location') }
          options     = { LOCATION_OPTIONS }
          onChange    = { (value) => { detailsData.setField({ attrName: 'location', data: value }); } }
        />
      );
    };

    const renderGrade = () => {
      return (
        <InputTextSingleLine
          name        = "grade"
          label       = { detailsData.getFieldLabel('grade') }
          placeholder = { detailsData.getFieldPlaceholder('grade') }
          value       = { detailsData.getField('grade') }
          onChange    = { (value) => { detailsData.setField({ attrName: 'grade', data: value }); } }
        />
      );
    };

    const renderSubjects = () => {
      return (
        <InputTextMultiline
          name        = "subjects"
          label       = { detailsData.getFieldLabel('subjects') }
          placeholder = { detailsData.getFieldPlaceholder('subjects') }
          hasHint     = { true }
          message     = "Please separate each subject with a comma."
          value       = { detailsData.getSubjectsAsText() }
          onChange    = { (value) => { detailsData.setField({ attrName: 'subjects', data: value }); } }
        />
      );
    };

    return (
      <div className = "v2-high-school-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Education Details'
          description = 'Enter your High School details to continue...'
        />

        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderHighSchoolInput() }</div>
          <div className = "column is-full">{ renderCampusInput() }</div>
          <div className = "column is-half">{ renderLocation() }</div>
          <div className = "column is-half">{ renderGrade() }</div>
          <div className = "column is-full">{ renderSubjects() }</div>
        </div>
      </div>
    );
  }
};

export default HighSchoolDetailsPanelForm;
