import { defined } from 'lib/helpers';

class DurationUnit {
  constructor(value) {
    const expectedValues = [
      null,
      DurationUnit.DAY,
      DurationUnit.WEEK,
      DurationUnit.MONTH,
      DurationUnit.YEAR,
      DurationUnit.DAY.toString(),
      DurationUnit.WEEK.toString(),
      DurationUnit.MONTH.toString(),
      DurationUnit.YEAR.toString(),
    ];

    if (expectedValues.indexOf(defined(value)) === -1) {
      throw Error('Unexpected value');
    }

    this.value = (value === null ? null : parseInt(value, 10));
  }

  toApiFormat() {
    switch(this.value) {
      case DurationUnit.DAY:   return 'DAY';
      case DurationUnit.WEEK:  return 'WEEK';
      case DurationUnit.MONTH: return 'MONTH';
      case DurationUnit.YEAR:  return 'YEAR';
      default:                 throw Error('Unexpected value');
    }
  }

  static fromApiFormat(data) {
    switch(data) {
      case 'DAY':   return new DurationUnit(DurationUnit.DAY);
      case 'WEEK':  return new DurationUnit(DurationUnit.WEEK);
      case 'MONTH': return new DurationUnit(DurationUnit.MONTH);
      case 'YEAR':  return new DurationUnit(DurationUnit.YEAR);
      default:      throw Error('Unexpected value');
    }
  }
}

export default DurationUnit;

DurationUnit.DAY   = 0;
DurationUnit.WEEK  = 1;
DurationUnit.MONTH = 2;
DurationUnit.YEAR  = 3;
