import './index.scss';
import React from 'react';

import { LandingPageTextContent }    from 'components/v2/landing-page-text-content';
import { LandingPageContentArtwork } from 'components/v2/landing-page-content-artwork';

export const LandingPageContentLayout = () => {
  return (
    <div className = "landing-page-content-layout">
      <LandingPageTextContent />
      <LandingPageContentArtwork />
    </div>
  );
};
