import './index.scss';

import React   from 'react';
import Cookies from 'cookies-js';
import config  from 'config';

import { DottedUnderlineButton } from 'component-lib/components/dotted-underline-button';

let _banner = null;

const AccessAllExperience = ({ profile }) => {
  const BANNER_COOKIE_NAME = 'public-profile-banner-dismissed';

  const profileId = profile.get('id');
  const firstName = profile.get('fullName').split(' ')[0];

  const getBannerCookie = () => {
    const value = Cookies.get(BANNER_COOKIE_NAME);
    return (typeof value === 'undefined' ? [] : JSON.parse(value));
  };

  const setBannerCookie = (value) => {
    return Cookies.set(BANNER_COOKIE_NAME, JSON.stringify(value));
  };

  const onDismissClick = (e) => {
    const dismissedProfileIds = getBannerCookie();

    e.preventDefault();

    setBannerCookie(dismissedProfileIds.concat([profileId]));
    _banner.classList.add('v2-access-all-experience--collapse');
  };

  if (getBannerCookie().indexOf(profileId) > -1) {
    return null;
  }

  return (
    <div ref = { (elem) => { _banner = elem; } } className = "v2-access-all-experience">
      <div className = "v2-access-all-experience__link-close-wrapper">
        <div className = "v2-access-all-experience__link-close">
          <DottedUnderlineButton
            copy    = "Dismiss"
            color   = "new-gray"
            onClick = { onDismissClick }
          />
        </div>
      </div>

      <div className = "v2-access-all-experience__content">
        <h4 className = "v2-access-all-experience__heading">
          { `Access all of ${firstName}’s experience!` }
        </h4>

        <div className = "v2-access-all-experience__subheading">
          Paddl Profiles capture valuable experience unseen on other services providing unique insight.
        </div>

        <a href = { config.SIGN_UP_PAGE_URL }>
          <button className = "v2-access-all-experience__btn v2-access-all-experience__btn--solid" type = "submit">
            Sign Up Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default AccessAllExperience;
