import './index.scss';
import React from 'react';

import Text from 'component-lib/type/text';

import { CompleteCircle }   from 'components/v2/activities-progress-circle/complete';
import { InProgressCircle } from 'components/v2/activities-progress-circle/in-progress';
import { IncompleteCircle } from 'components/v2/activities-progress-circle/incomplete';

import {
  style16pxRegularGreen,
  style16pxRegularBlueL,
  style16pxRegularGreyL,
} from 'component-lib/type/text/styles';

export const TimelineProgressStatus = ({ percentageComplete, copy, progressState }) => {
  const getIconContent = () => {
    switch (progressState) {
      case 'complete': return <CompleteCircle />;
      case 'in-progress': return <InProgressCircle percentageComplete = { percentageComplete || 75 } />;
      case 'incomplete': return <IncompleteCircle percentageComplete = { percentageComplete } />;
      default: throw Error(`Unexpected progressState: ${progressState}`);
    }
  };

  const getCopyStyle = () => {
    switch (progressState) {
      case 'complete': return style16pxRegularGreen;
      case 'in-progress': return style16pxRegularBlueL;
      case 'incomplete': return style16pxRegularGreyL;
      default: throw Error(`Unexpected progressState: ${progressState}`);
    }
  };

  return (
    <div className = "timeline-progress-status">
      <div className = "timeline-progress-status__icon">
        { getIconContent() }
      </div>
      <Text { ...getCopyStyle() } text = { copy } />
    </div>
  );
};
