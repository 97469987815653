import DurationUnit from 'lib/api/activities/generics/duration-value-unit/duration-unit';
import pluralize    from 'pluralize';

export const durationValueOptions =
  Array.from(Array(30).keys())
    .map((num) => {
      const value = num + 1;

      return {
        value: value,
        title: value,
      };
    });

export const fetchDurationUnitOptions = ({ durationValue }) => {
  const durationUnitOptions = [
    {
      value: DurationUnit.DAY,
      title: 'Day',
    },
    {
      value: DurationUnit.WEEK,
      title: 'Week',
    },
    {
      value: DurationUnit.MONTH,
      title: 'Month',
    },
    {
      value: DurationUnit.YEAR,
      title: 'Year',
    },
  ];

  return (
    durationUnitOptions.map(({ value, title }) => (
      {
        value: value,
        title: pluralize(title, durationValue, false),
      }
    ))
  );
};
