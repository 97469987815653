import { defined } from 'lib/helpers';

class VisaStatus {
  constructor(value) {
    const expectedValues = [
      null,
      VisaStatus.CITIZEN,
      VisaStatus.RESIDENT,
      VisaStatus.WORKER,
      VisaStatus.STUDENT,
      VisaStatus.TOURIST,
      VisaStatus.SPOUSE,
      VisaStatus.OTHER,
    ];

    if (expectedValues.indexOf(defined(value)) === -1) {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === null);
  }

  toApiFormat() {
    switch(this.value) {
      case VisaStatus.CITIZEN:  return 'OPTION_CITIZEN';
      case VisaStatus.RESIDENT: return 'OPTION_RESIDENT';
      case VisaStatus.WORKER:   return 'OPTION_WORKER';
      case VisaStatus.STUDENT:  return 'OPTION_STUDENT';
      case VisaStatus.TOURIST:  return 'OPTION_TOURIST';
      case VisaStatus.SPOUSE:   return 'OPTION_SPOUSE';
      case VisaStatus.OTHER:    return 'OPTION_OTHER';
      case null:                return null;
      default:                  throw Error('Unexpected value');
    }
  }

  static fromApiFormat(data) {
    switch(data) {
      case 'OPTION_CITIZEN':  return new VisaStatus(VisaStatus.CITIZEN);
      case 'OPTION_RESIDENT': return new VisaStatus(VisaStatus.RESIDENT);
      case 'OPTION_WORKER':   return new VisaStatus(VisaStatus.WORKER);
      case 'OPTION_STUDENT':  return new VisaStatus(VisaStatus.STUDENT);
      case 'OPTION_TOURIST':  return new VisaStatus(VisaStatus.TOURIST);
      case 'OPTION_SPOUSE':   return new VisaStatus(VisaStatus.SPOUSE);
      case 'OPTION_OTHER':    return new VisaStatus(VisaStatus.OTHER);
      case null:              return new VisaStatus(null);
      default:                throw Error('Unexpected value');
    }
  }

  static createEmpty() {
    return new VisaStatus(null);
  }
}

export default VisaStatus;

VisaStatus.CITIZEN  = "OPT-CITIZEN";
VisaStatus.RESIDENT = "OPT-RESIDENT";
VisaStatus.WORKER   = "OPT-WORKER";
VisaStatus.STUDENT  = "OPT-STUDENT";
VisaStatus.TOURIST  = "OPT-TOURIST";
VisaStatus.SPOUSE   = "OPT-SPOUSE";
VisaStatus.OTHER    = "OPT-OTHER";
