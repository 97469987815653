import { defined } from 'lib/helpers';

const validateNumericValue = (value) => {
  if (isNaN(value) || value < 0 || value > 100) {
    throw Error('Unexpected value');
  }
};

class PercentageComplete {
  constructor(value) {
    const intValue = parseInt(defined(value), 10);
    validateNumericValue(intValue);
    this.value = intValue;
  }

  toApiFormat() {
    return this.value;
  }

  static calculate({ startYear, startMonth, startDay, endYear, endMonth, endDay }) {
    if (startYear === null
      || startMonth === null
      || startDay === null
      || endYear === null
      || endMonth === null
      || endDay === null) {
      return new PercentageComplete(0);
    }

    const start = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);
    // If an event starts on a specific day, people assume it starts at the first second of the day.
    // It's better to be explicit so the time is specified as 00:00:00 here.

    const end = new Date(endYear, endMonth - 1, endDay, 23, 59, 59);
    // If an event ends on a specific day, people assume it ends at the last second of the day instead of the first second.
    // And `new Date(endYear, endMonth - 1, endDay)` will generate a datetime at 00:00:00 of the day
    // which is the first second of the day and is not what we want,
    // so it's needed to specify the time as 23:59:59 here.

    const now = new Date(Date.now());

    if (now < start) {
      return new PercentageComplete(0);
    }

    if (now > end) {
      return new PercentageComplete(100);
    }

    const real = (now - start)/(end - start);

    return new PercentageComplete(parseInt(real * 100, 10));
  }
}

export default PercentageComplete;
