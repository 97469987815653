import './index.scss';
import React from 'react';

const DetailsPanelEmpty = () => {
  return (
    <div className = "v2-details-panel-empty">
      <div className = "columns is-mobile is-vcentered">
        <div className = "column v2-details-panel-empty__column-icon">
          <div className = "v2-details-panel-empty__icon-wrapper">
            <img
              className = "v2-details-panel-empty__icon-static"
              src = { require('images/v2/xp/activity-details.svg') }
            />
            <img
              className = "v2-details-panel-empty__icon-hover"
              src = { require('images/v2/xp/activity-details-hover.svg') }
            />
          </div>
        </div>
        <div className = "column">
          <div className = "v2-details-panel-empty__title">
            Details
          </div>
          <div className = "v2-details-panel-empty__subtitle">
            Add details for this activity ...
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPanelEmpty;
