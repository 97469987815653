import { defined } from 'lib/helpers';

import DurationIndefiniteDayMonthYear from 'lib/api/activities/generics/duration-indefinite-day-month-year';
import Year                           from 'lib/api/activities/generics/duration-common/year';
import Month                          from 'lib/api/activities/generics/duration-common/month';
import Day                            from 'lib/api/activities/generics/duration-common/day';
import Bool                           from 'lib/api/activities/generics/fields/bool';

class IndefiniteDurationData {
  constructor({ state, appv2 }) {
    this._ourState = defined(state);
    this._appv2    = defined(appv2);

    this.isEqualToFields = ({ data }) => {
      const currentDuration = this._ourState.get({ id: 'duration' });

      if (data.duration !== currentDuration) {
        return false;
      }

      return true;
    };

    this.toActivityDuration = () => {
      return this._ourState.get({ id: 'duration' });
    };

    this.getDuration = () => {
      const duration = this._ourState.get({ id: 'duration' });
      const { startYear, startMonth, startDay, endYear, endMonth, endDay, isCurrent } = duration;

      return {
        startYear:  startYear  && startYear.value,
        startMonth: startMonth && startMonth.value,
        startDay:   startDay   && startDay.value,
        endYear:    endYear    && endYear.value,
        endMonth:   endMonth   && endMonth.value,
        endDay:     endDay     && endDay.value,
        isCurrent:  isCurrent  && isCurrent.value,
      };
    };

    this.setDuration = ({ startYear, startMonth, startDay, endYear, endMonth, endDay, isCurrent }) => {
      this._ourState.change({
        duration: new DurationIndefiniteDayMonthYear({
          startYear:  new Year(startYear),
          startMonth: new Month(startMonth),
          startDay:   new Day(startDay),
          endYear:    new Year(endYear),
          endMonth:   new Month(endMonth),
          endDay:     new Day(endDay),
          isCurrent:  new Bool(isCurrent),
        }),
      });
    };

    this.isEverythingEmpty = () => {
      return this._ourState.get({ id: 'duration' }).isEmpty();
    };

    this.isValid = () => {
      return this._ourState.get({ id: 'duration' }).isValid();
    };
  }

  static createEmptyFields() {
    return {
      duration: DurationIndefiniteDayMonthYear.createEmpty(),
    };
  }

  static fieldsFromActivityDuration({ activityDuration }) {
    return {
      duration: activityDuration,
    };
  }
}

export default IndefiniteDurationData;
