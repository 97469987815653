import { defined } from 'lib/helpers';

class IdNameObject {
  constructor({ id, name }) {
    if (typeof defined(name) !== 'string') {
      throw Error('Unexpected value');
    }

    this.id   = defined(id);
    this.name = name;
  }

  toApiFormat() {
    return {
      id:   this.id,
      name: this.name,
    };
  }

  isEmpty() {
    return (this.name === '');
  }

  isValid() {
    return (this.name !== '');
  }

  static createEmpty() {
    return new IdNameObject({ id: null, name: '' });
  }

  static fromApiFormat(data) {
    return new IdNameObject(data);
  }
}

export default IdNameObject;
