import React from 'react';

import './index.scss';

export const NavbarBlogButton = () => (
  <a
    className="navbar-button__btn navbar-button__btn--text-only"
    href="https://blog.paddl.com/"
    target="_blank"
    rel="noreferrer noopener"
  >
    Blog
  </a>
);
