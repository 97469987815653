import './index.scss';
import React from 'react';
import pluralize from 'pluralize';

import Text           from 'component-lib/type/text';
import InProgressIcon from 'component-lib/svg-components/in-progress-icon';
import CompletedIcon  from 'component-lib/svg-components/completed-icon';
import CollectedIcon  from 'component-lib/svg-components/collected-icon';

import {
  COOLDOWN,
  READY,
  COLLECTED,
} from 'component-lib/components/activity-card-content';

export const XpCollectionIconAndCopy = ({
  cooldownMax,
  cooldownLeft,
  state,
}) => {
  const calcCooldownLeftCopy = () => {
    const MINS_PER_HOUR = 60;
    const HOURS_PER_DAY = 24;

    const numberOfMins  = Math.floor(cooldownLeft % MINS_PER_HOUR);
    const numberOfHours = Math.floor((cooldownLeft / MINS_PER_HOUR) % HOURS_PER_DAY);
    const numberOfDays  = Math.floor((cooldownLeft / MINS_PER_HOUR) / HOURS_PER_DAY);

    const copyOfMins  = pluralize('min', numberOfMins, true).replace(' ', '');
    const copyOfHours = pluralize('hr', numberOfHours, true).replace(' ', '');
    const copyOfDays  = pluralize('day', numberOfDays, true).replace(' ', '');

    if (numberOfDays === 0 && numberOfHours === 0) {
      return copyOfMins;
    }

    if (numberOfDays === 0) {
      return `${copyOfHours} ${copyOfMins}`;
    }

    return `${copyOfDays} ${copyOfHours}`;
  };

  const fractionElapsed = (cooldownMax - cooldownLeft) / cooldownMax;

  const renderIcon = () => {
    switch (state) {
    case COOLDOWN:  return <InProgressIcon fractionElapsed = { fractionElapsed } />;
    case READY:     return <CompletedIcon />;
    case COLLECTED: return <CollectedIcon />;
    default:        throw Error(`Unexpected state: ${state}`);
    }
  };

  const renderCopyColor = () => {
    switch (state) {
    case COOLDOWN:  return 'blue';
    case READY:     return 'green';
    case COLLECTED: return 'bluegrey';
    default:        throw Error(`Unexpected state: ${state}`);
    }
  };

  const renderCopy = () => {
    switch (state) {
    case COOLDOWN:  return calcCooldownLeftCopy();
    case READY:     return 'Ready!';
    case COLLECTED: return 'Collected';
    default:        throw Error(`Unexpected state: ${state}`);
    }
  };

  return (
    <div className = "xp-collection-icon-and-copy">
      <div className = "xp-collection-icon-and-copy__icon">
        { renderIcon() }
      </div>
      <div className = "xp-collection-icon-and-copy__copy">
        <Text
          fontSize   = "14px"
          fontWeight = "regular"
          color      = { renderCopyColor() }
          text       = { renderCopy() }
        />
      </div>
    </div>
  );
};
