import './index.scss';
import React from 'react';

import DisabledDurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form/disabled';
import { FormFooter } from 'components/v2/form-footer';

export class ModalProgressPanelFormViewContent extends React.Component {
  render() {
    const { activity, closeFunction } = this.props;

    const duration = activity.get('duration');

    const percentageComplete = duration.percentageComplete && duration.percentageComplete.value;
    const startYear          = duration.startYear          && duration.startYear.value;
    const startMonth         = duration.startMonth         && duration.startMonth.value;
    const startDay           = duration.startDay           && duration.startDay.value;
    const endYear            = duration.endYear            && duration.endYear.value;
    const endMonth           = duration.endMonth           && duration.endMonth.value;
    const endDay             = duration.endDay             && duration.endDay.value;

    const startDate = new Date(startYear, startMonth - 1, startDay);
    const endDate   = new Date(endYear,   endMonth - 1,   endDay);

    return (
      <div className = "v2-modal-progress-panel-form-view-content">
        <div className = "v2-modal-progress-panel-form-view-content__container">
          <DisabledDurationPanelForm
            startDate          = { startDate }
            endDate            = { endDate }
            percentageComplete = { percentageComplete }
          />
          <FormFooter
            isSubmitDisabled
            customSubmitCopy = "Save & Close"
            onCancel = { closeFunction }
          />
        </div>
      </div>
    );
  }
}
