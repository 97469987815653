import './index.scss';
import React from 'react';

import { Artwork } from 'component-lib/components/artwork';

export const ArtworkSection = ({ artworks, selectedId, onSelect }) => {
  return (
    <div className = "v2-artworks-section">
      {
        artworks.map(artwork => {
          return (
            <Artwork
              key = { artwork.id }
              url = { artwork.url }
              onClick = { () => { onSelect(artwork.id); } }
              isSelected = { artwork.id === selectedId }
            />
          );
        })
      }
    </div>
  );
};
