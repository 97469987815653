import './index.scss';
import React from 'react';

class Select extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.selectInput.focus();
      }, 0);
    }
  }

  render() {
    const {
      name,
      value,
      label,
      options,
      message,
      isError,
      onChange,
      placeholder,
      inputFontSize,
      isReadonly,
      isAutoFocused,
    } = this.props;

    const renderMessage = ({ message, isError }) => {
      if (message === null) {
        return null;
      }

      return (
        <div className = { `v2-select__message ${isError ? 'v2-select__message--error' : ''}` }>
          { message }
        </div>
      );
    };

    const renderOptions = ({ options, placeholder }) => {
      if (options === null || options.length <= 0) {
        return <option value = "" hidden>No options found</option>;
      }

      return (
        [<option key = "" value = "" hidden>{ placeholder }</option>].concat(
          options.map(option =>
            <option key = { option.value } value = { option.value }>
              { option.title }
            </option>
          )
        )
      );
    };

    const blockClassName         = 'v2-select__select';
    const isErrorClassName       = isError                   ? `${blockClassName}--error`           : '';
    const inputFontSizeClassName = inputFontSize === "small" ? `${blockClassName}--small-font-size` : `${blockClassName}--default-font-size`;
    const className              = `${blockClassName} ${isErrorClassName} ${inputFontSizeClassName}`;

    return (
      <div className = "v2-select">
        <label className = "v2-select__label" htmlFor = { name }>
          { label }
        </label>
        <div className = "v2-select__wrapper">
          <select
            required
            ref       = { (realInputElementOnPage) => { this.selectInput = realInputElementOnPage; } }
            value     = { value !== null ? value : '' }
            name      = { name }
            className = { className }
            onChange  = { (event) => { onChange(event.target.value); } }
            disabled  = { isReadonly ? "disabled" : false }
          >
            { renderOptions({ options, placeholder }) }
          </select>
        </div>
        { renderMessage({ message, isError }) }
      </div>
    );
  }
};

export default Select;
