import './index.scss';
import React from 'react';

import ImgTag                    from 'component-lib/components/img-tag';
import Text                      from 'component-lib/type/text';
import { style20pxRegularWhite } from 'component-lib/type/text/styles';

export const VoteForNewCard = () => {
  const productPortalUrl = 'https://portal.productboard.com/xw87v7jxrrjqoxozbcd8awmx/tabs/2-under-consideration';

  return (
    <div className = "v2-vote-for-new-card">
      <Text { ...style20pxRegularWhite }
        text = "Vote for New Cards"
      />
      <div className = "v2-vote-for-new-card__content-with-shadow">
        <div className = "v2-vote-for-new-card__content-wrapper">
          <a href= { productPortalUrl } className="v2-vote-for-new-card__card-vote-link-button" target="_blank">
            <div className = "v2-vote-for-new-card__card">
              <div className = "v2-vote-for-new-card__vote-image-and-copy">
                <div className = "v2-vote-for-new-card__vote-image">
                  <ImgTag
                    src = { require(`components/svg-icon/svgs/vote.svg`) }
                  />
                </div>
                <div className = "v2-vote-for-new-card__copy">
                  <p>Can't find an Activity you are looking for?&nbsp;
                    <span className = "v2-vote-for-new-card__copy-link-text">Vote for New Cards on our&nbsp;
                      <span className="v2-vote-for-new-card__card-vote-link">Product Portal.</span>
                    </span>
                  </p>
                </div>
              </div>
              <div className = "v2-vote-for-new-card__activity-card-image">
                <ImgTag
                  src = { require('components/svg-icon/svgs/vote-activity-cards.svg') }
                />
              </div>
            </div>
          </a>
        </div>
        <div className = "v2-vote-for-new-card__shadow">
        </div>
      </div>
    </div>
  );
};
