import { defined } from 'lib/helpers';

class Subjects {
  constructor(value) {
    if (typeof value !== 'string') {
      throw Error(`${value} is not a string`);
    }

    this.value = value;
  }

  getAsList() {
    return this.value
      .split(',')
      .map(e => e.trim().replace(/\s+/, ' '))
      .filter(e => (e.length > 0));
  }

  toApiFormat() {
    return this.getAsList().map(e => ({ id: null, name: e }));
  }

  isEmpty() {
    return (this.getAsList().length === 0);
  }

  isValid() {
    return (this.getAsList().length > 0);
  }

  static createEmpty() {
    return new Subjects('');
  }

  static fromApiFormat(data) {
    return new Subjects(data.map(e => e.name).join(', '));
  }
}

export default Subjects;
