import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import IdNameObject      from 'lib/api/activities/generics/fields/id-name-object';
import Text              from 'lib/api/activities/generics/fields/text';
import CertificateType   from 'lib/api/activities/categories/education/fields/certificate-type';
import DurationValueUnit from 'lib/api/activities/generics/duration-value-unit';
import Commitment        from 'lib/api/activities/categories/education/fields/commitment';
import Bool              from 'lib/api/activities/generics/fields/bool';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/education/variants/certificate/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/education/variants/certificate/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/education/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form';

class Certificate {
  static categoryCssClassPrefix() {
    return 'v2-categories-education-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-education-certificate.svg');
  }

  static title() {
    return 'Certificate';
  }

  static subtitle() {
    return 'Official, certified course or unit.';
  }

  static detailsAttributes() {
    const fetchAuthoritiesSuggestions             = ({ appv2 }) => { return appv2.api.education.fetchAuthoritiesSuggestions.bind(appv2.api.education);             };
    const fetchCertificateCertificatesSuggestions = ({ appv2 }) => { return appv2.api.education.fetchCertificateCertificatesSuggestions.bind(appv2.api.education); };

    return {
      authority:           { required: true,  dataType: IdNameObject,      fieldLabel: 'Authority',        fieldPlaceholder: 'e.g. Bar School',                     suggestionsFunction: fetchAuthoritiesSuggestions             },
      certificate:         { required: true,  dataType: IdNameObject,      fieldLabel: 'Certificate Name', fieldPlaceholder: 'e.g. Responsible Serving of Alcohol', suggestionsFunction: fetchCertificateCertificatesSuggestions },
      location:            { required: true,  dataType: Text,              fieldLabel: 'Location',         fieldPlaceholder: 'Select Country'                },
      certificateType:     { required: true,  dataType: CertificateType,   fieldLabel: 'Certificate Type', fieldPlaceholder: 'Select Type'                   },
      certificateDuration: { required: true,  dataType: DurationValueUnit, fieldLabel: 'Course Duration',  fieldPlaceholder: { value: '00', unit: 'Select' } },
      commitment:          { required: true,  dataType: Commitment,        fieldLabel: 'Commitment',       fieldPlaceholder: 'Select Type'                   },
      attendanceOnline:    { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
      attendanceInPerson:  { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: details.get('certificate').name,
      activityProviderName: details.get('authority').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({
      attributes: this.detailsAttributes(),
      getDurationObjectFn: (detailsData) => {
        return detailsData.getField('certificateDuration');
      },
    });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default Certificate;
