import './index.scss';
import React           from 'react';
import { describeArc } from 'lib/helpers';

const InProgressIcon = ({ fractionElapsed }) => {
  const [cx, cy, r, sw, handLen] = [32, 32, 30, 4, 18];
  const arcAttrD = describeArc(cx, cy, r, 0, 360 * fractionElapsed);

  return (
    <svg className="in-progress-icon" width="56" height="56" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx = { cx } cy = { cy } r = { r } stroke = "#EAF4F7" strokeWidth = { sw } fill = "none" />
      <path id = "arc" fill = "none" stroke = "#6BC3FC" strokeWidth = { sw } d = { arcAttrD } />
      <line className = "in-progress-icon__hand" x1 = { cx } y1 = { cy } x2 = { cx + handLen } y2 = { cy } stroke = "#B1E5FC" strokeWidth = "3" strokeLinecap = "round" />
    </svg>
  );
};

export default InProgressIcon;
