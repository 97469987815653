import { defined }        from 'lib/helpers';
import { browserHistory } from 'react-router';

import ModalSkillsPanelFormView      from 'components/v2/modal-skills-panel-form-view';
import ModalProgressPanelFormView    from 'components/v2/modal-progress-panel-form-view';
import { ModalNewActivity }          from 'components/v2/modal-new-activity';
import { ModalExistingActivity }     from 'components/v2/modal-existing-activity';
import { ModalShareProfile }         from 'components/v2/modal-share-profile';
import { ModalSidenavCompany }       from 'components/v2/modal-sidenav-company';
import { ModalSidenavSupport }       from 'components/v2/modal-sidenav-support';
import { ModalSidenavLogout }        from 'components/v2/modal-sidenav-logout';
import { ModalProgress }             from 'components/v2/modal-progress';
import { ModalHeroBannerImage }      from 'components/v2/modal-hero-banner-image';
import { ModalAfterActivityUpdates } from 'components/v2/modal-after-activity-updates';

import { DURATION } from 'components/v2/activities/generics/card/existing';

class UI {
  constructor({ store, apiTransport, v1deps }) {
    this._store = defined(store);
    this._transport = defined(apiTransport);

    this.storeUxEvent = ({ k, v }) => {
      const args = {
        endpoint: '/ux-events',
        method:   'POST',
        data: {
          k: defined(k),
          v: defined(v),
        },
      };

      return this._transport.makeRequest(args);
    };

    this.refreshPage = () => {
      window.location.reload();
    };

    this.redirect = ({ url }) => {
      browserHistory.push(defined(url));
    };

    this.setModalHandlers = ({ open, close }) => {
      this._openModal = defined(open);
      this.closeModal = defined(close);
    };

    this.openNewActivityModal = ({ withLeftOffset, variantId }) => {
      this._openModal({
        modalComponent: ModalNewActivity,
        modalProps: {
          withLeftOffset,
          variantId,
        },
      });
    };

    this.openExistingActivityModal = ({ activityId, variantId }) => {
      this._openModal({
        modalComponent: ModalExistingActivity,
        modalProps: {
          activityId: defined(activityId),
          variantId:  defined(variantId),
        },
      });
    };

    this.openExistingActivityModalWithDuration = ({ activityId, variantId }) => {
      this._openModal({
        modalComponent: ModalExistingActivity,
        modalProps: {
          activityId: defined(activityId),
          variantId:  defined(variantId),
          openSection: DURATION,
        },
      });
    };

    this.openSkillsPanelFormViewModal = ({ activity }) => {
      this._openModal({
        modalComponent: ModalSkillsPanelFormView,
        modalProps: {
          activity: defined(activity),
        },
      });
    };

    this.openProgressPanelFormViewModal = ({ activity }) => {
      this._openModal({
        modalComponent: ModalProgressPanelFormView,
        modalProps: {
          activity: defined(activity),
        },
      });
    };

    this.openShareProfileModal = ({ withLeftOffset, isProfileOwner, profile }) => {
      this._openModal({
        modalComponent: ModalShareProfile,
        modalProps: {
          withLeftOffset,
          isProfileOwner,
          profile,
        },
      });
    };

    this.openSidenavCompanyModal = ({ withLeftOffset }) => {
      this._openModal({
        modalComponent: ModalSidenavCompany,
        modalProps: {
          withLeftOffset,
        },
      });
    };

    this.openSidenavSupportModal = ({ withLeftOffset }) => {
      this._openModal({
        modalComponent: ModalSidenavSupport,
        modalProps: {
          withLeftOffset,
        },
      });
    };

    this.openSidenavLogoutModal = ({ withLeftOffset, avatarImage, fullName }) => {
      this._openModal({
        modalComponent: ModalSidenavLogout,
        modalProps: {
          withLeftOffset,
          avatarImage,
          fullName,
        },
      });
    };

    this.openProgressModal = ({ activityId, basecampRefreshFunction }) => {
      this._openModal({
        modalComponent: ModalProgress,
        modalProps: {
          activityId,
          basecampRefreshFunction,
        },
      });
    };

    this.openHeroBannerImageModal = ({ withLeftOffset, onImageUploaded }) => {
      this._openModal({
        modalComponent: ModalHeroBannerImage,
        modalProps: {
          withLeftOffset,
          onImageUploaded,
        },
      });
    };

    this.openAfterActivityUpdatesModal = ({ xpNumber, onClick, events, activityId, isVariantUnlocked }) => {
      this._openModal({
        modalComponent: ModalAfterActivityUpdates,
        modalProps: { xpNumber, onClick, events, activityId, isVariantUnlocked },
      });
    };

    this.toggleLegacyAvatarUploader = () => {
      this._store.dispatch(v1deps['modules/ui'].toggleAvatarUploader());
    };

    this.setSnackBarHandlers = ({ show, hide }) => {
      this._showSnackBar = defined(show);
      this._hideSnackBar = defined(hide);
    };

    this.displaySnackBar = ({ style, text, callback = null }) => {
      this._showSnackBar({ style, text });

      setTimeout(() => {
        this._hideSnackBar();
        callback && callback();
      }, 2000);
    };
  }
}

export default UI;
