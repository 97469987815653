import './index.scss';
import React from 'react';

import { urlToBeShared } from 'lib/helpers';
import Text              from 'components/input-text';

class UrlCopier extends React.Component {
  constructor() {
    super();

    this.state = {
      isCopied: false
    };
  }

  handleCopy() {
    this.input.select();

    document.execCommand('copy');

    this.props.sendEvent();

    this.setState({
      isCopied: true
    });

    setTimeout(() => {
      this.setState({
        isCopied: false
      });
    }, 2000);
  }

  render() {
    const { isCopied } = this.state;

    const className   = `url-copier__description ${isCopied ? '' : 'url-copier__description--cta'}`;
    const onClick     = isCopied ? null : () => { this.handleCopy() };
    const description = isCopied ? 'Copied!' : 'Click to copy';

    return (
      <div className = "url-copier">
        <Text
          type         = "text"
          name         = "url"
          defaultValue = { urlToBeShared() }
          size         = { urlToBeShared().length }
          setRef       = { el => this.input = el }
          onFocus      = { e => e.target.select() }
          readOnly     = { true }
          style        = { {
            maxWidth:   'calc(375px - 16px - 16px)',
            textAlign:  'center',
            fontSize:   '16px',
            fontWeight: '400',
            color:      '#124f71'
          } }
        />
        <div className = { className } onClick = { onClick }>
          { description }
        </div>
      </div>
    );
  }
}

export default UrlCopier;
