import { defined } from 'lib/helpers';

// This list has to be in-sync with the back end data.
const KNOWN_EVENTS = [
  {
    id:             1,
    name:           'Canon Hack Challenge',
    location:       'Sydney AUS',
    hostCompany: {
      title:        'Canon Australia',
      id:           3440,
      icon:         'https://view.paddljobs.com/3440/zbj9x59je1_avatar',
    },
    challengeFocus: `The first ever Paddl Games Challenge located at Canon Australia HQ, where 30 participants in 5 teams collaborated with 10 Canon Team Members to consider: "How can Canon reimagine servicing multifunctional devices for Canon Business clients?"`,
    duration: {
      startDate:    new Date(2017, 5 - 1, 19),
      endDate:      new Date(2017, 5 - 1, 20),
    },
    teamNames: [
      'Canonballs',
      'Flash',
      'Rebellious5',
      'Thrive',
      'Univators',
    ],
  },
  {
    id:             2,
    name:           'Metro Scratch Space Challenge',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Metro Trains Melbourne',
      id:           6683,
      icon:         'https://view.paddljobs.com/6683/zz4dl7rnlu_avatar_resized',
    },
    challengeFocus: `60 participants paired with Metro staff in 10 teams to explore; "ways in which Metro can improve the passenger journey during planned works and unplanned events." Teams presented their concepts to Metro board members and senior executives.`,
    duration: {
      startDate:    new Date(2017, 9 - 1, 6),
      endDate:      new Date(2017, 9 - 1, 6),
    },
    teamNames: [
      'Sunergos',
      'Track Team',
      'Y-Nots',
      'ChooChooChooseYou',
      'The Train of Thought',
      'The Railblazers',
      'All Aboard',
      'Melbourne Chargers',
      'Metro-Nator',
      'SmartTrain',
    ],
  },
  {
    id:             3,
    name:           'ABC Hack Challenge',
    location:       'Sydney AUS',
    hostCompany: {
      title:        'Australian Broadcasting Company',
      id:           6582,
      icon:         'https://view.paddljobs.com/6582/ph87vtuhcc_avatar_resized',
    },
    challengeFocus: `62 participants together with ABC employees in 10 teams explored; "digital ideas and solutions to drive ABC's future growth." Opened by Tom Tilley of Triple J, teams presented their concepts to ABC senior executives in their bid to win the day.`,
    duration: {
      startDate:    new Date(2017, 12 - 1, 1),
      endDate:      new Date(2017, 12 - 1, 1),
    },
    teamNames: [
      'Wenom',
      'Two To Tango',
      'Talented Tech Team',
      'ABC DEF',
      'Makeshifters',
      'Audience Bring Credit',
      'Apollo 7',
      'Unicast',
      'Audience Whisperers',
      'Imagination Station',
    ],
  },
  {
    id:             4,
    name:           'Metro Skunkworks Project',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Metro Trains Melbourne',
      id:           6683,
      icon:         'https://view.paddljobs.com/6683/zz4dl7rnlu_avatar_resized',
    },
    challengeFocus: `45 participants from the 3 winning teams in the Scratch Space Challenge collaborated with Metro staff and innovation professionals to test the market viability of their winning concepts targeting passenger safety and convenience.`,
    duration: {
      startDate:    new Date(2017, 11 - 1, 1),
      endDate:      new Date(2017, 11 - 1, 30),
    },
    teamNames: [
      'On Track',
      'Innovatica',
      'Toward Zero',
    ],
  },
  {
    id:             5,
    name:           'Metro Chatsafe Trial',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Metro Trains Melbourne',
      id:           6683,
      icon:         'https://view.paddljobs.com/6683/zz4dl7rnlu_avatar_resized',
    },
    challengeFocus: `Pre roll out user testing of a "Personal Safety App" concept developed by a professional innovation team and supported by 10 Paddl interns.`,
    duration: {
      startDate:    new Date(2018,  6 - 1, 27),
      endDate:      new Date(2018, 11 - 1, 29),
    },
    teamNames: [
      'Trial User',
    ],
  },
  {
    id:             6,
    name:           'Metro Chatsafe Innovation Team',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Metro Trains Melbourne',
      id:           6683,
      icon:         'https://view.paddljobs.com/6683/zz4dl7rnlu_avatar_resized',
    },
    challengeFocus: `10 interns selected from over 800 applicants were chosen to support a professional innovation team during the development of a "Personal Safety App" concept originally conceived during a Paddl Games Challenge.`,
    duration: {
      startDate:    new Date(2018,  6 - 1, 21),
      endDate:      new Date(2018, 11 - 1, 29),
    },
    teamNames: [
      'Project Coordination',
      'Communications',
      'UX / UI',
      'Data Analytics',
    ],
  },
  {
    id:             7,
    name:           'COSBOA Innovation Games - Day 1',
    location:       'Hastings AUS',
    hostCompany: {
      title:        'AGL Energy',
      id:           12931,
      icon:         'https://view.paddljobs.com/12931/rrptq26okk_avatar_resized',
    },
    challengeFocus: `The first of 5 Game Days supported by the Victorian Government. 60 participants paired with 10 small businesses local to the Hastings area and AGL staff explored; "the efficient use of energy and an introduction to renewables."`,
    duration: {
      startDate:    new Date(2018, 9 - 1, 6),
      endDate:      new Date(2018, 9 - 1, 6),
    },
    teamNames: [
      'The Fast and the Curious',
      'Better Read than Dead',
      'The Optimists',
      'The Healthy Hackers',
      'Proper Tea',
      'The Biala Stars',
      'Grape Ideas',
      'The Know It Ale\'s',
      'Brainy Folkers',
      'Elite Genies',
    ],
  },
  {
    id:             8,
    name:           'COSBOA Innovation Games - Day 2',
    location:       'Bendigo AUS',
    hostCompany: {
      title:        'Australia Post',
      id:           13081,
      icon:         'https://view.paddljobs.com/13081/bdxvip4ez5l_avatar_resized',
    },
    challengeFocus: `60 participants from 10 major educators were selected and placed in 10 teams together with local small businesses from the Bendigo region and Australia Post employees to explore: "How businesses can grow domestically and internationally using digital tools."`,
    duration: {
      startDate:    new Date(2018, 9 - 1, 12),
      endDate:      new Date(2018, 9 - 1, 12),
    },
    teamNames: [
      'InnAte',
      'Beak Performance',
      'Bring it Home',
      'Fourth Dimension',
      'Beeping for the Win',
      'Pushing the Envelope',
      'Empower Rangers',
      'Digital Hitsquad',
      'Petrol Heads',
      'Back in Business',
    ],
  },
  {
    id:             9,
    name:           'Blakes Feast Skills Challenge',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Blakes Feast',
      id:           13065,
      icon:         'https://view.paddljobs.com/13065/4e9yz65lb5g_avatar_resized',
    },
    challengeFocus: `Three interactive challenges designed to test the skills required for representing one of Melbourne's top catering companies. Participants were briefed and judged by Blakes Feast staff in an environment designed to emulate The Spring Racing Carnival.`,
    duration: {
      startDate:    new Date(2018, 10 - 1, 2),
      endDate:      new Date(2018, 10 - 1, 2),
    },
    teamNames: [
      'Session 1',
      'Session 2',
      'Session 3',
      'Session 4',
    ],
  },
  {
    id:             10,
    name:           'Graduate Program Assessment Challenge',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Australia Post',
      id:           13081,
      icon:         'https://view.paddljobs.com/13081/bdxvip4ez5l_avatar_resized',
    },
    challengeFocus: `42 selected candidates working with Australia Post executives and managers to explore; "how to make the Post Office more relevant in a local community." Top performers would be considered for the 2019 Australia Post Graduate Program.`,
    duration: {
      startDate:    new Date(2018, 9 - 1, 25),
      endDate:      new Date(2018, 9 - 1, 25),
    },
    teamNames: [
      'Team 1',
      'Team 2',
      'Team 3',
      'Team 4',
      'Team 5',
      'Team 6',
    ],
  },
  {
    id:             11,
    name:           'COSBOA Innovation Games - Day 3',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Canon Australia',
      id:           3440,
      icon:         'https://view.paddljobs.com/3440/zbj9x59je1_avatar',
    },
    challengeFocus: `60 participants from 11 major Victorian educators were placed in 10 teams and paired with 10 small businesses and a Canon Australia employee to explore; "Staying ahead of the curve and accelerating your business to thrive in the digital world."`,
    duration: {
      startDate:    new Date(2018, 10 - 1, 11),
      endDate:      new Date(2018, 10 - 1, 11),
    },
    teamNames: [
      '50 Shades of Great',
      'Burning Candela',
      'High Risers',
      'Know Your DNA',
      'Officiency',
      'Partners in Grime',
      'Pharmacy Troopers',
      'PrintPros',
      'Sweet Relish',
      'Wheeling and Dealing',
    ],
  },
  {
    id:             12,
    name:           'Bunnings Paddl Games',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'Bunnings Group Limited',
      id:           14357,
      icon:         'https://view.paddljobs.com/14357/ov5qxt2ycv_avatar_resized',
    },
    challengeFocus: `60 participants from 13 local and interstate educators working with 20+ Bunnings Employees in a design thinking process to explore: "Innovating for Bunnings around the theme of engaging 18-28 year olds as consumers and employees of Bunnings."`,
    duration: {
      startDate:    new Date(2018, 12 - 1, 6),
      endDate:      new Date(2018, 12 - 1, 6),
    },
    teamNames: [
      'Stop! Hammer Time',
      'DIY Pie',
      'FunBuns',
      'Beat The Curve',
      'All About That Sizzle',
      'Blade Runners',
      'Do It Ourselves',
      'Bunny On The Loose',
      'Bunnovation',
      'Free Sausages Are Just The Beginning',
    ],
  },
  {
    id:             13,
    name:           'COSBOA Innovation Games - Day 4',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'GrowthOps',
      id:           15160,
      icon:         'https://view.paddljobs.com/15160/5hur3dt3nv_avatar_resized',
    },
    challengeFocus: `The 4th and biggest event in this series at the Melbourne Sports and Aquatic Centre. More than 140 participants and 11 teams ideating on how to "accelerate high impact growth" for their assigned business.`,
    duration: {
      startDate:    new Date(2019, 3 - 1, 13),
      endDate:      new Date(2019, 3 - 1, 13),
    },
    teamNames: [
      "Don't Panic It's Organic",
      'Zen Masters',
      'S(e)welling for Success',
      'Running with Scissors',
      'Prescribed to Win',
      'ImaGinnovAtors',
      'Hats Off',
      'Forza Gelato',
      'Hair We Go',
      'Twelve Aposties',
      'Colour Strikers',
    ],
  },
  {
    id:             14,
    name:           'COSBOA Innovation Games - Day 5',
    location:       'Melbourne AUS',
    hostCompany: {
      title:        'EnergyAustralia',
      id:           15546,
      icon:         'https://view.paddljobs.com/15546/v15uljvmqn_avatar_resized',
    },
    challengeFocus: `The 5th and final event in the Innovation Games series where 100 participants in 10 teams collaborated with 10 small businesses to ideate on; how EnergyAustralia can assist your business to achieve greater control over your energy costs both now and in the future.`,
    duration: {
      startDate:    new Date(2019, 5 - 1, 15),
      endDate:      new Date(2019, 5 - 1, 15),
    },
    teamNames: [
      'Prescriptive PowersAustralia',
      'The Fantastic Fleet of Foxes',
      'The Fun, Fabulous & Festive Foodies',
      'Hungry Peeps',
      'Go Green Warriors',
      'Caffeinators',
      'Win Diesel',
      'Rulers of the Roost',
      'The Energisers',
      'This is how we California Roll',
    ],
  },
  {
    id:             15,
    name:           'WSU Paddl Games 2019 with EnergyAustralia',
    location:       'Parramatta NSW, Aus',
    hostCompany: {
      title:        'EnergyAustralia',
      id:           15546,
      icon:         'https://view.paddljobs.com/15546/v15uljvmqn_avatar_resized',
    },
    challengeFocus: `The first challenge of the WSU Paddl Games 2019 exclusive to Western Sydney University students. With Challenge Day Partner EnergyAustralia, 11 teams with 11 small businesses were challenged to answer: "How can EnergyAustralia assist your business to achieve greater control over your energy cost both now and in the future?"`,
    duration: {
      startDate:    new Date(2019, 7 - 1, 10),
      endDate:      new Date(2019, 7 - 1, 10),
    },
    teamNames: [
      "Blue Spark",
      "En-Light, IT's Lit",
      "The Alchemists",
      "Maybe they're born with it",
      "De Beste Stijl",
      "Koalafication in Design Thinking",
      "Lord Voltemort",
      "Z.Dot",
      "RoboDomus",
      "Petrolheads",
      "Powerhouse",
    ],
  },
  {
    id:             16,
    name:           'National Innovation Games - Waste Management QLD',
    location:       'Gold Coast AUS',
    hostCompany: {
      title:        'Paddl Co.',
      id:           7439,
      icon:         'https://view.paddljobs.com/7439/flg1ancdxz_avatar_resized',
    },
    challengeFocus: `The launch event of the National Innovation Games supported by the Australian Federal Government and COSBOA, kicking off on the sunny Gold Coast in Queensland. Challengers from Queensland educators worked with 10 local small businesses on the topic of: "Waste Management & reducing Australia’s landfill."`,
    duration: {
      startDate:    new Date(2019, 8 - 1, 21),
      endDate:      new Date(2019, 8 - 1, 21),
    },
    teamNames: [
      'Recycle of the Fittest',
      'Grind Designs',
      'Royal Recycling',
      'Green Ease',
      'Waste Warriors',
      'Waste Wombats',
      'Green Wave',
      'Clean Machine',
      'Bintostore',
      'ReduceIt',
    ],
  },
  {
    id:             17,
    name:           'WSU Paddl Games 2019 with Westpac',
    location:       'Parramatta, NSW',
    hostCompany: {
      title:        'Westpac',
      id:           18051,
      icon:         'https://view.paddljobs.com/18051/4kbyrhuu9i_avatar_resized',
    },
    challengeFocus: `The second challenge of the WSU Paddl Games 2019 exclusive to Western Sydney University students. With Challenge Day Partner Westpac, 11 teams with 11 small businesses were challenged to answer: "How might Westpac assist you to overcome inhibitors to growth in your business?"`,
    duration: {
      startDate:    new Date(2019, 9 - 1, 26),
      endDate:      new Date(2019, 9 - 1, 26),
    },
    teamNames: [
      'Making Boulder Moves',
      'The Green Shippers',
      'Dunking with Wolves',
      'Flipping the Future',
      'The Think Tank',
      'Chocoholics-Ador-Us',
      'Cookie Monsters',
      'The Wheelers',
      'Lionpac',
      'Oasis',
      'The PerFORMance Project',
    ],
  },
  {
    id:             18,
    name:           'MyState Challenge, National Innovation Games',
    location:       'Hobart, TAS',
    hostCompany: {
      title:        'MyState Bank',
      id:           18507,
      icon:         'https://view.paddljobs.com/18507/1nf4hzs2b4_avatar_resized',
    },
    challengeFocus: `The National Innovation Games comes to Hobart with local students, innovation experts and 10 local micro businesses coming together to explore: "How might you use technology to support and grow your micro-business."`,
    duration: {
      startDate:    new Date(2019, 11 - 1, 12),
      endDate:      new Date(2019, 11 - 1, 12),
    },
    teamNames: [
      'Team Poenix',
      'Of the Highest Pedigree',
      'Spirits of Tasmania',
      'The Devils',
      'GrowTech Roar',
      'Purinina',
      'Craft Brew',
      'Sweet Toothy',
      'Leathertopia',
      'Leaps and Bounds',
    ],
  },
  {
    id:             19,
    name:           'EnergyAustralia, National Innovation Games',
    location:       'Lismore, NSW',
    hostCompany: {
      title:        'EnergyAustralia',
      id:           15546,
      icon:         'https://view.paddljobs.com/15546/v15uljvmqn_avatar_resized',
    },
    challengeFocus: `The National Innovation Games comes to Lismore with local students, innovation experts, 10 local micro businesses and a Paddl Games first remote team dialling in from Melbourne all coming together to explore: "How might you use technology to support and grow your micro-business."`,
    duration: {
      startDate:    new Date(2019, 11 - 1, 21),
      endDate:      new Date(2019, 11 - 1, 21),
    },
    teamNames: [
      'Sparky Brewsters',
      'Tiny Big Thinkers',
      'Innovation in a Nutshell',
      "The Adam Sandler's",
      'The Rainmakers',
      'Ginergy',
      'Wild Seeds Power',
      'Green Beans',
      'Marinated in Melbourne',
      'O-Live Long And Prosper',
    ],
  },
  {
    id:             20,
    name:           'National Innovation Games - Surfacing Healthcare Solutions',
    location:       'Adelaide, South Australia (Virtual)',
    hostCompany: {
      title:        'Commission on Excellence and Innovation in Health',
      id:           21484,
      icon:         'https://view.paddljobs.com/21484/xe6nvlnw4o_avatar_resized',
    },
    challengeFocus: `The first ever Virtual Paddl Games with over 180 students, small business owners, innovation experts and esteemed guests from South Australia and around the country logging into explore; a full range of healthcare services for all South Australians. `,
    duration: {
      startDate:    new Date(2020, 5 - 1, 7),
      endDate:      new Date(2020, 5 - 1, 7),
    },
    teamNames: [
      'Team Catalyst',
      'clinTech',
      'I.V. League',
      'All 4 One',
      'Decentralised Disruptors',
      'Game Changes',
      'Health_e_View',
      'Innov.8.ors',
      'Nine: Align: Design: Refine',
      'Innovation Stations'
    ],
  },
  {
    id:             21,
    name:           'Western Paddl Games: Future Makers',
    location:       'Virtual - Western Sydney',
    hostCompany: {
      title:        'Western Sydney University',
      id:           16715,
      icon:         'https://view.paddljobs.com/16715/0r9akdtztp_avatar_resized',
    },
    challengeFocus: `The 3rd Challenge in the Western Paddl Games series where over 100 students, predominantly from Western Sydney University and including students from interstate, formed teams to tackle the challenge “How might we better prepare our graduates for a virtual workplace?”`,
    duration: {
      startDate:    new Date(2020, 5 - 1, 14),
      endDate:      new Date(2020, 5 - 1, 14),
    },
    teamNames: [
      'Mind Benders',
      'Universally Challenged',
      'Quest Pirates',
      'Tech Warriors',
      'Virtual Connectors',
      'Virtual Change Makers',
      'Team Assistant (to the) Regional Manager',
      'The Crazy 8’s',
      'Sharp Shooters',
      'Team Catalyst',
    ],
  },
  {
    id:             22,
    name:           'National Innovation Games - Optimising Essential Services',
    location:       'Virtual - Regional Victoria',
    hostCompany: {
      title:        'EnergyAustralia',
      id:           15546,
      icon:         'https://view.paddljobs.com/15546/v15uljvmqn_avatar_resized',
    },
    challengeFocus: `Part of the National Innovation Games. Over 90 students, from regional Victorian universities together with interstate students, formed teams to tackle the challenge 'Business Resilience: Optimising essential services in times of crisis.'`,
    duration: {
      startDate:    new Date(2020, 5 - 1, 21),
      endDate:      new Date(2020, 5 - 1, 21),
    },
    teamNames: [
      'Double Diamond Designs',
      'Cow-Operation',
      'International Innovators',
      'Timber Merchants',
      'Strict Supply',
      'Unsinkable Sailors',
      'Seventure',
      'Knights of FoodWorks',
      'Lighthouse Pharmacy',
      'Turdus Merula',
    ],
  },
  {
    id:             23,
    name:           'National Innovation Games - Engaging your Customers and Community',
    location:       'Virtual - Queensland',
    hostCompany: {
      title:        'The Minderoo Foundation',
      id:           16980,
      icon:         'https://view.paddljobs.com/16980/zgpkre6wvr_avatar_resized',
    },
    challengeFocus: `Part of the National Innovation Games. Over 90 students, from Queensland universities and educational institutes together with interstate students, formed teams to tackle the challenge "How might you use digital solutions to engage with your customers and your community"`,
    duration: {
      startDate:    new Date(2020, 6 - 1, 18),
      endDate:      new Date(2020, 6 - 1, 18),
    },
    teamNames: [
      'Moo-ving Forward',
      'The Mighty Kandangas',
      'Holy Hell(th)',
      'Aussie Alchemy',
      'The AgTech Force',
      'Team SkyNet',
      'The Creative Crocs',
      'Emerald City Wizards',
      'Boom Shaka Laka',
      'Team Phoneix',
    ],
  },
  /***************************************************************
   *
   *                     I M P O R T A N T
   *
   * For each of the events there should be a corresponding record
   * in `var_competition_paddl_games_events` table on the server.
   *
   *
   ***************************************************************/
];

export const getAllEvents = () => {
  return KNOWN_EVENTS;
};

export const getEventDataFor = ({ eventId }) => {
  const found = KNOWN_EVENTS.find((e) => (e.id == eventId));
  return defined(found);
};
