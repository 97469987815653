import './index.scss';
import React from 'react';

import { VISA_STATUSES } from 'components/v2/profile/student-location-panel/visa-statuses';

var divStyle = {
  position: 'relative'
};

class StudentLocationPanelView extends React.Component {

  render() {
    const { profileExtra, canEdit } = this.props;
    const { lat, lng } = this.props.profileExtra.get('location');
    const ACCESS_TOKEN = 'pk.eyJ1IjoicGFkZGwiLCJhIjoiY2pyMWNoZWNiMDU1YzQzcnJ5NzdndWZtYSJ9.-8Fc4DSSlhjynfXHTDxgSw';
    const WIDTH = 592;
    const HEIGHT = 96;
    const STYLE_ID = 'cjrhmh3vc0vcv2sqhs2hm9eez';
    const ZOOM = 5;

    const visaStatusString = VISA_STATUSES[profileExtra.get('visaStatus').value];

    const coords = `${ lng },${ lat }`;
    const url = `https://api.mapbox.com/styles/v1/paddl/${ STYLE_ID }/static/${ coords },${ ZOOM },0,0/${ WIDTH }x${ HEIGHT }?access_token=${ ACCESS_TOKEN }`;

    const renderYes = () => (
      <span className = "v2-student-location-panel-view__list-icon-wrapper v2-student-location-panel-view__list-icon-wrapper--bg-green">
        <img className = "v2-student-location-panel-view__list-icon" src = { require('images/v2/icon-check.svg') } alt = "" />
      </span>
    );

    const renderNo = () => (
      <span className = "v2-student-location-panel-view__list-icon-wrapper v2-student-location-panel-view__list-icon-wrapper--bg-red">
        <img className = "v2-student-location-panel-view__list-icon" src = { require('images/v2/icon-cross.svg') } alt = "" />
      </span>
    );

    const onClick = () => {
      if (canEdit) {
        this.props.onStartEdit();
      }
    };

    const namesOfClasses = 'v2-student-location-panel-view'
      + (canEdit ? ' v2-student-location-panel-view--clickable' : '');

    return (
      <div className = { namesOfClasses } onClick = { onClick } >
        <h3 className = "v2-student-location-panel-view__heading">Current Location</h3>

        <div style= {divStyle}>
          <img src= { (lat === null || lng === null) ? require("images/paddl-gmap.png") : url } className = "v2-student-location-panel-view__map" />

          <div className = "v2-student-location-panel-view__map-content-text">
            <div className = "v2-student-location-panel-view__map-title">{ profileExtra.get('location').shortText }</div>
            <div className = "v2-student-location-panel-view__map-description">{ visaStatusString }</div>
          </div>
        </div>

        <div className = "row">
          <div className = "col-lg-6">
            <div className = "v2-student-location-panel-view__list-col-heading">Visa Conditions</div>

            <div className = "v2-student-location-panel-view__list-row">
              { profileExtra.get('visaConditionEmployment') ? renderYes() : renderNo() }
              <span className = "v2-student-location-panel-view__list-title">Employment</span>
            </div>

            <div className = "v2-student-location-panel-view__list-row">
              { profileExtra.get('visaConditionStudy') ? renderYes() : renderNo() }
              <span className = "v2-student-location-panel-view__list-title">Study</span>
            </div>
          </div>

          <div className = "col-lg-6">
            <div className = "v2-student-location-panel-view__list-col-heading">Opportunities</div>

            <div className = "v2-student-location-panel-view__list-row">
              { profileExtra.get('opportunitiesRelocation') ? renderYes() : renderNo() }
              <span className = "v2-student-location-panel-view__list-title">Relocation</span>
            </div>

            <div className = "v2-student-location-panel-view__list-row">
              { profileExtra.get('opportunitiesRemote') ? renderYes() : renderNo() }
              <span className = "v2-student-location-panel-view__list-title">Remote Opportunities</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StudentLocationPanelView;
