import React from 'react';

import { VERIFIED_VARIANTS } from 'lib/api/categories';
import { IssuerData } from 'lib/api/var-edu/verified/issuer-data';
import ImgTag from 'component-lib/components/img-tag';
import { ActivityCardContent, TIMELINE } from 'component-lib/components/activity-card-content';
import { getActivityCardContentProps } from 'component-lib/components/activity-card-content/get-props';

import './index.scss';

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      issuerProfile: null,
    };
  }

  componentDidMount() {
    const { activity, appv2 } = this.props;

    const issuerCode = activity.get('details').get('issuerCode');

    if (!issuerCode) { return null; }

    const issuerData = IssuerData.getFor(issuerCode.value);
    const issuerProfileId = issuerData.profileId;
    const isLoggedIn = appv2.auth.isLoggedIn();

    return appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: issuerProfileId, isLoggedIn })
      .then(({ profile }) => {
        this.setState({ issuerProfile: profile });
      });
  }

  render() {
    const {
      appv2,
      activity,
      canEdit,
      canOpenModal,
    } = this.props;

    const { issuerProfile } = this.state;

    const onClick = () => {
      const variantId = activity.get('variantId');
      const activityId = activity.get('id');

      if (VERIFIED_VARIANTS.indexOf(variantId) !== -1) {
        return appv2.ui.redirect({ url: `/verified-activities/${activityId}` });
      }

      return appv2.ui.openExistingActivityModal({ activityId, variantId });
    };

    return (
      <div
        className={`v2-timeline ${canOpenModal ? 'v2-timeline--can-open-modal' : ''}`}
        onClick={canOpenModal && onClick}
      >
        <ActivityCardContent
          {...getActivityCardContentProps({ activity, issuerProfile })}
          state={TIMELINE}
        />

        { canEdit &&
          <div className="v2-timeline__edit-icon">
            <ImgTag src={require('component-lib/icons/icon-edit.svg')} />
          </div>
        }
      </div>
    );
  }
}

export default Timeline;
