import './index.scss';
import React from 'react';

import { EmailInput } from 'components/v2/email-input';

export const LandingPageTextContent = () => {
  return (
    <div className = "landing-page-text-content">
      <div className = "landing-page-text-content__seo-strap">
        FREE RESUME BUILDER
      </div>
      <h1 className = "landing-page-text-content__heading">
        The only resume designed for infinite learning.
      </h1>
      <h2 className = "landing-page-text-content__subtitle">
        {
          "Paddl captures the learning that other profiles don't so you can".split(' ').map(word => {
            return <div key = { word }>{ word }</div>;
          })
        }
        {/* Wrap Bold texts in a div to ensure they are always in one line. */}
        <div>
          <b>show all of your experience</b>.
        </div>
      </h2>
      <div className = "landing-page-text-content__cta-section">
        <div className = "landing-page-text-content__cta-heading">
          Enter your email to get started
        </div>
        <EmailInput />
      </div>
      <div className = "landing-page-text-content__login-prompt">
        Already a member?&nbsp;
        <span>
          <a href = "/login" className = "landing-page-text-content__login-link">Log in here</a>
        </span>
      </div>
    </div>
  );
};
