import './index.scss';
import React from 'react';

import ImgTag                        from 'component-lib/components/img-tag';
import Text                          from 'component-lib/type/text';
import { style16pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style14pxLightGreyL }       from 'component-lib/type/text/styles';

const PaddlGamesCompanyReadonly = ({ icon, title }) => {
  return (
    <div className = "v2-paddl-games-company-readonly">
      <ImgTag
        src       = { icon }
        width     = '48px'
        isRounded = { true }
      />
      <div className = "v2-paddl-games-company-readonly__text">
        <Text { ...style16pxRegularBluegreyD } text = { title } />
        <Text { ...style14pxLightGreyL } text = "Host Company" />
      </div>
    </div>
  );
}

export default PaddlGamesCompanyReadonly;
// TODO: Similar structure to XpEntryLocationLogo, check if they should be the same.
