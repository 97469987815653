import './index.scss';
import React from 'react';

import ImgTag from 'component-lib/components/img-tag';

export const CourseUnitDetailsBanner = ({ bannerImage, bannerBackgroundColor }) => {
  return (
    <div className = "v2-course-unit-details-banner" style = { { backgroundColor: bannerBackgroundColor } }>
      <ImgTag src = { bannerImage } />
    </div>
  );
};
