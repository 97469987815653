import './index.scss';
import React from 'react';

import { defined }      from 'lib/helpers';
import { CancelButton } from 'components/v2/buttons/square-buttons/cancel-button';
import { SubmitButton } from 'components/v2/buttons/square-buttons/submit-button';

export const FormFooter = ({
  onCancel,
  onSubmit,
  isSubmitDisabled,
  customSubmitCopy,
}) => {
  return (
    <div className = "v2-form-footer">
      <CancelButton
        onClick = { onCancel }
      />
      <SubmitButton
        customCopy = { customSubmitCopy }
        onClick = { onSubmit }
        isDisabled = { defined(isSubmitDisabled) }
      />
    </div>
  );
};
