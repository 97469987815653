import './index.scss';
import React  from 'react';
import config from 'config';

const PublicProfileEmptyPage = () => {
  return(
    <div className = "v2-public-profile-empty-page">
      <img className = "v2-public-profile-empty-page__icon" src = { require('images/v2/icon-eye-user.svg') } alt = "" />
      <div className = "v2-public-profile-empty-page__description">This Paddl profile seems to be hidden <br /> from the public!</div>
      <div className = "v2-public-profile-empty-page__info">Sign up to view this profile.</div>
      <div className = "v2-public-profile-empty-page__btn-wrapper">
        <a className = "v2-public-profile-empty-page__btn" href = { config.SIGN_UP_PAGE_URL }>Sign Up</a>
      </div>
    </div>
  );
};

export default PublicProfileEmptyPage;
