import './index.scss';
import React from 'react';

import { PublicNavFooterLinks } from 'components/v2/public-nav-footer-links';
import { PublicNavFooterCopyright } from 'components/v2/public-nav-footer-copyright';

export const PageFooter = () => (
  <div className="page-footer">
    <PublicNavFooterLinks />
    <PublicNavFooterCopyright />
  </div>
);
