import React from 'react';

import './index.scss';

export const NavbarLogInButton = () => (
  <a
    className="navbar-button__btn navbar-button__btn--outline"
    href="/login"
  >
    Log in
  </a>
);
