import './index.scss';
import React from 'react';

export function TermsExplorerContent() {
  return (
    <div className="terms-section-content">

      <header>
        Terms of Use for Explorers
      </header>

      <div>
        <p>
          www.paddl.com (the <b>Platform</b>) is a web application wholly owned by Paddl Co. Pty Ltd (ACN 131 952 004) (<b>Paddl</b>, <b>we</b>, <b>our</b> or <b>us</b>).
        </p>
        <p>
          These are the terms on which Paddl will provide access to the Platform to an individual who is registered with Paddl or is seeking to be registered with Paddl for the purpose of using the Platform to build their Paddl Profile and/or gain employment, training, internships or placements, attend conferences and/or events or participate in competitions, social and/or other experiences and/or opportunities made available to users of the Platform (<b>you</b> or <b>your</b>).
        </p>
        <p>
          <b>IMPORTANT: If you are a business or other organisation, please refer to the <a href = "/terms#terms-for-providers">Terms of Use for Providers</a>.</b>
        </p>
      </div>

      <ol className = "terms-explorer-content__nested-number-list-layer-1">
        <li>
          <header>
            Definitions
          </header>
          <p>
            <b>Activity Cards</b> is a set of features available to Users in their Paddl Profile to add experiences including: education, employment, competition, personal and social experience.
          </p>
          <p>
            <b>Campaign</b> is an employment, training, internship, placement, conference, event, competition, social or other experience or opportunity advertised or otherwise published on the Platform by an Employer.
          </p>
          <p>
            <b>Campaign Fees</b> are all fees and other taxes, charges and amounts payable in respect of your access to the Platform and/or to any Campaign, notice, proposal, document or other publication posted, lodged, published or otherwise displayed on the Platform or otherwise provided or made available to Users (including you) and <b>Campaign Fee</b> means any one such fee.
          </p>
          <p>
            <b>Claim</b> means any claim, action, suit, proceeding, demand, notice, litigation, investigation, judgement or entitlement to the payment of compensation, however arising, whether present, unascertained, immediate, future or contingent, whether based in contract, tort, statute or otherwise and whether involving you or a third party.
          </p>
          <p>
            <b>Employer</b> is a business or organisation which is registered with us for the purpose of using the Platform to advertise, publicise or otherwise promote Campaigns to Users (including you) on the Platform.
          </p>
          <p>
            <b>Employer Account</b> means the account in the name of an Employer which permits it to access the Platform (via a username and password) for the purpose of publishing Campaigns and otherwise utilising the features available to the Employer on the Platform.
          </p>
          <p>
            <b>Intellectual Property Rights</b> means any Intellectual Property Rights in existence now or coming into existence in the future anywhere in the world and includes (but is not limited to) rights in respect of copyright, trade marks (whether registered or unregistered), inventions, patents, designs (whether registered or unregistered), confidential information and know how.
          </p>
          <p>
            <b>Liabilities</b> means, in relation to any Claim, all losses, costs, damages, expenses or other liabilities of any kind (including penalties, fines and interest) and however arising out of or in connection with the fact, matter or circumstance giving rise to the Claim including all legal and other professional expenses incurred in connection with investigating, disputing, defending or settling any claim, action, demand or proceeding relating to that fact, matter or circumstance (including any claim, action, demand or proceeding based on the Terms).
          </p>
          <p>
            <b>Paddl Profile</b> means the access granted to you (via use of a username and password) which allows you to login into Paddl, to create a profile, to use the features available on the Platform including, but not limited to, viewing and responding to Campaigns (e.g. by applying for employment or other opportunities advertised on the Platform) and to add Activity Cards.
          </p>
          <p>
            <b>Terms</b> means the terms and conditions set out in this document for your access to, and use of, the Platform.
          </p>
          <p>
            <b>User</b> is any person (other than you) who uses the Platform and, includes, but is not limited to, individuals (including students and graduates) who have a Paddl Profile and Employers.
          </p>
        </li>

        <li>
          <header>
            Variation &amp; Acceptance of Terms
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>You will be required to accept the Terms before being granted a Paddl Profile.  If, at any time after you have been granted a Paddl Profile, you determine that you cannot, or do not wish to, comply with any one or more of the Terms, you must immediately advise Paddl in writing and we will cancel your Paddl Profile (support@paddljobs.com). </p>
            </li>
            <li>
              <p>Your access to the Platform and your Paddl Profile may be terminated by us, with immediate effect and with or without prior notice to you, for non-compliance with any provision of the Terms. </p>
            </li>
            <li>
              <p>We may change the Terms at our absolute discretion and at any time.  A change to the Terms will become effective immediately on publication of the change (by notice (in writing) detailing the change) on the Platform.  You accept that publication of the notice of change on the Platform is sufficient notice to you of the change and that, on and from the date of publication of the change, you agree to be bound by the Terms (as amended). </p>
            </li>
            <li>
              <p>Reliance on any information provided by us and others on Paddl is solely at your own risk.</p>
            </li>
            <li>
              <p>Should you have any questions concerning these terms please contact support@paddljobs.com.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Account Information
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>Only persons with a Paddl Profile will be permitted to access a Campaign published on the Platform.  To create a Paddl Profile, you will be required to provide certain information about yourself, which may include your name, contact details and a valid email address (“<b>Account Information</b>”). You agree: </p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  to provide Account Information which is true, accurate and complete and to take all reasonable steps to ensure your Account Information remains true, accurate and complete; and
                </li>
                <li>
                  if you provide any information that is untrue, inaccurate, incomplete or inappropriate, or we have grounds to suspect that such information is untrue, inaccurate, incomplete or inappropriate, we have the right to suspend, terminate, lock or delete your Paddl Profile.
                </li>
              </ol>
            </li>
            <li>
              <p>We reserve the right to use your Account Information for any purpose for which it is reasonably required in the ordinary course of our business.  Its use will be in accordance with our policies and procedures, including our Privacy Policy which can be found <a href = "/terms#privacy">here</a>. In particular, we reserve the right to disclose your Account Information to third parties, including Employers, in order for them to interact with you (and other Users). </p>
            </li>
            <li>
              <p>By providing your email address to us, you consent to our use of that email address to send you: </p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  service-related notices, including among other things, notices required by law, in lieu of postal mail.  For so long as you hold a Paddl Profile, you may not opt out of service-related emails; and
                </li>
                <li>
                  information produced by or on behalf of any third party which may be relevant to your education, trade or profession or the provision of services to you (including any training, employment or other related service or experience).
                </li>
              </ol>
              <p>We may also use your email address (or, for subscribers to mobile notifications, your mobile number) to send you other notices and/or messages, including information about us and special offers. You may opt out of such email or text messages by sending an email to support@paddljobs.com or using an unsubscribe link in any such email.</p>
            </li>
            <li>
              <p>We may refuse your request for a Paddl Profile at any time and for any reason (and without any obligation to provide you with a reason for any such refusal).</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Fees and charges
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>Subject to clauses 4.2 and 4.3, use of the Platform by you is free.</p>
            </li>
            <li>
              <p>If you choose to accept, respond to or participate in a Campaign on the Platform (for example, you enter a competition or enrol to attend an event), you agree to pay all applicable Campaign Fees (if any) to the Employer in respect of that Campaign.</p>
            </li>
            <li>
              <p>You are responsible for all Campaign Fees incurred by yourself to any Employer in accordance with the terms and conditions set by the Employer.  Without limiting the foregoing, under no circumstances will we have any liability whatever to the Employer for any Campaign Fee incurred by you (or on your behalf).</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Copyright, trademarks &amp; Intellectual Property Rights
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>You acknowledge and agree that:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  you have no right, title or interest in the copyright of any Campaign materials published on the Platform (which copyright is owned by us or by the relevant Employer (as the case may be);
                </li>
                <li>
                  all Intellectual Property Rights of Paddl (including but not limited to copyright in the software and codes underpinning the Platform), Paddl advertisements, Paddl content and Paddl logos (collectively Paddl IP) are the sole and exclusive property of Paddl;
                </li>
                <li>
                  you have no right, title or interest in or to any Paddl IP and your access to, or use of, the Platform, does not convey to, or result in, any transfer to you of any right, title or interest in or to any Paddl IP;
                </li>
                <li>
                  you will not challenge or infringe any intellectual property rights (including the Paddl IP) owned by us; and
                </li>
                <li>
                  we are expressly authorised to use:
                  <ol className = "terms-explorer-content__lower-roman-list">
                    <li>
                      your name, Paddl Profile and Account Information (Your IP) for:
                      <ol className = "terms-explorer-content__upper-alpha-list">
                        <li>
                          the purpose of fulfilling our obligations to you under the Terms;
                        </li>
                        <li>
                          any purpose for which any such information was disclosed by you to us; and
                        </li>
                        <li>
                          any disclosure that is required by law; and
                        </li>
                      </ol>
                    </li>
                    <li>
                      information relating to you (including information which may form part of your Paddl Profile and/or Account Information) provided that the manner, form and timing of its use is such that it does not identify you.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>Except as provided in clause 5.1(e), any other use by us of Your IP, whether in print or electronically, in a manner which will, or is likely to, identify you will require your prior approval.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Termination
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>We may terminate or suspend your Paddl Profile at any time and for any reason whatsoever, including, without limitation, if you breach the Terms.  Termination may occur without prior notice to you and without need on our part to give you a reason for that termination.</p>
            </li>
            <li>
              <p>To the maximum extent permitted by law, we will have no liability whatsoever to you for or as a result of termination or suspension of your Paddl Profile.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Limitation of liability &amp; Disclaimer
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>Reliance and use by you of: </p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  any information on the Platform; or
                </li>
                <li>
                  other information provided to you by us and/or by any other Employer or User,
                </li>
              </ol>
              <p>is solely at your own risk.</p>
            </li>
            <li>
              <p>You are liable for all information and activity on your Paddl Profile.  Without limiting the foregoing, we accept no responsibility or liability for any information in your Paddl Profile.  You must carefully check any and all materials (of any description) provided to us or uploaded by you onto the Platform.  Errors in any such materials are your responsibility.</p>
            </li>
            <li>
              <p>The Platform is a medium through which you and other Users may interact with Employers and/or publish Activity Cards relevant to your experience in your Paddl Profile.  While we use reasonable efforts to ensure the legitimacy of any material published on the Platform (for example, in Campaign and/or Paddl Profiles), we neither vet, nor are we responsible for vetting, Employers or information published by them on the Platform (including but not limited to employment advertisements or any other invitation to participate in an event or experience, whether oral or in writing).</p>
            </li>
            <li>
              <p>We do not recommend or endorse any Employer or other User or guarantee that any or all materials published on the Platform by an Employer are genuine or that any or all information provided by an Employer on the Platform is true, accurate and/or complete.</p>
            </li>
            <li>
              <p>We expressly reserve the right to delete or otherwise remove from the Platform any content which we consider, in our absolute discretion, to be offensive, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, invasive of another’s privacy, racially or ethnically offensive or otherwise objectionable or damaging to us, our reputation, Employers or other Users and/or to any other person (or persons) generally.</p>
            </li>
            <li>
              <p>We provide no warranty to you that your access to the Platform and/or to the services or features generally available through the Platform or Paddl will be uninterrupted, error free or free from any risk of exposure to you or your computer systems of malicious software (malware), including but not limited to software that may harm your computer system or software (in the form of worms, viruses, trojans, spyware, adware and rootkits, etc.) designed to compromise, destroy or steal protected data and/or documents or of software not approved by you being installed on your computer systems.</p>
            </li>
            <li>
              <p>We will take such steps as are reasonable to maintain the security, integrity and privacy of your Paddl Profile and protect it from misuse, interference, loss, unauthorised access, modification and/or disclosure (other than as expressly required by law, permitted under the Terms or authorised by you).  Except where we are unable to exclude our liability by law, we, our officers, employees, agents and contractors will not be liable in any way to you or to anyone else for any Claim or Liability (whether in contract, tort (including negligence) or otherwise) arising out of or in connection with your access and use of the Platform and/or misuse, interference, loss, unauthorised access to, modification and/or disclosure of personal information or protected data in your Paddl Profile.</p>
            </li>
            <li>
              <p>To the maximum extent permitted at law, Paddl shall not be liable to you (or any person claiming through you or on your behalf) in respect of any Claim, regardless of whether the Claim is in contract, tort (includes negligence), under any statute or otherwise at law, for any special, indirect, incidental, punitive, or consequential loss or damages of any kind whatsoever, or for any loss of profit, use, content, data, opportunity, revenue or business, whether caused by Paddl, by any of its employees, agents or by any other persons acting on its behalf or by any other user of the Platform.</p>
            </li>
            <li>
              <p>Nothing in these terms purports to limit our liability under the Australian Consumer Law or otherwise required at law.</p>
            </li>
            <li>
              <p>In the event of a Claim by you (or by any person claiming through you or on your behalf) and regardless of whether the Claim is in contract, tort (includes negligence), under any statute or otherwise at law, to the maximum extent permitted by law, our Liability in respect of that Claim shall be limited to:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  the replacement of the goods or services or the re-supply of equivalent goods or services;
                </li>
                <li>
                  payment of the cost of replacing the goods or acquiring equivalent goods or services;
                </li>
                <li>
                  reimbursement or refund of any charges paid for the goods or services or incorrectly charged or deducted; or
                </li>
                <li>
                  compensation for any loss or damage caused to you, subject at all times to a maximum liability cap of $##[amount],
                </li>
              </ol>
              <p>as determined by Paddl (in its absolute discretion). </p>
            </li>
            <li>
              <p>You agree that we do not act as your agent or as the agent for any Employer. </p>
            </li>
            <li>
              <p>You agree that we do not recommend or endorse any Employers, organisations or service providers featured on the Platform. </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Security of your information
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>We will take such steps as are reasonable to maintain the privacy of all personal information in your Paddl Profile and protect it from misuse, interference, loss, unauthorised access, modification and/or disclosure (other than as expressly permitted under these terms).  If for any reason (including negligence or default on our part (or by our employees, agents and contractors) the privacy of personal information in your Paddl Profile is compromised, destroyed, modified or otherwise disclosed without proper authorisation, then, to the maximum extent permitted by law, we disclaim all liability for any Liability caused or suffered by you or any third party.</p>
            </li>
            <li>
              <p>You represent, warrant and acknowledge to us that:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  you will keep your Paddl Profile and Account Information secure and not permit any person to use your Account Information without your permission;
                </li>
                <li>
                  you will notify us immediately if you become aware of any unauthorised use of your Paddl Profile or Account Information;
                </li>
                <li>
                  you will not use the Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or accessibility of the Platform, or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose of activity;
                </li>
                <li>
                  you will not use the Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, trojan horse, worm, keystroke logger, rootkit or other malicious computer software; and
                </li>
                <li>
                  you will not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to the Platform without Paddl’s express written consent.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Your obligations
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>You represent, warrant and acknowledge that:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  you have the legal capacity and power to agree to be bound by the Terms and perform the obligations under them;
                </li>
                <li>
                  the materials, information and/or other content posted by you to your Paddl Profile do not breach any copyright or privacy laws or the Intellectual Property Rights of any third party;
                </li>
                <li>
                  the information provide in your Paddl Profile does not breach any law or the rights of any person (including legislation regarding consumer protection and anti-competitive conduct, equal opportunity and human rights);
                </li>
                <li>
                  you will not use the Platform for any purpose other than the express purpose for which access has been granted under the Terms;
                </li>
                <li>
                  you will not use the Platform or any features of the Platform or products offered on the Platform or by us to upload, download, transact, store or make available data that is unlawful, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, invasive of another’s privacy, racially or ethnically offensive or otherwise in our opinion objectionable or damaging to us, our reputation, other Employers and/or Users and/or to any other person (or persons) generally;
                </li>
                <li>
                  you will not assign or transfer any rights and obligations pursuant to the Terms to any other person or entity without our prior written approval (which approval will not be unreasonably withheld);
                </li>
                <li>
                  you will at all times deal with any information, products or services provided by us (or on our behalf) or accessed on the Platform in a manner which abides by all applicable laws of Australia and of any other relevant jurisdiction (including, without limitation, all privacy and copyright laws);
                </li>
                <li>
                  you will not make any public comment (includes news release, advertising and/or promotional material) naming us or otherwise relating to us without our prior written approval, which approval may be withheld by us in our sole discretion; and
                </li>
                <li>
                  you will not sell, transfer, hawk or otherwise distribute any materials supplied or prepared by us, or acquired by you from us, to any third parties without our prior written approval, which approval may be withheld by us in our sole discretion.
                </li>
              </ol>
            </li>
            <li>
              <p>You agree to indemnify (and to keep indemnified) Paddl, its officers, employees and agents against all Losses incurred by Paddl in connection with:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  any Claim arising from or relating to your listing or proposed listing of your Paddl Profile on the Platform;
                </li>
                <li>
                  any breach by you of the Terms;
                </li>
                <li>
                  all Claims to the extent that such Claims are a result, directly or indirectly, of any negligent, dishonest or fraudulent act or omission by you; or
                </li>
                <li>
                  any Claim arising from or relating to an actual or alleged breach by you of any law, legislation, regulations, by-laws, ordinances or codes of conduct consequent, directly or indirectly, on the publication of your Paddl Profile on the Platform.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Information for personal, non-commercial use only
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>You agree that information contained on the Platform is for your personal use only and must not be sold, redistributed or used for any commercial purpose (this includes but is not limited to the use of Employer contact details or other Employer information for any commercial purpose (other than responding to a Campaign).</p>
            </li>
            <li>
              <p>You may download material from Paddl for your personal, non-commercial use only, provided you keep intact all copyright and other proprietary notices.</p>
            </li>
            <li>
              <p>You must not under any circumstances provide any User’s personal information you have obtained through your use of the Platform to any third party (which includes any of your affiliates and/or related parties). This restriction on forwarding personal information applies irrespective of whether you receive direct financial benefit for doing so.</p>
            </li>
            <li>
              <p>If we believe you have misused any User's data for any reason, we reserve the right to:</p>
              <ol className = "terms-explorer-content__lower-alpha-list">
                <li>
                  immediately suspend or terminate your Paddl Profile;
                </li>
                <li>
                  immediately suspend or terminate the Paddl Profile of any party that has received User personal information from you in breach of these terms;
                </li>
                <li>
                  report any potential contraventions of the Privacy Act 1988 (Cth) by you to the relevant authorities; and
                </li>
                <li>
                  take legal action against you for any Loss suffered by us (or the User) as a result of your unauthorised use of any User’s personal information, including by seeking injunctive relief or the award of monetary damages
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Information does not represent professional advice
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>The Platform is designed principally for the purpose of you building a Paddl Profile and you accessing Campaigns published by Employers.</p>
            </li>
            <li>
              <p>You acknowledge and agree that neither Paddl (nor any officer, employee, agent or other person associated with Paddl) in any way endorses or recommends any Employer, Campaign, opportunity or other information published or otherwise made available on the Platform and, before accepting any offer of employment or entering into any other agreement or arrangement with any Employer, we strongly recommend that you obtain your own independent advice concerning the Employer, the offer in the Campaign or other agreement or arrangement (as the case may be).</p>
            </li>
            <li>
              <p>It is your responsibility solely to evaluate the accuracy, completeness and usefulness of any information, including in any Campaign or concerning an Employer, published on the Platform.  We do not independently check or confirm (and have no means whereby we can independently check or confirm) the accuracy or completeness of any information published on the Platform or on the internet generally.  Accordingly, under no circumstances will we be liable to you or any third party for the accuracy, completeness and usefulness of any information, including any Campaign, published on the Platform or any decision made or action taken by you (or any third party) in reliance on any information published on the Platform or for any omission or incompleteness of any information.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            No guarantee of job vacancy
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>Paddl does not guarantee you that any particular employment or other opportunity will be available to you on Paddl or that there will be at any time, and from time to time, any employment or other opportunities suited to you and/or your skills available on the Platform.</p>
            </li>
            <li>
              <p>Paddl does not guarantee the continued availability of any particular Campaign on Paddl and will not have any Liability or obligation to you in the event an Employer does not offer you the opportunity to apply for or participate in a Campaign,  withdraws a Campaign, offers employment to another person or otherwise acts in a manner contrary to your interests.</p>
            </li>
            <li>
              <p>Paddl does not guarantee to you that every Campaign published on the Platform represents an actual job vacancy or that an Employer, having published a Campaign, will fill the vacancy either at all or by offering it to you or any other User.</p>
            </li>
            <li>
              <p>We are not responsible for the ongoing vacancy of any opportunity referred to in a Campaign.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Changes to Paddl
          </header>
          <p>
            We reserve the right at all times, without the need to provide notice to you, to alter the functionality and/or appearance of the Platform and/or the terms and conditions relevant to your Paddl Profile (and under which you are permitted to use the Platform).
          </p>
        </li>

        <li>
          <header>
            Our rights to use information you send us
          </header>
          <p>
            We reserve the right at all times, without the need to provide notice to you, to alter the functionality and/or appearance of the Platform and/or the terms and conditions relevant to your Paddl Profile (and under which you are permitted to use the Platform).
          </p>
        </li>

        <li>
          <header>
            No misrepresentations allowed
          </header>
          <p>
            It is a condition of your use of the Platform and of any other services provided by us that you will not by any act or omission (including but not limited to creating a Paddl Profile and adding information about your education and experience to the profile in the form of Activity Cards) mislead or deceive, or attempt to mislead or deceive, any person.
          </p>
        </li>

        <li>
          <header>
            General Provisions
          </header>
          <ol className = "terms-explorer-content__nested-number-list-layer-2">
            <li>
              <p>If any part of the Terms is held invalid that part shall be severed from the Terms and the remainder of the Terms will continue to be valid and enforceable.</p>
            </li>
            <li>
              <p>Termination of your Paddl Profile will not end those provisions in the Terms that are capable of surviving the termination of your Paddl Profile.</p>
            </li>
            <li>
              <p>The laws governing the Terms will be the laws in Victoria, Australia and you submit to the exclusive jurisdiction of the courts of Victoria for the resolution of all disputes concerning or arising from or in connection with the Terms.</p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
