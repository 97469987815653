import './index.scss';
import React from 'react';

const XpAmountLarge = ({ xpNumber }) => {
  return (
    <div className = "v2-xp-amount-large">
      <span className = "v2-xp-amount-large__number">{ xpNumber }</span>
      <span className = "v2-xp-amount-large__unit">XP</span>
    </div>
  );
}

export default XpAmountLarge;
