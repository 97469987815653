import './index.scss';
import React from 'react';

export class Artwork extends React.Component {
  constructor(props) {
    super(props);

    this._setUrlToStyle = () => {
      this.bgImageDiv.style.setProperty('--bg-image', `url('${this.props.url}')`);
    }
  }

  componentDidMount() {
    this._setUrlToStyle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) { return; }

    this._setUrlToStyle();
  }

  render() {
    const { url, isSelected, onClick } = this.props;

    if (url === null) {
      return null;
    }

    return (
      <div
        className = { `v2-artwork v2-artwork--${isSelected ? 'selected' : 'default' }` }
        ref = { (element) => { this.bgImageDiv = element; } }
        onClick = { isSelected ? null : onClick }
      />
    );
  }
}
