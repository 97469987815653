import React from 'react';

import Text from 'component-lib/type/text';
import { BlueSubmitButton } from 'components/v2/buttons/square-buttons/blue-submit-button';

import {
  style20pxRegularWhite,
  style16pxLightWhite,
} from 'component-lib/type/text/styles';

import './index.scss';

export class VerifiedActivitiesCardRedeem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redemptionCode: '',
    };
  }

  render() {
    const { redirectToUrl } = this.props;
    const { redemptionCode } = this.state;

    return (
      <div className="v2-verified-activities-card-redeem">
        <Text
          {...style20pxRegularWhite}
          text="Verified Activities"
        />
        <div className="v2-verified-activities-card-redeem__content-with-shadow">
          <div className="v2-verified-activities-card-redeem__content-wrapper">
            <div className="v2-verified-activities-card-redeem__card">
              <Text
                {...style16pxLightWhite}
                text="Use a Code to find and redeem a Verified Activity."
              />
              <div className="v2-verified-activities-card-redeem__input-field">
                <input
                  type="text"
                  className="v2-verified-activities-card-redeem__form-field"
                  placeholder="#000000"
                  value={redemptionCode}
                  onChange={(event) => {
                    this.setState({ redemptionCode: event.target.value });
                  }}
                />
                <div className="v2-verified-activities-card-redeem__apply-button">
                  <BlueSubmitButton
                    customCopy="Apply"
                    onClick={() => {
                      redirectToUrl(`/verified-activities/redemption/${redemptionCode}`);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="v2-verified-activities-card-redeem__shadow" />
        </div>
      </div>
    );
  }
}
