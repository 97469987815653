import './index.scss';
import React from 'react';

import { getDurationValueUnit } from 'components/v2/activities/generics/duration-value-unit/helper';
import DurationUnit             from 'lib/api/activities/generics/duration-value-unit/duration-unit';
import Moment                   from 'lib/helpers/moment';

import ImgTag                   from 'component-lib/components/img-tag';
import Text                     from 'component-lib/type/text';
import { style16pxRegularBlue } from 'component-lib/type/text/styles';
import { style16pxLightBlue }   from 'component-lib/type/text/styles';

export const EndDateSuggestion = ({ startDate, duration, onClick }) => {
  if (startDate === null) return null;
  if (duration  === null) return null;

  const { value, unit } = duration;

  if (value === null)     return null;
  if (unit  === null)     return null;

  const durationCopy = getDurationValueUnit({ duration: duration });

  const calcSuggestedEndDate = () => {
    let yearsAfterStartDate  = 0;
    let monthsAfterStartDate = 0;
    let daysAfterStartDate   = 0;

    switch (unit) {
      // Minus 1 day when duration unit is set as DAY or WEEK in order to exclude the end date
      case DurationUnit.DAY:   daysAfterStartDate   = value - 1;     break;
      case DurationUnit.WEEK:  daysAfterStartDate   = value * 7 - 1; break;
      case DurationUnit.MONTH: monthsAfterStartDate = value;         break;
      case DurationUnit.YEAR:  yearsAfterStartDate  = value;         break;
      default:                 throw Error(`Unexpected unit: ${unit}`);
    }

    return new Date(
      startDate.getFullYear() + yearsAfterStartDate,
      startDate.getMonth() + monthsAfterStartDate,
      startDate.getDate() + daysAfterStartDate,
    );
  };

  const suggestedEndDate = calcSuggestedEndDate();

  return (
    <div
      className = "end-date-suggestion"
      onClick   = { () => { onClick(suggestedEndDate); } }
    >
      <ImgTag width = '24px' src = { require('component-lib/icons/clock.svg') } />
      <Text { ...style16pxRegularBlue } text = { `${durationCopy}: ` } />
      <div className = "end-date-suggestion__suggested-end-date">
        <Text { ...style16pxLightBlue } text = { Moment.format_D_MMMM_YYYY(suggestedEndDate) } />
      </div>
    </div>
  );
};
