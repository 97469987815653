import { defined } from 'lib/helpers';

import Year  from 'lib/api/activities/generics/duration-common/year';
import Month from 'lib/api/activities/generics/duration-common/month';
import Day   from 'lib/api/activities/generics/duration-common/day';

class DayMonthYear {
  constructor(data) {
    this.year  = defined(data.year);
    this.month = defined(data.month);
    this.day   = defined(data.day);
  }

  toApiFormat() {
    return {
      year:  this.year.toApiFormat(),
      month: this.month.toApiFormat(),
      day:   this.day.toApiFormat(),
    };
  }

  isEmpty() {
    return (
      this.year     === null
      && this.month === null
      && this.day   === null
    );
  }

  isValid() {
    return (
      this.year     !== null
      && this.month !== null
      && this.day   !== null
    );
  }

  static createEmpty() {
    return new DayMonthYear({
      year:  null,
      month: null,
      day:   null,
    });
  }

  static fromApiFormat(data) {
    return new DayMonthYear({
      year:  new Year(defined(data.year)),
      month: new Month(defined(data.month)),
      day:   new Day(defined(data.day)),
    });
  }
}

export default DayMonthYear;
