import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import SecondaryDetailReadonly       from 'components/v2/activities/generics/fields/secondary-detail/readonly';
import CampusReadonly                from 'components/v2/activities/categories/education/fields/campus/readonly';
import XpTags                        from 'component-lib/components/xp-tags';

import { getCourseDuration }              from 'components/v2/activities/categories/education/fields/course-duration/helper';
import { getCommitment }                  from 'components/v2/activities/categories/education/fields/commitment/helper';
import { getAttendanceOnCampusAndOnline } from 'components/v2/activities/categories/education/fields/attendance/on-campus-and-online/helper';

const EducationCommonDetailsPanelView = ({ data }) => {
  const { detailsData } = data;

  const courseDuration     = detailsData.getField('courseDuration');
  const commitment         = detailsData.getField('commitment');
  const attendanceOnCampus = detailsData.getField('attendanceOnCampus');
  const attendanceOnline   = detailsData.getField('attendanceOnline');

  return (
    <div className = "v2-education-common-details-panel-view">
      <div className = "v2-education-common-details-panel-view__subpanel">
        <Text { ...style20pxRegularBluegreyD } text = { detailsData.getField('course').name } />
        <SecondaryDetailReadonly detail = { detailsData.getField('educator').name } />
        <CampusReadonly campus = { detailsData.getField('campus') } />
      </div>

      <div className = "v2-education-common-details-panel-view__subpanel">
        <XpTags
          strings = { [
            getCourseDuration({ courseDuration }),
            getCommitment({ commitment }),
            getAttendanceOnCampusAndOnline({ attendanceOnCampus, attendanceOnline }),
          ] }
        />
      </div>
    </div>
  );
};

export default EducationCommonDetailsPanelView;
