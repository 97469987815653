import './index.scss';
import React from 'react';

import { style22pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style16pxLightBluegrey }    from 'component-lib/type/text/styles';
import Text                          from 'component-lib/type/text';
import CheckImage                    from 'components/v2/svg-icons/svg-icon-slim-check';
import SvgIconClose                  from 'components/v2/svg-icons/svg-icon-close';

const ActivityCardSuggestionListItem = ({
  hasCheckmark,
  isClickable,
  onClick,
  variantIcon,
  variantIconShape,
  variantTitle,
  variantSubtitle,
  footerBorderTopColor,
  categoryTitle,
  categoryTitleColor,
  onHide,
}) => {
  const renderImg = () => {
    if (hasCheckmark) {
      return (
        <div className = "activity-card-suggestion-list-item__check-img">
          <CheckImage
            width      = '52px'
            height     = '52px'
            isAnimated = { true }
          />
        </div>
      );
    }

    return (
      <div className = { `activity-card-suggestion-list-item__variant-icon activity-card-suggestion-list-item__variant-icon--${variantIconShape}` }>
        <img
          src = { variantIcon }
          alt = { `${variantTitle} icon` }
          style = { { verticalAlign: 'middle' } }
        />
      </div>
    );
  };

  const onClickIfClickable = () => {
    isClickable && onClick();
  };

  return (
    <div
      className = { `
        activity-card-suggestion-list-item
        ${ isClickable ? 'activity-card-suggestion-list-item--clickable' : '' }
      ` }
    >
      <div className = "activity-card-suggestion-list-item__content-wrapper">
        { onHide &&
          <a className = "activity-card-suggestion-list-item__close-link" onClick = { onHide }>
            <SvgIconClose />
          </a>
        }

        <div
          className = "activity-card-suggestion-list-item__body"
          onClick = { onClickIfClickable }
        >
          { renderImg() }
          <Text { ...style22pxRegularBluegreyD } text = { variantTitle } />
          <Text { ...style16pxLightBluegrey } text = { variantSubtitle } />
        </div>

        <div className = { `
          activity-card-suggestion-list-item__footer
          activity-card-suggestion-list-item__footer--${footerBorderTopColor}
        ` }
          onClick = { onClickIfClickable }
        >
          <Text
            text       = { categoryTitle }
            color      = { categoryTitleColor }
            fontSize   = '16px'
            fontWeight = 'regular'
          />
        </div>
      </div>
      <div className = "activity-card-suggestion-list-item__shadow">
      </div>
    </div>
  );
};

export default ActivityCardSuggestionListItem;
