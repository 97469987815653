import { defined } from 'lib/helpers';

const numericValue = (value) => {
  const intValue = parseInt(defined(value), 10);

  if (isNaN(intValue) || intValue < 1 || intValue > 12) {
    throw Error('Unexpected value');
  }

  return intValue;
};

class CourseDuration {
  constructor(value) {
    this.value = (value === null ? null : numericValue(value));
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new CourseDuration(null);
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new CourseDuration(data);
  }
}

export default CourseDuration;
