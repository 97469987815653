import './index.scss';
import React from 'react';

import { getEventDataFor }               from 'lib/api/var-competition/paddl-games-events';
import PaddlGamesNameAndLocationReadonly from 'component-lib/components/paddl-games-name-and-location-readonly';
import PaddlGamesCompanyReadonly         from 'component-lib/components/paddl-games-company-readonly';
import ChallengeFocusReadonly            from 'component-lib/components/challenge-focus-readonly';
import PaddlGamesTeamReadonly            from 'component-lib/components/paddl-games-team-readonly';
import XpTags                            from 'component-lib/components/xp-tags';

const PaddlGamesDetailsPanelView = ({ data }) => {
  const { detailsData } = data;

  const eventId   = detailsData.getField('eventId');
  const place     = detailsData.getField('competitionPlace');
  const teamName  = detailsData.getField('teamName');
  const eventData = eventId && getEventDataFor({ eventId });

  const getRoles = (detailsData) => {
    const roleArray = [
      { value: detailsData.getField('roleTeamLeader'),   string: 'Team Leader' },
      { value: detailsData.getField('rolePresenter'),    string: 'Presenter' },
      { value: detailsData.getField('roleDemonstrator'), string: 'Demonstrator' },
      { value: detailsData.getField('roleCreative'),     string: 'Creative' }
    ];

    const filteredRoles = roleArray.filter(role => (role.value));
    const mappedRoles   = filteredRoles.map(role => (role.string));

    return mappedRoles;
  }

  return (
    <div className = "v2-paddl-games-details-panel-view">
      <div className = "v2-paddl-games-details-panel-view__name-and-location">
        <PaddlGamesNameAndLocationReadonly
          name     = { eventData.name }
          location = { eventData.location }
        />
      </div>

      <div className = "v2-paddl-games-details-panel-view__company">
        <PaddlGamesCompanyReadonly
          icon  = { eventData.hostCompany.icon }
          title = { eventData.hostCompany.title }
        />
      </div>

      <div className = "v2-paddl-games-details-panel-view__challenge-focus">
        <ChallengeFocusReadonly challengeFocus = { eventData.challengeFocus } />
      </div>

      <div className = "v2-paddl-games-details-panel-view__team">
        <PaddlGamesTeamReadonly
          place    = { place }
          teamName = { teamName }
        />
      </div>

      {
        getRoles(detailsData).length > 0 &&
        <div className = "v2-paddl-games-details-panel-view__roles">
          <XpTags
            strings = { getRoles(detailsData) }
          />
        </div>
      }
    </div>
  );
};

export default PaddlGamesDetailsPanelView;
