import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';

import DurationValueField from './value-field';
import DurationUnitField  from './unit-field';

import { durationValueOptions, fetchDurationUnitOptions } from '../options';

const DurationValueUnitInput = ({
  label,
  value,
  valuePlaceholder,
  valueOnChange,
  unit,
  unitPlaceholder,
  unitOnChange,
  isAutoFocused
}) => {
  return (
    <div className = "v2-duration-value-unit-input">
      <div className = "v2-duration-value-unit-input__container">
        <label className = "v2-duration-value-unit-input__label">
          { label }
        </label>
        <div className = "columns">
          <div className = "column is-half">
            <DurationValueField
              placeholder   = { valuePlaceholder }
              value         = { value }
              onChange      = { (value) => { valueOnChange(defined(value)); } }
              options       = { durationValueOptions }
              isAutoFocused = { isAutoFocused }
            />
          </div>
          <div className = "column is-half">
            <DurationUnitField
              placeholder = { unitPlaceholder }
              value       = { unit }
              onChange    = { (unit) => { unitOnChange(defined(unit)); } }
              options     = { fetchDurationUnitOptions({ durationValue: value }) }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DurationValueUnitInput;
