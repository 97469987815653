import './index.scss';
import React from 'react';

const FindOutMoreButton = ({ link, target }) => {
  return (
    <a
      className = "find-out-more-button"
      href      = { link }
      target    = { target }
    >
      Find out more
    </a>
  );
};

export default FindOutMoreButton;
