import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-indefinite-day-month-year';

import Employer          from 'lib/api/activities/categories/employment/fields/employer';
import HoursPerWeek      from 'lib/api/activities/categories/employment/fields/hours-per-week';
import PrimaryIndustryId from 'lib/api/activities/categories/employment/fields/primary-industry-id';
import Level             from 'lib/api/activities/categories/employment/fields/level';
import Responsibility    from 'lib/api/activities/categories/employment/fields/responsibility';
import IdNameObject      from 'lib/api/activities/generics/fields/id-name-object';
import Text              from 'lib/api/activities/generics/fields/text';
import Bool              from 'lib/api/activities/generics/fields/bool';
import Location          from 'lib/api/location';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/employment/variants/full-time-part-time-contract/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/employment/variants/full-time-part-time-contract/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/employment/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/employment/variants/common/card/duration-panel/form';

class PartTime {
  static categoryCssClassPrefix() {
    return 'v2-categories-employment-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-employment-part-time.svg');
  }

  static title() {
    return 'Part Time';
  }

  static subtitle() {
    return 'Work less than 5 days a week.';
  }

  static detailsAttributes() {
    const fetchPartTimeRolesSuggestions = ({ appv2 }) => { return appv2.api.employment.fetchPartTimeRolesSuggestions.bind(appv2.api.employment); };
    const fetchEmployersSuggestions     = ({ appv2 }) => { return appv2.api.employment.fetchEmployersSuggestions.bind(appv2.api.employment);     };

    return {
      role:              { required: true,  dataType: IdNameObject,      fieldLabel: 'Role / Title',     fieldPlaceholder: 'e.g. Project Support', suggestionsFunction: fetchPartTimeRolesSuggestions },
      employer:          { required: true,  dataType: Employer,          fieldLabel: 'Employer Name',    fieldPlaceholder: 'e.g. ACME Company',    suggestionsFunction: fetchEmployersSuggestions     },
      workingLocation:   { required: true,  dataType: Location,          fieldLabel: 'Working Location', fieldPlaceholder: 'e.g. Melbourne, Vic AU'                                            },
      hoursPerWeek:      { required: true,  dataType: HoursPerWeek,      fieldLabel: 'Commitment',       fieldPlaceholder: '20'                                                                },
      primaryIndustryId: { required: true,  dataType: PrimaryIndustryId, fieldLabel: 'Primary Industry', fieldPlaceholder: 'Select Industry'                                                   },
      level:             { required: true,  dataType: Level,             fieldLabel: 'Level',            fieldPlaceholder: 'Select Level'                                                      },
      responsibility:    { required: true,  dataType: Responsibility,    fieldLabel: 'Responsibility',   fieldPlaceholder: 'Select Responsibility'                                             },
      description:       { required: false, dataType: Text,              fieldLabel: 'Description',      fieldPlaceholder: 'Share more about your day-to-day role and key responsibilities...' },
      attendanceInHouse: { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
      attendanceRemote:  { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: details.get('role').name,
      activityProviderName: details.get('employer').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({ attributes: this.detailsAttributes() });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default PartTime;
