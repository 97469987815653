import './index.scss';
import React from 'react';

const SkillsPanelEmpty = () => {
  return (
    <div className = "v2-skills-panel-empty">
      <div className = "columns is-mobile is-vcentered">
        <div className = "column v2-skills-panel-empty__column-icon">
          <div className = "v2-skills-panel-empty__icon-wrapper">
            <img
              className = "v2-skills-panel-empty__icon-static"
              src = { require('images/v2/xp/activity-skills.svg') }
            />
            <img
              className = "v2-skills-panel-empty__icon-hover"
              src = { require('images/v2/xp/activity-skills-hover.svg') }
            />
          </div>
        </div>
        <div className = "column">
          <div className = "v2-skills-panel-empty__title">
            Skills
          </div>
          <div className = "v2-skills-panel-empty__subtitle">
            Select the Skills that you developed during this activity ...
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsPanelEmpty;
