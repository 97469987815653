import { defined } from 'lib/helpers';
import State       from './state';

// TODO: tests

class StateWithSavePoints {
  constructor({ component }) {
    this._ourState = new State({ component });
    this._savePoint = {};

    this._hooks = {
      'setAsSavePoint': [],
    };

    this._runAllHooks = (name) => {
      this._hooks[name].forEach((callback) => { callback(); });
    };

    this.addHook = (name, callback) => {
      this._hooks[name].push(callback);
    };

    this.get = ({ id }) => {
      return this._ourState.dataGet({ id });
    };

    this.getAll = () => {
      return this._ourState.dataGetAll();
    };

    this.reset = (data, callback = () => {}) => {
      this._ourState.dataSet(data, () => {
        this.setAsSavePoint(callback);
      });
    };

    this.change = (data, callback = () => {}) => {
      this._ourState.dataSet({
        ...data,
        _changed: true,
      }, callback);
    };

    this.setWithoutChanging = (data, callback = () => {}) => {
      this._ourState.dataSet(data, callback);
    };

    this.setAsSavePoint = (callback = () => {}) => {
      const afterDataSet = () => {
        this._savePoint = this._ourState.dataGetAll();
        this._runAllHooks('setAsSavePoint');
        callback();
      };

      this._ourState.dataSet({
        _changed: false,
      }, afterDataSet);
    };

    this.resetToSavePoint = () => {
      this._ourState.dataSet(this._savePoint);
    };

    this.isChanged = () => {
      return this._ourState.dataGet({ id: '_changed' });
    };

    // We have to store UI-related data in the same component's state.
    // Hence the delegation of the following methods.

    this.uiSet = (obj, callback = () => {}) => {
      this._ourState.uiSet(obj, callback);
    }

    this.uiGet = ({ id }) => {
      return this._ourState.uiGet({ id });
    }
  }
}

export default StateWithSavePoints;
