class Location {
  constructor({ placeId, originalText, shortText, lat, lng }) {
    if (placeId !== null && typeof placeId !== 'string') {
      throw Error('Unexpected value of placeId');
    }

    if (originalText !== null && typeof originalText !== 'string') {
      throw Error('Unexpected value of originalText');
    }

    if (shortText !== null && typeof shortText !== 'string') {
      throw Error('Unexpected value of shortText');
    }

    // We use strings in JSON to represent decimal numbers,
    // which is a recommended way to keep precision intact.
    if (lat !== null && typeof lat !== 'string') {
      throw Error('Unexpected value of lat');
    }

    if (lng !== null && typeof lng !== 'string') {
      throw Error('Unexpected value of lng');
    }

    this.placeId      = placeId;
    this.originalText = originalText;
    this.shortText    = shortText;
    this.lat          = lat;
    this.lng          = lng;
  }

  isEmpty() {
    return (
      this.placeId === null
      && this.originalText === null
      && this.shortText === null
      && this.lat === null
      && this.lng === null
    );
  }

  isValid() {
    return (
      this.originalText !== null
      && this.shortText !== null
    );
  }

  toApiFormat() {
    return {
      placeId:      this.placeId,
      originalText: this.originalText,
      shortText:    this.shortText,
      lat:          this.lat,
      lng:          this.lng,
    }
  }

  static fromApiFormat(data) {
    if (data === null) {
      return Location.createEmpty();
    }

    return new Location({
      placeId:      data.placeId,
      originalText: data.originalText,
      shortText:    data.shortText,
      lat:          data.lat,
      lng:          data.lng,
    });
  }

  static createEmpty() {
    return new Location({
      placeId:      null,
      originalText: null,
      shortText:    null,
      lat:          null,
      lng:          null,
    });
  }
}

export default Location;
