import { defined } from 'lib/helpers';

class Responsibility {
  constructor(value) {
    const expectedValues = [
      null,
      Responsibility.TEAM_MEMBER,
      Responsibility.LEAD,
      Responsibility.MANAGER,
      Responsibility.LEADER,
    ];

    if (expectedValues.indexOf(defined(value)) === -1) {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new Responsibility(null);
  }

  toApiFormat() {
    switch (this.value) {
      case Responsibility.TEAM_MEMBER: return 'TEAM_MEMBER';
      case Responsibility.LEAD:        return 'LEAD';
      case Responsibility.MANAGER:     return 'MANAGER';
      case Responsibility.LEADER:      return 'LEADER';
      default:                         throw Error('Unexpected value');
    }
  }

  static fromApiFormat(data) {
    const value = (() => {
      switch (data) {
        case 'TEAM_MEMBER': return Responsibility.TEAM_MEMBER;
        case 'LEAD':        return Responsibility.LEAD;
        case 'MANAGER':     return Responsibility.MANAGER;
        case 'LEADER':      return Responsibility.LEADER;
        default:            throw Error('Unexpected value');
      }
    })();

    return new Responsibility(value);
  }
}

Responsibility.TEAM_MEMBER = 0;
Responsibility.LEAD        = 1;
Responsibility.MANAGER     = 2;
Responsibility.LEADER      = 3;

export default Responsibility;
