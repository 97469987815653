import './index.scss';
import React from 'react';

import LinkWrapper from 'component-lib/components/link-wrapper';
import ImgTag      from 'component-lib/components/img-tag';
import Text        from 'component-lib/type/text';

import {
  style16pxMediumBluegreyD,
  style16pxRegularBluegrey,
} from 'component-lib/type/text/styles';

export const VerifiedActivityFooter = ({
  issuerProfileLink,
  issuerProfileImage,
  issuer,
  location,
  sealImage,
}) => {
  return (
    <div className = "v2-verified-activity-footer">
      <LinkWrapper link = { issuerProfileLink } >
        <div className = "v2-verified-activity-footer__profile-image-and-info">
          <ImgTag
            width = "64px"
            src = { issuerProfileImage }
            isRounded = { true }
          />
          <div className = "v2-verified-activity-footer__issuer-info">
            <div className = "v2-verified-activity-footer__issuer-name">
              <Text { ...style16pxMediumBluegreyD } text = { issuer } />
            </div>
            <Text { ...style16pxRegularBluegrey } text = { location } />
          </div>
        </div>
      </LinkWrapper>
      <div className = "v2-verified-activity-footer__seal-image">
        <ImgTag
          src = { sealImage }
        />
      </div>
    </div>
  );
};
