// Link / Underline Btn
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=3345%3A686&viewport=946%2C-366%2C1.7046226263046265

// Link / Underline Active
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=3280%3A5&viewport=946%2C-366%2C1.7046226263046265

import './index.scss';
import React from 'react';

import Text from 'component-lib/type/text';

export const DottedUnderlineButton = ({ copy, onClick, color }) => {
  return (
    <div
      className = { `dotted-underline-button dotted-underline-button--${color}` }
      onClick = { onClick }
    >
      <Text
        fontSize = "16px"
        fontWeight = "medium"
        color = { color }
        text = { copy }
      />
    </div>
  );
};
