import './index.scss';
import React from 'react';

import Markdown from 'components/markdown';

class StudentAboutPanelView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    const namesOfClasses = 'v2-student-about-panel-view' +
      (data.canEdit() ? ' v2-student-about-panel-view--clickable' : '');

    const onClick = () => {
      if (data.canEdit()) {
        data.openSummaryForm();
      }
    };

    return (
      <div
        className = { namesOfClasses }
        onClick   = { onClick }
      >
        <h3 className = "v2-student-about-panel-view__heading">About me</h3>
        <div className = "v2-student-about-panel-view__content">
          <Markdown>
            { data.getSummary() }
          </Markdown>
        </div>
      </div>
    );
  }
};

export default StudentAboutPanelView;
