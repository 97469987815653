import { defined } from 'lib/helpers';

class Campaigns {
  constructor({ store, v1deps }) {
    this.campaignsOfEmployer = ({ profileId }) => {
      const state = defined(store).getState();
      return v1deps['selectors'].employerJobsSelector({ id: defined(profileId) })(state);
    };
  }
}

export default Campaigns;
