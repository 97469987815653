// Panel Event Link
// https://www.figma.com/file/bENcNjSL5eGnTxB8BCCjt6Jb/Capture-Your-Experience?node-id=1406%3A2101&viewport=1698%2C869%2C1.6869360208511353

import './index.scss';
import React from 'react';

import ImgTag              from 'component-lib/components/img-tag';
import XPEntryDetailsTitle from 'component-lib/components/xp-entry-details-title';

const PanelEventLink = ({ activityImg, activityName, sourceName, sourceIconSrc, isClickable }) => {
  return (
    <div className = { `panel-event-link ${isClickable ? 'panel-event-link--clickable' : ''}` }>
      <div className = "panel-event-link__content">
        <ImgTag
          src   = { activityImg }
          width = '112px'
        />
        <XPEntryDetailsTitle
          title           = { activityName }
          subtitle        = { sourceName }
          subtitleIconSrc = { sourceIconSrc }
        />
      </div>
      {
        isClickable &&
        <div className = 'panel-event-link__link-icon'>
        </div>
      }
    </div>
  );
};

export default PanelEventLink;
