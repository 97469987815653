import './index.scss';
import React from 'react';

import ImgTag                      from 'component-lib/components/img-tag';
import Text                        from 'component-lib/type/text';
import { style16pxLightBluegreyD } from 'component-lib/type/text/styles';

const FlagAndCopy = ({ countryIcon, copy }) => {
  return (
    <div className = "v2-flag-and-copy">
      <ImgTag width = '40px' src = { countryIcon } />
      <Text { ...style16pxLightBluegreyD } text = { copy } />
    </div>
  );
};

export default FlagAndCopy;
