import React     from 'react';
import pluralize from 'pluralize';

export const defined = (value, name = null) => {
  const msg = (name ? `of '${name}' ` : '');

  if (typeof value == 'undefined') {
    throw new Error(`¡no pasarán! The value ${msg}cannot be undefined.`);
  }

  return value;
};

export class DefinedMap {
  constructor({ attributes, data }) {
    const hash = attributes.reduce((result, attr) => {
      result[attr] = defined(data[attr], `${attr} attribute`);
      return result;
    }, {});

    this._hash = hash;
  }

  cloneWithChanges(changeSet) {
    return new this.constructor({
      ...this.toObject(),
      ...changeSet,
    });
  }

  get(attr) {
    return this._hash[attr];
  }

  toObject() {
    return this._hash;
  }
}

const calcNumberOfYMWD = ({ startDate, endDate }) => {
  const MILLISECONDS_IN_DAY = 1000*60*60*24;
  const DAYS_IN_WEEK        = 7;
  const MONTHS_IN_YEAR      = 12;

  const startDateWithoutTime = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );

  const endDateWithoutTime = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate() + 1, // include the last day of the period
  );

  // We don't care about the fractional part (milliseconds), we want to count only whole days.
  const diff = parseInt((endDateWithoutTime - startDateWithoutTime) / MILLISECONDS_IN_DAY);

  // Example: the period of [15.02.2019 - 10.03.2019] is less than a month,
  // even though the months have changed.
  const decreaseMonths = (endDateWithoutTime.getDate() < startDateWithoutTime.getDate());

  const calendarMonthsDiff =
    endDateWithoutTime.getMonth() - startDateWithoutTime.getMonth() +
    (decreaseMonths ? (-1) : 0) +
    (MONTHS_IN_YEAR * (endDateWithoutTime.getFullYear() - startDateWithoutTime.getFullYear()));

  return {
    years:  parseInt(calendarMonthsDiff / MONTHS_IN_YEAR),
    months: calendarMonthsDiff % MONTHS_IN_YEAR,
    weeks:  parseInt(diff / DAYS_IN_WEEK),
    days:   diff % DAYS_IN_WEEK,
  };
};

export const fetchStringOfYMWD = ({ startDate, endDate }) => {
  const { years, months, weeks, days } = calcNumberOfYMWD({ startDate, endDate });

  const yearsMonthsString = [
    (years > 0) ? pluralize('Year', years, true) : null,
    (months > 0) ? pluralize('Month', months, true) : null,
  ].filter(e => (e !== null)).join(', ');

  const weeksDaysString = [
    (weeks > 0) ? pluralize('Week', weeks, true) : null,
    (days > 0) ? pluralize('Day', days, true) : null,
  ].filter(e => (e !== null)).join(', ');

  return yearsMonthsString || weeksDaysString;
};

export const componentWithAppv2 = (Component, appv2) => {
  return (props => <Component { ...props } appv2 = { appv2 } />);
};

export const escapeForRegexp = (s) => {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const regexpWithUrlHead = (url) => {
  /***********
   * matches:
   *  `${url}`
   *  `${url}/something`
   *  `${url}?something`
   *
   * doesn't match:
   *  `${url}something`
   **********/

  return new RegExp('^' + escapeForRegexp(url) + '(?:$|[?/])');
};

export const fetchCurrentUrl = () => {
  return window.location.href.split('?')[0];
};

export const profilePageUrlRegExp = /(^.+\/profile\/[^\/]+).*$/;

export const isProfilePage = () => {
  return profilePageUrlRegExp.test(fetchCurrentUrl());
};

export const urlToBeShared = () => {
  const currentUrl = fetchCurrentUrl();

  return isProfilePage()
    ? currentUrl.replace(profilePageUrlRegExp, '\$1/experience')
    : currentUrl;
};

export const validUrlRegex = /^(?:(?:(?:https?):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[\/?#]\S*)?$/;

export const stringStartsWithANumber = /^[0-9]/;
export const stringIncludesOnlyExpectedCharacters = /^(?:[a-z]|[0-9]|-|_)+$/;

const _polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
};

/******
 * This function generates data for a SVG <path> element,
 * representing a partial circle (an arc).
 * The resulting string has to be set as a value of the `d` attribute of the <path> element.
 *
 * x, y - center of the circle
 * radius - radius of the circle
 * startAngle, endAngle - angles where to start and end the arc
 ******/
export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = _polarToCartesian(x, y, radius, endAngle);
  const end = _polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  if (endAngle >= 360) {
    return describeArc(x, y, radius, startAngle, 359) + ' Z';
  }

  return [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");
};

// Reference for algorithm of shuffling an array
// https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array/6274381#answer-6274381
export const shuffle = (array) => {
  for (let index = array.length - 1; index > 0; index--) {
    let randomIndex = Math.floor(Math.random() * (index + 1));
    [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
  }

  return array;
};
