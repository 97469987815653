import { defined, DefinedMap } from 'lib/helpers';
import VariantMapping from 'lib/api/variant-mapping';
import createDetailsClass from 'lib/api/activities/generics/create-details-class';

const getVariantHandler = (variantId) => {
  return VariantMapping.getFor({ variantId });
};

class ActivityAsTimelineItem extends DefinedMap {
  constructor(data) {
    super({
      attributes: [
        'id',
        'profileId',
        'variantId',
        'details',
        'skillsCount',
        'duration',
        'xpNumber',
      ],
      data
    });
  }

  static fromApiFormat(data, { isXpFeatureEnabled = false }) {
    const variantHandler = getVariantHandler(defined(data.variantId));

    const detailsHandler = createDetailsClass({ attributes: variantHandler.detailsAttributes() });
    const details        = detailsHandler.fromApiFormat(defined(data.details));

    const skillsCount = variantHandler
      .skillsCountHandler()
      .fromApiFormat(defined(data.skillsCount));

    const durationWithPercentageComplete = Object.assign(data.duration, { percentageComplete: data.percentageComplete });

    const duration = variantHandler
      .durationHandler()
      .fromApiFormat(defined(durationWithPercentageComplete));

    return new ActivityAsTimelineItem({
      ...data,
      details,
      skillsCount,
      duration,
      ...(isXpFeatureEnabled ? {} : { xpNumber: 0 }),
    });
  }
}

export default ActivityAsTimelineItem;
