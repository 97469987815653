import './index.scss';
import React from 'react';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.checkboxInput.focus();
      }, 0);
    }
  }

  render() {
    const {
      name,
      value,
      label,
      isChecked,
      message,
      isError,
      onChange,
      isAutoFocused
    } = this.props;

    const renderMessage = ({ message, isError }) => {
      if (message === null) {
        return null;
      }
      return(
        <div className = { `v2-checkbox__message
          ${isError ? 'v2-checkbox__message--error' : ''}` }>
          { message }
        </div>
      );
    };

    return (
      <div className = "v2-checkbox">
        <div className = "v2-checkbox__container">
          <input
            ref={(realInputElementOnPage) => { this.checkboxInput =  realInputElementOnPage; }}
            id = { name }
            name = { name }
            type = "checkbox"
            className = "v2-checkbox__input"
            value = { value }
            onChange = { (event) => onChange(event.target.checked) }
            checked = { isChecked ? ' checked' : '' }
          />
          <label className = "v2-checkbox__label" htmlFor = { name } >
            { label }
          </label>
        </div>
        { renderMessage({ message, isError }) }
      </div>
    );
  }
};

export default Checkbox;
