import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import SecondaryDetailReadonly       from 'components/v2/activities/generics/fields/secondary-detail/readonly';
import FlagAndCopy                   from 'component-lib/components/flag-and-copy';
import { getFlagFor }                from 'components/v2/activities/generics/location/country-flags';

const HighSchoolDetailsPanelView = ({ data }) => {
  const { detailsData } = data;

  const location = detailsData.getField('location');
  const grade    = detailsData.getField('grade');

  return (
    <div className = "v2-high-school-details-panel-view">
      <Text { ...style20pxRegularBluegreyD } text = { detailsData.getField('school').name } />
      <SecondaryDetailReadonly detail = { detailsData.getField('campus').name } />

      <FlagAndCopy
        countryIcon = { getFlagFor({ country: location }) }
        copy = { grade ? `${ location } | ${ grade }` : location }
      />

      <div className = "v2-high-school-details-panel-view__subjects">
        { detailsData.getSubjectsAsList().join(' • ') }
      </div>
    </div>
  );
};

export default HighSchoolDetailsPanelView;
