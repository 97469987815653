import './index.scss';
import React from 'react';

import PanelFormHeadline from 'components/v2/activities/generics/panel-form-headline';
import Datepicker        from 'components/v2/input-fields/datepicker';

import { EndDateSuggestion }            from 'component-lib/components/end-date-suggestion';
import { ProgressBarRangeInputSection } from 'component-lib/components/progress-bar-range-input-section';
import { ProgressBarRangeInput }        from 'component-lib/components/progress-bar-range-input';
import { isEndDateInThePast }           from 'lib/api/activities/generics/duration-common';

const EducationCommonDurationPanelForm = ({
  startDate,
  endDate,
  percentageComplete,
  isDurationDataValid,
  onStartDateChange,
  onEndDateChange,
  onPercentageCompleteChange,
  data,
  // All the props are passed in from renderFormView of DurationSection
}) => {
  const getProgressBarProps = () => {
    if (isDurationDataValid && typeof percentageComplete === 'number') {
      const endYear  = endDate.getFullYear();
      const endMonth = endDate.getMonth() + 1;
      const endDay   = endDate.getDate();

      return {
        isEditable:      isEndDateInThePast({ endYear, endMonth, endDay }),
        titleCopy:       `${percentageComplete}% Complete`,
        titleColor:      (percentageComplete === 100) ? 'green' : 'blue',
        fillColor:       (percentageComplete === 100) ? 'green' : 'blue',
        trackColor:      'blue-xxl',
        hintMessageCopy: 'You can edit % Complete once an Activity has Finished.',
      };
    }

    return {
      isEditable:      false,
      titleCopy:       'Enter Start and Finish dates to see % Complete.',
      titleColor:      'bluegrey',
      fillColor:       'transparent',
      trackColor:      'bluegrey-l',
      hintMessageCopy: null,
    };
  };

  const {
    isEditable,
    titleCopy,
    titleColor,
    fillColor,
    trackColor,
    hintMessageCopy,
  } = getProgressBarProps();

  return (
    <div className = "v2-education-common-duration-panel-form">
      <PanelFormHeadline
        iconSrc     = { require('images/v2/xp/activity-duration.svg') }
        title       = 'Progress'
        description = 'Enter your Start and Finish dates for this Activity...'
      />

      <div className = "v2-education-common-duration-panel-form__container">
        <div className = "columns v2-education-common-duration-panel-form__form">
          <div className = "column">
            <Datepicker
              isAutoFocused = { true }
              label         = "Start"
              placeholder   = "Select Date"
              value         = { startDate }
              onChange      = { (value) => { onStartDateChange(value); } }
            />
          </div>
          <div className = "column v2-education-common-duration-panel-form__end-date">
            <Datepicker
              label       = "Finish"
              placeholder = "Select Date"
              value       = { endDate }
              onChange    = { (value) => { onEndDateChange(value); } }
              message     = "Date finished or expected to finish"
            />
            <EndDateSuggestion
              startDate = { startDate }
              duration  = { data.detailsData.getDurationObject() }
              onClick   = { (value) => { onEndDateChange(value); } }
            />
          </div>
        </div>
      </div>

      <div className = "v2-education-common-duration-panel-form__progress-bar-range-input-section">
        <ProgressBarRangeInputSection
          titleCopy       = { titleCopy }
          titleColor      = { titleColor }
          hintMessageCopy = { hintMessageCopy }
        >
          <ProgressBarRangeInput
            isEditable                 = { isEditable }
            fillColor                  = { fillColor }
            trackColor                 = { trackColor }
            percentageComplete         = { isDurationDataValid ? percentageComplete : 0 }
            onPercentageCompleteChange = { onPercentageCompleteChange }
          />
        </ProgressBarRangeInputSection>
      </div>
    </div>
  );
};

export default EducationCommonDurationPanelForm;
