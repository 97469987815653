export const style14pxLightBluegreyItalic = { fontSize: '14px', fontWeight: 'light',   color: 'bluegrey', fontStyle: 'italic' };
export const style14pxLightBluegreyD      = { fontSize: '14px', fontWeight: 'light',   color: 'bluegrey-d',                   };
export const style14pxLightGreyL          = { fontSize: '14px', fontWeight: 'light',   color: 'grey-l'                        };
export const style14pxLightWhite          = { fontSize: '14px', fontWeight: 'light',   color: 'white'                         };

export const style14pxRegularBluegreyD    = { fontSize: '14px', fontWeight: 'regular', color: 'bluegrey-d'                    };

export const style16pxLightBlue           = { fontSize: '16px', fontWeight: 'light',   color: 'blue'                          };
export const style16pxLightWhite          = { fontSize: '16px', fontWeight: 'light',   color: 'white'                         };
export const style16pxLightBluegreyL      = { fontSize: '16px', fontWeight: 'light',   color: 'bluegrey-l'                    };
export const style16pxLightBluegrey       = { fontSize: '16px', fontWeight: 'light',   color: 'bluegrey'                      };
export const style16pxLightBluegreyD      = { fontSize: '16px', fontWeight: 'light',   color: 'bluegrey-d'                    };
export const style16pxLightGreyL          = { fontSize: '16px', fontWeight: 'light',   color: 'grey-l'                        };
export const style16pxLightGrey           = { fontSize: '16px', fontWeight: 'light',   color: 'grey'                          };

export const style16pxRegularWhite        = { fontSize: '16px', fontWeight: 'regular', color: 'white'                         };
export const style16pxRegularBlueL        = { fontSize: '16px', fontWeight: 'regular', color: 'blue-l'                        };
export const style16pxRegularBlue         = { fontSize: '16px', fontWeight: 'regular', color: 'blue'                          };
export const style16pxRegularBluegrey     = { fontSize: '16px', fontWeight: 'regular', color: 'bluegrey'                      };
export const style16pxRegularBluegreyD    = { fontSize: '16px', fontWeight: 'regular', color: 'bluegrey-d'                    };
export const style16pxRegularGreyD        = { fontSize: '16px', fontWeight: 'regular', color: 'grey-d'                        };
export const style16pxRegularGreen        = { fontSize: '16px', fontWeight: 'regular', color: 'green'                         };
export const style16pxRegularGreyL        = { fontSize: '16px', fontWeight: 'regular', color: 'grey-l'                        };

export const style16pxMediumWhite         = { fontSize: '16px', fontWeight: 'medium',  color: 'white'                         };
export const style16pxMediumBluegrey      = { fontSize: '16px', fontWeight: 'medium',  color: 'bluegrey'                      };
export const style16pxMediumBluegreyD     = { fontSize: '16px', fontWeight: 'medium',  color: 'bluegrey-d'                    };
export const style16pxMediumGreyL         = { fontSize: '16px', fontWeight: 'medium',  color: 'grey-l'                        };

export const style16pxBoldWhite           = { fontSize: '16px', fontWeight: 'bold', color: 'white'                            };

export const style18pxRegularBlueD        = { fontSize: '18px', fontWeight: 'regular', color: 'blue-d'                        };
export const style18pxRegularBluegrey     = { fontSize: '18px', fontWeight: 'regular', color: 'bluegrey'                      };

export const style20pxRegularWhite        = { fontSize: '20px', fontWeight: 'regular', color: 'white'                         };
export const style20pxRegularBlue         = { fontSize: '20px', fontWeight: 'regular', color: 'blue'                          };
export const style20pxRegularBluegreyL    = { fontSize: '20px', fontWeight: 'regular', color: 'bluegrey-l'                    };
export const style20pxRegularBluegrey     = { fontSize: '20px', fontWeight: 'regular', color: 'bluegrey'                      };
export const style20pxRegularBluegreyD    = { fontSize: '20px', fontWeight: 'regular', color: 'bluegrey-d'                    };
export const style20pxRegularGreyD        = { fontSize: '20px', fontWeight: 'regular', color: 'grey-d'                        };
export const style20pxRegularGreen        = { fontSize: '20px', fontWeight: 'regular', color: 'green'                         };
export const style20pxRegularGreyL        = { fontSize: '20px', fontWeight: 'regular', color: 'grey-l'                        };
export const style20pxRegularGrey         = { fontSize: '20px', fontWeight: 'regular', color: 'grey'                          };

export const style20pxMediumBlue          = { fontSize: '20px', fontWeight: 'medium',  color: 'blue'                          };

export const style22pxRegularWhite        = { fontSize: '22px', fontWeight: 'regular', color: 'white'                         };
export const style22pxRegularBluegreyD    = { fontSize: '22px', fontWeight: 'regular', color: 'bluegrey-d'                    };
export const style22pxRegularGreyD        = { fontSize: '22px', fontWeight: 'regular', color: 'grey-d'                        };

export const style26pxRegularBlueL        = { fontSize: '26px', fontWeight: 'regular', color: 'blue-l'                        };
export const style26pxRegularBlue         = { fontSize: '26px', fontWeight: 'regular', color: 'blue'                          };
export const style26pxRegularBluegrey     = { fontSize: '26px', fontWeight: 'regular', color: 'bluegrey'                      };

export const style26pxMediumBluegreyD     = { fontSize: '26px', fontWeight: 'medium',  color: 'bluegrey-d'                    };

export const style32pxRegularBluegreyD    = { fontSize: '32px', fontWeight: 'regular', color: 'bluegrey-d'                    };
