import './index.scss';
import React from 'react';

export const BlueSubmitButton = ({ customCopy, onClick, isDisabled }) => {
  return (
    <button
      className = { `blue-submit-button blue-submit-button--${isDisabled ? 'disabled' : 'enabled'}` }
      onClick   = { () => { !isDisabled && onClick(); } }
    >
      <span>{ customCopy || 'Submit' }</span>
    </button>
  );
};
