import './index.scss';
import React from 'react';

import FindOutMoreButton from 'components/v2/buttons/square-buttons/find-out-more-button';

const PublicProfileEmptyState = ({ iconSrc, description, link, target }) => {
  return(
    <div className = "v2-public-profile-empty-state">
      <img className = "v2-public-profile-empty-state__icon" src = { iconSrc } alt = "" />
      <div className = "v2-public-profile-empty-state__description">{ description }</div>
      <div className = "v2-public-profile-empty-state__btn">
        <FindOutMoreButton link = { link } target = { target } />
      </div>
    </div>
  );
};

export default PublicProfileEmptyState;
