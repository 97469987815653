import './index.scss';
import { defined } from 'lib/helpers';
import React       from 'react';

import { getAllEvents, getEventDataFor } from 'lib/api/var-competition/paddl-games-events';

import PanelFormHeadline  from 'components/v2/activities/generics/panel-form-headline';
import InputTextMultiline from 'components/v2/input-fields/input-text-multiline';
import Select             from 'components/v2/input-fields/select';
import Checkbox           from 'components/v2/input-fields/checkbox';

class PaddlGamesDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const eventId   = detailsData.getField('eventId');
    const eventData = eventId && getEventDataFor({ eventId });

    const renderEventOptions = () => {
      const date_sort_desc = function (event1, event2) {
        // This is a comparison function that will sort events by startDates in DESCENDING order, and if the startDates are the same it will sort events by endDates in DESCENDING order.
        if (event1.duration.startDate > event2.duration.startDate) return -1;
        if (event1.duration.startDate < event2.duration.startDate) return 1;
        if (event1.duration.endDate   > event2.duration.endDate)   return -1;
        if (event1.duration.endDate   < event2.duration.endDate)   return 1;
        return 0;
      };

      const eventOptions = getAllEvents().sort(date_sort_desc).map((evt) => ({
        value: evt.id,
        title: evt.name,
      }));

      return (
        <div className = "column is-full">
          <Select
            name = "eventId"
            value = { eventId }
            label = "Paddl Games Event"
            options = { eventOptions }
            onChange = { (id) => {
              detailsData.setField({
                attrName: 'eventId',
                data:     parseInt(id, 10)
              });
            } }
            placeholder = "Select an option"
            isAutoFocused
          />
        </div>
      );
    };

    const renderHostCompany = () => {
      return (
        <div className = "column is-half">
          <InputTextMultiline
            name = "hostCompany"
            label = "Host Company"
            placeholder = ""
            value = { eventData ? eventData.hostCompany.title : '' }
            isReadonly
          />
        </div>
      );
    };

    const renderLocation = () => {
      return (
        <div className = "column is-half">
          <InputTextMultiline
            name = "location"
            label = "Location"
            placeholder = ""
            value = { eventData ? eventData.location : '' }
            isReadonly
          />
        </div>
      );
    };

    const renderChallengeFocus = () => {
      return (
        <div className = "column is-full">
          <InputTextMultiline
            name = "challengeFocus"
            label = "Challenge Focus"
            placeholder = ""
            value = { eventData ? eventData.challengeFocus : '' }
            isReadonly
          />
        </div>
      );
    };

    const renderTeamName = () => {
      return (
        <div className = "column is-half">
          <Select
            name = "teamName"
            value = { detailsData.getField('teamName') }
            label = "Team Name"
            options = { eventData ? eventData.teamNames.sort().map(t => ({ value: t, title: t })) : [] }
            placeholder = "Select Team"
            onChange = { (value) => { detailsData.setField({ attrName: 'teamName', data: value }); } }
          />
        </div>
      );
    };

    const renderCompetitionPlace = () => {
      const generateOptions = () => {
        const maxPlaceNumber = Math.min(eventData.teamNames.length, 3);
        const numberedList = Array.from(Array(maxPlaceNumber).keys()).map(i => ({ value: (i + 1), title: (i + 1) }));
        return numberedList.concat({ value: 0, title: 'Participant' })
      };

      return (
        <div className = "column is-half">
          <Select
            name = "competitionPlace"
            value = { detailsData.getField('competitionPlace') }
            label = "Competition Place"
            options = { eventData ? generateOptions() : [] }
            placeholder = "Select Place"
            onChange = { (value) => { detailsData.setField({ attrName: 'competitionPlace', data: parseInt(value, 10) }); } }
          />
        </div>
      );
    };

    const renderRoleTeamLeader = () => {
      return (
        <div className = "column is-half">
          <Checkbox
            name = "roleTeamLeader"
            value = { 1 }
            label = "Team Leader"
            isChecked = { detailsData.getField('roleTeamLeader') }
            onChange = { (value) => { detailsData.setField({ attrName: 'roleTeamLeader', data: value }); } }
          />
        </div>
      );
    };

    const renderRolePresenter = () => {
      return (
        <div className = "column is-half">
          <Checkbox
            name = "rolePresenter"
            value = { 1 }
            label = "Presenter"
            isChecked = { detailsData.getField('rolePresenter') }
            onChange = { (value) => { detailsData.setField({ attrName: 'rolePresenter', data: value }); } }
          />
        </div>
      );
    };

    const renderRoleDemonstrator = () => {
      return (
        <div className = "column is-half">
          <Checkbox
            name = "roleDemonstrator"
            value = { 1 }
            label = "Demonstrator"
            isChecked = { detailsData.getField('roleDemonstrator') }
            onChange = { (value) => { detailsData.setField({ attrName: 'roleDemonstrator', data: value }); } }
          />
        </div>
      );
    };

    const renderRoleCreative = () => {
      return (
        <div className = "column is-half">
          <Checkbox
            name = "roleCreative"
            value = { 1 }
            label = "Creative"
            isChecked = { detailsData.getField('roleCreative') }
            onChange = { (value) => { detailsData.setField({ attrName: 'roleCreative', data: value }); } }
          />
        </div>
      );
    };

    return (
      <div className = "v2-paddl-games-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Games Details'
          description = 'Select a Paddl Games Event below to get started...'
        />

        <div className = "columns is-multiline">
          { renderEventOptions() }
          { renderHostCompany() }
          { renderLocation() }
          { renderChallengeFocus() }
          { renderTeamName() }
          { renderCompetitionPlace() }

          <div className = "column is-full">
            <label className = "v2-paddl-games-details-panel-form__roles-label">
              Roles
            </label>
          </div>
          { renderRoleTeamLeader() }
          { renderRolePresenter() }
          { renderRoleDemonstrator() }
          { renderRoleCreative() }
        </div>
      </div>
    );
  }
};

export default PaddlGamesDetailsPanelForm;
