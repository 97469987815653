import './index.scss';
import React from 'react';
import { defined } from 'lib/helpers';

import { getCategoryData } from 'lib/api/categories';
import VariantMapping      from 'lib/api/variant-mapping';

import CardHeader      from 'components/v2/activities/generics/card-header';
import CategoryItem    from 'components/v2/activities/generics/category-item';
import DropdownVariant from 'components/v2/input-fields/dropdown-variant';
import StepGuide       from 'components/v2/step-guide';

const variantOptions = (variants) => {
  return variants.map((variantId) => ({
    value: variantId,
    title: VariantMapping.getFor({ variantId }).title(),
  }));
};

const getVariantOptionTitle = (variants, variantId) => {
  const option = variantOptions(variants).find((opt) => (opt.value === variantId));
  return (option ? option.title : null);
};

class SelectVariantForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentVariantId: null,
      isListDisplayed:  false,
    };
  }

  setCurrentVariantId({id}) {
    this.setState({
      currentVariantId: id,
    });
  }

  getCurrentVariantId() {
    return this.state.currentVariantId;
  }

  showList() {
    this.setState({
      displayList: true,
    });
  }

  hideList() {
    this.setState({
      displayList: false,
    });
  }

  isListDisplayed() {
    return this.state.displayList;
  }

  render() {
    const {
      appv2,
      categoryId,
      onContinue,
    } = this.props;

    const categoryData = getCategoryData({ categoryId });
    const visibleVariants = categoryData.visibleVariants({ appv2 });

    const renderFooterInternals = (appv2, categoryTitle) => {
      return (
        <StepGuide categoryTitle = { categoryTitle } />
      );
    };

    return (
      <div className = "v2-select-variant-form">
        <div className = "v2-select-variant-form__form">
          <div className = "v2-select-variant-form__container">
            <div className = "v2-select-variant-form__header">
              <CardHeader
                title = "Add an Activity"
                subtitle = "Select an Activity below to get started..."
              />
            </div>

            <CategoryItem
              categoryId = { categoryId }
              isClickable = { false }
            />

            <div className = "v2-select-variant-form__dropdown">
              <DropdownVariant
                value              = { getVariantOptionTitle(visibleVariants, this.getCurrentVariantId()) }
                onPlaceholderClick = { () => { this.showList(); } }
                onOptionClick      = { (item) => {
                  const selectedId = item.value;
                  this.setCurrentVariantId({ id: selectedId });
                  this.hideList();
                  onContinue({ variantId: selectedId });
                } }
                onPlaceholderBlur  = { () => { this.hideList(); } }
                options            = {
                  (this.isListDisplayed() ? variantOptions(visibleVariants) : [])
                }
              />
            </div>
          </div>
        </div>
        { renderFooterInternals(appv2, categoryData.title()) }
      </div>
    );
  }
}

export default SelectVariantForm;
