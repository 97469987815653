import { defined } from 'lib/helpers';

const numericValue = (value) => {
  const parsedValue = parseFloat(defined(value), 10);

  if (isNaN(parsedValue)) {
    throw Error('Unexpected value: value should be a number');
  }

  if (parsedValue !== parseInt(parsedValue)) {
    throw Error('Unexpected value: value should be an integer');
  }

  if (parsedValue < 1 || parsedValue > 168) {
    throw Error('Unexpected value: value should be between 1 to 168');
  }

  return parsedValue;
};

class HoursPerWeek {
  constructor(value) {
    this.value = (value === null ? null : numericValue(value));
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new HoursPerWeek(null);
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new HoursPerWeek(data);
  }
}

export default HoursPerWeek;
