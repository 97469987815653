import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';
import Text         from 'lib/api/activities/generics/fields/text';
import Subjects     from 'lib/api/activities/categories/education/fields/subjects';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/education/variants/high-school/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/education/variants/high-school/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/education/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form';

import DurationUnit from 'lib/api/activities/generics/duration-value-unit/duration-unit';

class HighSchool {
  static categoryCssClassPrefix() {
    return 'v2-categories-education-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-education-high-school.svg');
  }

  static title() {
    return 'High School';
  }

  static subtitle() {
    return 'Subjects from Senior Years.';
  }

  static detailsAttributes() {
    const fetchHighSchoolsSuggestions        = ({ appv2 }) => { return appv2.api.education.fetchHighSchoolsSuggestions.bind(appv2.api.education);        };
    const fetchHighSchoolCampusesSuggestions = ({ appv2 }) => { return appv2.api.education.fetchHighSchoolCampusesSuggestions.bind(appv2.api.education); };

    return {
      school:   { required: true,  dataType: IdNameObject, fieldLabel: 'School Name', fieldPlaceholder: 'e.g. Hometown High', suggestionsFunction: fetchHighSchoolsSuggestions        },
      campus:   { required: true,  dataType: IdNameObject, fieldLabel: 'Campus Name', fieldPlaceholder: 'e.g. Melbourne',     suggestionsFunction: fetchHighSchoolCampusesSuggestions },
      location: { required: true,  dataType: Text,         fieldLabel: 'Location',    fieldPlaceholder: 'Select Country'              },
      grade:    { required: false, dataType: Text,         fieldLabel: 'Grade',       fieldPlaceholder: '00.00'                       },
      subjects: { required: true,  dataType: Subjects,     fieldLabel: 'Subjects',    fieldPlaceholder: 'e.g. English, History, etc.' },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: this.title(),
      activityProviderName: details.get('school').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({
      attributes: this.detailsAttributes(),
      getDurationObjectFn: (detailsData) => {
        return {
          value: 2,
          unit:  DurationUnit.YEAR,
        };
      },
    });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default HighSchool;
