import './index.scss';
import React from 'react';
import tippy from 'tippy.js';

import Text                        from 'component-lib/type/text';
import { TimelineProgressStatus }  from 'component-lib/components/timeline-progress-status';
import { XpNumberAndSkillsCount }  from 'component-lib/components/xp-number-and-skills-count';
import { XpCollectionIconAndCopy } from 'component-lib/components/xp-collection-icon-and-copy';

import {
  style22pxRegularGreyD,
  style16pxLightBluegreyD,
} from 'component-lib/type/text/styles';

export const TIMELINE  = 'timeline';
export const COOLDOWN  = 'cooldown';
export const READY     = 'ready';
export const COLLECTED = 'collected';

export class ActivityCardContent extends React.Component {
  componentDidMount() {
    // Useful refs of Tippy.js:
    // Creating Tooltips https://atomiks.github.io/tippyjs/creating-tooltips/
    // Creating a theme https://atomiks.github.io/tippyjs/themes/#creating-a-theme
    // All Props https://atomiks.github.io/tippyjs/all-props/
    tippy(this.variantIconImg, {
      content: this.props.variantTitle,
      theme: `activity-card-content--shared activity-card-content--${this.props.themeColor}`,
      placement: 'left',
      flipBehavior: ['left', 'top', 'bottom', 'right'],
      distance: 25, // %theme__activity-card: padding 24px + border 1px = 25px
    });
  }

  render() {
    const {
      variantIcon,
      variantIconShape,
      variantTitle,
      activityName,
      activityProviderName,
      percentageComplete,
      progressCopy,
      xpNumber,
      themeColor,
      themeColorD,
      skillsCount,
      cooldownMax,
      cooldownLeft,
      state,
      progressState,
    } = this.props;

    const renderStateElement = () => {
      switch (state) {
      case TIMELINE:
        return (
          <XpNumberAndSkillsCount
            xpNumber      = { xpNumber }
            xpNumberColor = { themeColor }
            xpUnitColor   = { themeColorD }
            skillsCount   = { skillsCount }
          />
        );
      case COOLDOWN:
      case READY:
      case COLLECTED:
        return (
          <XpCollectionIconAndCopy
            cooldownMax = { cooldownMax }
            cooldownLeft = { cooldownLeft }
            state = { state }
          />
        );
      default:
        throw Error(`Unexpected state: ${state}`);
      }
    };

    return (
      <div className = "activity-card-content">
        <div className = { `activity-card-content__variant-icon activity-card-content__variant-icon--${variantIconShape}` }>
          <img
            src = { variantIcon }
            alt = { `${variantTitle} icon` }
            style = { { verticalAlign: 'middle' } }
            ref = { (element) => { this.variantIconImg = element; } }
          />
        </div>
        <div className = "activity-card-content__activity-name">
          <Text
            { ...style22pxRegularGreyD }
            text = { activityName }
            isTruncated
          />
        </div>
        <div className = "activity-card-content__activity-provider-name">
          <Text
            { ...style16pxLightBluegreyD }
            text = { activityProviderName }
            isTruncated
          />
        </div>
        <div className = "activity-card-content__progress-status">
          <TimelineProgressStatus
            percentageComplete = { percentageComplete }
            copy = { progressCopy }
            progressState = { progressState }
          />
        </div>
        <div className = "activity-card-content__state-element">
          { renderStateElement() }
        </div>
      </div>
    );
  }
}
