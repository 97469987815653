import { defined } from 'lib/helpers';

class UI {
  constructor({ state }) {
    this._ourState = defined(state);

    this.initializeState = () => {
      this._ourState.uiSet({
        currentlyOpenPanel: 'details',
        sequence:           true,
      });
    };

    this.breakPanelSequence = () => {
      this._ourState.uiSet({ sequence: false });
    };

    this.arePanelsInSequence = () => {
      return this._ourState.uiGet({ id: 'sequence' });
    };

    this.getCurrentlyOpenPanel = () => {
      return this._ourState.uiGet({ id: 'currentlyOpenPanel' });
    };

    this.closePanel = () => {
      this._ourState.uiSet({ currentlyOpenPanel: null });
    };

    this.openPanel = ({ id }) => {
      this._ourState.uiSet({ currentlyOpenPanel: id });
    };
  }
}

export default UI;
