import React from 'react';

import config from 'config';
import './index.scss';

export const NavbarSignUpButton = () => (
  <a
    className="navbar-button__btn navbar-button__btn--solid"
    href={config.SIGN_UP_PAGE_URL}
  >
    Sign up
  </a>
);
