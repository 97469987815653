import './index.scss';
import React from 'react';

export const TermsPageHeroSection = () => {
  return (
    <div className = "v2-terms-page-hero-section" title="Paddl captures all of your learning experience">
      <div className = "v2-terms-page-hero-section__title">
        Paddl Terms & Conditions
      </div>
      <div className = "v2-terms-page-hero-section__subtitle">
        The nitty gritty on your relationship with Paddl
      </div>
    </div>
  );
};
