import './index.scss';
import React from 'react';

import PanelEventLink         from 'component-lib/components/panel-event-link';
import XPEntryDetailsTitle    from 'component-lib/components/xp-entry-details-title';
import LinkWrapper            from 'component-lib/components/link-wrapper';
import XpEntryLocationLogo    from 'component-lib/components/xp-entry-location-logo';
import ChallengeFocusReadonly from 'component-lib/components/challenge-focus-readonly';
import XpEntryPlace           from 'component-lib/components/xp-entry-place';
import XpTags                 from 'component-lib/components/xp-tags';

import { getSourceIcon }        from 'components/v2/activities/generics/source-icon/helper';
import { getProviderIcon }      from 'components/v2/activities/generics/provider-icon/helper';
import { getDurationValueUnit } from 'components/v2/activities/generics/duration-value-unit/helper';

class HackathonDetailsPanelView  extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      profilePath:   null,
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const eventName                           = detailsData.getField('event').name;
    const eventLocation                       = detailsData.getField('eventLocation').originalText;
    const eventDuration                       = detailsData.getField('eventDuration');
    const { name, profileId, icon, location } = detailsData.getField('organiser');
    const challengeDescription                = detailsData.getField('challengeDescription');
    const competitionPlace                    = detailsData.getField('competitionPlace');
    const eventImg                            = detailsData.getField('eventImg');
    const eventLink                           = detailsData.getField('eventLink');
    const sourceIcon                          = detailsData.getField('sourceIcon');
    const sourceName                          = detailsData.getField('sourceName');

    const profilePath = this.state.profilePath ||
      (
        profileId && window._appv2.slugs.generateProfilePath({
          id:       profileId,
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    const profileLink = profilePath && (profilePath + '/summary');

    const renderEvent = () => {
      if (sourceIcon && sourceName) {
        return (
          <PanelEventLink
            activityImg   = { eventImg }
            activityName  = { eventName }
            sourceName    = { sourceName }
            sourceIconSrc = { getSourceIcon({ sourceIcon }) }
            isClickable   = { true }
          />
        );
      }

      return (
        <XPEntryDetailsTitle
          title    = { eventName }
          subtitle = { eventLocation }
        />
      );
    };

    return (
      <div className = "v2-hackathon-details-panel-view">
        <LinkWrapper
          link        = { eventLink }
          target      = '_blank'
          isFullWidth = { true }
        >
          { renderEvent() }
        </LinkWrapper>
        <LinkWrapper link = { profileLink } >
          <XpEntryLocationLogo
            iconSrc  = { getProviderIcon({ icon, profileId }) }
            name     = { name }
            location = { location.shortText }
          />
        </LinkWrapper>
        <ChallengeFocusReadonly
          challengeFocus = { challengeDescription }
        />
        <XpEntryPlace
          place = { competitionPlace }
        />
        <XpTags
          strings = { [getDurationValueUnit({ duration: eventDuration })] }
        />
      </div>
    );
  }
}

export default HackathonDetailsPanelView;
