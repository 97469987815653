import './index.scss';
import React from 'react';

const RemoveActivityButton = ({
  onClick
}) => {
  return (
    <button
      type      = "button"
      className = "remove-activity-button"
      onClick   = { () => { onClick(); } }
    >
      Remove
    </button>
  );
};

export default RemoveActivityButton;
