import './index.scss';
import React          from 'react';
import { defined }    from 'lib/helpers';
import SvgIconUseItem from 'components/v2/svg-icons/svg-icon-use-item';

import XpEntryLocationLogo from 'component-lib/components/xp-entry-location-logo';
import { getProviderIcon } from 'components/v2/activities/generics/provider-icon/helper';

class InputTextDropdown extends React.Component {
  constructor(props) {
    super(props);

    this._ignoreBlur = false;
    this._minCursorValue = (props.isFreeTextRejected ? 0 : -1);

    this._handleKeyDown = (e) => {
      const { cursor } = this.state;
      const {
        onOptionClick,
        onUseClick,
        options,
        value,
      } = this.props;

      if (options === null) {
        return;
      }

      switch(e.keyCode) {
        case 38:
          e.preventDefault();

          if (cursor > this._minCursorValue) {
            this.setState(prevState => ({
              cursor: prevState.cursor - 1,
            }));
          }

          break;

        case 40:
          e.preventDefault();

          if (cursor < options.length - 1) {
            this.setState(prevState => ({
              cursor: prevState.cursor + 1,
            }));
          }

          break;

        case 13:
          e.preventDefault();

          if (cursor === -1) {
            this._ignoreBlur = true;
            onUseClick(defined(value));
          } else if (typeof options[cursor] !== 'undefined') {
            this._ignoreBlur = true;
            onOptionClick(options[cursor]);
          }

          break;
      }
    };

    this.state = {
      cursor: this._minCursorValue,
    };

  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.nameInput.focus();
      }, 0);
    }
  }

  render() {
    const {
      inputFontSize,
      name,
      label,
      placeholder,
      value,
      maxLength,
      message,
      isError,
      isWarning,
      options,
      isFreeTextRejected,
      onInputChange,
      onUseClick,
      onOptionClick,
      onFocus, // optional
      onBlur, // optional
    } = this.props;

    const { cursor } = this.state;

    const renderOptions = ({ value, options, onOptionClick, onUseClick }) => {
      if (options === null) {
        return null;
      }

      const extraClassNameForUse = (-1 === this.state.cursor ? 'v2-input-text-dropdown__item-button-use--active' : '');

      const renderSingleOption = (option) => {
        const props = ['name', 'profileId', 'icon', 'location'];
        const hasAllProperty = props.every(prop => option.hasOwnProperty(prop));

        if (hasAllProperty) {
          const { icon, profileId, name, location } = option;

          return (
            <XpEntryLocationLogo
              iconSrc  = { getProviderIcon({ icon, profileId }) }
              name     = { name }
              location = { location.shortText }
            />
          );
        }

        return option.name;
      };

      return (
        <ul
          ref       = { (elem) => { this._optionsListElem = elem; } }
          className = "v2-input-text-dropdown__list"

        >

          { !isFreeTextRejected &&
            <li
              className    = { "v2-input-text-dropdown__item-button-use " + extraClassNameForUse }
              onMouseDown  = { () => { this._ignoreBlur = true; onUseClick(defined(value)); } }
              onMouseEnter = { () => { this.setState(prevState => ({ cursor: -1 })); } }
            >
              <span>Use { value }</span>
              <SvgIconUseItem />
            </li>
          }
          {
            options.length > 0 && options.map((option, idx) => {
              const extraClassName = (idx === this.state.cursor ? 'v2-input-text-dropdown__list-item--active' : '');

              return (
                <li
                  className    = { "v2-input-text-dropdown__list-item " + extraClassName }
                  key          = { idx }
                  onMouseDown  = { () => { this._ignoreBlur = true; onOptionClick(option); } }
                  onMouseEnter = { () => { this.setState(prevState => ({ cursor: idx })); } }
                >
                  { renderSingleOption(option) }
                </li>
              )
            })
          }
        </ul>
      );
    };

    const onChange = (event) => {
      this.setState({ cursor: this._minCursorValue });
      onInputChange(event.target.value);
    };

    const getInputClassName = () => {
      const inputClassName = 'v2-input-text-dropdown__input';

      const isErrorClassName       = isError                   ? `${inputClassName}--error`           : '';
      const inputFontSizeClassName = inputFontSize === "small" ? `${inputClassName}--small-font-size` : `${inputClassName}--default-font-size`;

      return `${inputClassName} ${isErrorClassName} ${inputFontSizeClassName}`;
    };

    const getMessageClassName = () => {
      const messageClassName = 'v2-input-text-dropdown__message';

      if (isWarning) return `${messageClassName} ${messageClassName}--warning`;
      return messageClassName;
    };

    return (
      <div className = "v2-input-text-dropdown">
        <div className = "v2-input-text-dropdown__container">
          <label className = "v2-input-text-dropdown__label" htmlFor = { name }>
            { label }
          </label>
          <div className = "v2-input-text-dropdown__wrapper"
            onKeyDown = { (e) => { this._handleKeyDown(e); } }
            tabIndex  = "-1"
          >
            <input
              autoComplete = "off"
              ref          = { (realInputElementOnPage) => { this.nameInput = realInputElementOnPage; } }
              type         = "text"
              className    = { getInputClassName() }
              name         = { name }
              placeholder  = { placeholder }
              value        = { value }
              maxLength    = { maxLength }
              onChange     = { onChange }
              onFocus      = { onFocus }
              onBlur       = { () => {
                if (this._ignoreBlur) {
                  this._ignoreBlur = false; // ignore it only once
                  return;
                }

                if (onBlur instanceof Function) {
                  return onBlur();
                }

                return onUseClick(defined(value));
              } }
            />

            { renderOptions({ value, options, onOptionClick, onUseClick }) }
          </div>
          <div className = { getMessageClassName() }>
            { message }
          </div>
        </div>
      </div>
    );
  }
};

export default InputTextDropdown;
