import './index.scss';
import React from 'react';

export const TwitterCircle = (props) => {
  return (
    <a href = { props.url } target = "_blank" onClick = { props.onClick }>
      <svg className = "twitter-circle" width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path className = "twitter-circle__bg" d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z" />
        <path className = "twitter-circle__content" d="M19.6262 35.4824C29.977 35.4824 35.6373 26.8336 35.6373 19.334C35.6373 19.0883 35.6324 18.8436 35.6213 18.6003C36.72 17.7993 37.6751 16.7997 38.4286 15.6617C37.4203 16.1134 36.3351 16.4179 35.1969 16.5552C36.3587 15.8527 37.2508 14.7408 37.6714 13.4156C36.5838 14.0657 35.3801 14.5386 34.0983 14.7931C33.0713 13.69 31.6088 13 29.9906 13C26.8826 13 24.3623 15.5418 24.3623 18.6752C24.3623 19.1207 24.4118 19.5537 24.5084 19.9693C19.8316 19.7322 15.6843 17.4735 12.9091 14.0395C12.4254 14.878 12.147 15.8527 12.147 16.8921C12.147 18.8612 13.1405 20.5995 14.6512 21.6164C13.7282 21.5877 12.8609 21.3319 12.1024 20.9063C12.1012 20.9301 12.1012 20.9538 12.1012 20.9787C12.1012 23.7278 14.0412 26.0225 16.6159 26.5429C16.1433 26.6726 15.646 26.7425 15.1325 26.7425C14.7699 26.7425 14.4173 26.7064 14.0746 26.6402C14.791 28.8951 16.8683 30.536 19.3317 30.5822C17.4052 32.1046 14.979 33.0118 12.3425 33.0118C11.8884 33.0118 11.4405 32.9855 11 32.9331C13.4906 34.5429 16.4476 35.4824 19.6262 35.4824Z" />
      </svg>
    </a>
  );
};
