import { defined } from 'lib/helpers';
import axios       from 'axios';
import config      from 'config';
import PaddlError  from 'lib/paddl-error';

const setRequestMethod = (method) => {
  const EXPECTED_API_METHODS = ['GET', 'POST', 'DELETE'];

  if (EXPECTED_API_METHODS.indexOf(method) === -1 ){
    throw new Error('Unexpected API method');
  }
  return method;
};

class ApiTransport {
  constructor({ store }) {
    this._store = defined(store);

    this._mocks = {};
    this._cache = {};

    this._invalidateCache = (patterns) => {
      Object.keys(this._cache).map((url) => {
        if (patterns.some(pat => url.match(pat))) {
          delete this._cache[url];
        }
      });
    };
  }

  mockResponse({ endpoint, response }) {
    this._mocks[endpoint] = response;
  }

  clearMockResponse({ endpoint }) {
    delete(this._mocks[endpoint]);
  }

  getAuthHeaders() {
    const {
      auth: {
        token,
        profile_id
      }
    } = this._store.getState();

    return {
      'Authorization': `Bearer ${token}`,
      'X-Profile-ID':  profile_id
    };
  }

  makeRequest({ endpoint, method, data, params, useCache, invalidateCachePatterns }) {
    const {
      auth: {
        token,
      }
    } = this._store.getState();

    const baseHeaders = { 'Accept': 'application/json' };
    const authHeaders = this.getAuthHeaders();
    const headers = token ? Object.assign(authHeaders, baseHeaders) : baseHeaders;

    if (this._mocks.hasOwnProperty(endpoint)) {
      return new Promise((resolve) => {
        resolve(defined(this._mocks[endpoint]));
      });
    }

    if (invalidateCachePatterns) {
      this._invalidateCache(invalidateCachePatterns);
    }

    if (useCache && this._cache.hasOwnProperty(endpoint)) {
      return new Promise((resolve) => {
        resolve(defined(this._cache[endpoint]));
      });
    }

    return axios({
      url: [config.API_V2_URL, endpoint].join(''),
      method: setRequestMethod(method),
      headers,
      data,
      params,
      transformResponse: [(data) => (data)], // getting rid of all the magical transformations
    })
    .catch((error) => {
      if (typeof error.response == 'undefined') {
        throw error;
      }

      throw ({
        data:   error.response.data,
        status: error.response.status,
      });
    })
    .then(({ data, headers }) => {
      const paddlErrorCode = headers['paddl-error-code'];

      if (typeof paddlErrorCode !== 'undefined') {
        throw new PaddlError({ code: paddlErrorCode });
      }

      let result = JSON.parse(data);

      if (useCache) {
        this._cache[endpoint] = result;
      }

      return result;
    });
  }
}

export default ApiTransport;
