import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import Text              from 'lib/api/activities/generics/fields/text';
import IdNameObject      from 'lib/api/activities/generics/fields/id-name-object';
import Employer          from 'lib/api/activities/categories/employment/fields/employer';
import Location          from 'lib/api/location';
import DurationValueUnit from 'lib/api/activities/generics/duration-value-unit';
import NaturalNumber     from 'lib/api/activities/generics/fields/natural-number';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/competition/variants/hackathon/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/competition/variants/hackathon/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/competition/variants/hackathon/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/competition/variants/hackathon/card/duration-panel/form';

class Hackathon {
  static categoryCssClassPrefix() {
    return 'v2-categories-competition-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-competition-hackathon.svg');
  }

  static title() {
    return 'Hackathon';
  }

  static subtitle() {
    return 'Ideation or business challenge.';
  }

  static detailsAttributes() {
    const fetchHackathonSourceData           = ({ appv2 }) => { return appv2.api.competition.fetchSourceData.bind(appv2.api.competition);                    };
    const fetchHackathonEventSuggestions     = ({ appv2 }) => { return appv2.api.competition.fetchHackathonEventSuggestions.bind(appv2.api.competition);     };
    const fetchHackathonOrganiserSuggestions = ({ appv2 }) => { return appv2.api.competition.fetchHackathonOrganiserSuggestions.bind(appv2.api.competition); };

    return {
      eventLink:            { required: false, dataType: Text,              fieldLabel: 'Event Link',            fieldPlaceholder: 'http://',                                                       sourceDataFunction:  fetchHackathonSourceData           },
      event:                { required: true,  dataType: IdNameObject,      fieldLabel: 'Event Name',            fieldPlaceholder: 'e.g. Business Growth Hack',                                     suggestionsFunction: fetchHackathonEventSuggestions     },
      organiser:            { required: true,  dataType: Employer,          fieldLabel: 'Organiser',             fieldPlaceholder: 'e.g. AngelHack',                                                suggestionsFunction: fetchHackathonOrganiserSuggestions },
      eventLocation:        { required: true,  dataType: Location,          fieldLabel: 'Event Location',        fieldPlaceholder: 'e.g. Melbourne, Vic AUS'                                        },
      eventDuration:        { required: true,  dataType: DurationValueUnit, fieldLabel: 'Event Duration',        fieldPlaceholder: { value: '00', unit: 'Select' }                                  },
      competitionPlace:     { required: true,  dataType: NaturalNumber,     fieldLabel: 'Competition Place',     fieldPlaceholder: 'Select Place'                                                   },
      challengeDescription: { required: false, dataType: Text,              fieldLabel: 'Challenge Description', fieldPlaceholder: 'Describe the challenge you were given and how you responded...' },
      sourceIcon:           { required: false, dataType: Text },
      sourceName:           { required: false, dataType: Text },
      eventImg:             { required: false, dataType: Text },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: details.get('event').name,
      activityProviderName: details.get('organiser').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({
      attributes: this.detailsAttributes(),
      getDurationObjectFn: (detailsData) => {
        return detailsData.getField('eventDuration');
      },
    });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default Hackathon;
