import { defined, regexpWithUrlHead } from 'lib/helpers';

import axios  from 'axios';
import config from 'config';

const getS3Token = ({ authHeaders }) => {
  const endpoint = `/auth/upload_token`;

  return axios({
    url: [config.API_URL, endpoint].join(''),
    method: 'POST',
    headers: authHeaders,
    data: {},
    params: {},
    transformResponse: [(data) => (data)], // getting rid of all the magical transformations
  })
  .catch((error) => {
    if (typeof error.response == 'undefined') {
      throw error;
    }

    throw ({
      data:   error.response.data,
      status: error.response.status,
    });
  })
};

const upload = ({ token, data, subKey }) => {
  const rndKey   = (1 + Math.random()).toString(36).substr(2);
  const key      = `${ token.key_prefix }${ rndKey }_${ subKey }`;
  const formData = new FormData();

  formData.append('key',                   key);
  formData.append('AWSAccessKeyId',        token.access_key);
  formData.append('policy',                token.policy);
  formData.append('signature',             token.signature);
  formData.append('Content-Type',          '$Content-Type');
  formData.append('acl',                   'public-read');
  formData.append('success_action_status', '201');
  formData.append('file',                  data);

  return axios
    .post(config.FILE_UPLOAD_ENDPOINT, formData)
    .then(({ data }) => {
      const path = decodeURIComponent(/<Key>([^<]+)<\/Key>/.exec(data)[1])
        .split('/')
        .splice(1)
        .join('/');

      return `${ config.FILE_READ_URL }/${ path }`;
    });
};

class ApiUploading {
  constructor({ transport }) {
    this._transport = transport;
  }

  // `data` is binary data that will be send as one of POST values
  // `subKey` is a suffix to be appended at the end of a "filename"
  //
  // returns a Promise, its `resolve` outcome will get URL as an argument
  uploadToS3({ data, subKey }) {
    return getS3Token({ authHeaders: this._transport.getAuthHeaders() })
      .then(({ data: tokenStr }) => {
        return upload({
          token: JSON.parse(tokenStr),
          data,
          subKey,
        });
      });
  }
}

export default ApiUploading;
