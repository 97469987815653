import './index.scss';
import React from 'react';
import classNames from 'classnames';

const Text = ({ text, fontSize, fontWeight, fontStyle, color, opacity, lineHeight, isTruncated }) => {
  if (text === null) return null;

  const fontSizeClass   = `font-size-${fontSize || '16px'}`;
  const fontWeightClass = `font-weight-${fontWeight || 'regular'}`;
  const colorClass      = `color-${color || 'bluegrey-d'}`;
  const lineHeightClass = `crop-line-height-${lineHeight ? lineHeight.replace('.', '-point-') : '1-point-4'}`;

  const className = classNames(
    'v2-text',
    fontSizeClass,
    fontWeightClass,
    colorClass,
    lineHeightClass,
    { 'v2-text--truncated': isTruncated }
  );

  const style = {
    fontStyle: fontStyle,
    opacity:   opacity,
  };

  return (
    <div style = { style } className = { className }>
      { text }
    </div>
  );
};

export default Text;
