import { defined } from 'lib/helpers';

import { isEndDateBeforeStartDate } from 'lib/api/activities/generics/duration-common';

import Year  from 'lib/api/activities/generics/duration-common/year';
import Month from 'lib/api/activities/generics/duration-common/month';
import Day   from 'lib/api/activities/generics/duration-common/day';
import Bool  from 'lib/api/activities/generics/fields/bool';

class DurationIndefiniteDayMonthYear {
  constructor(data) {
    this.startYear  = defined(data.startYear);
    this.startMonth = defined(data.startMonth);
    this.startDay   = defined(data.startDay);
    this.endYear    = defined(data.endYear);
    this.endMonth   = defined(data.endMonth);
    this.endDay     = defined(data.endDay);
    this.isCurrent  = defined(data.isCurrent);
  }

  toApiFormat() {
    return {
      startDate: {
        year:  this.startYear.toApiFormat(),
        month: this.startMonth.toApiFormat(),
        day:   this.startDay.toApiFormat(),
      },
      endDate: {
        year:  this.endYear.toApiFormat(),
        month: this.endMonth.toApiFormat(),
        day:   this.endDay.toApiFormat(),
      },
      isCurrent: this.isCurrent.toApiFormat(),
    };
  }

  isEmpty() {
    return (
      this.startYear     === null
      && this.startMonth === null
      && this.startDay   === null
      && this.endYear    === null
      && this.endMonth   === null
      && this.endDay     === null
      && this.isCurrent  === null
    );
  }

  cloneWithNewFinishDate({ day, month, year }) {
    const newEndDay   = new Day(day);
    const newEndMonth = new Month(month);
    const newEndYear  = new Year(year);

    return new DurationIndefiniteDayMonthYear({
      startYear:          this.startYear,
      startMonth:         this.startMonth,
      startDay:           this.startDay,
      endYear:            newEndYear,
      endMonth:           newEndMonth,
      endDay:             newEndDay,
      isCurrent:          new Bool(false)
    });
  }

  isValid() {
    const isInFuture = ({ year, month, day }) => {
      const now = new Date();

      return isEndDateBeforeStartDate({
        startYear:  year,
        startMonth: month,
        startDay:   day,
        endYear:    now.getFullYear(),
        endMonth:   (now.getMonth() + 1),
        endDay:     now.getDate(),
      });
    };

    // start-date is required (in any case)
    if (
      this.startYear           === null
      || this.startMonth       === null
      || this.startDay         === null
      || this.startYear.value  === null
      || this.startMonth.value === null
      || this.startDay.value   === null
    ) { return false; }

    // if start-date is in the future
    if (isInFuture({
      year:  this.startYear.value,
      month: this.startMonth.value,
      day:   this.startDay.value,
    })) {
      return false;
    }

    if (this.isCurrent !== null && this.isCurrent.value === true) {
      return true;
    }

    // end-date is required (at this stage)
    if (
      this.endYear           === null
      || this.endMonth       === null
      || this.endDay         === null
      || this.endYear.value  === null
      || this.endMonth.value === null
      || this.endDay.value   === null
    ) { return false; }

    // if end-date is in the future
    if (isInFuture({
      year:  this.endYear.value,
      month: this.endMonth.value,
      day:   this.endDay.value,
    })) {
      return false;
    }

    // if end-date is before start-date
    if (isEndDateBeforeStartDate({
      startYear:  this.startYear.value,
      startMonth: this.startMonth.value,
      startDay:   this.startDay.value,
      endYear:    this.endYear.value,
      endMonth:   this.endMonth.value,
      endDay:     this.endDay.value,
    })) {
      return false;
    }

    return true;
  }

  getProgressState() {
    return (this.isCurrent.value) ? 'in-progress' : 'complete';
  }

  static createEmpty() {
    return new DurationIndefiniteDayMonthYear({
      startYear:  null,
      startMonth: null,
      startDay:   null,
      endYear:    null,
      endMonth:   null,
      endDay:     null,
      isCurrent:  null,
    });
  }

  static fromApiFormat(data) {
    const startDate = defined(data.startDate);
    const endDate = defined(data.endDate);

    return new DurationIndefiniteDayMonthYear({
      startYear:  new Year(defined(startDate.year)),
      startMonth: new Month(defined(startDate.month)),
      startDay:   new Day(defined(startDate.day)),
      endYear:    new Year(defined(endDate.year)),
      endMonth:   new Month(defined(endDate.month)),
      endDay:     new Day(defined(endDate.day)),
      isCurrent:  new Bool(defined(data.isCurrent)),
    });
  }
}

export default DurationIndefiniteDayMonthYear;
