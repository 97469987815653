import { defined } from 'lib/helpers';

class Day {
  constructor(value) {
    const intValue = (value === null ? null : parseInt(defined(value), 10));

    if (isNaN(intValue)) {
      throw Error('Unexpected value');
    }

    this.value = intValue;
  }

  toApiFormat() {
    return this.value;
  }
}

export default Day;
