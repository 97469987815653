import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './svg-icon-slim-check.scss';

const CheckImage = ({
  width, height, isAnimated, withShortDelay,
}) => (
  <svg
    className="svg-icon-slim-check"
    style={{ width, height }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10 10 30 30"
  >
    <path
      className={classNames('svg-icon-slim-check__mark', {
        'svg-icon-slim-check__mark--is-animated': isAnimated,
        'svg-icon-slim-check__mark--with-short-delay': withShortDelay,
      })}
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
);

CheckImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  isAnimated: PropTypes.bool,
  withShortDelay: PropTypes.bool,
};

CheckImage.defaultProps = {
  width: '48px',
  height: '48px',
  isAnimated: false,
  withShortDelay: false,
};

export default CheckImage;
