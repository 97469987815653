import './index.scss';
import React from 'react';

import { CourseUnitDetailsBanner }  from 'components/v2/course-unit-details-banner';
import { CourseUnitDetailsContent } from 'components/v2/course-unit-details-content';
import { VerifiedActivityFooter }   from 'components/v2/verified-activity-footer';

export const CourseUnitDetailsPanelView = ({
  bannerImage,
  bannerBackgroundColor,
  courseName,
  faculty,
  description,
  intake,
  verificationDate,
  courseCode,
  issuerProfileLink,
  issuerProfileImage,
  sealImage,
  issuer,
  location,
}) => {
  return (
    <div className = "v2-course-unit-details-panel-view">
      <CourseUnitDetailsBanner
        bannerImage           = { bannerImage }
        bannerBackgroundColor = { bannerBackgroundColor }
      />
      <div className = "v2-course-unit-details-panel-view__content">
        <CourseUnitDetailsContent
          courseName       = { courseName }
          faculty          = { faculty }
          description      = { description }
          intake           = { intake }
          verificationDate = { verificationDate }
          courseCode       = { courseCode }
        />
      </div>
      <div className = "v2-course-unit-details-panel-view__footer">
        <VerifiedActivityFooter
          issuerProfileLink  = { issuerProfileLink }
          issuerProfileImage = { issuerProfileImage }
          sealImage          = { sealImage }
          issuer             = { issuer }
          location           = { location }
        />
      </div>
    </div>
  );
};
