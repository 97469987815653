import './index.scss';
import React from 'react';
import classNames from 'classnames';

const ImgTag = ({ src, alt, width, height, isRounded }) => {
  if (!src) return null;

  const className = classNames(
    'img-tag',
    { [`img-tag--width-${width}`]: width },
    { [`img-tag--height-${height}`]: height },
    { 'img-tag--is-rounded': isRounded },
  );

  return (
    <img
      className = { className }
      src = { src }
      alt = { alt }
    />
  );
};

export default ImgTag;
