import './index.scss';
import React from 'react';

import {
  EDUCATION_ID,
  EMPLOYMENT_ID,
  COMPETITION_ID,
} from 'lib/api/categories';

import { getCategoryData }        from 'lib/api/categories';
import Text                       from 'component-lib/type/text';
import { style20pxRegularWhite }  from 'component-lib/type/text/styles';
import { ChooseFromCategoryCard } from 'components/v2/profile/choose-from-category-card';

export const ChooseFromCategorySection = ({ onSelectCategory }) => {
  const educationData = getCategoryData({ categoryId: EDUCATION_ID });
  const employmentData = getCategoryData({ categoryId: EMPLOYMENT_ID });
  const competitionData = getCategoryData({ categoryId: COMPETITION_ID });

  return (
    <div className = "v2-choose-from-category-section">
      <Text { ...style20pxRegularWhite }
        text = "Choose from category"
      />
      <div onClick = { () => { onSelectCategory(EDUCATION_ID); } } >
        <ChooseFromCategoryCard
          title = { educationData.title() }
          subTitle = { educationData.subTitle() }
        />
      </div>
      <div onClick = { () => { onSelectCategory(EMPLOYMENT_ID); } } >
        <ChooseFromCategoryCard
          title = { employmentData.title() }
          subTitle = { employmentData.subTitle() }
        />
      </div>
      <div onClick = { () => { onSelectCategory(COMPETITION_ID); } } >
        <ChooseFromCategoryCard
          title = { competitionData.title() }
          subTitle = { competitionData.subTitle() }
        />
      </div>
    </div>
  );
};
