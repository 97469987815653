import Moment         from 'lib/helpers/moment';
import { IssuerData } from 'lib/api/var-edu/verified/issuer-data';

export const getQualificationDetailsPanelViewProps = ({ activity, issuerProfile, ownerProfile }) => {
  const details = activity.get('details');

  const name       = details.get('name').value;
  const campus     = details.get('campus').value;
  const code       = details.get('code').value;
  const issuerCode = details.get('issuerCode').value;
  const verifiedAt = details.get('verifiedAt');

  const { issuerLogo, signatures, sealImage, issuer } = IssuerData.getFor(issuerCode);

  const { year, month, day } = verifiedAt;

  const verificationDate = Moment.format_D_MMMM_YYYY(
    new Date(year.value, month.value - 1, day.value)
  );

  const issuerProfileImage = (issuerProfile && issuerProfile.get('avatarUrl')) || require('images/placeholder--avatar-employer.svg');

  const recipientName = ownerProfile && ownerProfile.get('fullName');

  return {
    qualificationName: name,
    recipientName,
    issuerLogo,
    signatures,
    verificationDate,
    courseCode: code,
    issuerProfileImage,
    sealImage,
    issuer,
    location: campus,
  };
};
