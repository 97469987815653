import './index.scss';
import React from 'react';

import XpAmountXl from 'component-lib/components/xp-amount-xl';

const XpFooterAmount = ({ xpNumber }) => {
  return (
    <div className = "v2-xp-footer-amount">
      <div className = "v2-xp-footer-amount__xp">
        <XpAmountXl xpNumber = { xpNumber } />
      </div>
      <a
        className = "v2-xp-footer-amount__help"
        href      = "https://paddljobs.com" // Confirm url
        target    = "_blank"
      >
        What’s XP?
      </a>
    </div>
  );
}

export default XpFooterAmount;
