import React from 'react';

import { TermsMobileDropDown } from 'components/v2/terms-mobile-dropdown';
import { TermsContentSideMenu } from 'components/v2/terms-content-side-menu';
import { TermsExplorerContent } from 'components/v2/terms-section/explorer';
import { TermsProviderContent } from 'components/v2/terms-section/provider';
import { TermsPrivacyContent } from 'components/v2/terms-section/privacy';

import './index.scss';

const sectionLinks = [
  {
    text: 'Terms of Use for Explorers',
    href: '#terms-for-explorers',
    id: 'terms-for-explorers',
    content: <TermsExplorerContent />,
  },
  {
    text: 'Terms of Use for Providers',
    href: '#terms-for-providers',
    id: 'terms-for-providers',
    content: <TermsProviderContent />,
  },
  {
    text: 'Paddl Privacy Policy',
    href: '#privacy',
    id: 'privacy',
    content: <TermsPrivacyContent />,
  },
];

export const TermsContent = () => {
  const MainContent = (
    <div className="terms-content__main-content">
      {
        sectionLinks.map(({ id, content }) => (
          <div key={id}>
            <div className="terms-content__section-anchor" id={id} />
            { content }
          </div>
        ))
      }
    </div>
  );

  return (
    <div className="terms-content">
      <div className="terms-content__section-links">
        <div className="terms-content__section-links--mobile">
          <TermsMobileDropDown sectionLinks={sectionLinks} />
        </div>
        <div className="terms-content__section-links--desktop">
          <TermsContentSideMenu sectionLinks={sectionLinks} />
        </div>
      </div>

      { MainContent }
    </div>
  );
};
