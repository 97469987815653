import './index.scss';
import React from 'react';

import ImgTag                        from 'component-lib/components/img-tag';
import Text                          from 'component-lib/type/text';
import { style22pxRegularBluegreyD } from 'component-lib/type/text/styles';

export const ModalSidenavLogoutContent = ({
  avatar,
  name,
  onClick,
}) => {
  return (
    <div className = "v2-modal-sidenav-logout-content">
      <div className = "v2-modal-sidenav-logout-content__main-content">
        <div className = "v2-modal-sidenav-logout-content__avatar">
          <ImgTag
            src = { avatar }
            width = "96px"
            isRounded
          />
        </div>
        <div className = "v2-modal-sidenav-logout-content__copy">
          Logged in as
        </div>
        <Text
          { ...style22pxRegularBluegreyD }
          text = { name }
        />
      </div>
      <div className = "v2-modal-sidenav-logout-content__divider" />
      <button
        className = "v2-modal-sidenav-logout-content__log-out-button"
        onClick = { onClick }
      >
        Log Out
      </button>
    </div>
  );
};
