// IMPORTANT: to be able to access these variables from the code,
// it's required to specify them in `webpack.EnvironmentPlugin` (webpack.config.babel.js)

module.exports = {
  ENVIRONMENT          : process.env.NODE_ENV,
  PADDL_URL            : process.env.PADDL_URL,
  LANDING_PAGE_URL     : process.env.LANDING_PAGE_URL,
  SIGN_UP_PAGE_URL     : process.env.SIGN_UP_PAGE_URL,
  API_URL              : process.env.API_URL,
  API_V2_URL           : process.env.API_V2_URL,
  SEGMENT_WRITE_KEY    : process.env.SEGMENT_WRITE_KEY,
  STRIPE_KEY           : process.env.STRIPE_KEY,
  SENTRY_DSN           : process.env.SENTRY_DSN,
  FILE_UPLOAD_DIRECTORY: process.env.FILE_UPLOAD_DIRECTORY,
  FILE_READ_URL        : process.env.FILE_READ_URL,
  TYPEKIT_ID           : 'efd3krg',
  FILE_UPLOAD_ENDPOINT : 'https://paddl-view.s3.amazonaws.com',
  FACEBOOK_APP_ID      : '2189326967822830',
  SHARE_IMG            : 'https://paddljobs.com/assets/og-share.jpg',
  HELP_URL             : 'https://help.paddl.com'
};
