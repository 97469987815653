import './index.scss';
import React from 'react';

const CompletedIcon = () => {
  return (
    <svg className="completed-icon" width="56" height="56" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="completed-icon__internals">
        <text x="20" y="40" style={ { fill: '#5AF2D5', fontWeight: '400', fontSize: '23px' } }>XP</text>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M32 2.66667C15.7996 2.66667 2.66667 15.7996 2.66667 32C2.66667 48.2004 15.7996 61.3333 32 61.3333C48.2004 61.3333 61.3333 48.2004 61.3333 32C61.3333 15.7996 48.2004 2.66667 32 2.66667ZM0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#5AF2D5"/>
    </svg>
  );
};

export default CompletedIcon;
