export const calculateNumberOfDays = ({ now, durationDates }) => {
  const {
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
  } = durationDates;

  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

  let left;

  // If an event ends on a specific day, people assume it ends at the last second of the day instead of the first second.
  // So it's needed to specify the time as 23:59:59 here.
  const end = (endYear !== null && endMonth !== null && endDay !== null)
    ? new Date(endYear, endMonth - 1, endDay, 23, 59, 59)
    : now;

  const right = (now.getTime() < end.getTime()) ? now : end;

  if (startYear === null || startMonth === null || startDay === null) {
    return 0;
  }

  // If an event starts on a specific day, people assume it starts at the first second of the day.
  // It's better to be explicit so the time is specified as 00:00:00 here.
  left = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);

  if (now.getTime() < left.getTime()) {
    return 0;
  }

  return parseInt(Math.ceil(( right - left ) / MILLISECONDS_PER_DAY), 10);
};

export const isEndDateBeforeStartDate = ({
  startYear,
  startMonth,
  startDay,
  endYear,
  endMonth,
  endDay,
}) => {
  const somethingIsNull = (
    startYear     === null
    || startMonth === null
    || startDay   === null
    || endYear    === null
    || endMonth   === null
    || endDay     === null
  );

  if (somethingIsNull) {
    return false;
  }

  const startDate = new Date(startYear, startMonth - 1, startDay);
  const endDate   = new Date(endYear, endMonth - 1, endDay);

  return (startDate.getTime() > endDate.getTime());
};

export const isEndDateInThePast = ({ endYear, endMonth, endDay }) => {
  const endDateIsNotComplete = (
    endYear     === null
    || endMonth === null
    || endDay   === null
  );

  if (endDateIsNotComplete) {
    return false;
  }

  const theLastSecondOfEndDate = new Date(endYear, endMonth - 1, endDay, 23, 59, 59);
  // If an event ends on a specific day, people assume it ends at the last second of the day instead of the first second.
  // And `new Date(endYear, endMonth - 1, endDay)` will generate a datetime at 00:00:00 of the day
  // which is the first second of the day and is not what we want,
  // so it's needed to specify the time as 23:59:59 here.

  const now = new Date();

  return theLastSecondOfEndDate < now;
};

export const isStartDateBeforeEndDate = ({ startYear, startMonth, startDay, endYear, endMonth, endDay }) => {
  const startDateIsNotComplete = (
    startYear     === null
    || startMonth === null
    || startDay   === null
  );

  const endDateIsNotComplete = (
    endYear     === null
    || endMonth === null
    || endDay   === null
  );

  if (startDateIsNotComplete || endDateIsNotComplete) {
    return false;
  }

  const theFirstSecondOfStartDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);
  // If an event starts on a specific day, people assume it starts at the first second of the day.
  // It's better to be explicit so the time is specified as 00:00:00 here.

  const theLastSecondOfEndDate = new Date(endYear, endMonth - 1, endDay, 23, 59, 59);
  // If an event ends on a specific day, people assume it ends at the last second of the day instead of the first second.
  // And `new Date(endYear, endMonth - 1, endDay)` will generate a datetime at 00:00:00 of the day
  // which is the first second of the day and is not what we want,
  // so it's needed to specify the time as 23:59:59 here.

  return theFirstSecondOfStartDate < theLastSecondOfEndDate;
};
