import { defined, regexpWithUrlHead } from 'lib/helpers';

class ApiHeroBannerImages {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetchBanners() {
    const endpoint = `/banners`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then(({ listOfBanners, selectedId }) => {
        return {
          listOfBanners: defined(listOfBanners),
          selectedId: defined(selectedId),
        };
      });
  }

  setSelection({ id }) {
    const endpoint = `/banners`;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data: { id },
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }

  setCustomBanner({ url }) {
    const endpoint = `/banners/set-custom-banner`;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data: { customBannerUrl: url },
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }
}

export default ApiHeroBannerImages;
