import { defined } from 'lib/helpers';
import React       from 'react';

import PanelFormHeadline                from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown                from 'components/v2/input-fields/input-text-dropdown';
import DurationValueUnitInput           from 'components/v2/activities/generics/duration-value-unit/input';
import CommitmentInput                  from 'components/v2/activities/categories/education/fields/commitment/input';
import AttendanceInPersonAndOnlineInput from 'components/v2/activities/categories/education/fields/attendance/in-person-and-online/input';
import Select                           from 'components/v2/input-fields/select';
import { LOCATION_OPTIONS }             from 'components/v2/activities/generics/location/country-flags';
import { certificateTypeOptions }       from 'components/v2/activities/categories/education/variants/certificate/certificateTypeOptions';

class CertificateDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForAuthority:   true,
      isValueAcceptedForCertificate: true,
    };

    this.isValueAcceptedForAuthority = () => {
      return this.state.isValueAcceptedForAuthority;
    };

    this.isValueAcceptedForCertificate = () => {
      return this.state.isValueAcceptedForCertificate;
    };

    this.setValueAcceptedForAuthority = (value) => {
      return this.setState({ isValueAcceptedForAuthority: value });
    };

    this.setValueAcceptedForCertificate = (value) => {
      return this.setState({ isValueAcceptedForCertificate: value });
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const renderAuthorityInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'authority',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForAuthority(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'authority',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'authority',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForAuthority(true);
      };

      const onAcceptSuggestion = (authority) => {
        detailsData.setField({
          attrName: 'authority',
          data:     authority,
        });
        this.setValueAcceptedForAuthority(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { true }
          label         = { detailsData.getFieldLabel('authority') }
          placeholder   = { detailsData.getFieldPlaceholder('authority') }
          value         = { detailsData.getField('authority').name }
          options       = { this.isValueAcceptedForAuthority() ? null : detailsData.getSuggestions('authority') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCertificateInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'certificate',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCertificate(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'certificate',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'certificate',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCertificate(true);
      };

      const onAcceptSuggestion = (certificate) => {
        detailsData.setField({
          attrName: 'certificate',
          data:     certificate,
        });
        this.setValueAcceptedForCertificate(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { false }
          label         = { detailsData.getFieldLabel('certificate') }
          placeholder   = { detailsData.getFieldPlaceholder('certificate') }
          value         = { detailsData.getField('certificate').name }
          options       = { this.isValueAcceptedForCertificate() ? null : detailsData.getSuggestions('certificate') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderLocation = () => {
      return (
        <Select
          name        = "location"
          label       = { detailsData.getFieldLabel('location') }
          placeholder = { detailsData.getFieldPlaceholder('location') }
          value       = { detailsData.getField('location') }
          options     = { LOCATION_OPTIONS }
          onChange    = { (value) => { detailsData.setField({ attrName: 'location', data: value }); } }
        />
      );
    };

    const renderCertificateType = () => {
      return (
        <Select
          name        = "certificateType"
          label       = { detailsData.getFieldLabel('certificateType') }
          placeholder = { detailsData.getFieldPlaceholder('certificateType') }
          value       = { detailsData.getField('certificateType') }
          options     = { certificateTypeOptions }
          onChange    = { (value) => { detailsData.setField({ attrName: 'certificateType', data: parseInt(value, 10) }); } }
        />
      );
    };

    const renderCertificateDurationValueUnitInput = () => {
      const { value, unit } = detailsData.getField('certificateDuration');

      const valueOnChange = (selectedValue) => {
        detailsData.setField({
          attrName: 'certificateDuration',
          data: {
            value: selectedValue,
            unit:  unit,
          },
        });
      };

      const unitOnChange = (selectedUnit) => {
        detailsData.setField({
          attrName: 'certificateDuration',
          data: {
            value: value,
            unit:  selectedUnit,
          },
        });
      };

      return (
        <DurationValueUnitInput
          label            = { detailsData.getFieldLabel('certificateDuration') }
          valuePlaceholder = { detailsData.getFieldPlaceholder('certificateDuration').value }
          unitPlaceholder  = { detailsData.getFieldPlaceholder('certificateDuration').unit }
          value            = { value }
          unit             = { unit }
          valueOnChange    = { (selectedValue) => { valueOnChange(selectedValue); } }
          unitOnChange     = { (selectedUnit) => { unitOnChange(selectedUnit); } }
        />
      );
    };

    const renderCommitmentInput = () => {
      return (
        <CommitmentInput
          label       = { detailsData.getFieldLabel('commitment') }
          placeholder = { detailsData.getFieldPlaceholder('commitment') }
          value       = { detailsData.getField('commitment') }
          onChange    = { (commitment) => { detailsData.setField({ attrName: 'commitment', data: commitment }); } }
        />
      );
    };

    const renderAttendanceInput = () => {
      return (
        <AttendanceInPersonAndOnlineInput
          isInPersonChecked = { detailsData.getField('attendanceInPerson') }
          isOnlineChecked   = { detailsData.getField('attendanceOnline') }
          onInPersonChange  = { (value) => { detailsData.setField({ attrName: 'attendanceInPerson', data: value }); } }
          onOnlineChange    = { (value) => { detailsData.setField({ attrName: 'attendanceOnline', data: value }); } }
        />
      );
    };

    return (
      <div className = "v2-certificate-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Certificate Details'
          description = 'Enter your Certificate details and type to continue...'
        />

        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderAuthorityInput() }</div>
          <div className = "column is-full">{ renderCertificateInput() }</div>
          <div className = "column is-half">{ renderLocation() }</div>
          <div className = "column is-half">{ renderCertificateType() }</div>
          <div className = "column is-half">{ renderCertificateDurationValueUnitInput() }</div>
          <div className = "column is-half">{ renderCommitmentInput() }</div>
          <div className = "column is-full">{ renderAttendanceInput() }</div>
        </div>
      </div>
    );
  }
};

export default CertificateDetailsPanelForm;
