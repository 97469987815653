const COUNTRIES = {
  'Mauritius':                        require('components/v2/activities/generics/location/svg/001-mauritius.svg'),
  'Austria':                          require('components/v2/activities/generics/location/svg/003-austria.svg'),
  'Oman':                             require('components/v2/activities/generics/location/svg/004-oman.svg'),
  'Ethiopia':                         require('components/v2/activities/generics/location/svg/005-ethiopia.svg'),
  'Tanzania':                         require('components/v2/activities/generics/location/svg/006-tanzania.svg'),
  'Nicaragua':                        require('components/v2/activities/generics/location/svg/007-nicaragua.svg'),
  'Estonia':                          require('components/v2/activities/generics/location/svg/008-estonia.svg'),
  'Uganda':                           require('components/v2/activities/generics/location/svg/009-uganda.svg'),
  'Slovenia':                         require('components/v2/activities/generics/location/svg/010-slovenia.svg'),
  'Zimbabwe':                         require('components/v2/activities/generics/location/svg/011-zimbabwe.svg'),
  'Sao Tome and Principe':            require('components/v2/activities/generics/location/svg/012-sao-tome-and-principe.svg'),
  'Italy':                            require('components/v2/activities/generics/location/svg/013-italy.svg'),
  'Wales':                            require('components/v2/activities/generics/location/svg/014-wales.svg'),
  'El Salvador':                      require('components/v2/activities/generics/location/svg/015-el-salvador.svg'),
  'Nepal':                            require('components/v2/activities/generics/location/svg/016-nepal.svg'),
  'Lebanon':                          require('components/v2/activities/generics/location/svg/018-lebanon.svg'),
  'Ceuta':                            require('components/v2/activities/generics/location/svg/019-ceuta.svg'),
  'Iraq':                             require('components/v2/activities/generics/location/svg/020-iraq.svg'),
  'Cook Islands':                     require('components/v2/activities/generics/location/svg/021-cook-islands.svg'),
  'Syria':                            require('components/v2/activities/generics/location/svg/022-syria.svg'),
  'Honduras':                         require('components/v2/activities/generics/location/svg/024-honduras.svg'),
  'Anguilla':                         require('components/v2/activities/generics/location/svg/025-anguilla.svg'),
  'Qatar':                            require('components/v2/activities/generics/location/svg/026-qatar.svg'),
  'American Samoa':                   require('components/v2/activities/generics/location/svg/027-american-samoa.svg'),
  'Puerto Rico':                      require('components/v2/activities/generics/location/svg/028-puerto-rico.svg'),
  'Comoros':                          require('components/v2/activities/generics/location/svg/029-comoros.svg'),
  'North Korea':                      require('components/v2/activities/generics/location/svg/030-north-korea.svg'),
  'Corsica':                          require('components/v2/activities/generics/location/svg/031-corsica.svg'),
  'Zambia':                           require('components/v2/activities/generics/location/svg/032-zambia.svg'),
  'China':                            require('components/v2/activities/generics/location/svg/034-china.svg'),
  'Luxembourg':                       require('components/v2/activities/generics/location/svg/035-luxembourg.svg'),
  'Central African Republic':         require('components/v2/activities/generics/location/svg/036-central-african-republic.svg'),
  'Jamaica':                          require('components/v2/activities/generics/location/svg/037-jamaica.svg'),
  'Cape Verde':                       require('components/v2/activities/generics/location/svg/038-cape-verde.svg'),
  'Monaco':                           require('components/v2/activities/generics/location/svg/039-monaco.svg'),
  'Bhutan':                           require('components/v2/activities/generics/location/svg/040-bhutan.svg'),
  'Paraguay':                         require('components/v2/activities/generics/location/svg/041-paraguay.svg'),
  'Aruba':                            require('components/v2/activities/generics/location/svg/042-aruba.svg'),
  'Latvia':                           require('components/v2/activities/generics/location/svg/044-latvia.svg'),
  'Andorra':                          require('components/v2/activities/generics/location/svg/045-andorra.svg'),
  'Federated States of Micronesia':   require('components/v2/activities/generics/location/svg/046-micronesia.svg'),
  'Dominican Republic':               require('components/v2/activities/generics/location/svg/047-dominican-republic.svg'),
  'Abkhazia':                         require('components/v2/activities/generics/location/svg/048-abkhazia.svg'),
  'Tunisia':                          require('components/v2/activities/generics/location/svg/049-tunisia.svg'),
  'Mauritania':                       require('components/v2/activities/generics/location/svg/050-mauritania.svg'),
  'Kosovo':                           require('components/v2/activities/generics/location/svg/052-kosovo.svg'),
  'Ghana':                            require('components/v2/activities/generics/location/svg/053-ghana.svg'),
  'Canary Islands':                   require('components/v2/activities/generics/location/svg/054-canary-islands.svg'),
  'Scotland':                         require('components/v2/activities/generics/location/svg/055-scotland.svg'),
  'Guinea-Bissau':                    require('components/v2/activities/generics/location/svg/056-guinea-bissau.svg'),
  'Burundi':                          require('components/v2/activities/generics/location/svg/057-burundi.svg'),
  'Myanmar':                          require('components/v2/activities/generics/location/svg/058-myanmar.svg'),
  'Gabon':                            require('components/v2/activities/generics/location/svg/059-gabon.svg'),
  'Benin':                            require('components/v2/activities/generics/location/svg/060-benin.svg'),
  'Namibia':                          require('components/v2/activities/generics/location/svg/062-namibia.svg'),
  'Japan':                            require('components/v2/activities/generics/location/svg/063-japan.svg'),
  'Lithuania':                        require('components/v2/activities/generics/location/svg/064-lithuania.svg'),
  'Eritrea':                          require('components/v2/activities/generics/location/svg/065-eritrea.svg'),
  'Chad':                             require('components/v2/activities/generics/location/svg/066-chad.svg'),
  'Kenya':                            require('components/v2/activities/generics/location/svg/067-kenya.svg'),
  'Djibouti':                         require('components/v2/activities/generics/location/svg/068-djibouti.svg'),
  'Tuvalu':                           require('components/v2/activities/generics/location/svg/070-tuvalu-1.svg'),
  'Serbia':                           require('components/v2/activities/generics/location/svg/071-serbia.svg'),
  'Togo':                             require('components/v2/activities/generics/location/svg/073-togo.svg'),
  'Kazakhstan':                       require('components/v2/activities/generics/location/svg/074-kazakhstan.svg'),
  'Antigua and Barbuda':              require('components/v2/activities/generics/location/svg/075-antigua-and-barbuda.svg'),
  'Suriname':                         require('components/v2/activities/generics/location/svg/076-suriname.svg'),
  'Jordan':                           require('components/v2/activities/generics/location/svg/077-jordan.svg'),
  'Belize':                           require('components/v2/activities/generics/location/svg/078-belize.svg'),
  'Iceland':                          require('components/v2/activities/generics/location/svg/080-iceland.svg'),
  'Somalia':                          require('components/v2/activities/generics/location/svg/083-somalia.svg'),
  'Barbados':                         require('components/v2/activities/generics/location/svg/084-barbados.svg'),
  'Solomon Islands':                  require('components/v2/activities/generics/location/svg/085-solomon-islands.svg'),
  'Nigeria':                          require('components/v2/activities/generics/location/svg/086-nigeria.svg'),
  'Uruguay':                          require('components/v2/activities/generics/location/svg/088-uruguay.svg'),
  'Burkina Faso':                     require('components/v2/activities/generics/location/svg/090-burkina-faso.svg'),
  'Slovakia':                         require('components/v2/activities/generics/location/svg/091-slovakia.svg'),
  'Sierra Leone':                     require('components/v2/activities/generics/location/svg/092-sierra-leone.svg'),
  'South Korea':                      require('components/v2/activities/generics/location/svg/094-south-korea.svg'),
  'Mozambique':                       require('components/v2/activities/generics/location/svg/096-mozambique.svg'),
  'San Marino':                       require('components/v2/activities/generics/location/svg/097-san-marino.svg'),
  'Guatemala':                        require('components/v2/activities/generics/location/svg/098-guatemala.svg'),
  'Albania':                          require('components/v2/activities/generics/location/svg/099-albania.svg'),
  'Pakistan':                         require('components/v2/activities/generics/location/svg/100-pakistan.svg'),
  'Northern Cyprus':                  require('components/v2/activities/generics/location/svg/101-northern-cyprus.svg'),
  'Ecuador':                          require('components/v2/activities/generics/location/svg/104-ecuador.svg'),
  'Cameroon':                         require('components/v2/activities/generics/location/svg/105-cameroon.svg'),
  'Panama':                           require('components/v2/activities/generics/location/svg/106-panama.svg'),
  'Kuwait':                           require('components/v2/activities/generics/location/svg/107-kuwait.svg'),
  'Armenia':                          require('components/v2/activities/generics/location/svg/108-armenia.svg'),
  'Romania':                          require('components/v2/activities/generics/location/svg/109-romania.svg'),
  'Guinea':                           require('components/v2/activities/generics/location/svg/110-guinea.svg'),
  'Afghanistan':                      require('components/v2/activities/generics/location/svg/111-afghanistan.svg'),
  'Laos':                             require('components/v2/activities/generics/location/svg/112-laos.svg'),
  'Greenland':                        require('components/v2/activities/generics/location/svg/113-greenland.svg'),
  'Hungary':                          require('components/v2/activities/generics/location/svg/115-hungary.svg'),
  'Angola':                           require('components/v2/activities/generics/location/svg/117-angola.svg'),
  'Malaysia':                         require('components/v2/activities/generics/location/svg/118-malaysia.svg'),
  'Brunei':                           require('components/v2/activities/generics/location/svg/119-brunei.svg'),
  'Bahamas':                          require('components/v2/activities/generics/location/svg/120-bahamas.svg'),
  'New Zealand':                      require('components/v2/activities/generics/location/svg/121-new-zealand.svg'),
  'Vatican City':                     require('components/v2/activities/generics/location/svg/124-vatican-city.svg'),
  'Finland':                          require('components/v2/activities/generics/location/svg/125-finland.svg'),
  'Botswana':                         require('components/v2/activities/generics/location/svg/126-botswana.svg'),
  'Sri Lanka':                        require('components/v2/activities/generics/location/svg/127-sri-lanka.svg'),
  'Spain':                            require('components/v2/activities/generics/location/svg/128-spain.svg'),
  'Ossetia':                          require('components/v2/activities/generics/location/svg/129-ossetia.svg'),
  'Sicily':                           require('components/v2/activities/generics/location/svg/130-sicily.svg'),
  'Chile':                            require('components/v2/activities/generics/location/svg/131-chile.svg'),
  'Bosnia and Herzegovina':           require('components/v2/activities/generics/location/svg/132-bosnia-and-herzegovina.svg'),
  'Saudi Arabia':                     require('components/v2/activities/generics/location/svg/133-saudi-arabia.svg'),
  'Liechtenstein':                    require('components/v2/activities/generics/location/svg/134-liechtenstein.svg'),
  'Belarus':                          require('components/v2/activities/generics/location/svg/135-belarus.svg'),
  'Iran':                             require('components/v2/activities/generics/location/svg/136-iran.svg'),
  'Fiji':                             require('components/v2/activities/generics/location/svg/137-fiji.svg'),
  'Bahrain':                          require('components/v2/activities/generics/location/svg/138-bahrain.svg'),
  'Venezuela':                        require('components/v2/activities/generics/location/svg/139-venezuela.svg'),
  'East Timor':                       require('components/v2/activities/generics/location/svg/140-east-timor.svg'),
  'Azerbaijan':                       require('components/v2/activities/generics/location/svg/141-azerbaijan.svg'),
  'Tibet':                            require('components/v2/activities/generics/location/svg/142-tibet.svg'),
  'Norway':                           require('components/v2/activities/generics/location/svg/143-norway.svg'),
  'Algeria':                          require('components/v2/activities/generics/location/svg/144-algeria.svg'),
  'Ukraine':                          require('components/v2/activities/generics/location/svg/145-ukraine.svg'),
  'Gambia':                           require('components/v2/activities/generics/location/svg/146-gambia.svg'),
  'Bangladesh':                       require('components/v2/activities/generics/location/svg/147-bangladesh.svg'),
  'Czech Republic':                   require('components/v2/activities/generics/location/svg/149-czech-republic.svg'),
  'Bolivia':                          require('components/v2/activities/generics/location/svg/150-bolivia.svg'),
  'United Arab Emirates':             require('components/v2/activities/generics/location/svg/151-united-arab-emirates.svg'),
  'Kyrgyzstan':                       require('components/v2/activities/generics/location/svg/152-kyrgyzstan.svg'),
  'Cuba':                             require('components/v2/activities/generics/location/svg/153-cuba.svg'),
  'Swaziland':                        require('components/v2/activities/generics/location/svg/154-swaziland.svg'),
  'Israel':                           require('components/v2/activities/generics/location/svg/155-israel.svg'),
  'Costa Rica':                       require('components/v2/activities/generics/location/svg/156-costa-rica.svg'),
  'Congo':                            require('components/v2/activities/generics/location/svg/157-republic-of-the-congo.svg'),
  'Egypt':                            require('components/v2/activities/generics/location/svg/158-egypt.svg'),
  'Cambodia':                         require('components/v2/activities/generics/location/svg/159-cambodia.svg'),
  'Ivory Coast':                      require('components/v2/activities/generics/location/svg/161-ivory-coast.svg'),
  'Germany':                          require('components/v2/activities/generics/location/svg/162-germany.svg'),
  'Papua New Guinea':                 require('components/v2/activities/generics/location/svg/163-papua-new-guinea.svg'),
  'Croatia':                          require('components/v2/activities/generics/location/svg/164-croatia.svg'),
  'Belgium':                          require('components/v2/activities/generics/location/svg/165-belgium.svg'),
  'Morocco':                          require('components/v2/activities/generics/location/svg/166-morocco.svg'),
  'Macau':                            require('components/v2/activities/generics/location/svg/167-macau.svg'),
  'Bulgaria':                         require('components/v2/activities/generics/location/svg/168-bulgaria.svg'),
  'Liberia':                          require('components/v2/activities/generics/location/svg/169-liberia.svg'),
  'Greece':                           require('components/v2/activities/generics/location/svg/170-greece.svg'),
  'Saint Lucia':                      require('components/v2/activities/generics/location/svg/172-st-lucia.svg'),
  'Mali':                             require('components/v2/activities/generics/location/svg/173-mali.svg'),
  'Denmark':                          require('components/v2/activities/generics/location/svg/174-denmark.svg'),
  'Lesotho':                          require('components/v2/activities/generics/location/svg/176-lesotho.svg'),
  'Colombia':                         require('components/v2/activities/generics/location/svg/177-colombia.svg'),
  'Palau':                            require('components/v2/activities/generics/location/svg/178-palau.svg'),
  'Ireland':                          require('components/v2/activities/generics/location/svg/179-ireland.svg'),
  'Trinidad and Tobago':              require('components/v2/activities/generics/location/svg/181-trinidad-and-tobago.svg'),
  'Hong Kong':                        require('components/v2/activities/generics/location/svg/183-hong-kong.svg'),
  'Sweden':                           require('components/v2/activities/generics/location/svg/184-sweden.svg'),
  'Haiti':                            require('components/v2/activities/generics/location/svg/185-haiti.svg'),
  'Dominica':                         require('components/v2/activities/generics/location/svg/186-dominica.svg'),
  'Vanuatu':                          require('components/v2/activities/generics/location/svg/187-vanuatu.svg'),
  'Peru':                             require('components/v2/activities/generics/location/svg/188-peru.svg'),
  'Equatorial Guinea':                require('components/v2/activities/generics/location/svg/189-equatorial-guinea.svg'),
  'Uzbekistn':                        require('components/v2/activities/generics/location/svg/190-uzbekistn.svg'),
  'Tonga':                            require('components/v2/activities/generics/location/svg/191-tonga.svg'),
  'Philippines':                      require('components/v2/activities/generics/location/svg/192-philippines.svg'),
  'Malta':                            require('components/v2/activities/generics/location/svg/194-malta.svg'),
  'France':                           require('components/v2/activities/generics/location/svg/195-france.svg'),
  'Tajikistan':                       require('components/v2/activities/generics/location/svg/196-tajikistan.svg'),
  'Argentina':                        require('components/v2/activities/generics/location/svg/198-argentina.svg'),
  'Sudan':                            require('components/v2/activities/generics/location/svg/199-sudan.svg'),
  'South Africa':                     require('components/v2/activities/generics/location/svg/200-south-africa.svg'),
  'Taiwan':                           require('components/v2/activities/generics/location/svg/202-taiwan.svg'),
  'Sahrawi Arab Democratic Republic': require('components/v2/activities/generics/location/svg/203-sahrawi-arab-democratic-republic.svg'),
  'Switzerland':                      require('components/v2/activities/generics/location/svg/205-switzerland.svg'),
  'Rwanda':                           require('components/v2/activities/generics/location/svg/206-rwanda.svg'),
  'Guam':                             require('components/v2/activities/generics/location/svg/207-guam.svg'),
  'Palestine':                        require('components/v2/activities/generics/location/svg/208-palestine.svg'),
  'Indonesia':                        require('components/v2/activities/generics/location/svg/209-indonesia.svg'),
  'Grenada':                          require('components/v2/activities/generics/location/svg/210-grenada.svg'),
  'Poland':                           require('components/v2/activities/generics/location/svg/211-poland.svg'),
  'Moldova':                          require('components/v2/activities/generics/location/svg/212-moldova.svg'),
  'Gibraltar':                        require('components/v2/activities/generics/location/svg/213-gibraltar.svg'),
  'Malawi':                           require('components/v2/activities/generics/location/svg/214-malawi.svg'),
  'England':                          require('components/v2/activities/generics/location/svg/216-england.svg'),
  'Virgin Islands':                   require('components/v2/activities/generics/location/svg/217-virgin-islands.svg'),
  'Turkey':                           require('components/v2/activities/generics/location/svg/218-turkey.svg'),
  'Isle of Man':                      require('components/v2/activities/generics/location/svg/219-isle-of-man.svg'),
  'Vietnam':                          require('components/v2/activities/generics/location/svg/220-vietnam.svg'),
  'Niger':                            require('components/v2/activities/generics/location/svg/222-niger.svg'),
  'Turks and Caicos Islands':         require('components/v2/activities/generics/location/svg/223-turks-and-caicos.svg'),
  'Portugal':                         require('components/v2/activities/generics/location/svg/224-portugal.svg'),
  'Maldives':                         require('components/v2/activities/generics/location/svg/225-maldives.svg'),
  'United States':                    require('components/v2/activities/generics/location/svg/226-united-states.svg'),
  'Senegal':                          require('components/v2/activities/generics/location/svg/227-senegal.svg'),
  'Nauru':                            require('components/v2/activities/generics/location/svg/228-nauru.svg'),
  'Turkmenistan':                     require('components/v2/activities/generics/location/svg/229-turkmenistan.svg'),
  'Singapore':                        require('components/v2/activities/generics/location/svg/230-singapore.svg'),
  'Libya':                            require('components/v2/activities/generics/location/svg/231-libya.svg'),
  'Yemen':                            require('components/v2/activities/generics/location/svg/232-yemen.svg'),
  'Transnistria':                     require('components/v2/activities/generics/location/svg/233-transnistria.svg'),
  'Australia':                        require('components/v2/activities/generics/location/svg/234-australia.svg'),
  'Tokelau':                          require('components/v2/activities/generics/location/svg/235-tokelau.svg'),
  'Macedonia':                        require('components/v2/activities/generics/location/svg/236-republic-of-macedonia.svg'),
  'Netherlands':                      require('components/v2/activities/generics/location/svg/237-netherlands.svg'),
  'Thailand':                         require('components/v2/activities/generics/location/svg/238-thailand.svg'),
  'Montenegro':                       require('components/v2/activities/generics/location/svg/239-montenegro.svg'),
  'Azores':                           require('components/v2/activities/generics/location/svg/240-azores-islands.svg'),
  'Saint Vincent and the Grenadines': require('components/v2/activities/generics/location/svg/241-st-vincent-and-the-grenadines.svg'),
  'Madagascar':                       require('components/v2/activities/generics/location/svg/242-madagascar.svg'),
  'Canada':                           require('components/v2/activities/generics/location/svg/243-canada.svg'),
  'South Sudan':                      require('components/v2/activities/generics/location/svg/244-south-sudan.svg'),
  'India':                            require('components/v2/activities/generics/location/svg/246-india.svg'),
  'Somaliland':                       require('components/v2/activities/generics/location/svg/247-somaliland.svg'),
  'Russia':                           require('components/v2/activities/generics/location/svg/248-russia.svg'),
  'Democratic Republic of the Congo': require('components/v2/activities/generics/location/svg/249-democratic-republic-of-congo.svg'),
  'Samoa':                            require('components/v2/activities/generics/location/svg/251-samoa.svg'),
  'Mexico':                           require('components/v2/activities/generics/location/svg/252-mexico.svg'),
  'Seychelles':                       require('components/v2/activities/generics/location/svg/253-seychelles.svg'),
  'Brazil':                           require('components/v2/activities/generics/location/svg/255-brazil.svg'),
  'Georgia':                          require('components/v2/activities/generics/location/svg/256-georgia.svg'),
  'Mongolia':                         require('components/v2/activities/generics/location/svg/258-mongolia.svg'),
  'United Kingdom':                   require('components/v2/activities/generics/location/svg/260-united-kingdom.svg'),
  'Kiribati':                         require('components/v2/activities/generics/location/svg/261-kiribati.svg'),
};

export const getFlagFor = ({ country }) => {
  if (!COUNTRIES.hasOwnProperty(country)) {
    throw Error('Unknown country');
  }

  return COUNTRIES[country];
};

export const LOCATION_OPTIONS = Object.keys(COUNTRIES)
  .sort()
  .map((name) => ({ value: name, title: name }));
