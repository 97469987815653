import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';
import classNames from 'classnames';

class HeaderLayout extends React.Component {
  constructor(props) {
    super(props);

    // ------ Getting real dimensions of the image ------
    this._loadBgImage = (url) => {
      this._bgImage = new Image();
      this._bgImage.crossOrigin = 'anonymous';
      this._bgImage.src = url;

      this._bgImage.onload = () => {
        this._bgRealImageWidth = this._bgImage.width;
        this._bgRealImageHeight = this._bgImage.height;
      };
    };
    // --------------------------------------------------

    this._bgRealImageWidth = null;
    this._bgRealImageHeight = null;

    this._loadBgImage(defined(props.bannerImageUrl));

    this.state = {
      width:  0,
      height: 0,
      scale:  1,
    };

    this._updateDimensions = () => {
      if (this._bgRealImageWidth === null || this._bgRealImageHeight === null) {
        // the real image has not been loaded yet, retring in a moment
        setTimeout(this._updateDimensions, 1);
        return;
      }

      const node = this.refs['hero-container'];
      const realRatio = this._bgRealImageWidth / this._bgRealImageHeight;
      const nodeRatio = node.clientWidth / node.clientHeight;

      const scale = (nodeRatio > realRatio)
        ? (node.clientWidth / this._bgRealImageWidth)
        : (node.clientHeight / this._bgRealImageHeight);

      this.setState({
        width:  node.clientWidth,
        height: node.clientHeight,
        scale:  scale,
      });
    };
  }

  componentDidMount() {
    this._updateDimensions();
    window.addEventListener('resize', this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bannerImageUrl === this.props.bannerImageUrl) {
      return;
    }

    if (this.props.onBannerImageMouseDown) {
      this._loadBgImage(this.props.bannerImageUrl);
    }
  }

  render() {
    const {
      appv2,
      canEdit,
      renderAvatar,
      renderInfo,
      renderTabs,
      bannerImageUrl,
      onBannerImageMouseDown, // optional
      bannerImagePosX, // optional
      bannerImagePosY, // optional
      cursorState, // optional
    } = this.props;

    const isFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'customBanners' });
    const legacyBgImage = require('images/v2/legacy-background-profile-header.svg');

    const canEditAndFF = defined(canEdit) && isFeatureEnabled;

    const onHeaderDragStart = (e) => {
      if (!onBannerImageMouseDown) {
        return;
      }

      onBannerImageMouseDown(
        this.state.width,
        this.state.height,
        this._bgRealImageWidth,
        this._bgRealImageHeight,
        this.state.scale,
        e.clientX,
        e.clientY,
      );
    };

    const calcBackgroundPosition = () => {
      const x = (bannerImagePosX === undefined ? 'center' : `${ bannerImagePosX }px`);
      const y = (bannerImagePosY === undefined ? 'center' : `${ bannerImagePosY }px`);

      return `${ x } ${ y }`;
    };

    const generateHeaderStyle = () => {
      if (!isFeatureEnabled) {
        return {
          backgroundImage:     `url(${ legacyBgImage })`,
          backgroundSize:      'contain',
          backgroundRepeat:    'no-repeat',
          backgroundPosition:  'bottom',
          backgroundColor:     '#658599',
          backgroundBlendMode: 'multiply',
          cursor:              'default',
        };
      }

      return {
        backgroundImage:    `url(${ bannerImageUrl })`,
        backgroundSize:     'cover',
        backgroundRepeat:   'no-repeat',
        backgroundPosition: calcBackgroundPosition(),
        cursor:             (cursorState ? cursorState : 'default'),
      };
    };

    const avatarClassNames = classNames(
      'v2-profile-header-layout__hero-avatar',
      { 'v2-profile-header-layout__hero-avatar--editable': canEdit }
    );

    const heroOverlayClassNames = classNames(
      'v2-profile-header-layout__hero-overlay',
      { 'v2-profile-header-layout__hero-overlay--editable': canEditAndFF }
    );

    const onBannerClick = (e) => {
      if (!canEditAndFF) {
        return;
      }

      appv2.ui.openHeroBannerImageModal({
        withLeftOffset: true,
        onImageUploaded: () => {
          appv2.ui.redirect({
            url: window.location.pathname + '?positioning=1',
          });
        },
      });
    };

    const onAvatarClick = (e) => {
      if (!defined(canEdit)) {
        return;
      }

      appv2.ui.toggleLegacyAvatarUploader();
    };

    return (
      <div className = "v2-profile-header-layout">
        <div
          ref = "hero-container"
          className = "v2-profile-header-layout__hero-container"
          onMouseDown = { onHeaderDragStart }
          onTouchStart = { onHeaderDragStart }
          style = { generateHeaderStyle() }
        >
          <div className = { heroOverlayClassNames } onClick = { onBannerClick }>
            { onBannerImageMouseDown &&
              <div className = 'v2-profile-header-layout__drag-title'>
                <img src = { require('images/v2/icon-move.svg') } />
                <div>Move and set banner to size</div>
              </div>
            }
          </div>

          <div className = "v2-profile-header-layout__hero-content">

            <div className = "v2-profile-header-layout__hero-col">
              <div
                className = { avatarClassNames }
                onClick = { onAvatarClick }
              >
                { renderAvatar() }
              </div>
            </div>

            <div className = "v2-profile-header-layout__info-wrapper">
              { renderInfo() }
            </div>
          </div>
        </div>

        <div className = "v2-profile-header-layout__tabs-container">
          <div className = "v2-profile-header-layout__tabs-content">
            <div className = "v2-profile-header-layout__tab-items">
              { renderTabs() }
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default HeaderLayout;
