import React from 'react';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import SvgIcon from 'components/svg-icon';

import './button.scss';

export default ({
    onClick,
    icon,
    title,
    disabled,
    link,
    label,
    className,
    type,
    children,
    loading,
    hasShadow,
    isWide,
    isCentered,
    isBlock,
    isFixed,
    isPrimary,
    isAction,
    isDanger,
    isDark,
    isRounded,
    hasCaret,
    hasScore,
    isCompleted,
    isSmall,
    isMobileLink,
    isSuccess,
    isOpaque,
}) => (
  <button
    key={label || icon || title}
    disabled={disabled}
    type={type || (onClick ? 'button' : 'submit')}
    className={classNames('button', className, {
      'is--loading': loading,
      'is--wide': isWide,
      'is--centered': isCentered,
      'is--block': isBlock,
      'is--primary': isPrimary,
      'is--opaque': isOpaque,
      'is--action': isAction,
      'is--danger': isDanger,
      'is--rounded': isRounded,
      'is--fixed': isFixed,
      'is--completed': isCompleted,
      'is--dark': isDark,
      'is--small': isSmall,
      'is--mobile-link': isMobileLink,
      'is--success': isSuccess,
      'has--shadow': hasShadow,
      'has--caret': hasCaret,
      'has--score': hasScore,
    })}
    onClick={onClick || link && (() => browserHistory.push(link))}
  >
    { title && <div className="button__title"> { title }</div> }
    { label && <div className="button__label"> { label }</div> }
    { children && <div className="button__label"> { children }</div> }
    { icon && <div className="button__icon"><SvgIcon id={icon} /></div> }
    { hasCaret && <div className="button__icon"><SvgIcon id="arrow-right" /></div> }
  </button>
);
