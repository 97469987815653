import React from 'react';
import classNames from 'classnames';

import { V2NavbarLogin } from 'components/v2/navbar-login';
import { PageFooter } from 'components/v2/page-footer';

import './index.scss';

export class V2Public extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ghostNavOpaque: false,
    };

    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  componentDidMount() {
    this.onWindowScroll();
    window.addEventListener('scroll', this.onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onWindowScroll() {
    this.setState({ ghostNavOpaque: document.scrollingElement.scrollTop > 0 });
  }

  render() {
    const { children } = this.props;
    const { ghostNavOpaque } = this.state;

    return (
      <div
        className={classNames('v2-public', {
          '--ghostnav-opaque': ghostNavOpaque,
        })}
      >
        <div className="v2-public__page-header">
          <V2NavbarLogin />
        </div>

        <div className="v2-public__page-body">
          { React.cloneElement(children, this.props) }
        </div>

        <div className="v2-public__page-footer">
          <PageFooter />
        </div>
      </div>
    );
  }
}
