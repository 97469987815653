import { defined } from 'lib/helpers';

import { getAllPrimaryIndustries } from 'lib/api/var-employment/primary-industry';

const expectedValues = getAllPrimaryIndustries().map((e) => (e.id)).concat(null);

class PrimaryIndustryId {
  constructor(value) {
    if (expectedValues.indexOf(defined(value)) == -1) {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new PrimaryIndustryId(null);
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new PrimaryIndustryId(data);
  }
}

export default PrimaryIndustryId;
