import './index.scss';
import React from 'react';
import config from 'config';

import { isProfilePage, urlToBeShared } from 'lib/helpers';

import ImgTag    from 'component-lib/components/img-tag';
import UrlCopier from 'components/v2/url-copier';

const ENDPOINTS = {
  FACEBOOK: 'https://www.facebook.com/dialog/feed',
  LINKEDIN: 'https://www.linkedin.com/shareArticle',
  TWITTER:  'https://twitter.com/intent/tweet',
  EMAIL:    'mailto:',
};

const ITEMS_MAP = {
  facebook: {
    name:   'Facebook',
    getUrl: (url) => `${ENDPOINTS.FACEBOOK}?app_id=${config.FACEBOOK_APP_ID}&display=page&link=${url}&redirect_uri=${url}`,
  },
  linkedin: {
    name:   'LinkedIn',
    getUrl: (url) => `${ENDPOINTS.LINKEDIN}?mini=true&url=${url}`,
  },
  twitter: {
    name:   'Twitter',
    getUrl: (url, isProfileOwner) => {
      const text = isProfileOwner
        ? 'I capture all of my professional experience on paddl.com Check out my dynamic profile here'
        : 'I found this profile on paddl.com, what an amazing background! Check out all of their professional experience and how they got it…';

      const hashtags = isProfileOwner
        ? 'hustle'
        : 'workhard,hustle';

      const via = isProfileOwner
        ? 'PaddlJobs'
        : null;

      return via
        ? `${ENDPOINTS.TWITTER}?text=${text}%20${url}&hashtags=${hashtags}&via=${via}`
        : `${ENDPOINTS.TWITTER}?text=${text}%20${url}&hashtags=${hashtags}`;
    },
  },
  email: {
    name:   'Mailto',
    getUrl: (url, isProfileOwner) => {
      const subject = isProfileOwner
        ? 'My Experience Profile for your Review - paddl.com'
        : 'Check out this Experience Profile - paddl.com';

      const body = isProfileOwner
        ? 'Please click the link below to view my dynamic Experience Profile, showcasing all of the activities I have performed to build my skills and experience.'
        : 'I found this profile on paddl.com, it tracks and shows a person’s activities that contribute to their skills and experience. Click the link to check it out…';

      return `${ENDPOINTS.EMAIL}?subject=${subject}&body=${body}%0A%0A${url}`;
    },
  },
};

export const ModalShareProfileContent = ({ appv2, isProfileOwner, profile, shareTypes }) => {
  const title = isProfileOwner ? 'Share your Profile!' : 'Share this Profile!';

  const sendEvent = ({ shareType }) => {
    const pageType = isProfilePage() && 'Profile';

    isProfileOwner
      ? appv2.analytics.sendSharedXpProfileEvent({ shareType })
      : appv2.analytics.sendSharedAPageEvent({ pageType, shareType });
  };

  const fetchAvatarImage = () => {
    const id        = profile.get('id');
    const role      = profile.get('role');
    const avatarUrl = profile.get('avatarUrl');

    const placeholderId = (role === 'student')
      ? `${role}-${id % 5 + 1}`
      : 'employer';

    const avatarImage = avatarUrl || require(`images/placeholder--avatar-${placeholderId}.svg`);

    return avatarImage;
  };

  return (
    <div className = "modal-share-profile-content">
      <div className = "modal-share-profile-content__avatar">
        <ImgTag
          src       = { fetchAvatarImage() }
          width     = "96px"
          isRounded = { true }
        />
      </div>
      <div className = "modal-share-profile-content__title">
        { title }
      </div>
      <div className = "modal-share-profile-content__subtitle">
        Select to share or copy the link below.
      </div>
      <div className = "modal-share-profile-content__share-types">
        { shareTypes.map(shareType =>
          <a
            key     = { shareType }
            href    = { ITEMS_MAP[shareType].getUrl(urlToBeShared(), isProfileOwner) }
            target  = "_blank"
            rel     = "noopener noreferrer"
            onClick = { () => sendEvent({ shareType: ITEMS_MAP[shareType].name }) }
          >
            <div className = { `modal-share-profile-content__icon modal-share-profile-content__icon-${shareType}` } />
          </a>
        ) }
      </div>
      <UrlCopier
        sendEvent = { () => sendEvent({ shareType: 'URL Copy' }) }
      />
    </div>
  );
};
