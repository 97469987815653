import { defined } from 'lib/helpers';
import Activity from 'lib/api/activities/activity';
import VariantMapping from 'lib/api/variant-mapping';

class Data {
  constructor({ state, appv2, variantId, detailsData, skillsetData, durationData }) {
    this._ourState = defined(state);
    this._appv2 = defined(appv2);
    this._variantId = defined(variantId);
    this.detailsData = defined(detailsData);  // public
    this.skillsetData = defined(skillsetData); // public
    this.durationData = defined(durationData); // public

    this.initializeStateWithEmptyFields = () => {
      const combinedFields = Object.assign(
        {},
        this.detailsData.constructor.createEmptyFields(),
        this.skillsetData.constructor.createEmptyFields(),
        this.durationData.constructor.createEmptyFields(),
        {
          profileId: null,
          xpNumber: null,
        },
      );

      this._ourState.setWithoutChanging(combinedFields, () => {
        this.setAsPersistedState();
        this._ourState.setAsSavePoint();
      });
    };

    this.markAsSavePoint = () => {
      this._ourState.setAsSavePoint();
    };

    this.backToSavePoint = () => {
      this._ourState.resetToSavePoint();
    };

    this.isDataChanged = () => {
      return this._ourState.isChanged();
    };

    this.setAsPersistedState = () => {
      this._persistedState = this._ourState.getAll();
    };

    this.resetToPersistedState = () => {
      this._ourState.reset(this._persistedState);
    };

    this.isDataPersisted = () => {
      const data = this._persistedState;

      if (typeof data === 'undefined') {
        return true;
      }

      if (!this.detailsData.isEqualToFields({ data })) {
        return false;
      }

      if (!this.skillsetData.isEqualToFields({ data })) {
        return false;
      }

      if (!this.durationData.isEqualToFields({ data })) {
        return false;
      }

      return true;
    };

    this.fetchFromApi = ({ activityId, isLoggedIn }) => {
      const isXpFeatureEnabled = this._appv2.featureFlags.isEnabled({ featureName: 'xp' });

      return this._appv2.api.activities
        .fetch({ id: defined(activityId), isXpFeatureEnabled, isLoggedIn })
        .then((activity) => {
          const detailsFields = detailsData.constructor
            .fieldsFromActivityDetails({ activityDetails: activity.get('details') });
          const skillsetFields = skillsetData.constructor
            .fieldsFromActivitySkillset({ activitySkillset: activity.get('skillset') });
          const durationFields = durationData.constructor
            .fieldsFromActivityDuration({ activityDuration: activity.get('duration') });

          const combinedFields = Object.assign(
            {},
            detailsFields,
            skillsetFields,
            durationFields,
            {
              profileId: activity.get('profileId'),
              xpNumber: activity.get('xpNumber'),
            },
          );

          this._ourState.setWithoutChanging(combinedFields, () => {
            this.setAsPersistedState();
            this._ourState.setAsSavePoint();
          });
        });
    };

    this.createViaApi = () => {
      const activity = new Activity({
        id: null,
        profileId: null,
        variantId: this._variantId,
        details: this.detailsData.toActivityDetails(),
        skillset: this.skillsetData.toActivitySkillset(),
        duration: this.durationData.toActivityDuration(),
        xpNumber: 0,
      });

      const isXpFeatureEnabled = this._appv2.featureFlags.isEnabled({ featureName: 'xp' });

      return this._appv2.api.activities
        .create({ activity, isXpFeatureEnabled });
    };

    this.updateViaApi = ({ activityId }) => {
      const activity = new Activity({
        id: null,
        profileId: null,
        variantId: this._variantId,
        details: this.detailsData.toActivityDetails(),
        skillset: this.skillsetData.toActivitySkillset(),
        duration: this.durationData.toActivityDuration(),
        xpNumber: 0,
      });

      const isXpFeatureEnabled = this._appv2.featureFlags.isEnabled({ featureName: 'xp' });

      return this._appv2.api.activities
        .update({ id: activityId, activity, isXpFeatureEnabled });
    };

    this.deleteViaApi = ({ activityId }) => {
      const isXpFeatureEnabled = this._appv2.featureFlags.isEnabled({ featureName: 'xp' });

      return this._appv2.api.activities
        .delete({ id: activityId, isXpFeatureEnabled });
    };

    this.canEdit = () => {
      const profileId = this._ourState.get({ id: 'profileId' });
      return (profileId === null || this._appv2.auth.isCurrentProfile({ profileId }));
    };

    this.getXpNumber = () => {
      return this._ourState.get({ id: 'xpNumber' });
    };

    this.isValid = () => {
      return this.detailsData.isValid()
        && this.skillsetData.isValid()
        && this.durationData.isValid();
    };

    this.fetchVariantTitle = () => {
      return VariantMapping.getFor({ variantId: this._variantId }).title();
    };
  }
}

export default Data;
