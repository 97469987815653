import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalShareProfileContent } from 'components/v2/modal-share-profile-content';

export class ModalShareProfile extends React.Component {
  render() {
    const {
      appv2,
      withLeftOffset,
      isProfileOwner,
      profile
    } = this.props;

    const closeFunction = () => {
      appv2.ui.closeModal();
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { withLeftOffset }
        windowWidthSize = "small"
      >
        <ModalShareProfileContent
          appv2          = { appv2 }
          isProfileOwner = { isProfileOwner }
          profile        = { profile }
          shareTypes     = { ['facebook', 'linkedin', 'twitter', 'email'] }
        />
      </Modal>
    );
  }
}
