import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';
import Checkbox    from 'components/v2/input-fields/checkbox';

const AttendanceInHouseAndRemoteInput = ({
  isInHouseChecked,
  onInHouseChange,
  isRemoteChecked,
  onRemoteChange,
  isAutoFocused
}) => {
  return (
    <div className = "v2-attendance-in-house-and-remote-input">
      <div className = "v2-attendance-in-house-and-remote-input__container">
        <label className = "v2-attendance-in-house-and-remote-input__label">
          Attendance
        </label>
        <div className = "columns">
          <div className = "column">
            <Checkbox
              isAutoFocused = { isAutoFocused }
              name          = "attendance-in-house"
              label         = "In-House"
              value         = "in-house"
              isChecked     = { isInHouseChecked }
              onChange      = { (value) => { onInHouseChange(defined(value)); } }
              isError       = { false }
              message       = { null }
            />
          </div>
          <div className = "column">
            <Checkbox
              name      = "attendance-remote"
              label     = "Remote"
              value     = "remote"
              isChecked = { isRemoteChecked }
              onChange  = { (value) => { onRemoteChange(defined(value)); } }
              isError   = { false }
              message   = { null }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceInHouseAndRemoteInput;
