import React from 'react';
import { render } from 'react-dom';
import { browserHistory } from 'react-router';

import fakeV1deps from 'lib/fake-v1deps';
import Appv2 from 'lib/appv2';
import Slugs from 'lib/slugs';

import { V2PublicProfile } from 'components/v2/public-profile';
import { PublicSummaryPage } from 'pages/v2/public/profile/summary-page';
import { PublicExperiencePage } from 'pages/v2/public/profile/experience-page';
import { V2Public } from 'components/v2/public';
import { PublicLandingPage } from 'pages/v2/public/landing-page';
import { PublicTermsPage } from 'pages/v2/public/terms-page';
// TODO: Uncomment the new routes for verified activities in an integration branch
// import VerifiedActivityPage from 'pages/v2/verified-activity-page';
import { PublicNotFound } from 'components/not-found/public-not-found';

import { reducer as featureFlagsReducer } from 'modules/v2/feature-flags';
import { reducer as slugsReducer } from 'modules/v2/slugs';

import './app.scss';

const storeState = {
  auth: {},
  v2: {
    featureFlags: {},
    slugs: {
      byId: {},
      bySlug: {},
    },
  },
};

const store = {
  getState: () => storeState,
  dispatch: (action) => {
    storeState.v2.featureFlags = featureFlagsReducer(storeState.v2.featureFlags, action);
    storeState.v2.slugs = slugsReducer(storeState.v2.slugs, action);
  },
};

const appv2 = new Appv2({ store, v1deps: fakeV1deps });

window._appv2 = appv2; // providing access to appv2 from within v1 components

const setupFeatureFlags = (callback) => {
  appv2.featureFlags.refreshAll().then(callback);
};

const renderAsRoot = (reactNode) => {
  const domElement = document.getElementById('root');
  return render(reactNode, domElement);
};

const renderProfilePage = (idOrSlug, subpage) => {
  const componentMapping = {
    '': 'ROOT',
    '/': 'ROOT',
    '/summary': PublicSummaryPage,
    '/experience': PublicExperiencePage,
  };

  const Component = componentMapping[subpage || ''];

  if (Component === 'ROOT') {
    return appv2.ui.redirect({
      url: `${Slugs.createPath(idOrSlug, null)}/experience`,
    });
  }

  if (Component === undefined) {
    return renderAsRoot(<PublicNotFound />);
  }

  return renderAsRoot(
    <V2PublicProfile
      appv2={appv2}
      params={{ id: idOrSlug }}
    >
      <Component appv2={appv2} params={{ id: idOrSlug }} />
    </V2PublicProfile>
  );
};

// TODO: Uncomment the new routes for verified activities in an integration branch
// const renderVerifiedActivityPage = (activityId) => {
//   return renderAsRoot(
//     <VerifiedActivityPage
//       appv2={appv2}
//       params={{ id: activityId }}
//     />
//   );
// };

const renderPublicPage = (subpage) => {
  const componentMapping = {
    '': PublicLandingPage,
    '/': PublicLandingPage,
    '/terms': PublicTermsPage,
  };

  const Component = componentMapping[subpage || ''];

  if (Component === undefined) {
    return renderAsRoot(<PublicNotFound />);
  }

  return renderAsRoot(
    <V2Public>
      <Component />
    </V2Public>
  );
};

const renderPage = (location) => {
  const matchesProfile = location.pathname.match(/^\/profile\/([^/]+)(\/.*)?$/);

  if (matchesProfile) {
    return renderProfilePage(matchesProfile[1], matchesProfile[2]);
  }

  // TODO: Uncomment the new routes for verified activities in an integration branch
  // const matchesVerifiedActivity = location.pathname.match(/\/verified-activities\/(\d+)/);

  // if (matchesVerifiedActivity) {
  //   return renderVerifiedActivityPage(matchesVerifiedActivity[1]);
  // }

  switch (location.pathname) {
    case '/':
    case '/terms':
      return renderPublicPage(location.pathname);
    case '/register/feedback':
      // This is public-index.js file which serves only public pages.
      // `/register/feedback` page is implemented as part of the main application
      // which is served by `index.js` (not public).
      //
      // By refreshing the page we instruct the browser to request the page
      // from the server again, and that page will contain the correct `index.js` file.
      return appv2.ui.refreshPage();
    case '/not-found':
    default:
      return renderAsRoot(<PublicNotFound />);
  }
};

browserHistory.listen((location) => {
  setupFeatureFlags(() => {
    renderPage(location);
  });
});

setupFeatureFlags(() => {
  renderPage(window.location);
});
