import './index.scss';
import React from 'react';

import ImgTag                           from 'component-lib/components/img-tag';
import Text                             from 'component-lib/type/text';
import { style20pxMediumBlue }          from 'component-lib/type/text/styles';
import { style14pxLightBluegreyItalic } from 'component-lib/type/text/styles';
import dataURLtoBlob                    from 'utils/dataurl-to-blob';

export const UploadImageButton = ({ onImageUpload }) => {
  const iconSrc = require('component-lib/icons/add-round.svg');
  const title = 'Upload an image';
  const subtitle = "Recommended minimum size is 1440x2000px. Maximum file size is 10mb.";

  const onInputChange = () => {
    const file = document.getElementById('uploader-node').files[0];

    if (!file.type.startsWith('image/')) {
      return;
    }

    const reader = new FileReader();

    reader.onload = ((e) => {
      // base64-encoded data
      onImageUpload(dataURLtoBlob(e.target.result));
    });

    reader.readAsDataURL(file);
  };

  return (
    <div className = "v2-upload-image-button">
      <input
        id = "uploader-node"
        type = "file"
        accept = "image/*"
        className = "v2-upload-image-button__file-input-field"
        onChange = { onInputChange }
      />
      <div className = "v2-upload-image-button__content">
        <div className = "v2-upload-image-button__icon-and-title">
          <ImgTag src = { iconSrc } alt = "" width = '32px' height = '32px' />
          <Text { ...style20pxMediumBlue } text = { title } />
        </div>
        <Text { ...style14pxLightBluegreyItalic } text = { subtitle } />
      </div>
    </div>
  );
};
