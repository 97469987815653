import { defined } from 'lib/helpers';

import Skillset     from 'lib/api/activities/generics/skillset';
import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';

class SkillsetData {
  constructor({ state, appv2 }) {
    this._ourState = defined(state);
    this._appv2    = defined(appv2);

    this.isEqualToFields = ({ data }) => {
      const currentSkillset = this._ourState.get({ id: 'skillset' });

      if (data.skillset !== currentSkillset) {
        return false;
      }

      return true;
    };

    this.toActivitySkillset = () => {
      return this._ourState.get({ id: 'skillset' });
    };

    this.getSkillsList = () => {
      const skillset = this._ourState.get({ id: 'skillset' });

      return skillset.skills.map((skill) => {
        return {
          id:   skill.id,
          name: skill.name,
        };
      });
    };

    this.addSkill = ({ id, name }) => {
      const skillset = this._ourState.get({ id: 'skillset' });

      const isDuplicated = skillset.skills.some((skill) => {
        return (skill.name === defined(name));
      });

      if (isDuplicated) {
        return;
      }

      this._ourState.change({
        skillset: new Skillset({
          skills: skillset.skills.concat([new IdNameObject({ id, name })])
        }),
      });
    };

    this.removeSkill = ({ name }) => {
      const skillset = this._ourState.get({ id: 'skillset' });
      const remainingSkills = skillset.skills.filter((skill) => {
        return (skill.name !== defined(name));
      });

      this._ourState.change({
        skillset:    new Skillset({ skills: remainingSkills }),
      });
    };

    this.fetchSkillsSuggestionsFromApi = ({ substring }) => {
      return this._appv2.api.skills
        .fetchSuggestions({ substring: defined(substring) })
        .then((skills) => {
          const hashes = skills.map((obj) => {
            return {
              id:   defined(obj.id),
              name: defined(obj.name),
            };
          });

          this._ourState.setWithoutChanging({
            skillSuggestions: hashes
          });
        });
    };

    this.getSuggestionsForSkill = () => {
      return this._ourState.get({ id: 'skillSuggestions' });
    };

    this.isEverythingEmpty = () => {
      return this._ourState.get({ id: 'skillset' }).isEmpty();
    };

    this.isValid = () => {
      return this._ourState.get({ id: 'skillset' }).isValid();
    };
  }

  static createEmptyFields() {
    return {
      skillset:         Skillset.createEmpty(),
      skillSuggestions: [],
    };
  }

  static fieldsFromActivitySkillset({ activitySkillset }) {
    return {
      skillset: activitySkillset,
    };
  }
}

export default SkillsetData;
