const THRESHOLDS = [ 5, 10, 25, 50, 100, 200, 500, 1000, 2500, 5000, 10000 ];

export const animationSubtitle = ({ xpNumber }) => {
  if (xpNumber <= THRESHOLDS[0]) {
    return "👍 Every point counts"
  };
  if (xpNumber > THRESHOLDS[0] && xpNumber <= THRESHOLDS[1]) {
    return "👌 Little wins, every day"
  };
  if (xpNumber > THRESHOLDS[1] && xpNumber <= THRESHOLDS[2]) {
    return "👏 Well done, keep it going!"
  };
  if (xpNumber > THRESHOLDS[2] && xpNumber <= THRESHOLDS[3]) {
    return "🙌 Nice work"
  };
  if (xpNumber > THRESHOLDS[3] && xpNumber <= THRESHOLDS[4]) {
    return "💪 Good hustle!"
  };
  if (xpNumber > THRESHOLDS[4] && xpNumber <= THRESHOLDS[5]) {
    return "😎 Smooth, very smooth"
  };
  if (xpNumber > THRESHOLDS[5] && xpNumber <= THRESHOLDS[6]) {
    return "🎉 Great job, keep it up!"
  };
  if (xpNumber > THRESHOLDS[6] && xpNumber <= THRESHOLDS[7]) {
    return "⚡ Boom! This is excellent."
  };
  if (xpNumber > THRESHOLDS[7] && xpNumber <= THRESHOLDS[8]) {
    return "🔥 Now that's some good exploring!"
  };
  if (xpNumber > THRESHOLDS[8] && xpNumber <= THRESHOLDS[9]) {
    return "🚀 Amazing, what a score!"
  };

  return "🤯 Uhh, do you sleep?"
};
