import React from 'react';
import { defined } from 'lib/helpers';

import PanelFormHeadline from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown from 'components/v2/input-fields/input-text-dropdown';
import SelectedSkillsSubpanel from './selected-skills-subpanel';

import './index.scss';

class SkillsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  render() {
    const { skillsetData } = this.props.data;
    const { value } = this.state;

    const skills = skillsetData.getSkillsList().map(skill => skill.name);
    const skillSuggestions = skillsetData.getSuggestionsForSkill();

    const onChangeValue = ({ name }) => {
      this.setState({ value: name });
      skillsetData.fetchSkillsSuggestionsFromApi({ substring: defined(name) });
    };

    const onAcceptValue = ({ name }) => {
      skillsetData.addSkill({ id: null, name });
      this.setState({ value: null });
    };

    const onAcceptSuggestion = ({ id, name }) => {
      skillsetData.addSkill({ id, name });
      this.setState({ value: null });
    };

    const onDeleteSkill = ({ name }) => {
      skillsetData.removeSkill({ name });
    };

    return (
      <div className="v2-skills-panel-form">
        <PanelFormHeadline
          iconSrc={require('images/v2/xp/activity-skills.svg')}
          title="Relevant Skills"
          description="Add the skills that you developed during this activity..."
        />
        <div className="v2-skills-panel-form__content">
          <InputTextDropdown
            isAutoFocused
            label="Search and select Skills..."
            placeholder="Enter a skill..."
            value={value || ''}
            options={value === null ? null : skillSuggestions}
            onInputChange={(value) => { onChangeValue({ name: defined(value) }); }}
            onUseClick={(value) => { onAcceptValue({ name: defined(value) }); }}
            onOptionClick={({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); }}
            onBlur={() => { this.setState({ value: null }); }}
          />
          <SelectedSkillsSubpanel
            skills={skills}
            onDeleteSkill={onDeleteSkill}
          />
        </div>
      </div>
    );
  }
}

export default SkillsPanelForm;
