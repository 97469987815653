import './index.scss';
import React from 'react';

import ImgTag from 'component-lib/components/img-tag';

export const ModalSidenavSupportContent = () => {
  return (
    <div className = "v2-modal-sidenav-support-content">
      <div className = "v2-modal-sidenav-support-content__header">
        <div className = "v2-modal-sidenav-support-content__avatar">
          <ImgTag
            src = { require('components/svg-icon/svgs/help-and-support.svg') }
            width = "96px"
            isRounded
          />
        </div>
        <div className = "v2-modal-sidenav-support-content__modal-title">
          Help & Support
        </div>
      </div>
      <div className = "v2-modal-sidenav-support-content__list">
        <div className = "v2-modal-sidenav-support-content__bg">
          <a
            className = "v2-modal-sidenav-support-content__hover-range v2-modal-sidenav-support-content__hover-range--purple"
            href = "https://portal.productboard.com/xw87v7jxrrjqoxozbcd8awmx/tabs/2-under-consideration"
            target = "_blank"
            rel = "noreferrer noopener"
          >
            <div className = "v2-modal-sidenav-support-content__content-wrapper">
              <ImgTag
                src = { require('components/svg-icon/svgs/modal-vote.svg') }
                width = "64px"
              />
              <div className = "v2-modal-sidenav-support-content__texts">
                <div className = "v2-modal-sidenav-support-content__title v2-modal-sidenav-support-content__title--charcoal">
                  Vote for Features
                </div>
                <div className = "v2-modal-sidenav-support-content__subtitle v2-modal-sidenav-support-content__subtitle--new-gray">
                  Help us build the best XP Profile for you
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className = "v2-modal-sidenav-support-content__bg">
          <a
            className = "v2-modal-sidenav-support-content__hover-range v2-modal-sidenav-support-content__hover-range--purple"
            href = "https://help.paddl.com/"
            target = "_blank"
            rel = "noreferrer noopener"
          >
            <div className = "v2-modal-sidenav-support-content__content-wrapper">
              <ImgTag
                src = { require('components/svg-icon/svgs/safety-ring.svg') }
                width = "64px"
              />
              <div className = "v2-modal-sidenav-support-content__texts">
                <div className = "v2-modal-sidenav-support-content__title v2-modal-sidenav-support-content__title--charcoal">
                  Go to Help Centre
                </div>
                <div className = "v2-modal-sidenav-support-content__subtitle v2-modal-sidenav-support-content__subtitle--new-gray">
                  Find help articles for common questions
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className = "v2-modal-sidenav-support-content__bg v2-modal-sidenav-support-content__bg--purple">
          <div
            id = "open-intercom"
            className = "v2-modal-sidenav-support-content__hover-range v2-modal-sidenav-support-content__hover-range--sec-purple-darker"
          >
            <div className = "v2-modal-sidenav-support-content__content-wrapper">
              <ImgTag
                src = { require('components/svg-icon/svgs/intercom-chat-icon.svg') }
                width = "64px"
              />
              <div className = "v2-modal-sidenav-support-content__texts">
                <div className = "v2-modal-sidenav-support-content__title v2-modal-sidenav-support-content__title--white">
                  Chat to Support
                </div>
                <div className = "v2-modal-sidenav-support-content__subtitle v2-modal-sidenav-support-content__subtitle--white">
                  Chat directly with someone from Paddl
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
