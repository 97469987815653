import './index.scss';
import React from 'react';

export const ModalGradientDarkBlue = ({
  children,
  onFullPageClick,
  withLeftOffset,
  windowWidthSize,
}) => {
  return (
    <div className = "v2-modal-gradient-dark-blue">
      <div className = "v2-modal-gradient-dark-blue__overlay" onClick = { onFullPageClick }>
        <div className = { withLeftOffset ? "v2-modal-gradient-dark-blue__offset" : '' }>
          <div className = { windowWidthSize ? `v2-modal-gradient-dark-blue__window v2-modal-gradient-dark-blue__window--${windowWidthSize}` : "v2-modal-gradient-dark-blue__window" }>
            <div className = "v2-modal-gradient-dark-blue__content">
              { children }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
