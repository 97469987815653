import CertificateType from 'lib/api/activities/categories/education/fields/certificate-type';

export const certificateTypeOptions = [
  { value: CertificateType.GENERAL,    title: 'General'            },
  { value: CertificateType.CERT_I,     title: 'I'                  },
  { value: CertificateType.CERT_II,    title: 'II'                 },
  { value: CertificateType.CERT_III,   title: 'III'                },
  { value: CertificateType.CERT_IV,    title: 'IV'                 },
  { value: CertificateType.POST_GRAD,  title: 'Postgraduate'       },
  { value: CertificateType.POST_BAC,   title: 'Post Baccalaureate' },
  { value: CertificateType.POST_MST,   title: 'Post Masters'       },
  { value: CertificateType.SPECIALIST, title: 'Specialist'         },
];
