import React from 'react';

import VariantMapping from 'lib/api/variant-mapping';
import { findCategoryIdBy } from 'lib/api/categories';
import StateWithSavePoints from 'lib/helpers/state-with-save-points';

import Data from 'components/v2/activities/generics/card/data';
import UI from 'components/v2/activities/generics/card/ui';
import UIBehaviourNew from 'components/v2/activities/generics/card/ui-behaviour-new';

import CardHeader from 'components/v2/activities/generics/card-header';
import { FormFooter } from 'components/v2/form-footer';

import CategoryVariantHeader from 'components/v2/activities/generics/card/category-variant-header';
import DetailsSection from 'components/v2/activities/generics/card/details-section';
import SkillsSection from 'components/v2/activities/generics/skills-section';
import DurationSection from 'components/v2/activities/generics/duration-section';

import './index.scss';

class CardNew extends React.Component {
  constructor(props) {
    const { appv2, variantId, modalObject } = props;
    super(props);

    this._variantHandler = VariantMapping.getFor({ variantId });

    // This `state` is SHARED across all the "data" objects.
    // It utilizes (hijacks) the React component's internal state!
    const state = new StateWithSavePoints({ component: this });

    const detailsData = new (this._variantHandler.detailsDataClass())({ state, appv2 });
    const skillsetData = new (this._variantHandler.skillsetDataClass())({ state, appv2 });
    const durationData = new (this._variantHandler.durationDataClass())({ state, appv2 });

    this._data = new Data({
      state,
      appv2,
      variantId,
      detailsData,
      skillsetData,
      durationData,
    });

    this._ui = new UI({ state });
    this._uiBehaviour = new UIBehaviourNew({
      ui: this._ui,
      data: this._data,
    });

    modalObject.shouldAlertOnLeave = () => {
      return !this._data.isDataPersisted();
    };

    this._requestInProgress = false;
  }

  UNSAFE_componentWillMount() {
    this._data.initializeStateWithEmptyFields();
    this._uiBehaviour.reset();
  }

  render() {
    const { appv2, variantId } = this.props;

    const onSubmit = () => {
      if (this._requestInProgress) {
        return; // do not allow double submitting
      }

      this._requestInProgress = true;

      this._data
        .createViaApi()
        .then(({ id, xpNumber, events, isVariantUnlocked }) => {
          this._requestInProgress = false;

          if (appv2.featureFlags.isEnabled({ featureName: 'xp' })) {
            appv2.ui.openAfterActivityUpdatesModal({
              xpNumber,
              onClick: () => { this.props.onSubmit(); },
              events,
              activityId: id,
              isVariantUnlocked,
            });
          } else {
            this.props.onSubmit();
          }
        });
    };

    return (
      <div className="v2-generics-card">
        <div className="v2-generics-card__container">
          <div className="v2-generics-card__header">
            <div className="v2-generics-card__card-new-header">
              <div className="v2-generics-card__card-new-header-container">
                <CardHeader
                  title="Add an Activity"
                  subtitle="Complete each section below to submit your Activity..."
                />
                <CategoryVariantHeader
                  categoryId={findCategoryIdBy({ variantId })}
                  variantIcon={this._variantHandler.icon()}
                  variantTitle={this._variantHandler.title()}
                />
              </div>
            </div>
          </div>

          <div className="v2-generics-card__body">
            <div className="v2-generics-card__body-content">
              <div className="v2-generics-card__section">
                <DetailsSection
                  appv2={appv2}
                  uiBehaviour={this._uiBehaviour}
                  data={this._data}
                  formComponent={this._variantHandler.detailsPanelFormComponent()}
                  viewComponent={this._variantHandler.detailsPanelViewComponent()}
                />
              </div>

              <div className="v2-generics-card__section">
                <SkillsSection uiBehaviour={this._uiBehaviour} data={this._data} />
              </div>

              <div className="v2-generics-card__section">
                <DurationSection
                  uiBehaviour={this._uiBehaviour}
                  data={this._data}
                  formComponent={this._variantHandler.durationPanelFormComponent()}
                />
              </div>
            </div>
          </div>

          <div className="v2-generics-card__footer--form-view">
            <div className="v2-generics-card__footer-container--form-view">
              <FormFooter
                onSubmit={onSubmit}
                onCancel={() => { this.props.onCancel(); }}
                isSubmitDisabled={this._data.isDataChanged() || !this._data.isValid()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardNew;
