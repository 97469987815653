import React from 'react';

import SvgIconClose from 'components/v2/svg-icons/svg-icon-close';

import './index.scss';

const TagDeletable = ({
  label,
  onTagDelete,
}) => {
  return (
    <div className="v2-tag-deletable">
      <div className="v2-tag-deletable__container">
        <div className="v2-tag-deletable__label">
          { label }
        </div>
        <button
          className="v2-tag-deletable__delete-button"
          onClick={() => { onTagDelete(); }}
        >
          <SvgIconClose />
        </button>
      </div>
    </div>
  );
};

export default TagDeletable;
