import './index.scss';
import React from 'react';

const SecondaryDetailReadonly = ({ detail }) => {
  return (
    <div className = "v2-secondary-detail-readonly">
      { detail }
    </div>
  );
};

export default SecondaryDetailReadonly;
