import { fetchStringOfYMWD }                 from 'lib/helpers';
import VariantMapping                        from 'lib/api/variant-mapping';
import { findCategoryIdBy, getCategoryData } from 'lib/api/categories';
import { IssuerData }                        from 'lib/api/var-edu/verified/issuer-data';

export const getActivityCardContentProps = ({ activity, issuerProfile }) => {
  const variantId    = activity.get('variantId');
  const details      = activity.get('details');
  const duration     = activity.get('duration');
  const xpNumber     = activity.get('xpNumber');
  const cooldownMax  = activity.get('cooldownMax');
  const cooldownLeft = activity.get('cooldownLeft');

  const progressState = duration.getProgressState();

  const percentageComplete = duration.percentageComplete && duration.percentageComplete.value;
  const isCurrent          = duration.isCurrent          && duration.isCurrent.value;
  const startYear          = duration.startYear          && duration.startYear.value;
  const startMonth         = duration.startMonth         && duration.startMonth.value;
  const startDay           = duration.startDay           && duration.startDay.value;
  const endYear            = duration.endYear            && duration.endYear.value;
  const endMonth           = duration.endMonth           && duration.endMonth.value;
  const endDay             = duration.endDay             && duration.endDay.value;

  const startDate = new Date(startYear, startMonth - 1, startDay);
  const endDate   = new Date(endYear,   endMonth - 1,   endDay);

  const skillsCount = activity.get('skillsCount')
    ? activity.get('skillsCount').value
    : activity.get('skillset').skills.length;

  const variantHandler = VariantMapping.getFor({ variantId });

  const variantIcon          = variantHandler.icon();
  const variantTitle         = variantHandler.title();
  const activityCardViewData = variantHandler.activityCardViewData(activity);

  const {
    activityName,
    activityProviderName,
  } = activityCardViewData;

  const categoryId   = findCategoryIdBy({ variantId });
  const categoryData = getCategoryData({ categoryId });

  const categoryThemeColor  = categoryData.themeColor();
  const categoryThemeColorD = categoryData.themeColorD();

  const stringOfPercentageComplete = (percentageComplete === 100) ? 'Completed' : `${percentageComplete}% Completed`;
  const stringOfYMWD = fetchStringOfYMWD({ startDate, endDate: isCurrent ? new Date() : endDate });
  const progressCopy = percentageComplete ? stringOfPercentageComplete : stringOfYMWD;

  const issuerCode = details.get('issuerCode');

  if (!issuerCode) {
    return {
      variantIcon,
      variantIconShape: null,
      themeColor:       categoryThemeColor,
      themeColorD:      categoryThemeColorD,
      variantTitle,
      activityName,
      activityProviderName,
      percentageComplete,
      progressCopy,
      progressState,
      xpNumber,
      skillsCount,
      cooldownMax,
      cooldownLeft,
    };
  }

  const issuerProfileImage = (issuerProfile && issuerProfile.get('avatarUrl')) || require('images/placeholder--avatar-employer.svg');

  const issuerData = IssuerData.getFor(issuerCode.value);

  return {
    variantIcon:      issuerProfileImage,
    variantIconShape: 'rounded',
    themeColor:       issuerData.themeColor,
    themeColorD:      issuerData.themeColorD,
    variantTitle,
    activityName,
    activityProviderName,
    percentageComplete,
    progressCopy,
    progressState,
    xpNumber,
    skillsCount,
    cooldownMax,
    cooldownLeft,
  };
};
