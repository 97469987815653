import React from 'react';

const ChevronImage = () => {
  return (
    <svg width="14px" height="24px" viewBox="0 0 14 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
        <polyline id="Arrow" stroke="red" transform="translate(7.000000, 12.000000) rotate(-90.000000) translate(-7.000000, -12.000000) " points="-5 6 7 18 19 6"></polyline>
      </g>
    </svg>
  );
};

export default ChevronImage;
