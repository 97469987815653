export const MAPPING = {
  'white': '#FFFFFF',

  'red':   '#EF5350',
  'red-d': '#9E0300',

  'orange-xl': '#FFE3CE',
  'orange-l':  '#F6BB8B',
  'orange':    '#FF720C',
  'orange-d':  '#D6610B',

  'yellow-l': '#FDDD85',
  'yellow':   '#FEBF0E',
  'yellow-d': '#EBAB00',

  'purple-xl': '#E4D9EF',
  'purple-l':  '#BEAAE2',
  'purple':    '#7E57C2',
  'purple-d':  '#311B92',

  'blue-xxl': '#E0F5FE',
  'blue-xl':  '#B1E5FC',
  'blue-l':   '#6BC3FC',
  'blue':     '#089BFB',
  'blue-d':   '#007BCB',

  'bluegrey-xxl': '#FAFBFC',
  'bluegrey-xl':  '#EAF4F7',
  'bluegrey-l':   '#C4D9E3',
  'bluegrey':     '#6B9FB8',
  'bluegrey-d':   '#124F71',

  'green-l': '#A5FEDE',
  'green':   '#14E3BB',
  'green-d': '#13BB9D',

  'grey-xl': '#BCC0C3',
  'grey-l':  '#95A9B3',
  'grey':    '#324048',
  'grey-d':  '#1C1F21',

  // Branded colors
  'wsu-red-l': '#e86f83',
  'wsu-red':   '#9d2236',
  'wsu-red-d': '#63171b',

  // Legacy colors
  'grey-light':  '#eef1f3',
  'legacy-grey': '#b3bdbb',
  'grey-dark':   '#324048',
  'legacy-blue': '#03a9f4',
  'blue-light':  '#f4fafe',
  'blue-paddl':  '#69d2f7',
  'blue-grey':   '#95a9b3',
  'blue-darker': '#124f71',

  // Legacy status colors
  'warning-yellow': '#FEBF0E',
  'danger-red':     '#FF720C',
  'primary-blue':   '#69D2F7',
  'inactive-gray':  '#6B9FB8',
  'success-green':  '#10E7BE',

  // Legacy page panel colors
  'stat_number_gray': '#6B9FB8',
  'label_gray':       '#95A9B3',
  'divider_gray':     '#DCE7EA',
};

const validate = (colorName) => {
  if (!MAPPING.hasOwnProperty(colorName)) {
    throw Error(`Unexpected colorName: ${colorName}`);
  }
};

export class Swatches {
  static getHexCodeFor(colorName) {
    validate(colorName);
    return MAPPING[colorName];
  }
}
