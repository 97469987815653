import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import Text         from 'lib/api/activities/generics/fields/text';
import DayMonthYear from 'lib/api/activities/generics/day-month-year';

import { CourseUnitDetailsPanelView }         from 'components/v2/activities/categories/education/variants/verified/course-unit/details-panel/view';
import { getCourseUnitDetailsPanelViewProps } from 'components/v2/activities/categories/education/variants/verified/course-unit/details-panel/view/get-props';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/education/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form';

import { IssuerData } from 'lib/api/var-edu/verified/issuer-data';

class VerifiedCourseUnit {
  static categoryCssClassPrefix() {
    return 'v2-categories-education-common';
  }

  static icon() {
    return null;
  }

  static title() {
    return 'Course Unit';
  }

  static subtitle() {
    return null;
  }

  static detailsAttributes() {
    return {
      name:        { dataType: Text         },
      faculty:     { dataType: Text         },
      description: { dataType: Text         },
      intake:      { dataType: Text         },
      code:        { dataType: Text         },
      campus:      { dataType: Text         },
      issuerCode:  { dataType: Text         },
      verifiedAt:  { dataType: DayMonthYear },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    const name       = details.get('name').value;
    const issuerCode = details.get('issuerCode').value;

    return {
      activityName: name,
      activityProviderName: IssuerData.getFor(issuerCode).issuer,
    };
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelViewComponent() {
    return CourseUnitDetailsPanelView;
  }

  static getDetailsPanelViewComponentProps() {
    return getCourseUnitDetailsPanelViewProps;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default VerifiedCourseUnit;
