import './index.scss';
import React from 'react';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = (e) => {
      const { cursor } = this.state;
      const { onOptionClick, options } = this.props;

      switch(e.keyCode) {
        case 38:
          if (cursor > 0) {
            this.setState(prevState => ({
              cursor: prevState.cursor - 1,
            }));
          }
          break;
        case 40:
          if (cursor < this.props.options.length - 1) {
            this.setState(prevState => ({
              cursor: prevState.cursor + 1,
            }));
          }
          break;
        case 13:
          onOptionClick(options[cursor]);
          break;
      }
    };

    this.state = {
      cursor: 0,
    };
  }

  render() {
    const {
      value,
      name,
      label,
      placeholder,
      message,
      isError,
      options,
      onPlaceholderClick,
      onOptionClick,
      onPlaceholderBlur,
    } = this.props;

    const { cursor } = this.state;

    const renderMessage = ({ message, isError }) => {
      if (message === null) {
        return null;
      }
      return (
        <div
          className = { `v2-dropdown__message
          ${isError ? 'v2-dropdown__message--error' : ''}` }>
          { message }
        </div>
      );
    };

    const renderOptions = (options, onOptionClick) => {
      if (options === null || options.length <= 0) {
        return null;
      }

      return (
        <ul className = "v2-dropdown__list">
          {
            options.map((item, idx) => {
              const extraClassName = (idx === this.state.cursor ? 'v2-dropdown__list-item--active' : '');

              return (
                <li
                  className = { "v2-dropdown__list-item " + extraClassName }
                  key = { item.value }
                  onMouseDown = {
                    () => {
                      onOptionClick(item);
                    } }

                  onMouseEnter = {
                    () => {
                      this.setState(prevState => ({
                        cursor: idx,
                      }));
                    } }
                >
                  { item.title }
                </li>
              );
            })
          }
        </ul>
      );
    };

    const renderLabel = () => (
      <label className = "v2-dropdown__label" htmlFor = { name }>
        { label }
      </label>
    );

    return (
      <div className = "v2-dropdown">
        <div className = "v2-dropdown__container">
          { label !== null && renderLabel() }
          <div className = "v2-dropdown__wrapper"
          onKeyDown = { (e) => { this.handleKeyDown(e); } }
          tabIndex="0"
          >
            <button
              name = { name }
              className = { `v2-dropdown__toggle
              ${isError ? 'v2-dropdown__toggle--error' : ''}` }
              onClick = { () => { onPlaceholderClick(); } }
              onBlur = { () => { onPlaceholderBlur(); } }>
              { value || placeholder }
            </button>
            { renderOptions(options, onOptionClick) }
          </div>
          { renderMessage({ message, isError }) }
        </div>
      </div>
    );
  }
}

export default Dropdown;
