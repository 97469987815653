import './index.scss';
import React from 'react';
import { defined } from 'lib/helpers';

import IdNameObject    from 'lib/api/activities/generics/fields/id-name-object';
import SkillsPanelForm from 'components/v2/activities/generics/skills-panel/form';
import { FormFooter }  from 'components/v2/form-footer';

export class ModalSkillsPanelFormViewContent extends React.Component {
  constructor(props) {
    super(props);

    this._originalSkills = this.props.activity.get('skillset').skills;

    this.state = {
      skills: this._originalSkills,
      skillSuggestions: [],
    };
  }

  fetchSkillsSuggestionsFromApi({ substring }) {
    this.props.appv2.api.skills
      .fetchSuggestions({ substring: defined(substring) })
      .then((skills) => {
        const hashes = skills.map((obj) => {
          return {
            id:   defined(obj.id),
            name: defined(obj.name),
          };
        });

        return this.setState({ skillSuggestions: hashes });
      });
  }

  addSkill({ id, name }) {
    const { skills } = this.state;
    const updatedSkills = skills.concat([new IdNameObject({ id, name })]);

    const isDuplicated = skills.some((skill) => {
      return (skill.name === defined(name));
    });

    if (isDuplicated) {
      return;
    }

    this.setState({ skills: updatedSkills });
  }

  removeSkill({ name }) {
    const { skills } = this.state;
    const updatedSkills = skills.filter(skill => skill.name !== name);

    this.setState({ skills: updatedSkills });
  }

  getSkillsList() {
    return this.state.skills;
  }

  getSuggestionsForSkill() {
    return this.state.skillSuggestions;
  }

  render() {
    const { closeFunction } = this.props;
    const { skills } = this.state;

    const onSubmit = () => {
      const { appv2, activity } = this.props;

      const activityId = activity.get('id');
      const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

      const newActivity = activity.cloneWithChanges({
        skillset: activity.get('skillset').cloneWithNewSkills({ skills }),
      });

      const closeModalShowSnackBarAndRefreshPage = () => {
        closeFunction();

        appv2.ui.displaySnackBar({
          style:    'green',
          text:     'Skills updated',
          callback: appv2.ui.refreshPage,
        });
      };

      appv2.api.activities
        .update({ id: activityId, activity: newActivity, isXpFeatureEnabled })
        .then(({ xpNumber, events }) => {
          if (isXpFeatureEnabled) {
            appv2.ui.openAfterActivityUpdatesModal({
              xpNumber,
              events,
              activityId,
              onClick: closeModalShowSnackBarAndRefreshPage,
              isVariantUnlocked: false,
            });
          } else {
            closeModalShowSnackBarAndRefreshPage();
          }
        });
    };

    const skillsHaveNotBeenChanged = () => {
      const difference = skills.filter(skill => !this._originalSkills.includes(skill))
        .concat(this._originalSkills.filter(originalSkill => !skills.includes(originalSkill)));

      return difference.length === 0;
    };

    return (
      <div className = "v2-modal-skills-panel-form-view-content">
        <div className = "v2-modal-skills-panel-form-view-content__container">
          <SkillsPanelForm data = { { skillsetData: this } } />
          <FormFooter
            customSubmitCopy = "Save & Close"
            onCancel = { closeFunction }
            onSubmit = { onSubmit }
            isSubmitDisabled = { skills.length === 0 || skillsHaveNotBeenChanged() }
          />
        </div>
      </div>
    );
  }
}
