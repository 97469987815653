import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';

class ApiEducation {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetchEducatorsSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/education/educators/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const educatorsSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return educatorsSuggestions;
      });
  }

  fetchTrainersSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/education/trainers/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const trainersSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return trainersSuggestions;
      });
  }

  fetchAuthoritiesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/education/authorities/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const authoritiesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return authoritiesSuggestions;
      });
  }

  fetchCampusesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/campuses/suggestions',
        method:   'GET',
        params:   { substring: substring },
      })
      .then((response) => {
        const campusesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return campusesSuggestions;
      });
  }

  fetchBachelorCoursesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/bachelor/courses/suggestions',
        method:   'GET',
        params:   { substring: substring }
      })
      .then((response) => {
        const coursesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return coursesSuggestions;
      });
  }

  fetchMasterCoursesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/master/courses/suggestions',
        method:   'GET',
        params:   { substring: substring }
      })
      .then((response) => {
        const coursesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return coursesSuggestions;
      });
  }

  fetchDiplomaCoursesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/diploma/courses/suggestions',
        method:   'GET',
        params:   { substring: substring }
      })
      .then((response) => {
        const coursesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return coursesSuggestions;
      });
  }

  fetchCourseCoursesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/course/courses/suggestions',
        method:   'GET',
        params:   { substring: substring }
      })
      .then((response) => {
        const coursesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return coursesSuggestions;
      });
  }

  fetchCertificateCertificatesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/certificate/certificates/suggestions',
        method:   'GET',
        params:   { substring: substring },
      })
      .then((response) => {
        const certificateCertificatesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return certificateCertificatesSuggestions;
      });
  }

  fetchHighSchoolCampusesSuggestions({ substring }) {
    return this._transport
      .makeRequest({
        endpoint: '/education/variants/high-school/campuses/suggestions',
        method:   'GET',
        params:   { substring: substring },
      })
      .then((response) => {
        const highSchoolCampusesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return highSchoolCampusesSuggestions;
      });
  }

  fetchHighSchoolsSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/education/schools/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const highSchoolsSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return highSchoolsSuggestions;
      });
  }
}

export default ApiEducation;
