import './index.scss';

import React from 'react';

import ImgTag from 'component-lib/components/img-tag';

export const PublicNavFooterLinks = () => {
  const metaSocialItemObjects = [
    {
      link: 'https://www.facebook.com/paddljobs/',
      defaultImage: require('components/svg-icon/svgs/facebook--grey-dark.svg'),
      hoverImage: require('components/svg-icon/svgs/facebook--grey-dark-active.svg'),
    },
    {
      link: 'https://www.linkedin.com/company/paddl/',
      defaultImage: require('components/svg-icon/svgs/linkedin--grey-dark.svg'),
      hoverImage: require('components/svg-icon/svgs/linkedin--grey-dark-active.svg'),
    },
    {
      link: 'https://twitter.com/PaddlJobs',
      defaultImage: require('components/svg-icon/svgs/twitter--grey-dark.svg'),
      hoverImage: require('components/svg-icon/svgs/twitter--grey-dark-active.svg'),
    },
    {
      link: 'https://www.instagram.com/paddljobs/',
      defaultImage: require('components/svg-icon/svgs/instagram--grey-dark.svg'),
      hoverImage: require('components/svg-icon/svgs/instagram--grey-dark-active.svg'),
    },
  ];

  const metaSocialItems = metaSocialItemObjects.map((item) => (
    <li className="public-nav-footer-links__meta-social-item">
      <a href={item.link} target="_blank" rel="noopener noreferrer">
        <div className="public-nav-footer-links__meta-social-item--default">
          <ImgTag src={item.defaultImage} />
        </div>
        <div className="public-nav-footer-links__meta-social-item--hover">
          <ImgTag src={item.hoverImage} />
        </div>
      </a>
    </li>
  ));

  const LegalItemObjects = [
    {
      link: 'http://status.paddl.com/',
      text: 'Status',
      target: '_blank',
    },
    {
      link: '/terms',
      text: 'Terms',
      target: '_self',
    },
    {
      link: '/terms#privacy',
      text: 'Privacy',
      target: '_self',
    },
    // /*sitemap is not created yet, so it won't be rendered for now*/
    // {
    //   link:   '/sitemap',
    //   text:   'Sitemap',
    //   target: '_blank',
    // },
  ];

  const legalItems = LegalItemObjects.map((item) => (
    <li className="public-nav-footer-links__legal-item">
      <a href={item.link} target={item.target}>
        { item.text }
      </a>
    </li>
  ));

  return (
    <div className="public-nav-footer-links">
      <ul className="public-nav-footer-links__meta-social">
        { metaSocialItems }
      </ul>
      <ul className="public-nav-footer-links__legal">
        { legalItems }
      </ul>
    </div>
  );
};
