import './index.scss';
import React from 'react';

import ImgTag                  from 'component-lib/components/img-tag';
import Text                    from 'component-lib/type/text';
import { style14pxLightWhite } from 'component-lib/type/text/styles';
import { PaddlLogo }           from 'component-lib/components/paddl-logo';

export const ModalSidenavCompanyContent = () => {
  const socialIconObjects = [
    {
      key:          'facebook',
      link:         'https://www.facebook.com/paddljobs/',
      defaultImage: require('components/svg-icon/svgs/facebook-white.svg'),
      hoverImage:   require('components/svg-icon/svgs/facebook-white-active.svg'),
    },
    {
      key:          'linkedin',
      link:         'https://www.linkedin.com/company/paddl/',
      defaultImage: require('components/svg-icon/svgs/linkedin-white.svg'),
      hoverImage:   require('components/svg-icon/svgs/linkedin-white-active.svg'),
    },
    {
      key:          'twitter',
      link:         'https://twitter.com/PaddlJobs',
      defaultImage: require('components/svg-icon/svgs/twitter-white.svg'),
      hoverImage:   require('components/svg-icon/svgs/twitter-white-active.svg'),
    },
    {
      key:          'instagram',
      link:         'https://www.instagram.com/paddljobs/',
      defaultImage: require('components/svg-icon/svgs/instagram-white.svg'),
      hoverImage:   require('components/svg-icon/svgs/instagram-white-active.svg'),
    },
  ];

  const socialIconItems = socialIconObjects.map((item) => {
    return (
      <li key = { item.key } className = "v2-modal-sidenav-company-content__social-links-item">
        <a href = { item.link } target = "_blank" rel = "noreferrer noopener">
          <div className = "v2-modal-sidenav-company-content__social-links-item--default">
            <ImgTag width = "48px" src = { item.defaultImage } />
          </div>
          <div className = "v2-modal-sidenav-company-content__social-links-item--hover">
            <ImgTag width = "48px" src = { item.hoverImage } />
          </div>
        </a>
      </li>
    );
  });

  const siteLinkObjects = [
    {
      link:   'https://paddljobs.com/',
      text:   'About',
      target: '_blank',
      rel:    'noreferrer noopener',
    },
    {
      link:   '/terms',
      text:   'Terms',
      target: '_self',
    },
    {
      link:   '/terms#privacy',
      text:   'Privacy',
      target: '_self',
    },
    /*sitemap is not created yet, so it won't be rendered for now*/
    // {
    //   link:   '/sitemap',
    //   text:   'Sitemap',
    //   target: '_self',
    // },
  ];

  const siteLinkItems = siteLinkObjects.map((item) => {
    return (
      <li key = { item.text } className = "v2-modal-sidenav-company-content__site-links-item">
        <a href = { item.link } target = { item.target } rel = { item.rel }>
          { item.text }
        </a>
      </li>
    );
  });

  return (
    <div className = "v2-modal-sidenav-company-content">
      <PaddlLogo
        hasLink
        hasName
        customColor = "white"
        customWidthInPx = { 200 }
      />
      <div className = "v2-modal-sidenav-company-content__info">
        <div>
          Designed in <span className = "v2-modal-sidenav-company-content__emoji">🇸🇪</span> made in <span className = "v2-modal-sidenav-company-content__emoji">🇦🇺</span>
        </div>
        <div>
          Melbourne, AUS
        </div>
      </div>
      <ul className = "v2-modal-sidenav-company-content__social-links">
        { socialIconItems }
      </ul>
      <ul className = "v2-modal-sidenav-company-content__site-links">
        { siteLinkItems }
      </ul>
      <Text
        { ...style14pxLightWhite }
        text = "© Paddl Co. 2020, All rights reserved."
      />
    </div>
  );
};
