import './index.scss';
import React from 'react';
import config from 'config';

import Text                  from 'component-lib/type/text';
import { animationSubtitle } from 'component-lib/components/xp-value-update-view/animatron';
import { Odometer }          from 'components/v2/odometer';
import { FacebookCircle }    from 'component-lib/svg-components/facebook-circle';
import { LinkedinCircle }    from 'component-lib/svg-components/linkedin-circle';
import { TwitterCircle }     from 'component-lib/svg-components/twitter-circle';
import { EmailCircle }       from 'component-lib/svg-components/email-circle';

import {
  style22pxRegularWhite,
  style20pxRegularWhite,
  style16pxRegularWhite,
} from 'component-lib/type/text/styles';

export class XpValueUpdateView extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      profilePath: null,
    };
  }

  render() {
    const { appv2, xpNumber, isAnimated, onAnimationFinished } = this.props;

    const animateSubtitle = () => {
      const subtitle = document.getElementById('XpValueUpdateView-subtitle');

      if (subtitle === null) {
        // the component may have disappeared by the time this callback is called
        return;
      }

      subtitle.classList.add('xp-value-update-view__subtitle--animated');
      onAnimationFinished && setTimeout(onAnimationFinished, 1000);
    };

    const renderShareButtons = () => {
      const profilePath = this.state.profilePath ||
        (
          appv2.slugs.generateProfilePath({
            id:       appv2.auth.currentProfileId(),
            callback: ({ path }) => { this.setState({ profilePath: path }); },
          })
        );

      const urlToShare = config.PADDL_URL + profilePath + '/experience';
      const escapedUrl = encodeURIComponent(urlToShare);

      const getFacebookUrl = () => {
        return `https://www.facebook.com/dialog/feed?app_id=${config.FACEBOOK_APP_ID}&display=page&link=${escapedUrl}&redirect_uri=${escapedUrl}`;
      };

      const getLinkedinUrl = () => {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${escapedUrl}`;
      };

      const getTwitterUrl = () => {
        const text = encodeURIComponent('I capture all of my professional experience on paddl.com Check out my dynamic profile here');
        const hashtags = encodeURIComponent('workhard,hustle');

        return `https://twitter.com/intent/tweet?text=${text}%20${escapedUrl}&hashtags=${hashtags}`;
      };

      const getEmailUrl = () => {
        const subject = encodeURIComponent('My Experience Profile for your Review - paddl.com');
        const body = encodeURIComponent('Please click the link below to view my dynamic Experience Profile, showcasing all of the activities I have performed to build my skills and experience.');

        return `mailto:?subject=${subject}&body=${body}%0A%0A${escapedUrl}`;
      };

      const onClick = (event, shareType) => {
        // TODO: change pageType if we decide to share another page
        appv2.analytics.sendSharedAPageEvent({ pageType: 'Profile', shareType });
        event.stopPropagation();
      };

      return (
        <div className = "xp-value-update-view__share-buttons">
          <FacebookCircle url = { getFacebookUrl() } onClick = { (event) => { onClick(event, 'Facebook'); } } />
          <LinkedinCircle url = { getLinkedinUrl() } onClick = { (event) => { onClick(event, 'LinkedIn'); } } />
          <TwitterCircle url = { getTwitterUrl() } onClick = { (event) => { onClick(event, 'Twitter'); } } />
          <EmailCircle url = { getEmailUrl() } onClick = { (event) => { onClick(event, 'Mailto'); } } />
        </div>
      );
    };

    if (xpNumber > 0) {
      const subtitleClassname = `xp-value-update-view__subtitle ${isAnimated ? '' : 'xp-value-update-view__subtitle--animated'}`;

      return (
        <div className = "xp-value-update-view">
          <Text { ...style22pxRegularWhite } text = "You Earned" />
          <Odometer
            isAnimated = { isAnimated }
            startValue = { 0 }
            endValue = { xpNumber }
            shouldShowSign
            animationLength = { 4000 }
            onFinish = { animateSubtitle }
          />
          <div id = "XpValueUpdateView-subtitle" className = { subtitleClassname }>
            <Text { ...style20pxRegularWhite } text = { animationSubtitle({ xpNumber: xpNumber }) } />
          </div>
          <div className = "xp-value-update-view__divider" />
          <div className = "xp-value-update-view__support-section">
            <Text { ...style16pxRegularWhite } text = "Share your achievement!" />
            { renderShareButtons() }
          </div>
        </div>
      );
    }

    if (xpNumber < 0) {
      return (
        <div className = "xp-value-update-view">
          <Text { ...style22pxRegularWhite } text = "Oh no! You lost some XP" />
          <Odometer
            isAnimated = { isAnimated }
            startValue = { 0 }
            endValue = { xpNumber }
            shouldShowSign
            animationLength = { 4000 }
            onFinish = { onAnimationFinished }
          />
          <Text { ...style20pxRegularWhite } text = "Your XP for this Activity has been adjusted." />
          <div className = "xp-value-update-view__divider" />
          { /* TODO: Confirm hover state & url */ }
          <a
            className = "xp-value-update-view__dotted-underline"
            href      = "https://paddljobs.com/"
            target    = "_blank"
            rel       = "noopener noreferrer"
            onClick   = { event => event.stopPropagation() }
          >
            <Text { ...style16pxRegularWhite } text = "What does this mean?" />
          </a>
        </div>
      );
    }

    return null;
  }
}
