import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';
import Checkbox    from 'components/v2/input-fields/checkbox';

const AttendanceInPersonAndOnlineInput = ({
  isInPersonChecked,
  onInPersonChange,
  isOnlineChecked,
  onOnlineChange,
  isAutoFocused
}) => {
  return (
    <div className = "v2-attendance-in-person-and-online-input">
      <div className = "v2-attendance-in-person-and-online-input__container">
        <label className = "v2-attendance-in-person-and-online-input__label">
          Attendance
        </label>
        <div className = "columns">
          <div className = "column">
            <Checkbox
              isAutoFocused = { isAutoFocused }
              name          = "attendance-in-person"
              label         = "In Person"
              value         = "in-person"
              isChecked     = { isInPersonChecked }
              onChange      = { (value) => { onInPersonChange(defined(value)); } }
              isError       = { false }
              message       = { null }
            />
          </div>
          <div className = "column">
            <Checkbox
              name      = "attendance-online"
              label     = "Online"
              value     = "online"
              isChecked = { isOnlineChecked }
              onChange  = { (value) => { onOnlineChange(defined(value)); } }
              isError   = { false }
              message   = { null }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceInPersonAndOnlineInput;
