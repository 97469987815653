import { defined } from 'lib/helpers';
import React       from 'react';
import pluralize   from 'pluralize';

import PanelFormHeadline               from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown               from 'components/v2/input-fields/input-text-dropdown';
import InputTextSingleLine             from 'components/v2/input-fields/input-text-single-line';
import Select                          from 'components/v2/input-fields/select';
import AttendanceInHouseAndRemoteInput from 'components/v2/activities/categories/employment/fields/attendance/in-house-and-remote/input';
import InputTextMultiline              from 'components/v2/input-fields/input-text-multiline';

import Location                    from 'lib/api/location';
import { getAllPrimaryIndustries } from 'lib/api/var-employment/primary-industry';

class InternshipCasualWorkDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForRole: true,
      isValueAcceptedForEmployer: true,
      isValueAcceptedForWorkingLocation: true,
      workingLocationSuggestions: [],
    };

    this.isValueAcceptedForRole = () => {
      return this.state.isValueAcceptedForRole;
    };

    this.isValueAcceptedForEmployer = () => {
      return this.state.isValueAcceptedForEmployer;
    };

    this.isValueAcceptedForWorkingLocation = () => {
      return this.state.isValueAcceptedForWorkingLocation;
    };

    this.setValueAcceptedForRole = (value) => {
      return this.setState({ isValueAcceptedForRole: value });
    };

    this.setValueAcceptedForEmployer = (value) => {
      return this.setState({ isValueAcceptedForEmployer: value });
    };

    this.setValueAcceptedForWorkingLocation = (value) => {
      return this.setState({ isValueAcceptedForWorkingLocation: value });
    };

    this._fetchLocationSuggestions = ({ substring }) => {
      const updateSuggestions = (predictions, status) => {
        const hashes = predictions
          ? predictions.filter((obj) => obj.hasOwnProperty('place_id')).map((obj) => {
            return {
              id:   defined(obj.place_id),
              name: defined(obj.description),
            };
          })
          : [];

        this.setState({ workingLocationSuggestions: hashes })
      };

      if (typeof google === 'undefined') {
        console.log('Google has not been loaded yet');
        return null;
      }

      if (typeof this._googleService == 'undefined') {
        this._googleService = new google.maps.places.AutocompleteService();
      }

      if (defined(substring).trim().length > 0) {
        this._googleService.getQueryPredictions({ input: substring }, updateSuggestions);
      } else {
        this.setState({ workingLocationSuggestions: [] })
      }
    };
  }

  componentDidMount () {
    const script = document.createElement("script");

    if (typeof google === 'undefined') {
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDMJNmxwNJspSrQvgejfgB4KxqK8Wsxh-Q&libraries=places";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const { fetchVariantTitle, detailsData } = this.props.data;

    const renderRoleNameInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'role',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForRole(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'role',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'role',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForRole(true);
      };

      const onAcceptSuggestion = (role) => {
        detailsData.setField({
          attrName: 'role',
          data:     role,
        });
        this.setValueAcceptedForRole(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { true }
          label         = { detailsData.getFieldLabel('role') }
          placeholder   = { detailsData.getFieldPlaceholder('role') }
          value         = { detailsData.getField('role').name }
          options       = { this.isValueAcceptedForRole() ? null : detailsData.getSuggestions('role') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderEmployerInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'employer',
          data:     { id: null, name: name, profileId: null, icon: '', location: Location.createEmpty() },
        });
        this.setValueAcceptedForEmployer(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'employer',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'employer',
          data:     { id: null, name: name, profileId: null, icon: '', location: Location.createEmpty() },
        });
        this.setValueAcceptedForEmployer(true);
      };

      const onAcceptSuggestion = (employer) => {
        detailsData.setField({
          attrName: 'employer',
          data:     employer,
        });

        this.setValueAcceptedForEmployer(true);
      };

      return (
        <InputTextDropdown
          label         = { detailsData.getFieldLabel('employer') }
          placeholder   = { detailsData.getFieldPlaceholder('employer') }
          value         = { detailsData.getField('employer').name }
          options       = { this.isValueAcceptedForEmployer() ? null : detailsData.getSuggestions('employer') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name, profileId, icon, location }) => { onAcceptSuggestion({
            id:        defined(id),
            name:      defined(name),
            profileId: defined(profileId),
            icon:      defined(icon),
            location:  defined(location),
          }); } }
        />
      );
    };

    const renderHoursPerWeekInput = () => {
      const hoursPerWeek = detailsData.getField('hoursPerWeek');

      return (
        <InputTextSingleLine
          name           = "commitment"
          label          = { detailsData.getFieldLabel('hoursPerWeek') }
          placeholder    = { detailsData.getFieldPlaceholder('hoursPerWeek') }
          copyAfterField = { `${ pluralize('Hour', hoursPerWeek) } per Week` }
          copyWidthRatio = { 0.7 }
          value          = { hoursPerWeek || '' }
          onChange       = { (value) => {
            // Limit user to input only integer from 1 to 168 (24hrs * 7days)
            // NOTE: There is a bug in React allowing a trailing dot:
            // https://github.com/facebook/react/issues/9561

            const intValue = parseInt(value);

            if (!value.match(/^\d*$/)) {
              return;
            }

            if (intValue < 1 || intValue > 168) {
              return;
            }

            detailsData.setField({
              attrName: 'hoursPerWeek',
              data:     (isNaN(intValue) ? null : intValue),
            });
          } }
        />
      );
    };

    const renderPrimaryIndustryInput = () => {
      const primaryIndustryOptions = getAllPrimaryIndustries().map((industry) => ({
        value: industry.id,
        title: industry.name,
      }));

      return (
        <Select
          name          = "primary-industry"
          inputFontSize = "small"
          label         = { detailsData.getFieldLabel('primaryIndustryId') }
          placeholder   = { detailsData.getFieldPlaceholder('primaryIndustryId') }
          value         = { detailsData.getField('primaryIndustryId') }
          options       = { primaryIndustryOptions }
          onChange      = { (id) => { detailsData.setField({ attrName: 'primaryIndustryId', data: parseInt(id, 10) }); } }
        />
      );
    };

    const renderLocationInput = () => {
      return (
        <InputTextDropdown
          name               = "working-location"
          label              = { detailsData.getFieldLabel('workingLocation') }
          placeholder        = { detailsData.getFieldPlaceholder('workingLocation') }
          value              = { detailsData.getField('workingLocation').originalText || '' }
          options            = { this.isValueAcceptedForWorkingLocation() ? null : this.state.workingLocationSuggestions }
          isFreeTextRejected = { true }
          onBlur = { () => {
            if (detailsData.getField('workingLocation').placeId) return;

            const newLocation = new Location({
              placeId:      null,
              originalText: '',
              shortText:    '',
              lat:          null,
              lng:          null,
            });

            detailsData.setField({ attrName: 'workingLocation', data: newLocation });
            this._fetchLocationSuggestions({ substring: '' });
          } }

          onInputChange = { (value) => {
            const newLocation = new Location({
              placeId:      null,
              originalText: value,
              shortText:    value,
              lat:          null,
              lng:          null,
            });

            detailsData.setField({ attrName: 'workingLocation', data: newLocation });
            this._fetchLocationSuggestions({ substring: value });
            this.setValueAcceptedForWorkingLocation(false);
          } }

          onOptionClick = { ({ id, name }) => {
            const newLocation = new Location({
              placeId:      id,
              originalText: name,
              shortText:    name,
              lat:          null,
              lng:          null,
            });

            detailsData.setField({ attrName: 'workingLocation', data: newLocation });
            this.setValueAcceptedForWorkingLocation(true);
          } }
        />
      );
    };

    const renderAttendanceInput = () => {
      return (
        <AttendanceInHouseAndRemoteInput
          isInHouseChecked = { detailsData.getField('attendanceInHouse') }
          isRemoteChecked  = { detailsData.getField('attendanceRemote') }
          onInHouseChange  = { (value) => { detailsData.setField({ attrName: 'attendanceInHouse', data: value }); } }
          onRemoteChange   = { (value) => { detailsData.setField({ attrName: 'attendanceRemote', data: value }); } }
        />
      );
    };

    const renderDescriptionInput = () => {
      return (
        <InputTextMultiline
          name        = "description"
          label       = { detailsData.getFieldLabel('description') }
          placeholder = { detailsData.getFieldPlaceholder('description') }
          value       = { detailsData.getField('description') }
          onChange    = { (value) => { detailsData.setField({ attrName: 'description', data: value }); } }
          hasHint     = { true }
          message     = "Max 400 Characters"
          maxLength   = { 400 }
        />
      );
    };

    return (
      <div className = "v2-internship-casual-work-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = { `${ fetchVariantTitle() } Details` }
          description = { `Enter your ${ fetchVariantTitle() } details to continue` }
        />
        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderRoleNameInput() }</div>
          <div className = "column is-full">{ renderEmployerInput() }</div>
          <div className = "column is-half">{ renderHoursPerWeekInput() }</div>
          <div className = "column is-half">{ renderPrimaryIndustryInput() }</div>
          <div className = "column is-full">{ renderLocationInput() }</div>
          <div className = "column is-full">{ renderAttendanceInput() }</div>
          <div className = "column is-full">{ renderDescriptionInput() }</div>
        </div>
      </div>
    );
  }
};

export default InternshipCasualWorkDetailsPanelForm;
