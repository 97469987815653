import './index.scss';
import React from 'react';

export const EmailCircle = (props) => {
  return (
    <a href = { props.url } target = "_blank" onClick = { props.onClick }>
      <svg className = "email-circle" width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path className = "email-circle__bg" d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z" />
        <path className = "email-circle__content" d="M36.6 15H11.4C10.63 15 10 15.6329 10 16.4065V30.2953C10 31.0688 10.63 31.7018 11.4 31.7018H36.6C37.37 31.7018 38 31.0688 38 30.2953V16.4065C38 15.6329 37.37 15 36.6 15ZM36.6 15.879C36.8846 15.879 37.125 16.1206 37.125 16.4065V30.2953C37.125 30.5812 36.8846 30.8227 36.6 30.8227H11.4C11.1154 30.8227 10.875 30.5812 10.875 30.2953V16.4065C10.875 16.1206 11.1154 15.879 11.4 15.879H36.6Z" />
        <path className = "email-circle__content" d="M23.7544 25.3158L10.4912 16.2332L10.9729 15.4912L23.7544 24.2439L36.5359 15.4912L37.0175 16.2332L23.7544 25.3158Z" />
      </svg>
    </a>
  );
};
