import React from 'react';

export const ModalConductor = ({
  appv2,
  currentModalComponent,
  currentModalProps,
}) => {
  if (currentModalComponent === null) {
    return null;
  }

  const Component = currentModalComponent;

  return <Component { ...currentModalProps } appv2 = { appv2 } />;
};
