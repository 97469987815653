class ApiVisibilitySettings {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetch() {
    const endpoint = '/visibility-settings';

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then((data) => {
        return data;
      });
  }

  update({ data }) {
    const endpoint = '/visibility-settings';

    return this._transport
      .makeRequest({ endpoint, method: 'POST', data })
      .then((responseData) => {
        return true;
      });
  }
}

export default ApiVisibilitySettings;
