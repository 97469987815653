import { defined } from 'lib/helpers';

export const ACCESS_DENIED = 'ACCESS_DENIED';
export const BAD_INPUT     = 'BAD_INPUT';
const KNOWN_CODES          = [ACCESS_DENIED, BAD_INPUT];

class PaddlError extends Error {
  constructor({ code }) {
    super(code);

    if (KNOWN_CODES.indexOf(code) == -1) {
      throw Error('Unknown code of Paddl-Error-Code');
    }

    this.code         = defined(code);
    this.isPaddlError = true;
  }
}

export default PaddlError;
