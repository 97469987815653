import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import SecondaryDetailReadonly       from 'components/v2/activities/generics/fields/secondary-detail/readonly';
import FlagAndCopy                   from 'component-lib/components/flag-and-copy';
import XpTags                        from 'component-lib/components/xp-tags';

import { certificateTypeOptions }         from 'components/v2/activities/categories/education/variants/certificate/certificateTypeOptions';
import { getFlagFor }                     from 'components/v2/activities/generics/location/country-flags';
import { getDurationValueUnit }           from 'components/v2/activities/generics/duration-value-unit/helper';
import { getCommitment }                  from 'components/v2/activities/categories/education/fields/commitment/helper';
import { getAttendanceInPersonAndOnline } from 'components/v2/activities/categories/education/fields/attendance/in-person-and-online/helper';

const CertificateDetailsPanelView = ({ data }) => {
  const { detailsData } = data;

  const location            = detailsData.getField('location');
  const certificateType     = detailsData.getField('certificateType');
  const certificateDuration = detailsData.getField('certificateDuration');
  const commitment          = detailsData.getField('commitment');
  const attendanceInPerson  = detailsData.getField('attendanceInPerson');
  const attendanceOnline    = detailsData.getField('attendanceOnline');

  const certificateTypeTitle = certificateTypeOptions
    .find((e) => (e.value === certificateType))
    .title;

  return (
    <div className = "v2-certificate-details-panel-view">
      <div className = "v2-certificate-details-panel-view__subpanel">
        <Text { ...style20pxRegularBluegreyD } text = { detailsData.getField('certificate').name } />
        <SecondaryDetailReadonly detail = { detailsData.getField('authority').name } />
        <FlagAndCopy
          countryIcon = { getFlagFor({ country: location }) }
          copy = { `${ location } | ${ certificateTypeTitle }` }
        />
      </div>

      <div className = "v2-certificate-details-panel-view__subpanel">
        <XpTags
          strings = { [
            getDurationValueUnit({ duration: certificateDuration }),
            getCommitment({ commitment }),
            getAttendanceInPersonAndOnline({ attendanceInPerson, attendanceOnline }),
          ] }
        />
      </div>
    </div>
  );
};

export default CertificateDetailsPanelView;
