import { defined, DefinedMap } from 'lib/helpers';
import Location                from 'lib/api/location';
import VisaStatus              from './visa-status';

class ProfileExtra extends DefinedMap {
  constructor(data) {
    super({
      attributes: [
        'location',
        'visaStatus',
        'visaConditionEmployment',
        'visaConditionStudy',
        'opportunitiesRelocation',
        'opportunitiesRemote',
      ],
      data
    });
  }

  isEmpty() {
    return (this.get('location').isEmpty() && this.get('visaStatus').isEmpty());
  }

  toApiFormat() {
    return {
      location:                this.get('location').toApiFormat(),
      visaStatus:              this.get('visaStatus').toApiFormat(),
      visaConditionEmployment: this.get('visaConditionEmployment') || false,
      visaConditionStudy:      this.get('visaConditionStudy') || false,
      opportunitiesRelocation: this.get('opportunitiesRelocation') || false,
      opportunitiesRemote:     this.get('opportunitiesRemote') || false,
    };
  }

  static fromApiFormat(data) {
    return new ProfileExtra({
      location:                Location.fromApiFormat(data.location),
      visaStatus:              VisaStatus.fromApiFormat(data.visaStatus),
      visaConditionEmployment: data.visaConditionEmployment,
      visaConditionStudy:      data.visaConditionStudy,
      opportunitiesRelocation: data.opportunitiesRelocation,
      opportunitiesRemote:     data.opportunitiesRemote,
    });
  }

  static createEmpty() {
    return new ProfileExtra({
      location:                Location.createEmpty(),
      visaStatus:              VisaStatus.createEmpty(),
      visaConditionEmployment: false,
      visaConditionStudy:      false,
      opportunitiesRelocation: false,
      opportunitiesRemote:     false,
    });
  }
}

export default ProfileExtra;
