import './index.scss';
import React from 'react';

import { defined }         from 'lib/helpers';
import { getCategoryData } from 'lib/api/categories';
import ChevronImage        from './chevron';

class CategoryItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const categoryData = getCategoryData({ categoryId: this.props.categoryId });
    const image = <img src = { categoryData.icon() } />;
    const name = categoryData.title();
    const subtitle = categoryData.subTitle();

    const containerClassName =
      "columns is-vcentered" +
      (defined(this.props.isClickable) ? " v2-category-item__container--clickable" : '');

    const containerOnClick = () => {
      if (defined(this.props.isClickable)) {
        this.props.onClick(this.props.categoryId);
      }
    };

    const renderChevron = () => {
      return (
        <div className = "v2-category-item__icon-angle-right">
          <ChevronImage />
        </div>
      );
    };

    return (
      <div className = "v2-category-item">
        <div className = { containerClassName } onClick = { containerOnClick }>
          <div className = "column v2-category-item__category-icon-wrapper ">
            { image }
          </div>
          <div className = "column">
            <div className = "v2-category-item__category-name">
              { name }
            </div>
            <div className = "v2-category-item__subtitle">
              { subtitle }
            </div>
            { this.props.isClickable && renderChevron() }
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryItem;
