import './index.scss';
import React from 'react';

import CategoryItem from 'components/v2/activities/generics/category-item';

const CategoryVariantHeader = ({ categoryId, variantIcon, variantTitle }) => {
  return (
    <div className = "v2-category-variant-header">
      <div className = "v2-category-variant-header__container">
        <CategoryItem
          categoryId = { categoryId }
          isClickable = { false }
        />
        <div className = "v2-category-variant-header__item">
          <div className = "columns is-vcentered is-mobile">
            <div className = "column v2-category-variant-header__variant-icon-wrapper ">
              <img src = { variantIcon } />
            </div>
            <div className = "column">
              <div className = "v2-category-variant-header__variant-name">
                { variantTitle }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryVariantHeader;
