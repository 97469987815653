import './index.scss';
import React from 'react';

const CardHeader = ({ title, subtitle }) => {
  return (
    <div className = "v2-card-header">
      <div className = "v2-card-header__container">
        <div className = "v2-card-header__title">
          { title }
        </div>
        <div className = "v2-card-header__subtitle">
          { subtitle }
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
