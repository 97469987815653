import React from 'react';

import PanelFormHeadline from 'components/v2/activities/generics/panel-form-headline';
import Datepicker        from 'components/v2/input-fields/datepicker';

const PaddlGamesDurationPanelForm = ({
  startDate,
  endDate,
  // All the props are passed in from renderFormView of DurationSection
}) => {
  return (
    <div className = "v2-paddl-games-duration-panel-form">
      <PanelFormHeadline
        iconSrc     = { require('images/v2/xp/activity-duration.svg') }
        title       = 'Progress'
        description = 'Enter your Start and Finish dates for this Activity...'
      />

      <div className = "v2-paddl-games-duration-panel-form__container">
        <div className = "columns v2-paddl-games-duration-panel-form__form">
          <div className = "column">
            <Datepicker
              isReadonly  = { true }
              label       = "Start"
              placeholder = "Select Date"
              value       = { startDate }
            />
          </div>
          <div className = "column">
            <Datepicker
              isReadonly  = { true }
              label       = "Finish"
              placeholder = "Select Date"
              value       = { endDate }
              message     = "Date finished or expected to finish"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaddlGamesDurationPanelForm;
