import './index.scss';
import React from 'react';
import config from 'config';

import Text                          from 'component-lib/type/text';
import { style14pxLightGreyL }       from 'component-lib/type/text/styles';
import { style16pxRegularBluegreyD } from 'component-lib/type/text/styles';

export const ProgressModalBox = ({ title, subtitle, imageClass, onClick }) => {
  return (
    <div
      className = "modal-progress-box"
      onClick={ onClick }
    >
      <div className = { `modal-progress-box__icon modal-progress-box__icon-${imageClass}` } />
      <Text { ...style16pxRegularBluegreyD } text = { title } />
      <Text { ...style14pxLightGreyL } text = { subtitle } />
    </div>
  );
}
