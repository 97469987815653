import { defined } from 'lib/helpers';
import React       from 'react';

import PanelFormHeadline   from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown   from 'components/v2/input-fields/input-text-dropdown';
import CourseDurationInput from 'components/v2/activities/categories/education/fields/course-duration/input';
import CommitmentInput     from 'components/v2/activities/categories/education/fields/commitment/input';
import AttendanceInput     from 'components/v2/activities/categories/education/fields/attendance/on-campus-and-online/input';

class EducationCommonDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForEducator: true,
      isValueAcceptedForCampus:   true,
      isValueAcceptedForCourse:   true,
    };

    this.isValueAcceptedForEducator = () => {
      return this.state.isValueAcceptedForEducator;
    };

    this.isValueAcceptedForCampus = () => {
      return this.state.isValueAcceptedForCampus;
    };

    this.isValueAcceptedForCourse = () => {
      return this.state.isValueAcceptedForCourse;
    };

    this.setValueAcceptedForEducator = (value) => {
      return this.setState({ isValueAcceptedForEducator: value });
    };

    this.setValueAcceptedForCampus = (value) => {
      return this.setState({ isValueAcceptedForCampus: value });
    };

    this.setValueAcceptedForCourse = (value) => {
      return this.setState({ isValueAcceptedForCourse: value });
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const renderEducatorInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'educator',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForEducator(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'educator',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'educator',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForEducator(true);
      };

      const onAcceptSuggestion = (educator) => {
        detailsData.setField({
          attrName: 'educator',
          data:     educator,
        });
        this.setValueAcceptedForEducator(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { true }
          label         = { detailsData.getFieldLabel('educator') }
          placeholder   = { detailsData.getFieldPlaceholder('educator') }
          value         = { detailsData.getField('educator').name }
          options       = { this.isValueAcceptedForEducator() ? null : detailsData.getSuggestions('educator') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCampusInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'campus',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCampus(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'campus',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'campus',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCampus(true);
      };

      const onAcceptSuggestion = (campus) => {
        detailsData.setField({
          attrName: 'campus',
          data:     campus,
        });
        this.setValueAcceptedForCampus(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { false }
          label         = { detailsData.getFieldLabel('campus') }
          placeholder   = { detailsData.getFieldPlaceholder('campus') }
          value         = { detailsData.getField('campus').name }
          options       = { this.isValueAcceptedForCampus() ? null : detailsData.getSuggestions('campus') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCourseInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'course',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCourse(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'course',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'course',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCourse(true);
      };

      const onAcceptSuggestion = (course) => {
        detailsData.setField({
          attrName: 'course',
          data:     course,
        });
        this.setValueAcceptedForCourse(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { false }
          label         = { detailsData.getFieldLabel('course') }
          placeholder   = { detailsData.getFieldPlaceholder('course') }
          value         = { detailsData.getField('course').name }
          options       = { this.isValueAcceptedForCourse() ? null : detailsData.getSuggestions('course') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCourseDurationInput = () => {
      return (
        <CourseDurationInput
          label       = { detailsData.getFieldLabel('courseDuration') }
          placeholder = { detailsData.getFieldPlaceholder('courseDuration') }
          value       = { detailsData.getField('courseDuration') }
          onChange    = { (courseDuration) => { detailsData.setField({ attrName: 'courseDuration', data: courseDuration }); } }
        />
      );
    };

    const renderCommitmentInput = () => {
      return (
        <CommitmentInput
          label       = { detailsData.getFieldLabel('commitment') }
          placeholder = { detailsData.getFieldPlaceholder('commitment') }
          value       = { detailsData.getField('commitment') }
          onChange    = { (commitment) => { detailsData.setField({ attrName: 'commitment', data: commitment }); } }
        />
      );
    };

    const renderAttendanceInput = () => {
      return (
        <AttendanceInput
          isOnlineChecked   = { detailsData.getField('attendanceOnline') }
          isOnCampusChecked = { detailsData.getField('attendanceOnCampus') }
          onOnlineChange    = { (value) => { detailsData.setField({ attrName: 'attendanceOnline', data: value }); } }
          onOnCampusChange  = { (value) => { detailsData.setField({ attrName: 'attendanceOnCampus', data: value }); } }
        />
      );
    };

    return (
      <div className = "v2-education-common-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Course Details'
          description = 'Search & Select your course details or add something new...'
        />

        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderEducatorInput() }</div>
          <div className = "column is-full">{ renderCampusInput() }</div>
          <div className = "column is-full">{ renderCourseInput() }</div>
          <div className = "column is-half">{ renderCourseDurationInput() }</div>
          <div className = "column is-half">{ renderCommitmentInput() }</div>
          <div className = "column is-full">{ renderAttendanceInput() }</div>
        </div>
      </div>
    );
  }
};

export default EducationCommonDetailsPanelForm;
