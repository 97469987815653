import './markdown.scss';

import React    from 'react';
import Markdown from 'react-markdown';

// Check the allowedTypes in these two resources
// https://github.com/rexxars/react-markdown#node-types
// https://github.com/rexxars/react-markdown/blob/master/CHANGELOG.md#breaking-1
const ALLOWED_TYPES = [
  'Text', 'Softbreak', 'Emph',
  'Paragraph', 'Strong',
  'List', 'Item', 'ThematicBreak', 'Link'
];

export default ({ children, value, placeholder, className }) => {
  const source = children || value || placeholder;

  if (!source || typeof source !== 'string') return false;

  // Check this resource for available options
  // https://github.com/rexxars/react-markdown#options
  return (
    <Markdown
      className    = { `markdown ${className ? className : ''}` }
      allowedTypes = { ALLOWED_TYPES }
      source       = { source }
      softBreak    = "br"
      escapeHtml
    />
  );
};
