import './index.scss';
import React from 'react';

import Text                             from 'component-lib/type/text';
import { style14pxLightBluegreyItalic } from 'component-lib/type/text/styles';

export const ProgressBarRangeInputSection = ({
  children,
  titleCopy,
  titleColor,
  hintMessageCopy,
}) => {
  return (
    <div className = "progress-bar-range-input-section">
      <Text
        text       = { titleCopy }
        fontSize   = '20px'
        fontWeight = 'regular'
        color      = { titleColor }
      />
      { children }
      <Text
        { ...style14pxLightBluegreyItalic }
        text = { hintMessageCopy }
      />
    </div>
  );
};
