// This issuerCode & profileId have to be in-sync with the back end data.
const ISSUER_DATA = {
  WSU: {
    // TODO: Uncomment PRODUCTION profile id and comment out DEV & STAGING profile id before PR merged into master
    // profileId: 16715, // WSU profile id on PRODUCTION
    profileId: 24, // WSU has no profile on DEV, STAGING1 & STAGING2, use 'Irene Lin & Co' instead.
    issuer: 'Western Sydney University',
    themeColorL: 'wsu-red-l',
    themeColor: 'wsu-red',
    themeColorD: 'wsu-red-d',
    bannerImage: require('components/svg-icon/svgs/wsu-banner-image.svg'),
    sealImage: require('components/svg-icon/svgs/wsu-seal.svg'),
    issuerLogo: require('components/svg-icon/svgs/wsu-logo.svg'),
    signatures: [
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'Vice Chancellor',
      },
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'Teacher',
      },
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'Professor',
      },
    ],
  },
  PADDL: {
    // TODO: Uncomment PRODUCTION profile id and comment out DEV & STAGING profile id before PR merged into master
    // profileId: 7439, // Paddl profile id on PRODUCTION
    profileId: 1, // Paddl profile id on DEV, STAGING1 & STAGING2
    issuer: 'Paddl Co.',
    themeColorL: 'blue-xl',
    themeColor: 'blue',
    themeColorD: 'blue-d',
    bannerImage: 'https://view.paddljobs.com/7439/flg1ancdxz_avatar_resized',
    sealImage: 'https://view.paddljobs.com/7439/flg1ancdxz_avatar_resized',
    issuerLogo: 'https://view.paddljobs.com/7439/flg1ancdxz_avatar_resized',
    signatures: [
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'CEO',
      },
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'VP Product',
      },
      {
        img: require('components/svg-icon/svgs/signature.svg'),
        title: 'VP Operations',
      },
    ],
  },
  /***************************************************************
   *
   *                     I M P O R T A N T
   *
   * For each set of the issuer data there should be a corresponding issuerCode
   * in verified activity table on the server.
   * E.g. `var_edu_branded_qualifications`, `var_edu_branded_course_units`, etc.
   *
   ***************************************************************/
};

const validate = (issuerCode) => {
  if (!ISSUER_DATA.hasOwnProperty(issuerCode)) {
    throw Error(`Unexpected issuerCode: ${issuerCode}`);
  }
};

export class IssuerData {
  static getFor(issuerCode) {
    validate(issuerCode);
    return ISSUER_DATA[issuerCode];
  }
}
