// XP / Amount
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=2993%3A15840&viewport=4053%2C-3482%2C4.5145

import './index.scss';
import React from 'react';

import Text from 'component-lib/type/text';

const XpAmount = ({ number, numberColor, unitColor, unitOpacity }) => {
  if (typeof number !== 'number') return null;

  return (
    <div className = "xp-amount">
      <Text
        text       = { number }
        fontSize   = "22px"
        fontWeight = "regular"
        color      = { numberColor }
      />
      <Text
        text       = "XP"
        fontSize   = "18px"
        fontWeight = "regular"
        color      = { unitColor }
        opacity    = { unitOpacity }
      />
    </div>
  );
};

export default XpAmount;
