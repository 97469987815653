import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalSidenavLogoutContent } from 'components/v2/modal-sidenav-logout-content';

export class ModalSidenavLogout extends React.Component {
  render() {
    const {
      appv2,
      withLeftOffset,
      avatarImage,
      fullName,
    } = this.props;

    const closeFunction = () => {
      appv2.ui.closeModal();
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { withLeftOffset }
        windowWidthSize = "small"
      >
        <ModalSidenavLogoutContent
          avatar = { avatarImage }
          name = { fullName }
          onClick = { appv2.auth.logout }
        />
      </Modal>
    );
  }
}
