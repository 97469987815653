import { defined } from 'lib/helpers';

export const STUDENT      = 1;
export const EMPLOYER     = 2;
export const UNKNOWN_ROLE = 3;

class Auth {
  constructor({ store, v1deps }) {
    this.currentProfileId = () => {
      return defined(store).getState().auth.profile_id;
    };

    this.isLoggedIn = () => {
      return !!this.currentProfileId();
    };

    this.currentProfileRole = () => {
      const role = defined(store).getState().auth.role;

      switch (role) {
        case 'student':  return STUDENT;
        case 'employer': return EMPLOYER;
        default:         return UNKNOWN_ROLE;
      }
    };

    this.isCurrentProfile = ({ profileId }) => {
      return (parseInt(defined(profileId)) === this.currentProfileId());
    };

    this.refreshCurrentProfileLegacy = () => {
      store.dispatch(v1deps['modules/profiles'].fetchProfile(this.currentProfileId()));
    };

    this.simulateLogin = ({ payload }) => {
      store.dispatch({
        type: v1deps['modules/auth'].LOGIN_SUCCESS,
        payload,
      });

      return store.dispatch(v1deps['modules/app'].initApp());
    };

    this.logout = () => {
      return store.dispatch(v1deps['modules/auth'].logout);
    };
  }
}

export default Auth;
