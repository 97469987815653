import Api          from 'lib/api';
import FeatureFlags from 'lib/feature-flags';
import Slugs        from 'lib/slugs';
import UI           from 'lib/ui';
import Auth         from 'lib/auth';
import Analytics    from 'lib/analytics';
import Campaigns    from 'lib/campaigns';

import {
  PREPOPULATE_APPLICATIONS,
  PREPOPULATE_CONVERSATIONS,
  PREPOPULATE_JOBS,
  PREPOPULATE_PROFILES,
  PREPOPULATE_VILLAGES,
  PREPOPULATE_EDUCATORS,
  PREPOPULATE_CAMPUSES,
  PREPOPULATE_COURSES,
} from 'modules/prepopulate';

class Appv2 {
  constructor({ store, v1deps }) {
    this.v1deps = v1deps;

    this.auth         = new Auth({ store, v1deps: this.v1deps });
    this.analytics    = new Analytics({ auth: this.auth });
    this.api          = new Api({ store, analytics: this.analytics });
    this.featureFlags = new FeatureFlags({ store, apiTransport: this.api.transport });
    this.slugs        = new Slugs({ store, api: this.api, auth: this.auth });
    this.ui           = new UI({ store, apiTransport: this.api.transport, v1deps: this.v1deps });

    this.prepopulateLegacyStore = () => {
      const makePrepopulatingRequests = (endpoint, actionType) => {
        return this.api.transport
          .makeRequest({ endpoint: endpoint, method: 'GET' })
          .then((data) => {
            store.dispatch({
              type:    actionType,
              payload: data,
            });
          });
      };

      return Promise.all([
        makePrepopulatingRequests('/stress/applications', PREPOPULATE_APPLICATIONS),
        makePrepopulatingRequests('/stress/campaigns', PREPOPULATE_JOBS),
        makePrepopulatingRequests('/stress/conversations', PREPOPULATE_CONVERSATIONS),
        makePrepopulatingRequests('/stress/profiles', PREPOPULATE_PROFILES),
        makePrepopulatingRequests('/stress/educators', PREPOPULATE_EDUCATORS),
        makePrepopulatingRequests('/stress/campuses', PREPOPULATE_CAMPUSES),
        makePrepopulatingRequests('/stress/courses', PREPOPULATE_COURSES),
        makePrepopulatingRequests('/stress/villages', PREPOPULATE_VILLAGES),
      ]);
    };

    this.campaigns = new Campaigns({ store, v1deps: this.v1deps });
  }
}

export default Appv2;
