// XP / Entry / Location & XP / Entry / Location Logo
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=3556%3A352&viewport=-13711%2C11155%2C5.0116963386535645

import './index.scss';
import React from 'react';

import ImgTag                       from 'component-lib/components/img-tag';
import Text                         from 'component-lib/type/text';
import { style16pxMediumBluegreyD } from 'component-lib/type/text/styles';
import { style14pxLightGreyL }      from 'component-lib/type/text/styles';

const XpEntryLocationLogo = ({ iconSrc, name, location }) => {
  if (!iconSrc && !name && !location) return null;

  return (
    <div className = "xp-entry-location-logo">
      <ImgTag
        src       = { iconSrc }
        width     = '40px'
        isRounded = { true }
      />
      <div className = "xp-entry-location-logo__text">
        <div className = "xp-entry-location-logo__name">
          <Text { ...style16pxMediumBluegreyD } text = { name } />
        </div>
        <Text { ...style14pxLightGreyL } text = { location } />
      </div>
    </div>
  );
};

export default XpEntryLocationLogo;
