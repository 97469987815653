import React                 from 'react';
import { defined }           from 'lib/helpers';
import Select                from 'components/v2/input-fields/select';
import { commitmentOptions } from '../options';

const CommitmentInput = ({
  label,
  placeholder,
  value,
  onChange,
  isAutoFocused
}) => {
  const isError = (value) => {
   return false;
  };
  const hasError = isError(value);
  const message = hasError ? 'error!' : '';

  const sanitizeOptions = (options) => {
    return options.map(
      ({ value, title }) => ({
        value: defined(value),
        title: defined(title),
      })
    );
  };

  return (
    <Select
      value         = { value }
      onChange      = { (value) => { onChange(defined(value)); } }
      options       = { sanitizeOptions(commitmentOptions) }
      name          = "commitment"
      label         = { label }
      message       = { message }
      isError       = { hasError }
      placeholder   = { placeholder }
      isAutoFocused = { isAutoFocused }
    />
  );
};

export default CommitmentInput;
