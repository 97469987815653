import './index.scss';
import React from 'react';

import Text                      from 'component-lib/type/text';
import { style22pxRegularWhite } from 'component-lib/type/text/styles';

const generateElementId = (eventId) => {
  return `activityUpdateEvent${ eventId }`;
};

export class ActivityEvents extends React.Component {
  componentDidMount() {
    this.props.events.map((eventId, index) => {
      window.setTimeout(() => {
        const element = document.getElementById(generateElementId(eventId));
        element.classList.add('v2-activity-events__event--shown');
      }, 1000 * index);
    });
  }

  render() {
    const eventTexts = {
      'ADDED_TO_PROFILE':      'Activity added to your Profile',
      'REMOVED_FROM_PROFILE':  'Activity removed from Profile',
      'COMPLETE':              'Activity Complete!',
      'INCOMPLETE_ENDED':      'Incomplete Activity Ended',
      'UPDATED':               'Activity Card Updated',
      'REMOVED_FROM_BASECAMP': 'Card removed from Basecamp',
      'ADDED_TO_BASECAMP':     'Active Card added to Basecamp',
    };

    const renderOneEvent = (eventId) => {
      const text = eventTexts[eventId];

      if (text === undefined) {
        throw new Error('Unknown eventId');
      }

      return (
        <div key = { eventId } id = { generateElementId(eventId) } className = "v2-activity-events__event">
          <img src = { require('./icon.svg') /* TODO: this icon will be different for different events */ } />
          <Text { ...style22pxRegularWhite } text = { text } />
        </div>
      );
    };

    return (
      <div className = "v2-activity-events">
        { this.props.events.map(renderOneEvent) }
      </div>
    );
  }
}
