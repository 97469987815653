import { defined } from 'lib/helpers';
import Text        from 'lib/api/activities/generics/fields/text';

class Data {
  constructor({ state, appv2 }) {
    this._ourState       = defined(state);
    this._appv2          = defined(appv2);
    this._persistedState = {};

    this.initializeState = ({ profile, canEdit }) => {
      const afterDataSet = () => {
        this.setAsPersistedState();
      };

      const summary = profile.get('summary');

      this._ourState.dataSet({
        profileId:     profile.get('id'),
        summary:       summary ? new Text(summary) : Text.createEmpty(),
        isDataChanged: false,

        // ui related, to be refactored
        isSummaryFormOpen: false,
        canEdit:           canEdit,
      }, afterDataSet);
    };

    this.setAsPersistedState = () => {
      this._persistedState = this._ourState.dataGetAll();
    };

    this.resetToPersistedState = () => {
      this._ourState.dataSet(this._persistedState);
    };

    this.getIsDataChanged = () => {
      return this._ourState.dataGet({ id: 'isDataChanged' });
    };

    this.setIsDataChanged = (value) => {
      this._ourState.dataSet({
        isDataChanged: defined(value)
      });
    };

    this.getProfileId = () => {
      return this._ourState.dataGet({ id: 'profileId' });
    };

    this.getSummary = () => {
      return this._ourState.dataGet({ id: 'summary' }).value;
    };

    this.setSummary = (value) => {
      this._ourState.dataSet({
        summary:       new Text(value),
        isDataChanged: true,
      });
    };

    this.isSummaryDataPresent = () => {
      return !this._ourState.dataGet({ id: 'summary' }).isEmpty();
    };

    this.updateSummaryViaApi = () => {
      const profileId = this.getProfileId();
      const summary = new Text(this.getSummary());

      return this._appv2.api.profiles
        .updateSummary({ id: profileId, summary: summary })
        .then(() => {
          this._appv2.ui.displaySnackBar({ style: 'green', text: 'Profile saved!' });
        });
    };

    // ui related, to be refactored
    this.isSummaryFormOpen = () => {
      return this._ourState.dataGet({ id: 'isSummaryFormOpen' });
    };

    this.openSummaryForm = () => {
      this._ourState.dataSet({
        isSummaryFormOpen: true
      });
    };

    this.closeSummaryForm = () => {
      this._ourState.dataSet({
        isSummaryFormOpen: false
      });
    };

    this.canEdit = () => {
      return this._ourState.dataGet({ id: 'canEdit' });
    };

    this.refreshCurrentProfileLegacy = () => {
      this._appv2.auth.refreshCurrentProfileLegacy();
    };
  }
}

export default Data;
