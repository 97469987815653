import './index.scss';
import React from 'react';

class InputTextSingleLine extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.singlelineInput.focus();
      }, 0);
    }
  }

  render() {
    const {
      name,
      label,
      copyAfterField,
      copyWidthRatio,
      placeholder,
      value,
      inputFontSize,
      message,
      isError,
      hasHint,
      maxLength,
      onChange,
      isReadonly,
      isAutoFocused,
    } = this.props;

    const renderMessage = ({ message, isError, hasHint }) => {
      if (message === null) {
        return null;
      }

      const determineClassName = (isError, hasHint) => {
        if (isError) {
          return 'v2-input-text-single-line__message v2-input-text-single-line__message--error';
        }

        if (hasHint) {
          return 'v2-input-text-single-line__message v2-input-text-single-line__message--hint';
        }

        return 'v2-input-text-single-line__message';
      }

      return(
        <div className = { determineClassName(isError, hasHint) }>
          { message }
        </div>
      );
    };

    const calcCopyWidthClassName = () => {
      const copyColumns = Math.round(copyWidthRatio * 12);

      return `is-${ copyColumns }`;
    };

    const blockClassName         = 'v2-input-text-single-line__input';
    const isErrorClassName       = isError                   ? `${blockClassName}--error`           : '';
    const inputFontSizeClassName = inputFontSize === "small" ? `${blockClassName}--small-font-size` : `${blockClassName}--default-font-size`;
    const className              = `${blockClassName} ${isErrorClassName} ${inputFontSizeClassName}`;

    return(
      <div className = "v2-input-text-single-line">
        <label className = "v2-input-text-single-line__label" htmlFor = { name }>
          { label }
        </label>
        <div className = "columns is-mobile v2-input-text-single-line__input-wrapper">
          <div className = "column">
            <input
              ref         = { (realInputElementOnPage) => { this.singlelineInput =  realInputElementOnPage; } }
              type        = "text"
              className   = { className }
              maxLength   = { maxLength }
              name        = { name }
              placeholder = { placeholder }
              value       = { value }
              onChange    = { (event) => { onChange(event.target.value) } }
              disabled    = { isReadonly ? "disabled" : false }
            />
          </div>
          {
            copyAfterField &&
            <div className = { `column ${ calcCopyWidthClassName() } v2-input-text-single-line__input-copy` }>
              { copyAfterField }
            </div>
          }
        </div>
        { renderMessage({ message, isError, hasHint }) }
      </div>
    );
  }
};

export default InputTextSingleLine;
