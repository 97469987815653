import './index.scss';
import React from 'react';
import Cookies from 'cookies-js';

import { RegistrationButton } from 'components/v2/buttons/square-buttons/registration-button';

export class EmailInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  render() {
    const onSubmit = () => {
      const email = document.getElementById('email-field').value;
      const appv2 = window._appv2;
      const emailNotValid = (email.match(/^.+@.+$/) === null);

      if (emailNotValid) {
        this.setState({
          errorMessage: 'Please input a valid email'
        });
        return null;
      }

      appv2.api.profiles
        .registerEmail({ email })
        .then((success) => {
          if (success) {
            Cookies.set('v2-registered-email', email, { expires: 365 * 24 * 3600, path: '/' });
            return appv2.ui.redirect({ url: '/register/feedback' });
          }

          this.setState({
            errorMessage: 'Cannot register with this email address',
          });
        });
    };

    return (
      <div className = "email-input">
        <form className = "email-input__input-form" onSubmit = { onSubmit } >
          <input id = "email-field" type = "email" className = "email-input__input-field" name = "email" placeholder = "your@email.com" />
          <div className = "email-input__input-button">
            <RegistrationButton
              onClick = { onSubmit }
              customCopy = "Let's Go"
            />
          </div>
        </form>

        { this.state.errorMessage &&
          <div className = "email-input__error-message">
            { this.state.errorMessage }
          </div>
        }
      </div>
    );
  }
}
