import './index.scss';
import React from 'react';

const LinkWrapper = ({ children, link, target, isFullWidth }) => {
  if (!link) return children;

  const widthModifier = isFullWidth ? 'is-full-width' : 'is-fit-content-width';

  return (
    <a
      className = { `link-wrapper link-wrapper--${widthModifier}` }
      href      = { link }
      target    = { target }
    >
      { children }
    </a>
  );
};

export default LinkWrapper;
