import React    from 'react';
import { Data } from './data';

import VariantMapping                        from 'lib/api/variant-mapping';
import { findCategoryIdBy, getCategoryData } from 'lib/api/categories';

import { Swiper }                              from 'components/v2/swiper';
import { ActivityCardSuggestionListItemSmall } from 'component-lib/components/activity-card-suggestion-list-item-small';

export class ActivityCardSuggestionListNew extends React.Component {
  constructor(props) {
    super(props);
    this._data = new Data({ component: this });
  }

  UNSAFE_componentWillMount() {
    this._data.initializeState();
  }

  render() {
    const { appv2 } = this.props;
    const variantsToDisplay = this._data.getVariantIds();

    const renderListItem = (variantId) => {
      const variantHandler = VariantMapping.getFor({ variantId: variantId });
      const categoryData   = getCategoryData({ categoryId: findCategoryIdBy({ variantId }) });

      const onClick = () => {
        appv2.ui.openNewActivityModal({ withLeftOffset: false, variantId });
      };

      const onHideClick = (e) => {
        this._data.hideVariantId({variantId});
        e.stopPropagation();
      };


      return (
        <ActivityCardSuggestionListItemSmall
          key                  = { variantId }
          onClick              = { () => { onClick(); } }
          variantIcon          = { variantHandler.icon() }
          variantTitle         = { variantHandler.title() }
          variantSubtitle      = { variantHandler.subtitle() }
          categoryTitle        = { categoryData.title() }
          categoryTitleColor   = { categoryData.themeColor() }
          footerBorderTopColor = { categoryData.themeColorL() }
          onHide               = { (e) => { onHideClick(e); } }
        />
      );
    };

    return (
      <Swiper>
        {
          variantsToDisplay.map((variantId) => {
            return renderListItem(variantId);
          })
        }
      </Swiper>
    );
  }
}
