import './index.scss';
import React from 'react';
import { validUrlRegex } from 'lib/helpers';

class InputTextSingleLineForUrl extends React.Component {
  constructor(props) {
    super(props);

    this._timerId = null;

    this.state = {
      fetchingStatus: null,
    }
  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.singlelineInput.focus();
      }, 0);
    }
  }

  render() {
    const {
      name,
      label,
      placeholder,
      value,
      setValue,
      fetchSourceDataFromApi,
      autoFillFields,
      inputFontSize,
      isAutoFocused,
      maxLength,
      isReadonly,
    } = this.props;

    const { fetchingStatus } = this.state;

    const isError = () => {
      return value && !validUrlRegex.test(value);
    };

    const onChange = (value) => {
      this.setState({ fetchingStatus: null });
      setValue(value);

      if (!validUrlRegex.test(value)) {
        return null;
      }

      if (this._timerId !== null) {
        clearTimeout(this._timerId);
        this._timerId = null;
      }

      this._timerId = setTimeout(() => {
        this.setState({ fetchingStatus: 'fetching' });

        fetchSourceDataFromApi({
          value: value,
          callbackOnSuccess: () => {
            autoFillFields();
            this.setState({ fetchingStatus: 'success' });
          },
          callbackOnFailure: () => {
            this.setState({ fetchingStatus: 'failure' });
          },
        })

        this._timerId = null;
      }, 2000);
    };

    const getInputClassName = () => {
      const inputClassName = 'v2-input-text-single-line-for-url__input';

      const isErrorClassName       = isError()                 ? `${inputClassName}--error`           : '';
      const inputFontSizeClassName = inputFontSize === "small" ? `${inputClassName}--small-font-size` : `${inputClassName}--default-font-size`;

      return `${inputClassName} ${isErrorClassName} ${inputFontSizeClassName}`;
    };

    const getIconClassName = () => {
      const iconClassName = 'v2-input-text-single-line-for-url__icon';

      if (fetchingStatus === null)                                 return `${iconClassName} ${iconClassName}--default`;
      if (fetchingStatus === 'fetching' || 'success' || 'failure') return `${iconClassName} ${iconClassName}--${fetchingStatus}`;
      throw Error('Unexpected fetchingStatus');
    };

    const getMessageClassName = () => {
      const messageClassName = 'v2-input-text-single-line-for-url__message';

      if (isError())                     return `${messageClassName} ${messageClassName}--error`;
      if (fetchingStatus === 'failure')  return `${messageClassName} ${messageClassName}--warning`;
      if (fetchingStatus === 'fetching') return `${messageClassName} ${messageClassName}--hint`;
      return messageClassName;
    };

    const getMessage = () => {
      if (isError())                     return 'Please input a valid url';
      if (fetchingStatus === 'failure')  return 'Could not fetch data';
      if (fetchingStatus === 'fetching') return 'Fetching data from page';
      return null;
    };

    return(
      <div className = "v2-input-text-single-line-for-url">
        <label className = "v2-input-text-single-line-for-url__label" htmlFor = { name }>
          { label }
        </label>
        <div className = "is-mobile v2-input-text-single-line-for-url__input-wrapper">
          <input
            ref         = { (realInputElementOnPage) => { this.singlelineInput = realInputElementOnPage; } }
            type        = "text"
            className   = { getInputClassName() }
            maxLength   = { maxLength }
            name        = { name }
            placeholder = { placeholder }
            value       = { value }
            onChange    = { (event) => { onChange(event.target.value) } }
            disabled    = { isReadonly ? "disabled" : false }
          />
          <div className = { getIconClassName() }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className = { getMessageClassName() }>
          { getMessage() }
        </div>
      </div>
    );
  }
};

export default InputTextSingleLineForUrl;
