import './index.scss';
import React from 'react';

const TagReadonly = ({
  label
}) => {
  return (
    <div className = "v2-tag-readonly">
      <div className = "v2-tag-readonly__container">
        <span className = "v2-tag-readonly__label">
          { label }
        </span>
      </div>
    </div>
  );
};

export default TagReadonly;
