import './index.scss';
import React from 'react';

import Text   from 'component-lib/type/text';
import ImgTag from 'component-lib/components/img-tag';

import {
  style20pxRegularBluegreyD,
  style16pxMediumBluegrey,
  style16pxMediumBluegreyD,
  style16pxLightBluegreyD,
  style16pxLightBluegrey,
} from 'component-lib/type/text/styles';

export const CourseUnitDetailsContent = ({ courseName, faculty, description, intake, verificationDate, courseCode }) => {
  return (
    <div className = "v2-course-unit-details-content">
      <div className = "v2-course-unit-details-content__course-info">
        <Text { ...style20pxRegularBluegreyD } text = { courseName } />
        <Text { ...style16pxMediumBluegrey } text = { faculty } />
      </div>
      <div className = "v2-course-unit-details-content__course-decsription">
        <Text { ...style16pxMediumBluegreyD } text = "Description" />
        <Text { ...style16pxLightBluegreyD } text = { description } />
      </div>
      <div className = "v2-course-unit-details-content__intake">
        <Text { ...style16pxLightBluegrey } text = "Intake:" />
        <Text { ...style16pxMediumBluegrey } text = { intake } />
      </div>
      <div className = "v2-course-unit-details-content__verified-panel">
        <div className = "v2-course-unit-details-content__verification">
          <ImgTag width = '24px' src = { require('../../svg-icon/svgs/verified.svg') } />
          <Text { ...style16pxMediumBluegreyD } text = "Verified:" />
          <Text { ...style16pxLightBluegreyD } text = { verificationDate } />
        </div>
        <div className = "v2-course-unit-details-content__course-code">
          <Text { ...style16pxLightBluegrey } text = "Course Code:" />
          <Text { ...style16pxMediumBluegrey } text = { courseCode } />
        </div>
      </div>
    </div>
  );
};
