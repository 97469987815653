import './index.scss';

import React from 'react';

import { getPath } from './helper.js';

const COLORS = {
  secondary: '#B1DBF5',
  primary: '#37B1FF'
};

export const InProgressCircle = ({
  percentageComplete,
}) => {
  const stroke   = 1.3;
  const svg_size = 24;
  const radius   = (svg_size - stroke) / 2;

  return (
    <svg
      viewBox   = { `0 0 ${svg_size} ${svg_size}` }
      className = "activities-progress-circle"
    >
      <circle
        className   = "activities-progress-circle__circle"
        cx          = { svg_size / 2 }
        cy          = { svg_size / 2 }
        r           = { radius }
        fill        = "none"
        stroke      = { COLORS.secondary }
        strokeWidth = { stroke }
      />
      <path
        className   = "activities-progress-circle__path"
        d           = { getPath({ svg_size, percentageComplete, radius }) }
        fill        = "none"
        stroke      = { COLORS.primary }
        strokeWidth = { stroke }
      />
    </svg>
  );
};
