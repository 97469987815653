// XP / Entry / Details Title
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=4079%3A27064&viewport=-9397%2C7831%2C3.526329278945923

import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import ImgTag                        from 'component-lib/components/img-tag';
import { style14pxLightGreyL }       from 'component-lib/type/text/styles';

const XPEntryDetailsTitle = ({ title, subtitle, subtitleIconSrc }) => {
  return (
    <div className = "xp-entry-details-title">
      <div className = "xp-entry-details-title__title">
        <Text { ...style20pxRegularBluegreyD } lineHeight = '1.2' text = { title } />
      </div>
      <div className = "xp-entry-details-title__subtitle">
        <ImgTag
          src   = { subtitleIconSrc }
          width = '12px'
        />
        <Text { ...style14pxLightGreyL } text = { subtitle } />
      </div>
    </div>
  );
};

export default XPEntryDetailsTitle;
