import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import IdNameObject      from 'lib/api/activities/generics/fields/id-name-object';
import DurationValueUnit from 'lib/api/activities/generics/duration-value-unit';
import Commitment        from 'lib/api/activities/categories/education/fields/commitment';
import Bool              from 'lib/api/activities/generics/fields/bool';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/education/variants/course/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/education/variants/course/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/education/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form';

class Course {
  static categoryCssClassPrefix() {
    return 'v2-categories-education-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-education-course.svg');
  }

  static title() {
    return 'Course';
  }

  static subtitle() {
    return 'Online, offline, short or long.';
  }

  static detailsAttributes() {
    const fetchTrainersSuggestions      = ({ appv2 }) => { return appv2.api.education.fetchTrainersSuggestions.bind(appv2.api.education);      };
    const fetchCourseCoursesSuggestions = ({ appv2 }) => { return appv2.api.education.fetchCourseCoursesSuggestions.bind(appv2.api.education); };

    return {
      trainer:            { required: true,  dataType: IdNameObject,      fieldLabel: 'Trainer',         fieldPlaceholder: 'e.g. Code Academy',    suggestionsFunction: fetchTrainersSuggestions      },
      course:             { required: true,  dataType: IdNameObject,      fieldLabel: 'Course Name',     fieldPlaceholder: 'e.g. Web Development', suggestionsFunction: fetchCourseCoursesSuggestions },
      courseDuration:     { required: true,  dataType: DurationValueUnit, fieldLabel: 'Course Duration', fieldPlaceholder: { value: '00', unit: 'Select' } },
      commitment:         { required: true,  dataType: Commitment,        fieldLabel: 'Commitment',      fieldPlaceholder: 'Select Type'                   },
      attendanceOnline:   { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
      attendanceInPerson: { required: false, dataType: Bool,              multiSelectGroup: 'attendance' },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: details.get('course').name,
      activityProviderName: details.get('trainer').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({
      attributes: this.detailsAttributes(),
      getDurationObjectFn: (detailsData) => {
        return detailsData.getField('courseDuration');
      },
    });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default Course;
