import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import SecondaryDetailReadonly       from 'components/v2/activities/generics/fields/secondary-detail/readonly';
import XpTags                        from 'component-lib/components/xp-tags';

import { getDurationValueUnit }           from 'components/v2/activities/generics/duration-value-unit/helper';
import { getCommitment }                  from 'components/v2/activities/categories/education/fields/commitment/helper';
import { getAttendanceInPersonAndOnline } from 'components/v2/activities/categories/education/fields/attendance/in-person-and-online/helper';

const CourseDetailsPanelView = ({ data }) => {
  const { detailsData } = data;

  const courseDuration     = detailsData.getField('courseDuration');
  const commitment         = detailsData.getField('commitment');
  const attendanceInPerson = detailsData.getField('attendanceInPerson');
  const attendanceOnline   = detailsData.getField('attendanceOnline');

  return (
    <div className = "v2-course-details-panel-view">
      <div className = "v2-course-details-panel-view__subpanel">
        <Text { ...style20pxRegularBluegreyD } text = { detailsData.getField('course').name } />
        <SecondaryDetailReadonly detail = { detailsData.getField('trainer').name } />
      </div>

      <div className = "v2-course-details-panel-view__subpanel">
        <XpTags
          strings = { [
            getDurationValueUnit({ duration: courseDuration }),
            getCommitment({ commitment }),
            getAttendanceInPersonAndOnline({ attendanceInPerson, attendanceOnline }),
          ] }
        />
      </div>
    </div>
  );
};

export default CourseDetailsPanelView;
