const Moment = {
  months: () => (
    [
      'January', 'February', 'March',
      'April', 'May', 'June',
      'July', 'August', 'September',
      'October', 'November', 'December',
    ]
  ),

  monthsShort: () => (
    [
      'Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec',
    ]
  ),

  format_YYYY: (date) => (
    date.getFullYear()
  ),

  format_MMMM: (date) => (
    Moment.months()[date.getMonth()]
  ),

  format_MMM: (date) => (
    Moment.monthsShort()[date.getMonth()]
  ),

  format_D: (date) => (
    date.getDate()
  ),

  format_MMMM_YYYY: (date) => (
    `${ Moment.format_MMMM(date) } ${ Moment.format_YYYY(date) }`
  ),

  format_MMM_YYYY: (date) => (
    `${ Moment.format_MMM(date) } ${ Moment.format_YYYY(date) }`
  ),

  format_D_MMMM_YYYY: (date) => (
    `${ Moment.format_D(date) } ${ Moment.format_MMMM(date) } ${ Moment.format_YYYY(date) }`
  ),

  format_L: (date) => (
    date.toISOString().replace(/^(\d+)-(\d+)-(\d+)T.+$/, '$3/$2/$1')
  ),
};

export default Moment;
