import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style22pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style20pxRegularBluegrey }  from 'component-lib/type/text/styles';
import { UploadImageButton }         from 'component-lib/components/upload-image-button';
import { ImagePreviewSection }       from 'component-lib/components/image-preview-section';
import { ArtworkSection }            from 'component-lib/components/artworks-section';
import { FormFooter }                from 'components/v2/form-footer';

export class ModalHeroBannerImageContent extends React.Component {
  render() {
    const {
      appv2,
      artworks,
      onSelect,
      onRemove,
      onCancel,
      onSubmit,
      afterImageUploaded,
      selectedBanner,
      isSubmitDisabled,
    } = this.props;

    const title = 'Update your Profile Banner';
    const subtitle = '... or choose from Paddl artwork:';
    const selectedId = selectedBanner && selectedBanner.id;

    const onImageUpload = (base64data) => {
      appv2.api.uploading.uploadToS3({ data: base64data, subKey: 'v2banner' })
        .then(afterImageUploaded);
    };

    return (
      <div className = "modal-hero-banner-image-content">
        <Text { ...style22pxRegularBluegreyD } text = { title } />
        {
          selectedBanner && selectedBanner.isUploadedByUser
            ? <ImagePreviewSection url = { selectedBanner.url } onRemove = { onRemove } />
            : <UploadImageButton onImageUpload = { onImageUpload } />
        }
        <Text { ...style20pxRegularBluegrey } text = { subtitle } />
        <ArtworkSection
          artworks = { artworks }
          selectedId = { selectedId }
          onSelect = { onSelect }
        />
        <FormFooter
          customSubmitCopy = 'Save & Close'
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          isSubmitDisabled = { isSubmitDisabled }
        />
      </div>
    );
  }
}
