import './index.scss';
import React from 'react';

import SvgIconShare from 'components/v2/svg-icons/svg-icon-share';

const ShareProfileButton = ({ onClick }) => {
  return (
    <div className = "share-profile-button" onClick = { onClick } >
      <SvgIconShare />
    </div>
  )
};

export default ShareProfileButton;
