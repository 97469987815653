import { defined }     from 'lib/helpers';
import * as slugModule from 'modules/v2/slugs';

class Slugs {
  constructor({ store, api, auth }) {
    this._store = defined(store);
    this._api   = defined(api);
    this._auth  = defined(auth);

    this._getFromStoreById = (id) => {
      return this._store.getState().v2.slugs.byId[id]; // can be null or undefined
    };

    this._getFromStoreBySlug = (slug) => {
      return this._store.getState().v2.slugs.bySlug[slug]; // can be null or undefined
    };

    this._updateInStore = (id, slug) => {
      const action = slugModule.actionCreators.setMapping({
        id:   parseInt(id),
        slug: slug,
      });

      this._store.dispatch(action);
    };
  }

  generateProfilePath({ id, slug, callback }) {
    if (defined(id) === null) {
      return ''; // edge-case of the first-time renders, before ComponentDidMount
    }

    if (slug !== undefined) {
      this._updateInStore(id, slug);
      return Slugs.createPath(id, slug);
    }

    const cachedSlug = this._getFromStoreById(defined(id));

    if (cachedSlug !== undefined) {
      return Slugs.createPath(id, cachedSlug);
    }

    const isLoggedIn = this._auth.isLoggedIn();

    const getProfileFromApi = (onResult) => {
      this._api.profiles
        .fetch({ id, isLoggedIn })
        .then((data) => {
          onResult(isLoggedIn ? data : data.profile);
        });
    };

    getProfileFromApi((profile) => {
      const slug = profile.get('urlSlug');

      this._updateInStore(id, slug);

      // NOTE: callback is optional
      callback && callback({
        path: Slugs.createPath(id, slug),
      });
    });

    return Slugs.createPath(id, null); // assuming the default value for `slug` is null
  }
}

Slugs.REGEX = /^\/profile\/([^\/]+)/;

Slugs.extractSlug = (path) => {
  const matches = path.match(Slugs.REGEX);
  return matches ? decodeURIComponent(matches[1]) : null;
};

Slugs.createPath = (id, slug) => {
  if (slug === null) {
    return `/profile/${ encodeURIComponent(id) }`;
  }

  return `/profile/${ encodeURIComponent(slug) }`;
};

export default Slugs;
