import './index.scss';
import React from 'react';

import { fetchStringOfYMWD } from 'lib/helpers';
import Moment                from 'lib/helpers/moment';
import PercentageComplete    from 'lib/api/activities/generics/duration-common/percentage-complete';

import { isStartDateBeforeEndDate, isEndDateInThePast } from 'lib/api/activities/generics/duration-common';

import DurationPanelView  from 'components/v2/activities/generics/duration-panel/view';
import DurationPanelEmpty from 'components/v2/activities/generics/duration-panel/empty';
import { FormFooter }     from 'components/v2/form-footer';

const renderFormView = ({ uiBehaviour, data, formComponent }) => {
  const setDurationOnDateChange = ({
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    isCurrent,
    percentageComplete,
    isPercentageCompleteSetManually,
  }) => {
    const isProgressBarEditableAndWasSetManually =
      isStartDateBeforeEndDate({ startYear, startMonth, startDay, endYear, endMonth, endDay })
      && isEndDateInThePast({ endYear, endMonth, endDay })
      && isPercentageCompleteSetManually;

    const calculatedPercentageComplete = PercentageComplete.calculate({ startDay, startMonth, startYear, endDay, endMonth, endYear }).value;

    data.durationData.setDuration({
      startDay:                        startDay,
      startMonth:                      startMonth,
      startYear:                       startYear,
      endDay:                          endDay,
      endMonth:                        endMonth,
      endYear:                         endYear,
      isCurrent:                       isCurrent,
      percentageComplete:              isProgressBarEditableAndWasSetManually ? percentageComplete : calculatedPercentageComplete,
      isPercentageCompleteSetManually: isProgressBarEditableAndWasSetManually ? true : false,
    });
  };

  const onStartDateChange = (startDate) => {
    const startDay   = startDate.getDate();
    const startMonth = startDate.getMonth() + 1;
    const startYear  = startDate.getFullYear();

    const {
      endDay,
      endMonth,
      endYear,
      isCurrent,
      percentageComplete,
      isPercentageCompleteSetManually,
    } = data.durationData.getDuration();

    setDurationOnDateChange({
      startDay,
      startMonth,
      startYear,
      endDay,
      endMonth,
      endYear,
      isCurrent,
      percentageComplete,
      isPercentageCompleteSetManually,
    });
  };

  const onEndDateChange = (endDate) => {
    const endDay   = endDate.getDate();
    const endMonth = endDate.getMonth() + 1;
    const endYear  = endDate.getFullYear();

    const {
      startDay,
      startMonth,
      startYear,
      isCurrent,
      percentageComplete,
      isPercentageCompleteSetManually,
    } = data.durationData.getDuration();

    setDurationOnDateChange({
      startDay,
      startMonth,
      startYear,
      endDay,
      endMonth,
      endYear,
      isCurrent,
      percentageComplete,
      isPercentageCompleteSetManually,
    });
  };

  const onCurrentChange = (value) => {
    const duration = data.durationData.getDuration();

    data.durationData.setDuration({
      startDay:   duration.startDay,
      startMonth: duration.startMonth,
      startYear:  duration.startYear,
      endDay:     duration.endDay,
      endMonth:   duration.endMonth,
      endYear:    duration.endYear,
      isCurrent:  value,
    });
  };

  const onPercentageCompleteChange = (value) => {
    const duration = data.durationData.getDuration();

    data.durationData.setDuration({
      startDay:                        duration.startDay,
      startMonth:                      duration.startMonth,
      startYear:                       duration.startYear,
      endDay:                          duration.endDay,
      endMonth:                        duration.endMonth,
      endYear:                         duration.endYear,
      isCurrent:                       duration.isCurrent,
      percentageComplete:              value,
      isPercentageCompleteSetManually: true,
    });
  };

  const onCancel = () => {
    data.backToSavePoint();
    uiBehaviour.durationPanelCancel();
  };

  const onSubmit = () => {
    data.markAsSavePoint();
    uiBehaviour.durationPanelContinue();
  };

  const Component = formComponent;

  const {
    startYear,
    startMonth,
    startDay,
    endYear,
    endMonth,
    endDay,
    percentageComplete,
    isCurrent,
  } = data.durationData.getDuration();

  const isDurationDataValid = data.durationData.isValid();

  const startDate = (startYear && startMonth && startDay) ? new Date(startYear, startMonth - 1, startDay) : null;
  const endDate   = (endYear   && endMonth   && endDay)   ? new Date(endYear,   endMonth - 1,   endDay)   : null;

  return (
    <div className = "v2-duration-section__form-view">
      <div className = "v2-duration-section__container">
        <Component
          startDate                  = { startDate }
          endDate                    = { endDate }
          isDurationDataValid        = { isDurationDataValid }
          percentageComplete         = { percentageComplete }
          isCurrent                  = { isCurrent }   // not every Duration class has "isCurrent"
          onStartDateChange          = { onStartDateChange }
          onEndDateChange            = { onEndDateChange }
          onCurrentChange            = { onCurrentChange }
          onPercentageCompleteChange = { onPercentageCompleteChange }
          data                       = { data }
        />

        <FormFooter
          customSubmitCopy = 'Continue'
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          isSubmitDisabled = { !data.isDataChanged() || !data.durationData.isValid() }
        />
      </div>
    </div>
  );
};

const onOpenSection = ({ uiBehaviour, data }) => {
  data.backToSavePoint();
  uiBehaviour.clickDurationSection();
};

const renderSavedView = ({ uiBehaviour, data }) => {
  const {
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    percentageComplete,
    isPercentageCompleteSetManually,
    isCurrent,
  } = data.durationData.getDuration();

  const startDate = (startYear && startMonth && startDay) ? new Date(startYear, startMonth - 1, startDay) : null;
  const endDate   = (endYear   && endMonth   && endDay)   ? new Date(endYear,   endMonth - 1,   endDay)   : null;

  const getProps = () => {
    if (typeof percentageComplete === 'number') {
      if (isPercentageCompleteSetManually) {
        // duration panel for incomplete timebox
        return {
          progressState: 'incomplete',
          title:         'Incomplete',
        };
      }

      if (percentageComplete === 100) {
        // duration panel for complete timebox
        return {
          progressState: 'complete',
          title:         'Completed',
        };
      }

      // duration panel for in-progress timebox
      return {
        progressState: 'in-progress',
        title:         'In Progress',
      };
    }

    if (isCurrent && isCurrent === true) {
      // duration panel for in-progress Indefinite
      return {
        progressState: 'in-progress',
        title:         fetchStringOfYMWD({ startDate, endDate: new Date() }),
      };
    }

    if (isCurrent === null || isCurrent === false) {
      // duration panel for complete Indefinite
      return {
        progressState: 'complete',
        title:         fetchStringOfYMWD({ startDate, endDate }),
      };
    }

    throw Error('Should not happen, nothing being rendered in duration panel');
  };

  const renderDurationPanel = () => {
    const dateCopy = (date) => {
      if (date) { return Moment.format_MMM_YYYY(date); }

      throw Error(`Unexpected date: ${date}`);
    };

    return (
      <DurationPanelView
        { ...getProps() }
        percentageComplete = { percentageComplete }
        subtitle           = { `${dateCopy(startDate)} - ${endDate ? dateCopy(endDate) : 'Current'}` }
      />
    );
  };

  if (data.canEdit()) {
    return (
      <div className = "v2-duration-section__saved-view v2-duration-section__saved-view--can-edit" onClick = { () => { onOpenSection({ uiBehaviour, data }); } }>
        <div className = "v2-duration-section__container">
          { renderDurationPanel() }
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-duration-section__saved-view">
      <div className = "v2-duration-section__container">
        { renderDurationPanel() }
      </div>
    </div>
  );
};

const renderEmptyView = ({ uiBehaviour, data }) => {
  if (data.canEdit()) {
    return (
      <div className = "v2-duration-section__empty-view v2-duration-section__empty-view--can-edit" onClick = { () => { onOpenSection({ uiBehaviour, data }); } }>
        <div className = "v2-duration-section__container">
          <DurationPanelEmpty />
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-duration-section__empty-view">
      <div className = "v2-duration-section__container">
        <DurationPanelEmpty />
      </div>
    </div>
  );
};

const renderPanel = ({ uiBehaviour, data, formComponent }) => {
  if (data.canEdit() && uiBehaviour.isDurationPanelOpen()) {
    return renderFormView({ uiBehaviour, data, formComponent });
  }

  if (data.durationData.isEverythingEmpty()) {
    return renderEmptyView({ uiBehaviour, data });
  }

  if (data.durationData.isValid()) {
    return renderSavedView({ uiBehaviour, data });
  }

  throw Error('Should not happen, invalid Duration data');
};

const DurationSection = ({ uiBehaviour, data, formComponent }) => {
  return (
    <div className = "v2-duration-section">
      { renderPanel({ uiBehaviour, data, formComponent }) }
    </div>
  );
};

export default DurationSection;
