import React from 'react';
import { defined } from 'lib/helpers';

import PanelFormHeadline         from 'components/v2/activities/generics/panel-form-headline';
import InputTextSingleLineForUrl from 'components/v2/input-fields/input-text-single-line-for-url';
import InputTextDropdown         from 'components/v2/input-fields/input-text-dropdown';
import DurationValueUnitInput    from 'components/v2/activities/generics/duration-value-unit/input';
import Select                    from 'components/v2/input-fields/select';
import InputTextMultiline        from 'components/v2/input-fields/input-text-multiline';

import Location from 'lib/api/location';

import { competitionPlaceOptions } from 'components/v2/activities/categories/competition/variants/hackathon/options/competitionPlaceOptions';

class HackathonDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForEvent: true,
      isValueAcceptedForOrganiser: true,
      isValueAcceptedForLocation: true,
      locationSuggestions: [],
    };

    this.isValueAcceptedForEvent = () => {
      return this.state.isValueAcceptedForEvent;
    };

    this.isValueAcceptedForOrganiser = () => {
      return this.state.isValueAcceptedForOrganiser;
    };

    this.isValueAcceptedForLocation = () => {
      return this.state.isValueAcceptedForLocation;
    };

    this.setValueAcceptedForEvent = (value) => {
      return this.setState({ isValueAcceptedForEvent: value });
    };

    this.setValueAcceptedForOrganiser = (value) => {
      return this.setState({ isValueAcceptedForOrganiser: value });
    };

    this.setValueAcceptedForLocation = (value) => {
      return this.setState({ isValueAcceptedForLocation: value });
    };

    this._fetchLocationSuggestions = ({ substring }) => {
      const updateSuggestions = (predictions, status) => {
        const hashes = predictions
          ? predictions.filter((obj) => obj.hasOwnProperty('place_id')).map((obj) => {
            return {
              id:   defined(obj.place_id),
              name: defined(obj.description),
            };
          })
          : [];

        this.setState({ locationSuggestions: hashes })
      };

      if (typeof google === 'undefined') {
        console.log('Google has not been loaded yet');
        return null;
      }

      if (typeof this._googleService == 'undefined') {
        this._googleService = new google.maps.places.AutocompleteService();
      }

      if (defined(substring).trim().length > 0) {
        this._googleService.getQueryPredictions({ input: substring }, updateSuggestions);
      } else {
        this.setState({ locationSuggestions: [] })
      }
    };
  }

  componentDidMount () {
    const script = document.createElement("script");

    if (typeof google === 'undefined') {
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDMJNmxwNJspSrQvgejfgB4KxqK8Wsxh-Q&libraries=places";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const renderEventLinkInput = () => {
      const attrName = 'eventLink';

      const autoFillFields = () => {
        const sourceData = detailsData.getSourceData(attrName);
        const { title, description, image, location, sourceIcon, sourceName } = sourceData;

        const newLocation = new Location({
          placeId:      null,
          originalText: location,
          shortText:    location,
          lat:          null,
          lng:          null,
        });

        if (Object.keys(sourceData).length === 0) { return; }

        detailsData.setField({ attrName: 'event',                data: { id: null, name: title } });
        detailsData.setField({ attrName: 'challengeDescription', data: description               });
        detailsData.setField({ attrName: 'eventImg',             data: image                     });
        detailsData.setField({ attrName: 'eventLocation',        data: newLocation               });
        detailsData.setField({ attrName: 'sourceIcon',           data: sourceIcon                });
        detailsData.setField({ attrName: 'sourceName',           data: sourceName                });
      };

      const fetchSourceDataFromApi = ({ value, callbackOnSuccess, callbackOnFailure }) => {
        detailsData.fetchSourceDataFromApi(
          {
            attrName: attrName,
            link:     defined(value),
          },
          callbackOnSuccess,
          callbackOnFailure,
        );
      };

      return (
        <InputTextSingleLineForUrl
          isAutoFocused          = { true }
          inputFontSize          = "small"
          name                   = { attrName }
          label                  = { detailsData.getFieldLabel(attrName) }
          placeholder            = { detailsData.getFieldPlaceholder(attrName) }
          value                  = { detailsData.getField(attrName) }
          setValue               = { (value) => { detailsData.setField({ attrName: attrName, data: value.trim() }); } }
          fetchSourceDataFromApi = { fetchSourceDataFromApi }
          autoFillFields         = { autoFillFields }
        />
      );
    };

    const renderEventNameInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'event',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForEvent(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'event',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'event',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForEvent(true);
      };

      const onAcceptSuggestion = (event) => {
        detailsData.setField({
          attrName: 'event',
          data:     event,
        });
        this.setValueAcceptedForEvent(true);
      };

      return (
        <InputTextDropdown
          label         = { detailsData.getFieldLabel('event') }
          placeholder   = { detailsData.getFieldPlaceholder('event') }
          value         = { detailsData.getField('event').name }
          options       = { this.isValueAcceptedForEvent() ? null : detailsData.getSuggestions('event') }
          isError       = { false }
          hasHint       = { true }
          message       = "Max 100 Characters"
          maxLength     = { 100 }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderOrganiserInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'organiser',
          data:     { id: null, name: name, profileId: null, icon: '', location: Location.createEmpty() },
        });
        this.setValueAcceptedForOrganiser(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'organiser',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'organiser',
          data:     { id: null, name: name, profileId: null, icon: '', location: Location.createEmpty() },
        });
        this.setValueAcceptedForOrganiser(true);
      };

      const onAcceptSuggestion = (organiser) => {
        detailsData.setField({
          attrName: 'organiser',
          data:     organiser,
        });
        this.setValueAcceptedForOrganiser(true);
      };

      return (
        <InputTextDropdown
          label         = { detailsData.getFieldLabel('organiser') }
          placeholder   = { detailsData.getFieldPlaceholder('organiser') }
          value         = { detailsData.getField('organiser').name }
          options       = { this.isValueAcceptedForOrganiser() ? null : detailsData.getSuggestions('organiser') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name, profileId, icon, location }) => { onAcceptSuggestion({
            id:        defined(id),
            name:      defined(name),
            profileId: defined(profileId),
            icon:      defined(icon),
            location:  defined(location),
          }); } }
        />
      );
    };

    const renderEventLocationInput = () => {
      const attrName     = 'eventLocation';
      const label        = detailsData.getFieldLabel(attrName);
      const placeholder  = detailsData.getFieldPlaceholder(attrName);
      const originalText = detailsData.getField(attrName).originalText;
      const placeId      = detailsData.getField(attrName).placeId;
      const isWarning    = originalText && placeId === null;

      const onAcceptValue = ({ name }) => {
        const newLocation = new Location({
          placeId:      placeId,
          originalText: name,
          shortText:    name,
          lat:          null,
          lng:          null,
        });

        detailsData.setField({ attrName: attrName, data: newLocation });
        this.setValueAcceptedForLocation(true);
      };

      const onInputChange = (value) => {
        const newLocation = new Location({
          placeId:      null,
          originalText: value,
          shortText:    value,
          lat:          null,
          lng:          null,
        });

        detailsData.setField({ attrName: attrName, data: newLocation });
        this._fetchLocationSuggestions({ substring: value });
        this.setValueAcceptedForLocation(false);
      }

      const onOptionClick = ({ id, name }) => {
        const newLocation = new Location({
          placeId:      id,
          originalText: name,
          shortText:    name,
          lat:          null,
          lng:          null,
        });

        detailsData.setField({ attrName: attrName, data: newLocation });
        this.setValueAcceptedForLocation(true);
      }

      const onFocus = () => {
        if (placeId) return;
        if (typeof originalText !== 'string') return;

        this._fetchLocationSuggestions({ substring: originalText });
        this.setValueAcceptedForLocation(false);
      }

      return (
        <InputTextDropdown
          inputFontSize = "small"
          name          = { attrName }
          label         = { label }
          placeholder   = { placeholder }
          value         = { originalText || '' }
          options       = { this.isValueAcceptedForLocation() ? null : this.state.locationSuggestions }
          isWarning     = { isWarning }
          message       = { isWarning && 'Address not selected' }
          onInputChange = { (value) => { onInputChange(value); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onOptionClick({ id, name }); } }
          onFocus       = { onFocus }
        />
      );
    };

    const renderEventDurationValueUnitInput = () => {
      const { value, unit } = detailsData.getField('eventDuration');

      const valueOnChange = (selectedValue) => {
        detailsData.setField({
          attrName: 'eventDuration',
          data: {
            value: selectedValue,
            unit:  unit,
          },
        });
      };

      const unitOnChange = (selectedUnit) => {
        detailsData.setField({
          attrName: 'eventDuration',
          data: {
            value: value,
            unit:  selectedUnit,
          },
        });
      };

      return (
        <DurationValueUnitInput
          label            = { detailsData.getFieldLabel('eventDuration') }
          valuePlaceholder = { detailsData.getFieldPlaceholder('eventDuration').value }
          unitPlaceholder  = { detailsData.getFieldPlaceholder('eventDuration').unit }
          value            = { value }
          unit             = { unit }
          valueOnChange    = { (selectedValue) => { valueOnChange(selectedValue); } }
          unitOnChange     = { (selectedUnit) => { unitOnChange(selectedUnit); } }
        />
      );
    };

    const renderCompetitionPlaceInput = () => {
      return (
        <Select
          name        = "competitionPlace"
          label       = { detailsData.getFieldLabel('competitionPlace') }
          placeholder = { detailsData.getFieldPlaceholder('competitionPlace') }
          value       = { detailsData.getField('competitionPlace') }
          options     = { competitionPlaceOptions }
          onChange    = { (value) => { detailsData.setField({ attrName: 'competitionPlace', data: parseInt(value, 10) }); } }
        />
      );
    };

    const renderChallengeDescriptionInput = () => {
      return (
        <InputTextMultiline
          name        = "challengeDescription"
          label       = { detailsData.getFieldLabel('challengeDescription') }
          placeholder = { detailsData.getFieldPlaceholder('challengeDescription') }
          value       = { detailsData.getField('challengeDescription') }
          onChange    = { (value) => { detailsData.setField({ attrName: 'challengeDescription', data: value }); } }
          hasHint     = { true }
          message     = "Max 1500 Characters"
          maxLength   = { 1500 }
        />
      );
    };

    return (
      <div className = "v2-hackathon-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Hackathon Details'
          description = 'Share the details of this event below...'
        />

        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderEventLinkInput() }</div>
          <div className = "column is-full">{ renderEventNameInput() }</div>
          <div className = "column is-full">{ renderOrganiserInput() }</div>
          <div className = "column is-full">{ renderEventLocationInput() }</div>
          <div className = "column is-half">{ renderEventDurationValueUnitInput() }</div>
          <div className = "column is-half">{ renderCompetitionPlaceInput() }</div>
          <div className = "column is-full">{ renderChallengeDescriptionInput() }</div>
        </div>
      </div>
    );
  }
};

export default HackathonDetailsPanelForm;
