import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';
import Checkbox    from 'components/v2/input-fields/checkbox';

const AttendanceOnCampusAndOnlineInput = ({
  isOnCampusChecked,
  onOnCampusChange,
  isOnlineChecked,
  onOnlineChange,
  isAutoFocused
}) => {
  return (
    <div className = "v2-attendance-on-campus-and-online-input">
      <div className = "v2-attendance-on-campus-and-online-input__container">
        <label className = "v2-attendance-on-campus-and-online-input__label">
          Attendance
        </label>
        <div className = "columns">
          <div className = "column">
            <Checkbox
              isAutoFocused = { isAutoFocused }
              name          = "attendance-on-campus"
              label         = "On Campus"
              value         = "on-campus"
              isChecked     = { isOnCampusChecked }
              onChange      = { (value) => { onOnCampusChange(defined(value)); } }
              isError       = { false }
              message       = { null }
            />
          </div>
          <div className = "column">
            <Checkbox
              name      = "attendance-online"
              label     = "Online"
              value     = "online"
              isChecked = { isOnlineChecked }
              onChange  = { (value) => { onOnlineChange(defined(value)); } }
              isError   = { false }
              message   = { null }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceOnCampusAndOnlineInput;
