import './index.scss';
import React from 'react';

import { VERIFIED_VARIANTS } from 'lib/api/categories';
import { IssuerData }        from 'lib/api/var-edu/verified/issuer-data';

import { ModalGradientDarkBlue }       from 'components/v2/modal-gradient-dark-blue';
import { XpValueUpdateView }           from 'component-lib/components/xp-value-update-view';
import { CardUpdatesView }             from 'component-lib/components/card-updates-view';
import { NewCardUnlockedView }         from 'component-lib/components/new-card-unlocked-view';
import { getNewCardUnlockedViewProps } from 'component-lib/components/new-card-unlocked-view/get-props';
import { WhiteOutlineButton }          from 'components/v2/buttons/square-buttons/white-outline-button';
import { GreenSolidButton }            from 'components/v2/buttons/square-buttons/green-solid-button';

const VARIANT_UNLOCKED              = 'VARIANT_UNLOCKED';
const XP_CHANGED_IS_BEING_ANIMATING = 'XP_CHANGED_IS_BEING_ANIMATING';
const XP_CHANGED_FINAL              = 'XP_CHANGED_FINAL';
const CARD_EVENTS                   = 'CARD_EVENTS';

export class ModalAfterActivityUpdates extends React.Component {
  constructor(props) {
    const { xpNumber, events, isVariantUnlocked } = props;

    super(props);

    this.state = {
      viewComponentIndex: 0,
      activity:           null,
      issuerProfile:      null,
    };

    this._viewSequence = [].concat(
      isVariantUnlocked ? [VARIANT_UNLOCKED] : []
    ).concat(
      (xpNumber !== 0) ? [XP_CHANGED_IS_BEING_ANIMATING, XP_CHANGED_FINAL] : []
    ).concat(
      (events && events.length > 0) ? [CARD_EVENTS] : []
    );
  }

  componentDidMount() {
    const { appv2, activityId } = this.props;

    if (activityId === null) {
      return;
    }

    const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

    appv2.api.activities
      .fetch({ id: activityId, isXpFeatureEnabled, isLoggedIn: true })
      .then((activity) => {
        this.setState({ activity });

        const issuerCode = activity.get('details').get('issuerCode');

        if (!issuerCode) { return null; }

        const issuerData = IssuerData.getFor(issuerCode.value);
        const issuerProfileId = issuerData.profileId;

        return appv2.api.profiles
          .fetchWithExtraByIdOrSlug({ idOrSlug: issuerProfileId, isLoggedIn: true })
          .then(({ profile }) => {
            this.setState({ issuerProfile: profile });
          });
      });
  }

  render() {
    const { onClick } = this.props;
    const { viewComponentIndex } = this.state;

    const jumpToTheNextStep = () => {
      this.setState({
        viewComponentIndex: viewComponentIndex + 1,
      });
    };

    const onNextClick = (event) => {
      event.stopPropagation();

      if (viewComponentIndex === this._viewSequence.length - 1) {
        return onClick();
      }

      jumpToTheNextStep();
    };

    const renderSubcomponent = () => {
      const { appv2, xpNumber, events } = this.props;
      const { activity, issuerProfile } = this.state;

      const sequenceId = this._viewSequence[viewComponentIndex];

      switch (sequenceId) {
        case VARIANT_UNLOCKED:
          return (
            activity && <NewCardUnlockedView { ...getNewCardUnlockedViewProps({ activity, issuerProfile }) } />
          );

        case XP_CHANGED_IS_BEING_ANIMATING:
        case XP_CHANGED_FINAL:
          return (
            <XpValueUpdateView
              appv2 = { appv2 }
              xpNumber = { xpNumber }
              isAnimated = { sequenceId === XP_CHANGED_IS_BEING_ANIMATING }
              onAnimationFinished = { jumpToTheNextStep }
            />
          );

        case CARD_EVENTS:
          const onCardClick = (event) => {
            event.stopPropagation();

            const variantId = activity.get('variantId');
            const activityId = activity.get('id');

            if (VERIFIED_VARIANTS.indexOf(variantId) !== -1) {
              return appv2.ui.redirect({ url: `/verified-activities/${activityId}` });
            }

            return appv2.ui.openExistingActivityModal({ activityId, variantId });
          };

          return (
            <CardUpdatesView
              events = { events }
              activity = { activity }
              issuerProfile = { issuerProfile }
              onClick = { (event) => { onCardClick(event); } }
            />
          );

        default:
          throw Error(`Unexpected sequenceId: ${sequenceId}`);
      }
    };

    const renderButton = () => {
      return (viewComponentIndex === this._viewSequence.length - 1)
        ? <GreenSolidButton text = "Done!" onClick = { onNextClick } />
        : <WhiteOutlineButton text = "Continue" onClick = { onNextClick } />;
    };

    return (
      <div className = "modal-after-activity-updates">
        <ModalGradientDarkBlue
          onFullPageClick = { onNextClick }
          withLeftOffset = { false }
          windowWidthSize = "large"
        >
          <div className = "modal-after-activity-updates__content">
            { renderSubcomponent() }
            { renderButton() }
          </div>
        </ModalGradientDarkBlue>
      </div>
    );
  }
}
