import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalProgressPanelFormViewContent } from 'components/v2/modal-progress-panel-form-view-content';

class ModalProgressPanelFormView extends React.Component {
  constructor(props) {
    super(props);

    this.shouldAlertOnLeave = () => (false); // this will be overridden by the internal component
    const ALERT_MESSAGE = `There is unsaved information on this card, changes will not be saved.\n\nAre you sure you want to leave this page?`;

    this._confirmPageClose = (event) => {
      if (this.shouldAlertOnLeave()) {
        event.preventDefault();
        event.returnValue = ALERT_MESSAGE;
        return event.returnValue;
      }
    };

    this._confirmModalClose = () => {
      if (this.shouldAlertOnLeave()) {
        return confirm(ALERT_MESSAGE);
      }

      return true;
    };
  }

  componentDidMount() {
    window.onbeforeunload = this._confirmPageClose;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  render() {
    const { appv2, activity } = this.props;

    const closeFunction = () => {
      if (this._confirmModalClose()) { appv2.ui.closeModal(); }
    };

    return (
      <Modal
        withLeftOffset
        closeFunction = { closeFunction }
        windowWidthSize = "large"
      >
        <ModalProgressPanelFormViewContent
          activity      = { activity }
          closeFunction = { closeFunction }
        />
      </Modal>
    );
  }
}

export default ModalProgressPanelFormView;
