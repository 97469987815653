import './index.scss';
import React  from 'react';
import config from 'config';

import ProfileExtra from 'lib/api/profiles/profile-extra';
import State        from 'components/v2/profile/student-summary-content/state';
import Data         from 'components/v2/profile/student-summary-content/data';

import StudentAboutPanelView    from 'components/v2/profile/student-about-panel/view';
import StudentLocationPanelView from 'components/v2/profile/student-location-panel/view';
import PublicProfileEmptyState  from 'components/v2/public-profile/empty-state';

class PublicStudentSummaryContent extends React.Component {
  constructor(props) {
    super(props);

    // Warning: this `_state` is shared across this component and `_data`.
    // Do not use the same field names!

    this._state = new State({ component: this });
    this._data  = new Data({ state: this._state, appv2: props.appv2 });
  }

  UNSAFE_componentWillMount() {
    const { appv2, profile, canEdit } = this.props;
    const profileId = profile.get('id');

    this._data.initializeState({ profile, canEdit });

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: profileId, isLoggedIn: false })
      .then(({ profileExtra }) => {
        this._state.dataSet({
          currentLocation_profileExtra: profileExtra,
        });
      });

    this._state.dataSet({
      currentLocation_profileExtra: ProfileExtra.createEmpty(),
    });
  }

  render() {
    const { profile, visibilitySettings } = this.props;

    const firstName    = profile.get('fullName').split(" ")[0];
    const profileExtra = this._state.dataGet({ id: 'currentLocation_profileExtra' });

    const isProfileVisible         = visibilitySettings.master;
    const isAboutMeVisible         = visibilitySettings.aboutMe;
    const isCurrentLocationVisible = visibilitySettings.currentLocation;

    const isAboutMeDataPresent         = this._data.isSummaryDataPresent();
    const isCurrentLocationDataPresent = !profileExtra.isEmpty();

    const showAboutMePanel         = isProfileVisible && isAboutMeVisible && isAboutMeDataPresent;
    const showCurrentLocationPanel = isProfileVisible && isCurrentLocationVisible && isCurrentLocationDataPresent;


    return (
      <div className = "v2-public-student-summary-content">
        {
          showAboutMePanel &&
          <div className = "v2-public-student-summary-content__panel">
            <StudentAboutPanelView data = { this._data } />
          </div>
        }
        {
          showCurrentLocationPanel &&
          <div className = "v2-public-student-summary-content__panel">
            <StudentLocationPanelView
              profileExtra = { profileExtra }
              canEdit      = { false }
              onStartEdit  = { null }
            />
          </div>
        }
        {
          !showAboutMePanel && !showCurrentLocationPanel &&
          <div className = "v2-public-student-summary-content__panel">
            <PublicProfileEmptyState
              iconSrc     = { require('images/v2/icon-eye-user.svg') }
              description = { `${ firstName }’s details may be hidden to the public, login or sign up to view this profile.` }
              link        = { config.LANDING_PAGE_URL }
              target      = "_blank"
            />
          </div>
        }
      </div>
    );
  }
};

export default PublicStudentSummaryContent;
