import { defined } from 'lib/helpers';

import DurationValue from 'lib/api/activities/generics/duration-value-unit/duration-value';
import DurationUnit  from 'lib/api/activities/generics/duration-value-unit/duration-unit';

class DurationValueUnit {
  constructor({ value, unit }) {
    if (value instanceof DurationValue === false) {
      throw Error('value is not an instance of DurationValue');
    }

    if (unit instanceof DurationUnit === false) {
      throw Error('unit is not an instance of DurationUnit');
    }

    this.value = value;
    this.unit  = unit;
  }

  toApiFormat() {
    return {
      value: this.value.toApiFormat(),
      unit:  this.unit.toApiFormat(),
    };
  }

  isEmpty() {
    return (
      this.value.value   === null
      && this.unit.value === null
    );
  }

  isValid() {
    return (
      this.value.value !== null
      && this.unit.value !== null
    );
  }

  static createEmpty() {
    return new DurationValueUnit({
      value: new DurationValue(null),
      unit:  new DurationUnit(null),
    });
  }

  static fromApiFormat(data) {
    return new DurationValueUnit({
      value: DurationValue.fromApiFormat(defined(data.value)),
      unit:  DurationUnit.fromApiFormat(defined(data.unit)),
    });
  }
}

export default DurationValueUnit;
