import { defined } from 'lib/helpers';

const DETAILS  = 'details';
const SKILLS   = 'skills';
const DURATION = 'duration';

class UIBehaviourNew {
  constructor({ ui, data }) {
    this._ui = defined(ui);

    this.reset = () => {
      this._ui.initializeState();
    };

    this.detailsPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
      this._ui.breakPanelSequence();
    };

    this.detailsPanelContinue = () => {
      this._ui.closePanel();

      if (this._ui.arePanelsInSequence()) {
        this._ui.openPanel({ id: SKILLS });
      }
    };

    this.isDetailsPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === DETAILS;
    };

    this.clickDetailsSection = () => {
      this._ui.openPanel({ id: DETAILS });
      this._ui.breakPanelSequence();
    };

    this.skillsPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
      this._ui.breakPanelSequence();
    };

    this.skillsPanelContinue = () => {
      this._ui.closePanel();

      if (this._ui.arePanelsInSequence()) {
        if (data.durationData.isValid()) {
          return this.durationPanelContinue();
        }

        this._ui.openPanel({ id: DURATION });
      }
    };

    this.isSkillsPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === SKILLS;
    };

    this.clickSkillsSection = () => {
      this._ui.openPanel({ id: SKILLS });
      this._ui.breakPanelSequence();
    };

    this.durationPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
      this._ui.breakPanelSequence();
    };

    this.durationPanelContinue = () => {
      this._ui.closePanel();
    };

    this.isDurationPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === DURATION;
    };

    this.clickDurationSection = () => {
      this._ui.openPanel({ id: DURATION });
      this._ui.breakPanelSequence();
    };
  }
}

export default UIBehaviourNew;
