import './index.scss';
import React from 'react';

import ImgTag                        from 'component-lib/components/img-tag';
import Text                          from 'component-lib/type/text';
import { style16pxRegularBluegreyD } from 'component-lib/type/text/styles';

const PaddlGamesTeamReadonly = ({ place, teamName }) => {
  const iconSrc = (place) => {
    switch (place) {
      case 1:  return require('component-lib/components/paddl-games-team-readonly/comp1st.svg');
      case 2:  return require('component-lib/components/paddl-games-team-readonly/comp2nd.svg');
      case 3:  return require('component-lib/components/paddl-games-team-readonly/comp3rd.svg');
      default: return require('component-lib/components/paddl-games-team-readonly/comp.svg');
    }
  };

  return (
    <div className = "v2-paddl-games-team-readonly">
      <ImgTag
        src       = { iconSrc(place) }
        width     = '48px'
        isRounded = { false }
      />
      <Text { ...style16pxRegularBluegreyD } text = { teamName } />
    </div>
  );
}

export default PaddlGamesTeamReadonly;
