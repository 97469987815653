import './index.scss';
import React from 'react';

import Text                         from 'component-lib/type/text';
import { style26pxMediumBluegreyD } from 'component-lib/type/text/styles';
import { style16pxLightBluegrey }   from 'component-lib/type/text/styles';

export const ChooseFromCategoryCard = ({ title, subTitle }) => {
  const contentClassName = (title) => {
    switch (title) {
      case 'Education':   return 'v2-choose-from-category-card__content-wrapper v2-choose-from-category-card__bg-img--education';
      case 'Employment':  return 'v2-choose-from-category-card__content-wrapper v2-choose-from-category-card__bg-img--employment';
      case 'Competition': return 'v2-choose-from-category-card__content-wrapper v2-choose-from-category-card__bg-img--competition';
      default:            throw Error('Unexpected Category Title');
    }
  }

  return (
    <div className = "v2-choose-from-category-card">
      <div className = { contentClassName(title) }>
        <div className = "v2-choose-from-category-card__text">
          <Text { ...style26pxMediumBluegreyD }
            text = { title }
          />
          <Text { ...style16pxLightBluegrey }
            text = { subTitle }
          />
        </div>
      </div>
      <div className = "v2-choose-from-category-card__shadow">
      </div>
    </div>
  );
};
