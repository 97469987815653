import React from 'react';

import Text                        from 'component-lib/type/text';
import { style16pxLightBluegreyD } from 'component-lib/type/text/styles';

const ChallengeFocusReadonly = ({ challengeFocus }) => {
  if (!challengeFocus) return null;

  return (
    <div className = "v2-challenge-focus-readonly">
      <Text { ...style16pxLightBluegreyD } text = { challengeFocus } />
    </div>
  );
}

export default ChallengeFocusReadonly;
