import { defined } from 'lib/helpers';

class ApiProfileSlugs {
  constructor({ transport }) {
    this._transport = transport;
  }

  retrieveId({ slug }) {
    const endpoint = `/slugs/${ encodeURIComponent(slug) }`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then(({ id }) => {
        return { id: defined(id) }; // it may be `null`
      });
  }

  checkAvailability(slug) {
    const endpoint = `/slugs/available/${ encodeURIComponent(slug) }`;

    return this._transport
      .makeRequest({ endpoint, method: 'POST' })
      .then((isAvailable) => {
        return isAvailable;
      });
  }

  update(slug) {
    const endpoint = '/slugs';
    const params   = { slug: slug };

    return this._transport
      .makeRequest({ endpoint, method: 'POST', params })
      .then((data) => {
        return true;
      });
  }

  remove() {
    const endpoint = '/slugs';

    return this._transport
      .makeRequest({ endpoint, method: 'DELETE' })
      .then((data) => {
        return true;
      });
  }

  fetchSuggestions() {
    const endpoint = '/slugs/suggestions';

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then((suggestions) => {
        return suggestions;
      });
  }
}

export default ApiProfileSlugs;
