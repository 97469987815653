import ApiTransport          from './transport';
import ApiActivities         from './activities';
import ApiProfiles           from './profiles';
import ApiUploading          from './uploading';
import ApiSkills             from './skills';
import ApiEducation          from './education';
import ApiEmployment         from './employment';
import ApiCompetition        from './competition';
import ApiVisibilitySettings from './visibility-settings';
import ApiHeroBannerImages   from './hero-banner-images';
import ApiProfileSlugs       from './profile-slugs';

class Api {
  constructor({ store, analytics }) {
    this.transport          = new ApiTransport({ store });
    this.activities         = new ApiActivities({ transport: this.transport, analytics });
    this.profiles           = new ApiProfiles({ transport: this.transport, analytics });
    this.uploading          = new ApiUploading({ transport: this.transport });
    this.skills             = new ApiSkills({ transport: this.transport });
    this.education          = new ApiEducation({ transport: this.transport });
    this.employment         = new ApiEmployment({ transport: this.transport });
    this.competition        = new ApiCompetition({ transport: this.transport });
    this.visibilitySettings = new ApiVisibilitySettings({ transport: this.transport });
    this.heroBannerImages   = new ApiHeroBannerImages({ transport: this.transport });
    this.profileSlugs       = new ApiProfileSlugs({ transport: this.transport });
  }
}

export default Api;
