import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalHeroBannerImageContent } from 'components/v2/modal-hero-banner-image-content';

const DEFAULT_SELECTED_ID = 1;

export class ModalHeroBannerImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listOfBanners: [],
      originalSelectedId: null,
      currentSelectedId: null,
    };
  }

  componentDidMount() {
    this.props.appv2.api.heroBannerImages
      .fetchBanners()
      .then(({ listOfBanners, selectedId }) => {
        this.setState({
          listOfBanners,
          originalSelectedId: selectedId,
          currentSelectedId: selectedId,
        });
      });
  }

  render() {
    const { appv2, withLeftOffset, onImageUploaded } = this.props;
    const { listOfBanners, originalSelectedId, currentSelectedId } = this.state;

    const artworks = listOfBanners.filter(item => !item.isUploadedByUser);
    const originalSelectedBanner = originalSelectedId ? listOfBanners.find(item => item.id === originalSelectedId) : null;
    const currentSelectedBanner = currentSelectedId ? listOfBanners.find(item => item.id === currentSelectedId) : null;
    const isSubmitDisabled = (currentSelectedBanner === null || currentSelectedBanner === originalSelectedBanner);

    const closeFunction = () => {
      appv2.ui.closeModal();
    };

    const onSelect = (artworkId) => {
      if (currentSelectedBanner && currentSelectedBanner.isUploadedByUser) {
        if (confirm("Are you sure you want to remove your custom banner and select a predefined banner?")) {
          return this.setState({ currentSelectedId: artworkId });
        }

        return null;
      }

      return this.setState({ currentSelectedId: artworkId });
    };

    const onRemove = () => {
      if (confirm("Are you sure you want to remove your custom banner and use the default banner?")) {
        return this.setState({ currentSelectedId: DEFAULT_SELECTED_ID });
      }
    };

    const onSubmit = () => {
      return appv2.api.heroBannerImages
        .setSelection({ id: currentSelectedBanner.id })
        .then(() => {
          appv2.ui.closeModal();
          appv2.ui.displaySnackBar({
            style:    'green',
            text:     'Banner saved!',
            callback: () => { appv2.ui.refreshPage(); },
          });
        });
    };

    const onCancel = () => {
      appv2.ui.closeModal();
    };

    const afterImageUploaded = (url) => {
      appv2.api.heroBannerImages
        .setCustomBanner({ url })
        .then(() => {
          onImageUploaded();
        });
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { withLeftOffset }
        windowWidthSize = 'large'
      >
        <ModalHeroBannerImageContent
          appv2 = { appv2 }
          artworks = { artworks }
          selectedBanner = { currentSelectedBanner }
          onSelect = { onSelect }
          onRemove = { onRemove }
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          afterImageUploaded = { afterImageUploaded }
          isSubmitDisabled = { isSubmitDisabled }
        />
      </Modal>
    );
  }
}
