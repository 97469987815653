import React from 'react';
import pluralize from 'pluralize';

import TagDeletable from 'components/v2/tag-deletable';

import './index.scss';

const SelectedSkillsSubpanel = ({
  skills,
  onDeleteSkill,
}) => {
  return (
    <div className="v2-selected-skills-subpanel">
      <div className="v2-selected-skills-subpanel__title">
        { pluralize('Skill', skills.length, true) } Selected
      </div>

      { skills && skills.length > 0 &&
        <div className="v2-selected-skills-subpanel__list">
          <div className="v2-selected-skills-subpanel__tags-wrapper">
            { skills.map((skill, i) => (
              <TagDeletable
                key={i}
                label={skill}
                onTagDelete={() => { onDeleteSkill({ name: skill }); }}
              />
            )) }
          </div>
        </div>
      }

      <div className="v2-selected-skills-subpanel__message">
        Skills in Grey will be reviewed for approval
      </div>
    </div>
  );
};

export default SelectedSkillsSubpanel;
