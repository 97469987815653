import { defined, DefinedMap } from 'lib/helpers';

import ProfileExtra from './profile-extra';

// TODO change this hardcode values
export const STUDENT      = 'student';
export const EMPLOYER     = 'employer';
export const UNKNOWN_ROLE = 'unknown_role';

class Profile extends DefinedMap {
  constructor(data) {
    super({
      attributes: [
        'id',
        'fullName',
        'avatarUrl',
        'bannerImageUrl',
        'summary',
        'strapline',
        'businessName',
        'role',
        'legacyEducatorName',
        'legacyCampusName',
        'legacyCourseName',
        'legacyCourseThrough',
        'legacyCourseLength',
        'legacyVillageTitles',
        'urlSlug',
      ],
      data
    });
  }

  toApiFormat() {
    return {
      id:             this.get('id'),
      fullName:       this.get('fullName'),
      avatarUrl:      this.get('avatarUrl'),
      bannerImageUrl: this.get('bannerImageUrl'),
      summary:        this.get('summary'),
      strapline:      this.get('strapline'),
      businessName:   this.get('businessName'),
      role:           this.get('role'),
      legacy: {
        educatorName:  this.get('legacyEducatorName'),
        campusName:    this.get('legacyCampusName'),
        courseName:    this.get('legacyCourseName'),
        courseThrough: this.get('legacyCourseThrough'),
        courseLength:  this.get('legacyCourseLength'),
        villageTitles: this.get('legacyVillageTitles'),
      },
      urlSlug: this.get('urlSlug'),
    };
  }

  static fromApiFormat(data) {
    const legacy = defined(data.legacy);

    return new Profile({
      ...data,
      legacyEducatorName:  legacy.educatorName,
      legacyCampusName:    legacy.campusName,
      legacyCourseName:    legacy.courseName,
      legacyCourseThrough: legacy.courseThrough,
      legacyCourseLength:  legacy.courseLength,
      legacyVillageTitles: legacy.villageTitles,
    });
  }

  static getExtraFromApiFormat(data) {
    return ProfileExtra.fromApiFormat(defined(data.extra));
  }
}

export default Profile;
