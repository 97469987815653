import './index.scss';
import React from 'react';

import Text                   from 'component-lib/type/text';
import { style16pxBoldWhite } from 'component-lib/type/text/styles';

export const RegistrationButton = ({ customCopy, onClick }) => {
  return (
    <a
      className = "registration-button"
      onClick   = { () => { onClick(); } }
    >
      <Text { ...style16pxBoldWhite } text = { customCopy || 'Submit' } />
    </a>
  );
};
