import './index.scss';
import React from 'react';

export const ActivityCardWrapper = ({ children, onClick }) => {
  if (children === null) {
    return null;
  }

  return (
    <div className = "activity-card-wrapper" onClick = { onClick }>
      { children }
    </div>
  );
};
