import { defined } from 'lib/helpers';

const validateNumericValue = (value) => {
  if (isNaN(value) || value < 0) {
    throw Error('Unexpected value');
  }
};

class SkillsCount {
  constructor(value) {
    const intValue = parseInt(defined(value), 10);
    validateNumericValue(intValue);
    this.value = intValue;
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new SkillsCount(defined(data));
  }
}

export default SkillsCount;
