import './index.scss';
import React from 'react';

import Text                           from 'component-lib/type/text';
import { style22pxRegularWhite }      from 'component-lib/type/text/styles';
import ActivityCardSuggestionListItem from 'component-lib/components/activity-card-suggestion-list-item';

export const NewCardUnlockedView = ({
  variantIcon,
  variantIconShape,
  variantTitle,
  variantSubtitle,
  categoryTitle,
  categoryTitleColor,
  footerBorderTopColor,
}) => {
  return (
    <div className = "new-card-unlocked-view">
      <ActivityCardSuggestionListItem
        hasCheckmark         = { false }
        isClickable          = { false }
        variantIcon          = { variantIcon }
        variantIconShape     = { variantIconShape }
        variantTitle         = { variantTitle }
        variantSubtitle      = { variantSubtitle }
        categoryTitle        = { categoryTitle }
        categoryTitleColor   = { categoryTitleColor }
        footerBorderTopColor = { footerBorderTopColor }
      />

      <div className = "new-card-unlocked-view__subtitle">
        <Text { ...style22pxRegularWhite } text = "🎉 New Card Unlocked!" />
      </div>
    </div>
  );
};
