import Profile from 'lib/api/profiles/profile';

class Common {
  static createEmptyProfile() {
    return new Profile({
      id:                  null,
      fullName:            '',
      avatarUrl:           '',
      bannerImageUrl:      '',
      summary:             '',
      strapline:           null,
      businessName:        null,
      role:                null,
      legacyEducatorName:  '',
      legacyCampusName:    '',
      legacyCourseName:    '',
      legacyCourseThrough: null,
      legacyCourseLength:  null,
      legacyVillageTitles: [],
      urlSlug:             null,
    });
  }

  static isProfileEmpty({ profile }) {
    return (profile.get('id') === null);
  }
}

export default Common;
