// XP / Tags
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=3546%3A0&viewport=-2162%2C2227%2C2.94333

import React from 'react';

import Text                    from 'component-lib/type/text';
import { style16pxLightGreyL } from 'component-lib/type/text/styles';

const XpTags = ({ strings }) => {
  const concatenatedString = strings.join(" • ");

  return (
    <div className = "xp-tags">
      <Text { ...style16pxLightGreyL } text = { concatenatedString } />
    </div>
  );
}

export default XpTags;
