import { fetchDurationUnitOptions } from './options';

export const getDurationValueUnit = ({ duration }) => {
  const { value, unit } = duration;

  const durationUnitOptions = fetchDurationUnitOptions({ durationValue: value });
  const currentUnitOption = durationUnitOptions.find((option) => { return (option.value === unit); });

  return `${value} ${currentUnitOption.title}`;
};
