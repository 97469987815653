import './index.scss';
import React from "react";
import Slick from "react-slick";

import "scss/plugins/slick/slick.scss";

export class Swiper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const settings = {
      variableWidth: true,
      dots:          false,
      arrows:        true,
      centerMode:    true,
      infinite:      true,
      speed:         500,
      slidesToShow:  3,
    };

    return (
      <div>
        <div className = "swiper">
          <Slick { ...settings }>
            { this.props.children }
          </Slick>
        </div>
      </div>
    );
  }
}
