import { defined, regexpWithUrlHead } from 'lib/helpers';
import VariantMapping from 'lib/api/variant-mapping';
import { findCategoryIdBy, getCategoryData } from 'lib/api/categories';

import Activity from './activity';
import ActivityAsTimelineItem from './activity-as-timeline-item';
import ActivityAsBasecampCard from './activity-as-basecamp-card';

class ApiActivities {
  constructor({ transport, analytics }) {
    this._transport = transport;
    this._analytics = analytics;
  }

  fetch({ id, isXpFeatureEnabled = false, isLoggedIn = false }) {
    const endpoint = isLoggedIn
      ? `/activities/${encodeURIComponent(id)}`
      : `/public-activities/${encodeURIComponent(id)}`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then((data) => {
        return Activity.fromApiFormat(data, { isXpFeatureEnabled });
      });
  }

  create({ activity, isXpFeatureEnabled = false }) {
    const endpoint = '/activities';
    const data = activity.toApiFormat();

    const sendAnalyticsEvent = () => {
      // Yes, this is ugly, but another option is to pass that "isOnboarding" flag
      // through the whole stack of layers... Don't wanna do that.
      const nameOfEvent = (window.location.pathname.indexOf('/onboarding') === -1)
        ? 'Added an Activity Card'
        : 'OB - Added a Card';

      const variantId = activity.get('variantId');
      const variantHandler = VariantMapping.getFor({ variantId });
      const categoryId = findCategoryIdBy({ variantId });
      const categoryData = getCategoryData({ categoryId });
      const skillset = activity.get('skillset');
      const progressValue = activity.get('duration').percentageComplete &&
        activity.get('duration').percentageComplete.value;

      this._analytics.track({
        event: nameOfEvent,
        metaData: {
          variantName: variantHandler.title(),
          categoryName: categoryData.title(),
          numberOfSkills: skillset.skills.length,
          progressStatus: (progressValue < 100) ? 'IN_PROGRESS' : 'COMPLETE',
        },
      });
    };

    data.id = null;
    data.profileId = null;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then(({ id, xpNumber, events, isVariantUnlocked }) => {
        const outputData = {
          id: defined(id),
          xpNumber: isXpFeatureEnabled ? defined(xpNumber) : 0,
          events: isXpFeatureEnabled ? defined(events) : [],
          isVariantUnlocked: isXpFeatureEnabled ? defined(isVariantUnlocked) : false,
        };

        sendAnalyticsEvent();

        return new Promise((resolve) => {
          // we'll get rid of this delay, replacing it with the "circle" animation
          setTimeout(() => { resolve(outputData); }, 1000);
        });
      });
  }

  update({ id, activity, isXpFeatureEnabled = false }) {
    const endpoint = `/activities/${encodeURIComponent(id)}`;
    const data = activity.toApiFormat();

    data.id = null;
    data.profileId = null;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        const outputData = {
          xpNumber: isXpFeatureEnabled ? defined(data.xpNumber) : 0,
          events: isXpFeatureEnabled ? defined(data.events) : [],
        };

        return outputData;
      });
  }

  delete({ id, isXpFeatureEnabled = false }) {
    const endpoint = `/activities/${encodeURIComponent(id)}`;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'DELETE',
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        const outputData = {
          xpNumber: isXpFeatureEnabled ? defined(data.xpNumber) : 0,
          events: isXpFeatureEnabled ? defined(data.events) : [],
        };

        return outputData;
      });
  }

  fetchTimeline({ profileId, isXpFeatureEnabled = false, isLoggedIn = false }) {
    const endpoint = isLoggedIn
      ? `/timeline/${encodeURIComponent(profileId)}`
      : `/public-timeline/${encodeURIComponent(profileId)}`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then((arr) => {
        return arr.map((item) => {
          return ActivityAsTimelineItem.fromApiFormat(item, { isXpFeatureEnabled });
        });
      });
  }

  collectAllAvailableXP() {
    const endpoint = '/basecamp/cards/collect-all-xp';

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then(({ xpNumber }) => {
        return xpNumber;
      });
  }

  fetchBasecampCards({ profileId, cleanTransportCache }) {
    const endpoint = `/basecamp/cards/${encodeURIComponent(profileId)}`;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'GET',
        useCache: true,
        invalidateCachePatterns: cleanTransportCache ? [regexpWithUrlHead(endpoint)] : undefined,
      })
      .then((arr) => {
        return arr.map((item) => {
          return ActivityAsBasecampCard.fromApiFormat(item);
        });
      });
  }

  redeemPreview({ redemptionCode, isXpFeatureEnabled = false }) {
    const endpoint = '/activities/redeem/preview';

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data: { redeem: redemptionCode },
      })
      .then((data) => {
        return Activity.fromApiFormat(data, { isXpFeatureEnabled });
      });
  }

  redeem({ redemptionCode, isXpFeatureEnabled = false }) {
    const endpoint = '/activities/redeem';

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data: { redeem: redemptionCode },
      })
      .then(({ id, xpNumber, events, isVariantUnlocked }) => {
        const outputData = {
          id: defined(id),
          xpNumber: isXpFeatureEnabled ? defined(xpNumber) : 0,
          events: isXpFeatureEnabled ? defined(events) : [],
          isVariantUnlocked: isXpFeatureEnabled ? defined(isVariantUnlocked) : false,
        };

        return outputData;
      });
  }
}

export default ApiActivities;
