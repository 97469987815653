import './index.scss';
import React from 'react';

import XpAmount from 'component-lib/components/xp-amount';
import TimelineSkillsCount from 'component-lib/components/timeline-skills-count';

export const XpNumberAndSkillsCount = ({
  xpNumber,
  xpNumberColor,
  xpUnitColor,
  skillsCount,
}) => {
  const isXpFeatureEnabled = window._appv2 &&
    window._appv2.featureFlags.isEnabled({ featureName: 'xp' });

  return (
    <div className = "xp-number-and-skills-count">
      {
        isXpFeatureEnabled &&
        <div className = "xp-number-and-skills-count__xp-number">
          <XpAmount
            number      = { xpNumber }
            numberColor = { xpNumberColor }
            unitColor   = { xpUnitColor }
          />
        </div>
      }
      <div className = "xp-number-and-skills-count__skills-count">
        <TimelineSkillsCount skillsCount = { skillsCount } />
      </div>
    </div>
  );
};
