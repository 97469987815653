import './index.scss';
import React from 'react';
import SvgIconClose from 'components/v2/svg-icons/svg-icon-close';

export const Modal = ({
  children,
  closeFunction,
  withLeftOffset,
  windowWidthSize,
}) => {
  let overlayRef;
  let offsetRef;

  const setOverlayRef = (elem) => {
    overlayRef = elem;
  };

  const setOffsetRef = (elem) => {
    offsetRef = elem;
  };

  const onOverlayClick = (elem) => {
    if (elem.target == overlayRef || elem.target == offsetRef) {
      closeFunction();
    }
  };

  return (
    <div className = "v2-modal">
      <div ref = { setOverlayRef } className = "v2-modal__overlay" onClick = { onOverlayClick }>
        <div ref = { setOffsetRef } className = { withLeftOffset ? 'v2-modal__offset' : '' }>
          <div className = { windowWidthSize ? `v2-modal__window v2-modal__window--${windowWidthSize}` : 'v2-modal__window' }>
            <a className = "v2-modal__close-link" onClick = { closeFunction }>
              <SvgIconClose />
            </a>
            <div className = "v2-modal__content">
              { children }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
