const createPrepopulatingReducer = (actionType) => {
  return (state = {}, action) => {
    if (action.type === actionType) {
      return action.payload;
    }

    return state;
  };
};

const createEntitiesPrepopulatingReducer = (actionType) => {
  return (state = {}, action) => {
    if (action.type === actionType) {
      return {
        ...state,
        entities: action.payload
      };
    }

    return state;
  };
};

export const PREPOPULATE_APPLICATIONS  = 'stress/PREPOPULATE_APPLICATIONS';
export const PREPOPULATE_JOBS          = 'stress/PREPOPULATE_JOBS';
export const PREPOPULATE_CONVERSATIONS = 'stress/PREPOPULATE_CONVERSATIONS';
export const PREPOPULATE_PROFILES      = 'stress/PREPOPULATE_PROFILES';
export const PREPOPULATE_EDUCATORS     = 'stress/PREPOPULATE_EDUCATORS';
export const PREPOPULATE_CAMPUSES      = 'stress/PREPOPULATE_CAMPUSES';
export const PREPOPULATE_COURSES       = 'stress/PREPOPULATE_COURSES';
export const PREPOPULATE_VILLAGES      = 'stress/PREPOPULATE_VILLAGES';

export const prepopulateApplications  = createEntitiesPrepopulatingReducer(PREPOPULATE_APPLICATIONS);
export const prepopulateJobs          = createEntitiesPrepopulatingReducer(PREPOPULATE_JOBS);
export const prepopulateConversations = createPrepopulatingReducer(PREPOPULATE_CONVERSATIONS);
export const prepopulateProfiles      = createPrepopulatingReducer(PREPOPULATE_PROFILES);
export const prepopulateEducators     = createPrepopulatingReducer(PREPOPULATE_EDUCATORS);
export const prepopulateCampuses      = createPrepopulatingReducer(PREPOPULATE_CAMPUSES);
export const prepopulateCourses       = createPrepopulatingReducer(PREPOPULATE_COURSES);
export const prepopulateVillages      = createPrepopulatingReducer(PREPOPULATE_VILLAGES);
