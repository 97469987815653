import React from 'react';

import { LandingPageContentLayout } from 'components/v2/landing-page-content-layout';
import { LandingPageBackgroundArtwork } from 'components/v2/landing-page-background-artwork';
// TODO: Render PublicNavFooterMenu after all links are ready
// import { PublicNavFooterMenu } from 'components/v2/public-nav-footer-menu';
import './index.scss';

export const PublicLandingPage = () => (
  <div className="v2-public-landing-page">
    <div className="v2-public-landing-page__viewport-height">
      <div className="v2-public-landing-page__content">
        <LandingPageContentLayout />
      </div>
      <LandingPageBackgroundArtwork />
    </div>
    {/*
    // TODO: Render PublicNavFooterMenu after all links are ready
    <div className="v2-public-landing-page__footer-menu">
      <PublicNavFooterMenu />
    </div>
    */}
  </div>
);
