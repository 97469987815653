import React from 'react';

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L19 19" stroke="#DCE7EA" strokeWidth="2"/>
      <path d="M19 1L1 19" stroke="#DCE7EA" strokeWidth="2"/>
    </svg>
  );
};
