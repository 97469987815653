import './index.scss';
import React from 'react';

import ImgTag from 'component-lib/components/img-tag';

export const LandingPageContentArtwork = () => {
  return (
    <div className = "landing-page-content-artwork">
      <div className = "landing-page-content-artwork__desktop">
        <ImgTag
          src = { require('components/svg-icon/svgs/landing-content-artwork-desktop.svg') }
          alt = "Paddl captures all of your learning experience"
        />
      </div>
      <div className = "landing-page-content-artwork__mobile">
        <ImgTag
          src = { require('components/svg-icon/svgs/landing-content-artwork-mobile.svg') }
          alt = "Paddl captures all of your learning experience"
        />
      </div>
    </div>
  );
};
