import './index.scss';
import React from 'react';

const PanelFormHeadline = ({ iconSrc, title, description }) => {
  return (
    <div className = "v2-panel-form-headline">
      <div className = "v2-panel-form-headline__container">
        <div className = "v2-panel-form-headline__left">
          <img src = { iconSrc } />
        </div>
        <div>
          <strong className = "v2-panel-form-headline__title">
            { title }
          </strong>
          <span className = "v2-panel-form-headline__description">
            { description }
          </span>
        </div>
      </div>
    </div>
  );
};

export default PanelFormHeadline;
