// https://www.npmjs.com/package/directory-metagen
// ./node_modules/.bin/metagen --filter=svg src/components/svg-icon/svgs commonJS

module.exports = {
  'alert': require('./alert.svg'),
  'align-left': require('./align-left.svg'),
  'archived': require('./archived.svg'),
  'arrow-down': require('./arrow-down.svg'),
  'arrow-left': require('./arrow-left.svg'),
  'arrow-right': require('./arrow-right.svg'),
  'arrow-up': require('./arrow-up.svg'),
  'basecamp': require('./basecamp.svg'),
  'built': require('./built.svg'),
  'business': require('./business.svg'),
  'camera': require('./camera.svg'),
  'campaign-radar': require('./campaign-radar.svg'),
  'campaign': require('./campaign.svg'),
  'circle-add': require('./circle-add.svg'),
  'circle-close': require('./circle-close.svg'),
  'circle': require('./circle.svg'),
  'clock': require('./clock.svg'),
  'close': require('./close.svg'),
  'colorful-applications': require('./colorful-applications.svg'),
  'commerce': require('./commerce.svg'),
  'communications': require('./communications.svg'),
  'community': require('./community.svg'),
  'confirm': require('./confirm.svg'),
  'copy': require('./copy.svg'),
  'course': require('./course.svg'),
  'creative': require('./creative.svg'),
  'drag': require('./drag.svg'),
  'edit': require('./edit.svg'),
  'education': require('./education.svg'),
  'email-circle': require('./email-circle.svg'),
  'envelope': require('./envelope.svg'),
  'environment': require('./environment.svg'),
  'export': require('./export.svg'),
  'eye': require('./eye.svg'),
  'facebook--grey-dark-active': require('./facebook--grey-dark-active.svg'),
  'facebook--grey-dark': require('./facebook--grey-dark.svg'),
  'facebook-circle': require('./facebook-circle.svg'),
  'facebook-white-active': require('./facebook-white-active.svg'),
  'facebook-white': require('./facebook-white.svg'),
  'facebook': require('./facebook.svg'),
  'group': require('./group.svg'),
  'health': require('./health.svg'),
  'heartbeat': require('./heartbeat.svg'),
  'help-and-support': require('./help-and-support.svg'),
  'icon-gdpr': require('./icon-gdpr.svg'),
  'icon-search': require('./icon-search.svg'),
  'instagram--grey-dark-active': require('./instagram--grey-dark-active.svg'),
  'instagram--grey-dark': require('./instagram--grey-dark.svg'),
  'instagram-white-active': require('./instagram-white-active.svg'),
  'instagram-white': require('./instagram-white.svg'),
  'instagram': require('./instagram.svg'),
  'intercom-chat-icon': require('./intercom-chat-icon.svg'),
  'intro': require('./intro.svg'),
  'lab': require('./lab.svg'),
  'landing-artwork-background': require('./landing-artwork-background.svg'),
  'landing-content-artwork-desktop': require('./landing-content-artwork-desktop.svg'),
  'landing-content-artwork-mobile': require('./landing-content-artwork-mobile.svg'),
  'landing-landscape-background': require('./landing-landscape-background.svg'),
  'linkedin--grey-dark-active': require('./linkedin--grey-dark-active.svg'),
  'linkedin--grey-dark': require('./linkedin--grey-dark.svg'),
  'linkedin-circle': require('./linkedin-circle.svg'),
  'linkedin-white-active': require('./linkedin-white-active.svg'),
  'linkedin-white': require('./linkedin-white.svg'),
  'list': require('./list.svg'),
  'location': require('./location.svg'),
  'logistics': require('./logistics.svg'),
  'mobile': require('./mobile.svg'),
  'modal-vote': require('./modal-vote.svg'),
  'outpost': require('./outpost.svg'),
  'paper-plane': require('./paper-plane.svg'),
  'paper': require('./paper.svg'),
  'pencil': require('./pencil.svg'),
  'phone': require('./phone.svg'),
  'pinterest': require('./pinterest.svg'),
  'plane': require('./plane.svg'),
  'planet': require('./planet.svg'),
  'plus': require('./plus.svg'),
  'refresh': require('./refresh.svg'),
  'retail': require('./retail.svg'),
  'sad-face': require('./sad-face.svg'),
  'safety-ring': require('./safety-ring.svg'),
  'save': require('./save.svg'),
  'sciences': require('./sciences.svg'),
  'search': require('./search.svg'),
  'security': require('./security.svg'),
  'share-blue': require('./share-blue.svg'),
  'share': require('./share.svg'),
  'shortlist': require('./shortlist.svg'),
  'sign': require('./sign.svg'),
  'skype': require('./skype.svg'),
  'star-wire-blue': require('./star-wire-blue.svg'),
  'star-wire': require('./star-wire.svg'),
  'star': require('./star.svg'),
  'sticker-new': require('./sticker-new.svg'),
  'sticker-shortlist': require('./sticker-shortlist.svg'),
  'sticker-unsuccess': require('./sticker-unsuccess.svg'),
  'tag': require('./tag.svg'),
  'technology': require('./technology.svg'),
  'tent': require('./tent.svg'),
  'terms-page-hero-section-bg': require('./terms-page-hero-section-bg.svg'),
  'three-dots': require('./three-dots.svg'),
  'three-lines': require('./three-lines.svg'),
  'thumbs-down': require('./thumbs-down.svg'),
  'thumbs-up': require('./thumbs-up.svg'),
  'trades': require('./trades.svg'),
  'twitter--grey-dark-active': require('./twitter--grey-dark-active.svg'),
  'twitter--grey-dark': require('./twitter--grey-dark.svg'),
  'twitter-circle': require('./twitter-circle.svg'),
  'twitter-white-active': require('./twitter-white-active.svg'),
  'twitter-white': require('./twitter-white.svg'),
  'twitter': require('./twitter.svg'),
  'two-and-half-lines': require('./two-and-half-lines.svg'),
  'uniE61E': require('./uniE61E.svg'),
  'unsuccessful-wire-blue': require('./unsuccessful-wire-blue.svg'),
  'unsuccessful-wire': require('./unsuccessful-wire.svg'),
  'unsuccessful': require('./unsuccessful.svg'),
  'user-details': require('./user-details.svg'),
  'user': require('./user.svg'),
  'vote-activity-cards': require('./vote-activity-cards.svg'),
  'vote': require('./vote.svg'),
  'wallet': require('./wallet.svg'),
  'website': require('./website.svg')
};
