import './index.scss';
import React from 'react';

import Text                 from 'component-lib/type/text';
import { CompleteCircle }   from 'components/v2/activities-progress-circle/complete';
import { InProgressCircle } from 'components/v2/activities-progress-circle/in-progress';
import { IncompleteCircle } from 'components/v2/activities-progress-circle/incomplete';

import {
  style20pxRegularGreen,
  style20pxRegularBlue,
  style16pxRegularBluegreyD,
  style20pxRegularGreyL,
} from 'component-lib/type/text/styles';


const GenericsProgressSavedView = ({ percentageComplete, title, subtitle, progressState }) => {
  const getIconContent = () => {
    switch (progressState) {
      case 'complete': return <CompleteCircle />;
      case 'in-progress': return <InProgressCircle percentageComplete = { percentageComplete || 75 } />;
      case 'incomplete': return <IncompleteCircle percentageComplete = { percentageComplete } />;
      default: throw Error(`Unexpected progressState: ${progressState}`);
    }
  };

  const getTitleStyle = () => {
    switch (progressState) {
      case 'complete': return style20pxRegularGreen;
      case 'in-progress': return style20pxRegularBlue;
      case 'incomplete': return style20pxRegularGreyL;
      default: throw Error(`Unexpected progressState: ${progressState}`);
    }
  };

  return (
    <div className = "v2-generics-progress-saved-view">
      <div className = "v2-generics-progress-saved-view__icon">
        { getIconContent() }
      </div>
      <div className = "v2-generics-progress-saved-view__text">
        <Text { ...getTitleStyle() } text = { title } />
        <Text { ...style16pxRegularBluegreyD } text = { subtitle } />
      </div>
    </div>
  );
};

export default GenericsProgressSavedView;
