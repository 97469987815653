import React from 'react';

import Common from 'pages/v2/profile/common';
import { defined } from 'lib/helpers';
import { ACCESS_DENIED } from 'lib/paddl-error';
import { EMPLOYER } from 'lib/api/profiles/profile';
import ProfileExtra from 'lib/api/profiles/profile-extra';

import Timeline from 'components/v2/activities/generics/timeline';

import AccessAllExperience from 'components/v2/public-profile/access-all-experience';
import StartJourneyBanner from 'components/v2/public-profile/start-journey-banner';
import PublicProfileEmptyState from 'components/v2/public-profile/empty-state';

import Header, {
  SUMMARY_TAB,
  EXPERIENCE_TAB,
} from 'components/v2/profile/header';

import './index.scss';

export class PublicExperiencePage extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this.state = {
      profile: Common.createEmptyProfile(),
      profileExtra: ProfileExtra.createEmpty(),
      visibilitySettings: {},
      activities: null,
    };

    this._fetchProfile = (idOrSlug) => {
      appv2.api.profiles
        .fetchWithExtraByIdOrSlug({ idOrSlug, isLoggedIn: false })
        .then(({ profile, profileExtra, visibilitySettings }) => {
          const publicSuffix = (window.location.search.indexOf('public=1') > -1 ? '?public=1' : '');

          const baseUrl = appv2.slugs.generateProfilePath({
            id: profile.get('id'),
            slug: profile.get('urlSlug'),
          });

          if (profile.get('role') === EMPLOYER) {
            return appv2.ui.redirect({ url: `${baseUrl}${publicSuffix}` });
          }

          if (profile.get('urlSlug') && idOrSlug !== profile.get('urlSlug')) {
            return appv2.ui.redirect({ url: `${baseUrl}/experience${publicSuffix}` });
          }

          if (visibilitySettings.master === false) {
            return appv2.ui.redirect({ url: '/not-found' });
          }

          this.setState({ profile, profileExtra, visibilitySettings });

          appv2.api.activities
            .fetchTimeline({ profileId: profile.get('id'), isXpFeatureEnabled: false, isLoggedIn: false })
            .then((activities) => {
              this.setState({ activities });
            });
        })
        .catch((error) => {
          if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
            return appv2.ui.redirect({ url: '/not-found' });
          }

          throw error;
        });
    };
  }

  componentDidMount() {
    this._fetchProfile(defined(this.props.params.id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this._fetchProfile(defined(this.props.params.id));
  }

  render() {
    const { appv2 } = this.props;
    const { profile, profileExtra, activities } = this.state;

    const onTabSwitch = (tab) => {
      const publicSuffix = (window.location.search.indexOf('public=1') > -1 ? '?public=1' : '');

      const baseUrl = appv2.slugs.generateProfilePath({
        id: profile.get('id'),
        slug: profile.get('urlSlug'),
      });

      if (tab === SUMMARY_TAB) {
        return appv2.ui.redirect({ url: `${baseUrl}/summary${publicSuffix}` });
      }

      throw Error('Unexpected TAB');
    };

    const renderActivity = (appv2, activity) => (
      <Timeline
        key={activity.get('id')}
        appv2={appv2}
        activity={activity}
        canEdit={false}
        canOpenModal
      />
    );

    const renderActivitiesPanel = ({ appv2, activities }) => {
      if (activities && activities.length > 0) {
        return (
          activities.map((activity) => (
            <div key={activity.get('id')} className="v2-public-experience-page__activity">
              { renderActivity(appv2, activity) }
            </div>
          ))
        );
      }

      return (
        <PublicProfileEmptyState
          iconSrc={require('images/v2/icon-cards.svg')}
          description="Paddl Profiles use 'Activity Cards' to capture valuable experience."
          link="https://blog.paddljobs.com/blog/paddl-launches-activity-cards-helping-you-collect-real-world-experience-and-showcase-it-like-never-before"
          target="_blank"
        />
      );
    };

    if (Common.isProfileEmpty({ profile })) {
      return null;
    }

    return (
      <div className="v2-public-experience-page">
        <Header
          appv2={appv2}
          profile={profile}
          profileExtra={profileExtra}
          canEdit={false}
          currentTab={EXPERIENCE_TAB}
          onTabSwitch={onTabSwitch}
          secondTabPlaceholderText={'Experience'}
        />

        <AccessAllExperience profile={profile} />

        <div className="v2-public-experience-page__page-wrapper">
          { renderActivitiesPanel({ appv2, activities }) }
        </div>
        <StartJourneyBanner />
      </div>
    );
  }
}
