import './index.scss';
import React from 'react';

import Text   from 'component-lib/type/text';
import ImgTag from 'component-lib/components/img-tag';

import {
  style16pxMediumBluegrey,
  style16pxMediumBluegreyD,
  style16pxLightBluegreyD,
  style20pxRegularGrey,
  style14pxLightGreyL,
  style16pxLightBluegrey,
} from 'component-lib/type/text/styles';

export const QualificationDetailsPanelContent = ({
  issuerLogo,
  issuer,
  recipientName,
  qualificationName,
  signatures,
  verificationDate,
  courseCode,
}) => {
  const renderSignature = (signature, index) => {
    return (
      <div className = "v2-qualification-details-panel-content__signature-info" key = { index }>
        <ImgTag src = { signature.img } />
        <Text { ...style16pxMediumBluegreyD } text = { signature.title } />
        <Text { ...style14pxLightGreyL } text = { issuer } />
      </div>
    );
  };

  return (
    <div className = "v2-qualification-details-panel-content">
      <div className = "v2-qualification-details-panel-content__logo">
        <ImgTag src = { issuerLogo } />
      </div>

      <div className = "v2-qualification-details-panel-content__statement">
        <Text { ...style16pxLightBluegreyD } text = { `In the name of the Board of Trustees of ${ issuer } and by the Authority of the same be it known that` } />
        <Text { ...style20pxRegularGrey } text = { recipientName } />
        <Text { ...style16pxLightBluegreyD } text = "having fulfilled all the requirements has this day been admitted to the" />
        <Text { ...style20pxRegularGrey } text = { qualificationName } />
        <Text { ...style16pxLightBluegreyD } text = "with all privileges attached to the same and the Board of Trustees has authorised the Seal of the University to become hereunto affixed" />
      </div>

      <div className = "v2-qualification-details-panel-content__signatures">
        { signatures.map((signature, index) => renderSignature(signature, index)) }
      </div>

      <div className = "v2-qualification-details-panel-content__verified-panel">
        <div className = "v2-qualification-details-panel-content__verification">
          <ImgTag width = '24px' src = { require('../../svg-icon/svgs/verified.svg') } />
          <Text { ...style16pxMediumBluegreyD } text = "Verified:" />
          <Text { ...style16pxLightBluegreyD } text = { verificationDate } />
        </div>
        <div className = "v2-qualification-details-panel-content__course-code">
          <Text { ...style16pxLightBluegrey } text = "Course Code:" />
          <Text { ...style16pxMediumBluegrey } text = { courseCode } />
        </div>
      </div>
    </div>
  );
};
