import { defined } from 'lib/helpers';

class CertificateType {
  constructor(value) {
    const expectedValues = [
      null,
      CertificateType.GENERAL,
      CertificateType.CERT_I,
      CertificateType.CERT_II,
      CertificateType.CERT_III,
      CertificateType.CERT_IV,
      CertificateType.POST_GRAD,
      CertificateType.POST_BAC,
      CertificateType.POST_MST,
      CertificateType.SPECIALIST,
    ];

    if (expectedValues.indexOf(defined(value)) === -1) {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new CertificateType(null);
  }

  toApiFormat() {
    switch (this.value) {
      case CertificateType.GENERAL:    return 'GENERAL';
      case CertificateType.CERT_I:     return 'CERT_I';
      case CertificateType.CERT_II:    return 'CERT_II';
      case CertificateType.CERT_III:   return 'CERT_III';
      case CertificateType.CERT_IV:    return 'CERT_IV';
      case CertificateType.POST_GRAD:  return 'POST_GRAD';
      case CertificateType.POST_BAC:   return 'POST_BAC';
      case CertificateType.POST_MST:   return 'POST_MST';
      case CertificateType.SPECIALIST: return 'SPECIALIST';
      default:                         throw Error('Unexpected value');
    }
  }

  static fromApiFormat(data) {
    const value = (() => {
      switch (data) {
        case 'GENERAL':    return CertificateType.GENERAL;
        case 'CERT_I':     return CertificateType.CERT_I;
        case 'CERT_II':    return CertificateType.CERT_II;
        case 'CERT_III':   return CertificateType.CERT_III;
        case 'CERT_IV':    return CertificateType.CERT_IV;
        case 'POST_GRAD':  return CertificateType.POST_GRAD;
        case 'POST_BAC':   return CertificateType.POST_BAC;
        case 'POST_MST':   return CertificateType.POST_MST;
        case 'SPECIALIST': return CertificateType.SPECIALIST;
        default:           throw Error('Unexpected value');
      }
    })();

    return new CertificateType(value);
  }
}

CertificateType.GENERAL    = 0;
CertificateType.CERT_I     = 1;
CertificateType.CERT_II    = 2;
CertificateType.CERT_III   = 3;
CertificateType.CERT_IV    = 4;
CertificateType.POST_GRAD  = 5;
CertificateType.POST_BAC   = 6;
CertificateType.POST_MST   = 7;
CertificateType.SPECIALIST = 8;

export default CertificateType;
