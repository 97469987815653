import './index.scss';

import React                 from 'react';
import { defined }           from 'lib/helpers';
import { STUDENT, EMPLOYER } from 'lib/api/profiles/profile';
import XpAmountLarge         from 'component-lib/components/xp-amount-large';
import HeaderLayout          from 'components/v2/profile/header-layout';

// common tabs:
export const SUMMARY_TAB = 0;

// student tabs:
export const EXPERIENCE_TAB = 1;

// employer tabs:
export const CAMPAIGNS_TAB = 2;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalXpNumber: null,
    };
  }

  componentDidMount() {
    const { appv2, profile } = this.props;
    const profileId = profile.get('id');

    if (profileId === null) { return }

    appv2.api.profiles
      .fetchTotalXpNumber({ id: profileId })
      .then((totalXpNumber) => {
        this.setState({ totalXpNumber });
      })
      .catch((error) => {
        throw error;
      });
  }

  componentDidUpdate(prevProps) {
    const { appv2, profile } = this.props;
    const profileId = profile.get('id');

    if (profile === prevProps.profile) { return }

    appv2.api.profiles
      .fetchTotalXpNumber({ id: profileId })
      .then((totalXpNumber) => {
        this.setState({ totalXpNumber });
      })
      .catch((error) => {
        throw error;
      });
  }

  render() {
    const {
      appv2,
      profile,
      profileExtra,
      canEdit,
      currentTab,
      onTabSwitch,
      secondTabPlaceholderText,
    } = this.props;

    const renderSummaryTab = (currentTab, onTabSwitch) => {
      if (currentTab === SUMMARY_TAB) {
        return (
          <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab v2-profile-header__tab--active">
            <div className = "column">
              <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--summary">&nbsp;</div>
              <div className = "v2-profile-header__tab-label">Summary</div>
            </div>
          </div>
        );
      }

      return (
        <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab" onClick = { () => { onTabSwitch(SUMMARY_TAB); } }>
          <div className = "column">
            <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--summary">&nbsp;</div>
            <div className = "v2-profile-header__tab-label">Summary</div>
          </div>
        </div>
      );
    };

    const renderExperienceTab = (appv2, totalXpNumber, currentTab, onTabSwitch) => {
      const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

      if (currentTab === EXPERIENCE_TAB) {
        return (
          <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab v2-profile-header__tab--active">
            <div className = "column">
              {
                (isXpFeatureEnabled && totalXpNumber) ?
                <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--experience"><XpAmountLarge xpNumber = { totalXpNumber } /></div> :
                <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--experience-placeholder">&nbsp;</div>
              }
              <div className = "v2-profile-header__tab-label">Experience</div>
            </div>
          </div>
        );
      }

      return (
        <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab" onClick = { () => { onTabSwitch(EXPERIENCE_TAB); } }>
          <div className = "column">
            {
              (isXpFeatureEnabled && totalXpNumber) ?
              <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--experience"><XpAmountLarge xpNumber = { totalXpNumber } /></div> :
              <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--experience-placeholder">&nbsp;</div>
            }
            <div className = "v2-profile-header__tab-label">Experience</div>
          </div>
        </div>
      );
    };

    const renderCampaignTab = (appv2, profile, currentTab, onTabSwitch) => {
      const profileId = profile.get('id');
      const numberOfCampaigns = appv2.campaigns.campaignsOfEmployer({ profileId }).length;

      if (currentTab === CAMPAIGNS_TAB) {
        return (
          <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab v2-profile-header__tab--active">
            <div className = "column">
              <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--campaign">{ numberOfCampaigns }</div>
              <div className = "v2-profile-header__tab-label">Campaigns</div>
            </div>
          </div>
        );
      }

      return (
        <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab" onClick = { () => { onTabSwitch(CAMPAIGNS_TAB); } }>
          <div className = "column">
            <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--campaign">{ numberOfCampaigns }</div>
            <div className = "v2-profile-header__tab-label">Campaigns</div>
          </div>
        </div>
      );
    };

    const renderPlaceholderTab = (placeholderText) => {
      return (
        <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab">
          <div className = "column">
            <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--experience-placeholder">&nbsp;</div>
            <div className = "v2-profile-header__tab-label">{ placeholderText }</div>
          </div>
        </div>
      );
    };

    const renderAchievementsTab = () => {
      return (
        <div className = "columns is-gapless is-centered is-vcentered v2-profile-header__tab">
          <div className = "column">
            <div className = "v2-profile-header__tab-data v2-profile-header__tab-data--achievements">&nbsp;</div>
            <div className = "v2-profile-header__tab-label">&nbsp;</div>
          </div>
        </div>
      );
    };

    const renderRoleSpecificTab = (appv2, profile, currentTab, onTabSwitch, placeholderText) => {
      const { totalXpNumber } = this.state;

      switch (profile.get('role')) {
        case STUDENT:  return (renderExperienceTab(appv2, totalXpNumber, currentTab, onTabSwitch));
        case EMPLOYER: return (renderCampaignTab(appv2, profile, currentTab, onTabSwitch));
        case null:     return (renderPlaceholderTab(placeholderText));
        default:       throw Error('Unexpected role');
      }
    };

    const fetchAvatarImage = () => {
      const id        = profile.get('id');
      const role      = profile.get('role');
      const avatarUrl = profile.get('avatarUrl');

      const placeholderId = (role === 'student')
        ? `${role}-${id % 5 + 1}`
        : 'employer';

      const avatarImage = avatarUrl || require(`images/placeholder--avatar-${placeholderId}.svg`);

      return avatarImage;
    };

    const renderAvatar = () => {
      return (
        <img
          src = { fetchAvatarImage() }
          className = 'v2-profile-header__hero-avatar-img'
        />
      );
    };

    const renderInfo = () => {
      return (
        <div className = "v2-profile-header__hero-info">
          <div className = "v2-profile-header__hero-info-title">
            {
              (profile.get('role') == EMPLOYER)
              ? profile.get('businessName')
              : profile.get('fullName')
            }
          </div>
          {
            (profileExtra.get('location').shortText) &&
            <div className = "v2-profile-header__hero-info-subtitle">
              <img
                className = 'v2-profile-header__hero-icon'
                src       = { require('images/v2/icon-location-header.svg') }
                alt       = ""
              />
              { profileExtra.get('location').shortText }
            </div>
          }
        </div>
      );
    };

    const renderTabs = () => {
      return (
        <div className = "columns is-gapless is-mobile">
          <div className = "column is-one-third v2-profile-header__tab-item">
            { renderSummaryTab(currentTab, onTabSwitch) }
          </div>
          <div className = "column is-one-third v2-profile-header__tab-item">
            {
              renderRoleSpecificTab(
                appv2,
                profile,
                currentTab,
                onTabSwitch,
                secondTabPlaceholderText
              )
            }
          </div>
          <div className = "column is-one-third v2-profile-header__tab-item">
            { /* renderAchievementsTab() */ }
          </div>
        </div>
      );
    };

    const AvatarUploader = appv2.v1deps['components/avatar-uploader'].default;

    const onAvatarUpdate = () => {
      appv2.ui.displaySnackBar({
        style:    'green',
        text:     'Picture saved!',
        callback: () => { appv2.ui.refreshPage() },
      });
    };

    return (
      <div className = "v2-profile-header">
        {
          AvatarUploader &&
          <AvatarUploader onSubmit = { onAvatarUpdate } />
        }

        <HeaderLayout
          appv2 = { appv2 }
          canEdit = { defined(canEdit) }
          renderAvatar = { renderAvatar }
          renderInfo = { renderInfo }
          renderTabs = { renderTabs }
          bannerImageUrl = { profile.get('bannerImageUrl') }
        />
      </div>
    );
  }
};

export default Header;
