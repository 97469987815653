import './index.scss';
import React from 'react';

const resize = (target) => {
  if (!target) return;

  target.style.height = 'auto';
  target.style.height = `${target.scrollHeight}px`;
};

class InputTextMultiline extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    resize(this.multilineInput);

    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.multilineInput.focus();
      }, 0);
    }
  }

  componentDidUpdate() {
    resize(this.multilineInput);
  }

  render() {
    const {
      name,
      label,
      placeholder,
      value,
      message,
      isError,
      hasHint,
      maxLength,
      onChange,
      isReadonly,
      isAutoFocused
    } = this.props;

    const renderMessage = ({ message, isError, hasHint }) => {
      if (message === null) {
        return null;
      }

      const determineClassName = (isError, hasHint) => {
        if (isError) {
          return 'v2-input-text-multiline__message v2-input-text-multiline__message--error';
        }

        if (hasHint) {
          return 'v2-input-text-multiline__message v2-input-text-multiline__message--hint';
        }

        return 'v2-input-text-multiline__message';
      }

      return(
        <div className = { determineClassName(isError, hasHint) }>
          { message }
        </div>
      );
    };

    return(
      <div className = "v2-input-text-multiline">
        <label className = "v2-input-text-multiline__label" htmlFor = { name }>
          { label }
        </label>
        <textarea
          ref = { (realInputElementOnPage) => { this.multilineInput = realInputElementOnPage; } }
          rows        = "1"
          type        = "text"
          className   = {`v2-input-text-multiline__input ${isError ? 'v2-input-text-multiline__input--error' : ''}` }
          maxLength   = { maxLength }
          name        = { name }
          placeholder = { placeholder }
          value       = { value }
          onChange    = { (event) => { onChange(event.target.value) } }
          disabled    = { isReadonly ? "disabled" : false }
        />
        { renderMessage({ message, isError, hasHint }) }
      </div>
    );
  }
};

export default InputTextMultiline;
