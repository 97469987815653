import './index.scss';
import React from 'react';

import XPEntryDetailsTitle       from 'component-lib/components/xp-entry-details-title';
import XpEntryLocationLogo       from 'component-lib/components/xp-entry-location-logo';
import LinkWrapper               from 'component-lib/components/link-wrapper';
import XpTags                    from 'component-lib/components/xp-tags';
import { levelOptions }          from 'components/v2/activities/categories/employment/variants/full-time-part-time-contract/levelOptions';
import { responsibilityOptions } from 'components/v2/activities/categories/employment/variants/full-time-part-time-contract/responsibilityOptions';
import LevelResponsibility       from 'component-lib/components/level-responsibility';
import { getProviderIcon }       from 'components/v2/activities/generics/provider-icon/helper';

import { getPrimaryIndustry }            from 'components/v2/activities/categories/employment/fields/primary-industry/helper';
import { getHoursPerWeek }               from 'components/v2/activities/categories/employment/fields/hours-per-week/helper';
import { getAttendanceInHouseAndRemote } from 'components/v2/activities/categories/employment/fields/attendance/in-house-and-remote/helper';

class FullTimePartTimeContractDetailsPanelView extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      profilePath:   null,
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const roleName                            = detailsData.getField('role').name;
    const workingLocation                     = detailsData.getField('workingLocation').shortText;
    const { name, profileId, icon, location } = detailsData.getField('employer');
    const description                         = detailsData.getField('description');
    const primaryIndustryId                   = detailsData.getField('primaryIndustryId');
    const level                               = detailsData.getField('level');
    const responsibility                      = detailsData.getField('responsibility');
    const hoursPerWeek                        = detailsData.getField('hoursPerWeek');
    const attendanceInHouse                   = detailsData.getField('attendanceInHouse');
    const attendanceRemote                    = detailsData.getField('attendanceRemote');

    const levelTitle = levelOptions
      .find((e) => (e.value === level))
      .title;

    const responsibilityTitle = responsibilityOptions
      .find((e) => (e.value === responsibility))
      .title;

    const profilePath = this.state.profilePath ||
      (
        profileId && window._appv2.slugs.generateProfilePath({
          id:       profileId,
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    const profileLink = profilePath && (profilePath + '/summary');

    return (
      <div className = "v2-full-time-part-time-contract-details-panel-view">
        <div className = "v2-full-time-part-time-contract-details-panel-view__subpanel">
          <XPEntryDetailsTitle
            title    = { roleName }
            subtitle = { workingLocation }
          />
        </div>

        <div className = "v2-full-time-part-time-contract-details-panel-view__subpanel">
          <LinkWrapper link = { profileLink } >
            <XpEntryLocationLogo
              iconSrc  = { getProviderIcon({ icon, profileId }) }
              name     = { name }
              location = { location.shortText }
            />
          </LinkWrapper>
        </div>

        <div className = "v2-full-time-part-time-contract-details-panel-view__subpanel">
          <LevelResponsibility
            level          = { levelTitle }
            responsibility = { responsibilityTitle }
          />
        </div>

        {
          description &&
          <div className = "v2-full-time-part-time-contract-details-panel-view__subpanel">
            <div className = "v2-full-time-part-time-contract-details-panel-view__description">
              { description }
            </div>
          </div>
        }

        <div className = "v2-full-time-part-time-contract-details-panel-view__subpanel">
          <XpTags
            strings = { [
              getPrimaryIndustry({ primaryIndustryId }),
              getHoursPerWeek({ hoursPerWeek }),
              getAttendanceInHouseAndRemote({ attendanceInHouse, attendanceRemote }),
            ] }
          />
        </div>
      </div>
    );
  }
}

export default FullTimePartTimeContractDetailsPanelView;
