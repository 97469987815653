import React from 'react';
import { Modal } from 'components/v2/modal';
import { defined } from 'lib/helpers';

import CardExisting from 'components/v2/activities/generics/card/existing';

export class ModalExistingActivity extends React.Component {
  constructor(props) {
    super(props);

    const ALERT_MESSAGE = `There is unsaved information on this card, changes will not be saved.\n\nAre you sure you want to leave this page?`;

    this.shouldAlertOnLeave = () => (false);
    // this will be overridden by the internal component
    // modalObject.shouldAlertOnLeave in CardExisting
    // src/components/v2/activities/generics/card/existing.jsx:52
    // FIXME: Refactor to untangle ModalExistingActivity & CardExisting

    this._confirmPageClose = (event) => {
      if (this.shouldAlertOnLeave()) {
        event.preventDefault();
        event.returnValue = ALERT_MESSAGE;
        return event.returnValue;
      }
    };

    this._confirmModalClose = () => {
      if (this.shouldAlertOnLeave()) {
        return window.confirm(ALERT_MESSAGE);
      }

      return true;
    };
  }

  componentDidMount() {
    window.onbeforeunload = this._confirmPageClose;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  render() {
    const {
      appv2,
      activityId,
      variantId,
      openSection
    } = this.props;

    const closeFunction = () => {
      if (this._confirmModalClose()) {
        appv2.ui.closeModal();
      }
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { true }
        windowWidthSize = "large"
      >
        <CardExisting
          appv2       = { appv2 }
          activityId  = { defined(activityId) }
          variantId   = { variantId }
          openSection = { openSection }
          onSubmit    = { () => { appv2.ui.closeModal(); appv2.ui.refreshPage(); } }
          onRemove    = { () => { appv2.ui.closeModal(); appv2.ui.refreshPage(); } }
          modalObject = { this }
        />
      </Modal>
    );
  }
}
