import { defined } from 'lib/helpers';
import * as ffModule from 'modules/v2/feature-flags';

class FeatureFlags {
  constructor({ store, apiTransport }) {
    this._store = defined(store);
    this._transport = defined(apiTransport);
  }

  refreshAll() {
    const { dispatch } = this._store;

    const args = {
      endpoint: '/feature-flags',
      method:   'GET',
    };

    return this._transport.makeRequest(args)
      .then((response) => {
        dispatch(ffModule.actionCreators.refresh(response));
        return true;
      });
  }

  isEnabled({ featureName }) {
    const currentState = this._store.getState();

    if (featureName in currentState.v2.featureFlags) {
      return currentState.v2.featureFlags[featureName];
    }

    throw new Error('Feature name not found in current state');
  }
}

export default FeatureFlags;
