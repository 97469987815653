const polarToCartesian = (svg_size, radius, angle) => {
  const angleInRadians = (angle - 90) * Math.PI / 180.0;

  return {
    x: svg_size / 2 + (radius * Math.cos(angleInRadians)),
    y: svg_size / 2 + (radius * Math.sin(angleInRadians))
  };
};

export const getPath = ({ svg_size, radius, percentageComplete }) => {
  if (percentageComplete > 100 || percentageComplete < 0) throw Error('invalid completion range');

  const left        = percentageComplete / 100;
  const startAgngle = 0;

  // 360 * left == 1 doesn't show any circle, hence a value smaller than 1 is used
  const endAngle  = 360 * Math.min(left, 0.9999);

  const start = polarToCartesian(svg_size, radius, endAngle);
  const end   = polarToCartesian(svg_size, radius, startAgngle);

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, endAngle <= 180 ? '0' : '1', 0, end.x, end.y
  ].join(' ');

  return d + (percentageComplete === 100 ? ' Z' : ''); // to remove any gap and close the loop
};
