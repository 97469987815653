import React from 'react';

import { defined } from 'lib/helpers';
import { V2PublicNavbar } from 'components/v2/public-navbar';
import { V2PublicNavbarPageActions } from 'components/v2/public-navbar-page-actions';
import { PageFooter } from 'components/v2/page-footer';
import { ModalConductor } from 'components/v2/modal-conductor';

import './index.scss';

export class V2PublicProfile extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this.state = {
      currentModalComponent: null,
      currentModalProps: null,
    };

    appv2.ui.setModalHandlers({
      open: ({ modalComponent, modalProps }) => {
        this.setState({
          currentModalComponent: defined(modalComponent),
          currentModalProps: defined(modalProps),
        });
      },
      close: () => {
        this.setState({
          currentModalComponent: null,
          currentModalProps: null,
        });
      },
    });
  }

  render() {
    const {
      appv2,
      children,
      params,
    } = this.props;

    const {
      currentModalComponent,
      currentModalProps,
    } = this.state;

    return (
      <div className="v2-public-profile">
        <div className="v2-public-profile__page-header">
          <V2PublicNavbar>
            <V2PublicNavbarPageActions
              appv2={appv2}
              params={params}
            />
          </V2PublicNavbar>
        </div>

        <div className="v2-public-profile__page-body">
          { React.cloneElement(children, this.props) }
        </div>

        <div className="v2-public-profile__page-footer">
          <PageFooter />
        </div>

        <ModalConductor
          appv2={appv2}
          currentModalComponent={currentModalComponent}
          currentModalProps={currentModalProps}
        />
      </div>
    );
  }
}
