import { defined } from 'lib/helpers';

class Text {
  constructor(value) {
    if (typeof defined(value) !== 'string') {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === '');
  }

  isValid() {
    return (this.value !== '');
  }

  static createEmpty() {
    return new Text('');
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new Text(data);
  }
}

export default Text;
