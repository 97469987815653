import { defined, DefinedMap } from 'lib/helpers';
import VariantMapping from 'lib/api/variant-mapping';
import createDetailsClass from 'lib/api/activities/generics/create-details-class';

const getVariantHandler = (variantId) => {
  return VariantMapping.getFor({ variantId });
};

class Activity extends DefinedMap {
  constructor(data) {
    super({
      attributes: [
        'id',
        'profileId',
        'variantId',
        'details',
        'skillset',
        'duration',
        'xpNumber',
      ],
      data
    });
  }

  toApiFormat() {
    const details  = this.get('details').toApiFormat();
    const skillset = this.get('skillset').toApiFormat();
    const duration = this.get('duration').toApiFormat();

    // When a variant has no 'percentageComplete' field in duration data, it should pass null to BE api.
    // E.g. variant with indefinite progress.
    const percentageComplete = duration.hasOwnProperty('percentageComplete') ? duration.percentageComplete : null;

    return {
      ...this.toObject(),
      details,
      skillset,
      duration,
      percentageComplete,
    };
  }

  static fromApiFormat(data, { isXpFeatureEnabled = false }) {
    const variantHandler = getVariantHandler(defined(data.variantId));

    const detailsHandler = createDetailsClass({ attributes: variantHandler.detailsAttributes() });
    const details        = detailsHandler.fromApiFormat(defined(data.details));

    const skillset = variantHandler
      .skillsetHandler()
      .fromApiFormat(defined(data.skillset));

    const durationWithPercentageComplete = Object.assign(data.duration, { percentageComplete: data.percentageComplete });

    const duration = variantHandler
      .durationHandler()
      .fromApiFormat(defined(durationWithPercentageComplete));

    return new Activity({
      ...data,
      details,
      skillset,
      duration,
      ...(isXpFeatureEnabled ? {} : { xpNumber: 0 }),
    });
  }
}

export default Activity;
