import './index.scss';
import React from 'react';

const CampusReadonly = ({campus}) => {
  return (
    <div className = "v2-campus-readonly">
      { campus.name }
    </div>
  );
}

export default CampusReadonly;
