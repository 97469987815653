import React from 'react';

const CollectedIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#A5FEDE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M41.7558 17.5L22.8344 36.0768L14.2518 27.6431L12.25 29.6084L22.8344 40L43.75 19.4654L41.7558 17.5Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28 2.33333C13.8247 2.33333 2.33333 13.8247 2.33333 28C2.33333 42.1753 13.8247 53.6667 28 53.6667C42.1753 53.6667 53.6667 42.1753 53.6667 28C53.6667 13.8247 42.1753 2.33333 28 2.33333ZM0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z" fill="#A5FEDE"/>
    </svg>
  );
};

export default CollectedIcon;
