export const getAttendanceInHouseAndRemote = ({ attendanceInHouse, attendanceRemote }) => {
  const attendanceArray = [
    { value: attendanceInHouse, string: 'In-House' },
    { value: attendanceRemote,  string: 'Remote'   },
  ];

  const filteredAttendance = attendanceArray.filter(attendance => (attendance.value));
  const mappedAttendance   = filteredAttendance.map(attendance => (attendance.string));

  return mappedAttendance.join(" / ");
};
