const INITIAL_RENDERABLE_VARIANT_IDS = [1, 2, 5, 6, 7, 9, 10, 11, 12];
const MIN_CARDS_TO_RENDER = 5;

export class Data {
  constructor({component}) {
    this._component = component;

    this.initializeState = () => {
      this._component.setState({
        orderedVariantIds: INITIAL_RENDERABLE_VARIANT_IDS,
        hiddenVariantIds: [],
      });
    };

    this._hideVariantByMoveToHidden = (variantIdToHide, variantIds, hiddenVariantIds) => {
      const filteredVariantIds = variantIds.filter((e) => { return e !== variantIdToHide; });
      const newHiddenVariantIds = hiddenVariantIds.concat(variantIdToHide);

      this._component.setState({
        orderedVariantIds: filteredVariantIds,
        hiddenVariantIds: newHiddenVariantIds,
      });
    };

    this._hideVariantBySwapWithHidden = (variantIdToHide, variantIds, hiddenVariantIds) => {
      const unhiddenVariantId   = hiddenVariantIds[0];
      const newHiddenVariantIds = hiddenVariantIds.slice(1, hiddenVariantIds.length).concat(variantIdToHide);
      const replaceVariantIds   = variantIds.map((id) => (id === variantIdToHide ? unhiddenVariantId : id));

      this._component.setState({
        orderedVariantIds: replaceVariantIds,
        hiddenVariantIds: newHiddenVariantIds,
      });
    };

    this.hideVariantId = ({variantId}) => {
      const hiddenVariantIds            = this._component.state.hiddenVariantIds;
      const newVariantIds               = this._component.state.orderedVariantIds;

      if (newVariantIds.length > MIN_CARDS_TO_RENDER) {
        this._hideVariantByMoveToHidden(variantId, newVariantIds, hiddenVariantIds);
      } else {
        this._hideVariantBySwapWithHidden(variantId, newVariantIds, hiddenVariantIds);
      }
    };

    this.getVariantIds = () => {
      return this._component.state.orderedVariantIds;
    };
  }
}
