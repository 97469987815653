import React     from 'react';
import pluralize from 'pluralize';

import Text                     from 'component-lib/type/text';
import { style16pxMediumGreyL } from 'component-lib/type/text/styles';

const TimelineSkillsCount = ({ skillsCount }) => {
  if (typeof skillsCount !== 'number') return null;

  const text = pluralize('Skill', skillsCount, true);

  return (
    <div className = "timeline-skills-count">
      <Text { ...style16pxMediumGreyL } text = { text } />
    </div>
  );
};

export default TimelineSkillsCount;
