import React from 'react';

import { TermsPageHeroSection } from 'component-lib/components/terms-page-hero-section';
import { TermsContent } from 'components/v2/terms-content';
import { LandingPageBackgroundArtwork } from 'components/v2/landing-page-background-artwork';

import './index.scss';

export const PublicTermsPage = () => (
  <div className="v2-public-terms-page">
    <TermsPageHeroSection />
    <TermsContent />
    <LandingPageBackgroundArtwork />
  </div>
);
