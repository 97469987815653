export const actionTypes = {
  SET_MAPPING: 'v2/slugs/SET_MAPPING',
};

export const actionCreators = {
  setMapping: (data) => ({
    type: actionTypes.SET_MAPPING,
    data
  }),
};

const INITIAL_STATE = {
  byId:   {},
  bySlug: {},
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_MAPPING:
      const { id, slug } = action.data;

      const byIdUpdate = { [id]: slug };
      const bySlugUpdate = (slug === null) ? {} : { [slug]: id };

      return {
        byId: {
          ...state.byId,
          ...byIdUpdate,
        },
        bySlug: {
          ...state.bySlug,
          ...bySlugUpdate,
        },
      };
    default:
      return state;
  }
};
