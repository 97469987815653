import './index.scss';
import React from 'react';

import VariantMapping       from 'lib/api/variant-mapping';
import RemoveActivityButton from 'components/v2/buttons/square-buttons/remove-activity-button';
import XpFooterAmount       from 'component-lib/components/xp-footer-amount';

class CardFooterExisting extends React.Component {
  constructor(props) {
    const { variantId } = props;
    super(props);

    this._variantHandler = VariantMapping.getFor({ variantId });

    this.state = {
      isConfirming: false,
    };

    this._renderConfirmation = () => {
      const onCancel = () => {
        this.setState({
          isConfirming: false,
        });
      };

      return (
        <div className = "v2-card-footer-existing__confirmation">
          <div className = "v2-card-footer-existing__confirmation-container">
            <div className = "columns is-vcentered">
              <div className = "column">
                <div className = "v2-card-footer-existing__confirmation-question">
                  Are you sure you want to remove this activity?
                </div>
                <div>
                <span className = "v2-card-footer-existing__confirmation-cancel" onClick = { onCancel } >
                  Cancel Remove
                </span>
                </div>
              </div>

              <div className = "column is-one-quarter">
                <div className = "v2-card-footer-existing__confirmation-remove-button">
                  <RemoveActivityButton onClick = { this.props.onRemove } />
                </div>
                <div className = "v2-card-footer-existing__confirmation-remove-comment">
                  Cannot be Undone
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    this._renderInitialState = () => {
      const onRemoveAttempt = () => {
        this.setState({
          isConfirming: true,
        });
      };

      const footerClass = this._variantHandler.categoryCssClassPrefix() + '--footer-existing';

      const isXpFeatureEnabled = window._appv2 &&
        window._appv2.featureFlags.isEnabled({ featureName: 'xp' });

      return (
        <div className = { "v2-card-footer-existing " + footerClass } >
          <div className = "v2-card-footer-existing__container">
            <div className = "v2-card-footer-existing__row">
              <div className = "v2-card-footer-existing__col">
                {
                  this.props.canEdit &&
                  <div className = "v2-card-footer-existing__remove-activity" onClick = { onRemoveAttempt }>
                    Remove Activity
                  </div>
                }
              </div>

              { isXpFeatureEnabled &&
                <div className = "v2-card-footer-existing__col">
                  <XpFooterAmount xpNumber = { this.props.xpNumber } />
                </div>
              }
            </div>
          </div>
        </div>
      );
    };
  }

  render() {
    if (this.state.isConfirming) {
      return this._renderConfirmation();
    }

    return this._renderInitialState();
  }
}

export default CardFooterExisting;
