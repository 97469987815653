import { Swatches }   from 'component-lib/swatches';
import Moment         from 'lib/helpers/moment';
import { IssuerData } from 'lib/api/var-edu/verified/issuer-data';

export const getCourseUnitDetailsPanelViewProps = ({ activity, issuerProfile }) => {
  const details = activity.get('details');

  const name        = details.get('name').value;
  const faculty     = details.get('faculty').value;
  const description = details.get('description').value;
  const intake      = details.get('intake').value;
  const code        = details.get('code').value;
  const campus      = details.get('campus').value;
  const issuerCode  = details.get('issuerCode').value;
  const verifiedAt  = details.get('verifiedAt');

  const { bannerImage, themeColor, sealImage, issuer } = IssuerData.getFor(issuerCode);

  const bannerBackgroundColor = Swatches.getHexCodeFor(themeColor);

  const { year, month, day } = verifiedAt;

  const verificationDate = Moment.format_D_MMMM_YYYY(
    new Date(year.value, month.value - 1, day.value)
  );

  const issuerProfileImage = (issuerProfile && issuerProfile.get('avatarUrl')) || require('images/placeholder--avatar-employer.svg');

  return {
    bannerImage,
    bannerBackgroundColor,
    courseName: name,
    faculty,
    description,
    intake,
    verificationDate,
    courseCode: code,
    issuerProfileImage,
    sealImage,
    issuer,
    location: campus,
  };
};
