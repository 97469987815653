import React from 'react';

import config from 'config';
import './index.scss';

export const PaddlLogo = ({ hasLink, hasName, customColor, customWidthInPx }) => {
  const renderLogo = () => {
    const getFillColor = () => {
      switch (customColor) {
        case undefined:
        case 'plum-paddl': return '#5320A3'; // Color is defined in src/component-lib/_swatches.scss
        case 'new-gray': return '#797596'; // Color is defined in src/component-lib/_swatches.scss
        case 'white': return 'white';
        default: throw Error(`Unexpected customColor: ${customColor}`);
      }
    };

    const originalWidthInPx = hasName ? 137 : 48;
    const originalHeightInPx = 33;

    const scale = customWidthInPx
      ? (customWidthInPx / originalWidthInPx)
      : 1;

    return (
      <svg
        role="img"
        aria-labelledby="paddl-logo-desc"
        className="paddl-logo"
        width={originalWidthInPx * scale}
        height={originalHeightInPx * scale}
        viewBox={`0 0 ${originalWidthInPx} ${originalHeightInPx}`}
        fill={getFillColor()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc id="paddl-logo-desc">Track your experience with Paddl</desc>
        <path d="M43.5931 9.27598C45.7805 11.4633 47.1577 14.4608 47.4007 17.5393C47.4007 17.9038 47.4412 18.2279 47.4412 18.5924C47.4412 20.2937 47.0767 22.0355 46.4286 23.6152C45.7805 25.195 44.8083 26.6532 43.5931 27.8684C42.3779 29.0836 40.9197 30.0557 39.34 30.7038C37.7602 31.3924 36.0184 31.7165 34.3172 31.7165C32.6159 31.7165 30.8741 31.3519 29.2944 30.7038C27.7147 30.0557 26.2785 29.061 25.0633 27.8458C25.0613 27.8438 23.8103 26.5905 21.315 24.0952L21.0905 23.8706C20.9772 23.7573 20.9772 23.5736 21.0906 23.4604L24.775 19.7784C24.8885 19.665 25.0724 19.6652 25.1856 19.7789L25.2604 19.854L29.1324 23.7367C30.5096 25.114 32.3729 25.8836 34.3172 25.8836C36.2615 25.8836 38.1248 25.114 39.502 23.7367C40.8561 22.3826 41.6488 20.4962 41.6488 18.5519C41.6357 18.3255 41.6418 18.1985 41.6221 17.9584C41.4747 16.1563 40.7172 14.6228 39.502 13.3671L34.5223 8.38743C34.409 8.27416 34.2253 8.27413 34.1121 8.38741L23.9476 18.5519L19.8564 22.6836L10.7064 31.8336C10.6488 31.8912 10.5055 32.0245 10.5055 32.0245C10.5055 32.0245 10.3502 31.8887 10.2942 31.8318C8.13243 29.6354 5.93489 27.476 3.77541 25.3165C1.99313 23.5342 0.980469 21.0633 0.980469 18.5519C0.980469 16.081 1.99313 13.6102 3.77541 11.8279C5.51718 10.0456 8.02857 9.03294 10.4995 9.03294C12.9692 9.03294 15.4958 10.042 17.2375 11.8227L17.2422 11.8274C17.2754 11.8598 17.6728 12.2478 18.4398 13.0148L18.6382 13.2256C18.7457 13.3398 18.743 13.5188 18.632 13.6296L14.9368 17.3207C14.8236 17.4339 14.64 17.4338 14.5267 17.3206L14.3284 17.1222L13.1205 15.9143C12.4319 15.2257 11.4902 14.8299 10.5181 14.8299C9.50542 14.8299 8.55515 15.2304 7.86654 15.919C7.17794 16.6076 6.77287 17.5798 6.77287 18.5519C6.77287 19.5646 7.17794 20.4962 7.86654 21.1848L10.2943 23.6126C10.4076 23.7259 10.5913 23.7259 10.7046 23.6126L15.7653 18.5519L19.8564 14.4608L34.1134 0.203799C34.1706 0.146614 34.3237 0.00957128 34.3237 0.00957128C34.3237 0.00957128 34.4663 0.147282 34.5222 0.202521C37.5711 3.21522 40.5821 6.26497 43.5931 9.27598Z" />
        { hasName && <path d="M70.1194 18.8005C70.1194 13.4883 67.1577 10.7306 62.5409 10.7306C59.7244 10.7306 57.4014 11.8627 56.6465 13.4883L56.5303 11.079H52.7266V32.0664H56.6465V24.9255C57.7208 26.493 59.8986 27.1607 62.5119 27.1607C67.2158 27.1607 70.1194 24.2578 70.1194 18.8005ZM66.1705 19.0618C66.1705 21.9646 64.8638 23.9095 61.3504 23.9095C58.1273 23.9095 56.5303 22.2258 56.5303 19.0618C56.5303 16.1299 57.9822 14.214 61.3504 14.214C64.9219 14.214 66.1705 16.1589 66.1705 19.0618Z" /> }
        { hasName && <path d="M79.3722 10.9048C74.9877 10.9048 71.7647 13.9818 71.7647 19.265C71.7647 24.6352 74.8135 27.3348 79.3142 27.3348C82.0436 27.3348 84.3665 26.2608 85.2086 24.6352L85.6151 27.0445H89.2446C89.2446 27.0445 89.1285 25.9124 89.1285 25.0996C89.1285 21.6743 89.1285 10.8241 89.1285 10.8241H85.2086V13.14C84.1052 11.5724 81.8984 10.9048 79.3722 10.9048ZM80.5047 14.3011C83.8148 14.3011 85.3247 15.9847 85.3247 19.1779C85.3247 22.2549 83.6696 24.0546 80.5047 24.0546C77.1945 24.0546 75.7136 22.2839 75.7136 19.1779C75.7136 16.1589 77.3397 14.3011 80.5047 14.3011Z" /> }
        { hasName && <path d="M98.6793 10.9048C94.2948 10.9048 91.0717 13.9818 91.0717 19.265C91.0717 24.6352 94.1206 27.3348 98.6212 27.3348C101.351 27.3348 103.674 26.2608 104.516 24.6352L104.922 27.0445H108.552C108.552 27.0445 108.436 25.9124 108.436 25.0996C108.436 21.6743 108.436 4.02507 108.436 4.02507H104.516V13.14C103.412 11.5724 101.205 10.9048 98.6793 10.9048ZM99.8117 14.3011C103.122 14.3011 104.632 15.9847 104.632 19.1779C104.632 22.2549 102.977 24.0546 99.8117 24.0546C96.5016 24.0546 95.0207 22.2839 95.0207 19.1779C95.0207 16.1589 96.6467 14.3011 99.8117 14.3011Z" /> }
        { hasName && <path d="M118.699 10.9048C114.314 10.9048 111.091 13.9818 111.091 19.265C111.091 24.6352 114.14 27.3348 118.641 27.3348C121.37 27.3348 123.693 26.2608 124.535 24.6352L124.941 27.0445H128.571C128.571 27.0445 128.455 25.9124 128.455 25.0996C128.455 21.6743 128.455 4.02507 128.455 4.02507H124.535V13.14C123.432 11.5724 121.225 10.9048 118.699 10.9048ZM119.831 14.3011C123.141 14.3011 124.651 15.9847 124.651 19.1779C124.651 22.2549 122.996 24.0546 119.831 24.0546C116.521 24.0546 115.04 22.2839 115.04 19.1779C115.04 16.1589 116.666 14.3011 119.831 14.3011Z" /> }
        { hasName && <path d="M136.017 27.0445C135.93 26.8994 135.93 26.4059 135.93 25.5641C135.93 21.8194 135.93 7.9439 135.93 5.41843C135.93 4.69273 135.872 4.17022 135.814 3.93799H131.923C131.952 4.19924 131.981 4.89593 131.981 5.67969C131.981 8.58252 131.981 23.6773 131.981 25.8544C131.981 26.7833 132.01 26.9865 132.098 27.0445H136.017Z" /> }
      </svg>
    );
  };

  if (hasLink) {
    return (
      <a className="paddl-logo__link" href={config.LANDING_PAGE_URL}>
        { renderLogo() }
      </a>
    );
  }

  return renderLogo();
};
