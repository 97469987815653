import React from 'react';

import PanelFormHeadline from 'components/v2/activities/generics/panel-form-headline';
import Checkbox          from 'components/v2/input-fields/checkbox';
import Datepicker        from 'components/v2/input-fields/datepicker';

import './index.scss';

const EmploymentCommonDurationPanelForm = ({
  startDate,
  endDate,
  isCurrent,
  onStartDateChange,
  onEndDateChange,
  onCurrentChange,
  // All the props are passed in from renderFormView of DurationSection
}) => {
  return (
    <div className = "v2-employment-common-duration-panel-form">
      <PanelFormHeadline
        iconSrc     = { require('images/v2/xp/activity-duration.svg') }
        title       = 'Progress'
        description = 'Enter your Start and Finish dates for this Activity...'
      />

      <div className = "v2-employment-common-duration-panel-form__container">
        <div className = "columns v2-employment-common-duration-panel-form__form">
          <div className = "column">
            <Datepicker
              isAutoFocused = { true }
              label         = "Start"
              placeholder   = "Select Date"
              maxDate       = { new Date() }
              value         = { startDate }
              onChange      = { (value) => { onStartDateChange(value); } }
            />
          </div>
          <div className = "column v2-employment-common-duration-panel-form__end-date">
            <Datepicker
              isReadonly  = { isCurrent }
              label       = "Finish"
              placeholder = "Select Date"
              maxDate     = { new Date() }
              value       = { endDate }
              onChange    = { (value) => { onEndDateChange(value); } }
              message     = "Select ‘Current Role’ if this Activity is ongoing."
            />
            <Checkbox
              name      = "current-role"
              label     = "Current Role"
              value     = "current role"
              isChecked = { isCurrent }
              onChange  = { (value) => { onCurrentChange(value); } }
              message   = { null }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentCommonDurationPanelForm;
