import { defined }        from 'lib/helpers';
import createDetailsClass from 'lib/api/activities/generics/create-details-class';
import CertificateType    from 'lib/api/activities/categories/education/fields/certificate-type';
import CourseDuration     from 'lib/api/activities/categories/education/fields/course-duration';
import EventId            from 'lib/api/activities/categories/competition/fields/event-id';
import NaturalNumber      from 'lib/api/activities/generics/fields/natural-number';
import Bool               from 'lib/api/activities/generics/fields/bool';
import Text               from 'lib/api/activities/generics/fields/text';
import Commitment         from 'lib/api/activities/categories/education/fields/commitment';
import DurationValueUnit  from 'lib/api/activities/generics/duration-value-unit';
import DurationValue      from 'lib/api/activities/generics/duration-value-unit/duration-value';
import DurationUnit       from 'lib/api/activities/generics/duration-value-unit/duration-unit';
import IdNameObject       from 'lib/api/activities/generics/fields/id-name-object';
import Subjects           from 'lib/api/activities/categories/education/fields/subjects';
import Location           from 'lib/api/location';
import PrimaryIndustryId  from 'lib/api/activities/categories/employment/fields/primary-industry-id';
import Employer           from 'lib/api/activities/categories/employment/fields/employer';
import HoursPerWeek       from 'lib/api/activities/categories/employment/fields/hours-per-week';
import Level              from 'lib/api/activities/categories/employment/fields/level';
import Responsibility     from 'lib/api/activities/categories/employment/fields/responsibility';

const createDetailsDataClass = ({ attributes, getDurationObjectFn }) => {
  const theDetailsDataClass = class {
    constructor({ state, appv2 }) {
      this._ourState = defined(state);
      this._appv2    = defined(appv2);

      this.isEqualToFields = ({ data }) => {
        const attrNames = Object.keys(attributes);
        const currentState = this._ourState.getAll();

        return attrNames.every((name) => {
          return defined(data[name]) === currentState[name];
        });
      };

      this.toActivityDetails = () => {
        const object = {};
        const Details = createDetailsClass({ attributes });

        for (const name in attributes) {
          object[name] = this._ourState.get({ id: name });
        };

        return new Details(object);
      };

      this.isEverythingEmpty = () => {
        const attrNames = Object.keys(attributes);
        const requiredAttrNames = attrNames.filter((name) => (attributes[name].required));

        return requiredAttrNames.every((name) => {
          return this._ourState.get({ id: name }).isEmpty();
        });
      };

      this.isValid = () => {
        const attrNames           = Object.keys(attributes);
        const requiredAttrNames   = attrNames.filter((name) => (attributes[name].required));
        const attendanceAttrNames = attrNames.filter((name) => (attributes[name].multiSelectGroup === 'attendance'));

        const allValid = requiredAttrNames.every((name) => {
          return this._ourState.get({ id: name }).isValid();
        });

        if (attendanceAttrNames.length === 0) {
          return allValid;
        };

        const attendanceHasSelection = attendanceAttrNames.some((name) => {
          return this._ourState.get({ id: name }).value === true;
        });

        return allValid && attendanceHasSelection;
      };

      this.fetchSourceDataFromApi = ({ attrName, link }, callbackOnSuccess, callbackOnFailure) => {
        const { sourceDataFunction } = attributes[attrName];
        const fetchingFunction = sourceDataFunction({ appv2: this._appv2 });

        return fetchingFunction({ link: defined(link) })
          .then((objs) => {
            this.setSourceData({ attrName: attrName, value: objs });
            callbackOnSuccess();
          })
          .catch(() => {
            callbackOnFailure();
          });
      };

      this.setSourceData = ({ attrName, value }) => {
        const sourceDataName = `${attrName}SourceData`;

        this._ourState.setWithoutChanging({
          [sourceDataName]: value,
        });
      };

      this.getSourceData = (attrName) => {
        const sourceDataName = `${attrName}SourceData`;

        return this._ourState.get({ id: sourceDataName });
      };

      this.fetchSuggestionsFromApi = ({ attrName, substring }) => {
        const { suggestionsFunction } = attributes[attrName];
        const fetchingFunction = suggestionsFunction({ appv2: this._appv2 });
        const suggestionName = `${attrName}Suggestions`;

        return fetchingFunction({ substring: defined(substring) })
          .then((objs) => {
            this._ourState.setWithoutChanging({
              [suggestionName]: objs,
            });
          });
      };

      this.getSuggestions = (attrName) => {
        const suggestionName = `${attrName}Suggestions`;

        return this._ourState.get({ id: suggestionName });
      };

      this.getFieldLabel = (attrName) => {
        const { required, fieldLabel } = attributes[attrName];

        return required ? fieldLabel : `${fieldLabel} (Optional)`;
      };

      this.getFieldPlaceholder = (attrName) => {
        return attributes[attrName].fieldPlaceholder;
      };

      this.getField = (attrName) => {
        const { dataType } = attributes[attrName];
        const attrObj      = this._ourState.get({ id: attrName });

        switch (dataType) {
          case CertificateType:   return attrObj.value;
          case CourseDuration:    return attrObj.value;
          case EventId:           return attrObj.value;
          case NaturalNumber:     return attrObj.value;
          case Bool:              return attrObj.value;
          case Text:              return attrObj.value;
          case Commitment:        return attrObj.value;
          case PrimaryIndustryId: return attrObj.value;
          case HoursPerWeek:      return attrObj.value;
          case Level:             return attrObj.value;
          case Responsibility:    return attrObj.value;
          case DurationValueUnit: return {
            value: attrObj.value.value,
            unit:  attrObj.unit.value,
          };
          case IdNameObject: return {
            id:   attrObj.id,
            name: attrObj.name,
          };
          case Employer: return {
            id:        attrObj.id,
            name:      attrObj.name,
            profileId: attrObj.profileId,
            icon:      attrObj.icon,
            location:  attrObj.location,
          };
          case Location: return {
            placeId:      attrObj.placeId,
            originalText: attrObj.originalText,
            shortText:    attrObj.shortText,
          };
          default: throw Error('Unexpected dataType');
        };
      };

      this.getDurationObject = () => {
        return getDurationObjectFn(this);
      };

      this.getSubjectsAsText = () => {
        return this._ourState.get({ id: 'subjects' }).value;
      };

      this.getSubjectsAsList = () => {
        return this._ourState.get({ id: 'subjects' }).getAsList();
      };

      this.setField = ({ attrName, data }) => {
        const { dataType } = attributes[attrName];

        let newObj;

        switch (dataType) {
          case CertificateType:   newObj = new CertificateType(data);                                                                          break;
          case CourseDuration:    newObj = new CourseDuration(data);                                                                           break;
          case EventId:           newObj = new EventId(data);                                                                                  break;
          case NaturalNumber:     newObj = new NaturalNumber(data);                                                                            break;
          case Bool:              newObj = new Bool(data);                                                                                     break;
          case Text:              newObj = new Text(data);                                                                                     break;
          case Commitment:        newObj = new Commitment(data);                                                                               break;
          case Subjects:          newObj = new Subjects(data);                                                                                 break;
          case IdNameObject:      newObj = new IdNameObject(data);                                                                             break;
          case DurationValueUnit: newObj = new DurationValueUnit({ value: new DurationValue(data.value), unit: new DurationUnit(data.unit) }); break;
          case Location:          newObj = new Location(data);                                                                                 break;
          case PrimaryIndustryId: newObj = new PrimaryIndustryId(data);                                                                        break;
          case Employer:          newObj = new Employer(data);                                                                                 break;
          case HoursPerWeek:      newObj = new HoursPerWeek(data);                                                                             break;
          case Level:             newObj = new Level(data);                                                                                    break;
          case Responsibility:    newObj = new Responsibility(data);                                                                           break;
          default:                throw Error('Unexpected dataType');
        };

        this._ourState.change({ [attrName]: newObj });
      };
    }
  };

  theDetailsDataClass.createEmptyFields = () => {
    const object = {};

    for (const name in attributes) {
      const { dataType, suggestionsFunction, sourceDataFunction } = attributes[name];
      const emptyField = dataType === Bool ? new Bool(false) : dataType.createEmpty();
      const suggestionName = `${name}Suggestions`;

      object[name] = emptyField;

      if (suggestionsFunction) { object[suggestionName]      = []; }
      if (sourceDataFunction)  { object[`${name}SourceData`] = []; }
    };

    return object;
  };

  theDetailsDataClass.fieldsFromActivityDetails = ({ activityDetails }) => {
    const object = {};

    for (const name in attributes) {
      object[name] = activityDetails.get(name);
    };

    return object;
  };

  return theDetailsDataClass;
};

export default createDetailsDataClass;
