import './index.scss';
import React from 'react';

import { Swatches } from 'component-lib/swatches';

export class ProgressBarRangeInput extends React.Component {
  constructor(props) {
    super(props);

    this._getFillColorHexCode = () => {
      const { fillColor } = this.props;

      switch(fillColor) {
        case 'blue':        return Swatches.getHexCodeFor('blue');
        case 'green':       return Swatches.getHexCodeFor('green');
        case 'transparent': return 'transparent';
        default:            throw Error(`Unexpected fillColor: ${fillColor}`);
      }
    }

    this._getTrackColorHexCode = () => {
      const { trackColor } = this.props;

      switch(trackColor) {
        case 'blue-xxl':   return Swatches.getHexCodeFor('blue-xxl');
        case 'bluegrey-l': return Swatches.getHexCodeFor('bluegrey-l');
        default:           throw Error(`Unexpected trackColor: ${trackColor}`);
      }
    }

    this._setPropertiesToStyle = () => {
      this.rangeInput.style.setProperty('--range-input-value', this.rangeInput.value + '%');
      this.rangeInput.style.setProperty('--fill-color', this._getFillColorHexCode());
      this.rangeInput.style.setProperty('--track-color', this._getTrackColorHexCode());
    }
  }

  componentDidMount() {
    this._setPropertiesToStyle();
    window.addEventListener("input", this._setPropertiesToStyle, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) { return; }

    this._setPropertiesToStyle();
  }

  componentWillUnmount() {
    window.removeEventListener("input", this._setPropertiesToStyle, false);
  }

  render() {
    const {
      isEditable,
      percentageComplete,
      onPercentageCompleteChange,
    } = this.props;

    return (
      <input
        className = { `
          progress-bar-range-input
          progress-bar-range-input__fill
          progress-bar-range-input__track
          progress-bar-range-input__thumb--${isEditable ? 'show-thumb' : 'hide-thumb'}
        ` }
        ref       = { (realInputElementOnPage) => { this.rangeInput = realInputElementOnPage; }}
        disabled  = { !isEditable }
        type      = "range"
        min       = { isEditable ? "1" : "0" } // Set min as 1 when progress bar is editable to avoid user from setting the value as 0.
        max       = "100"
        onChange  = { (event) => { onPercentageCompleteChange(event.target.value) } }
        value     = { percentageComplete }
      />
    );
  }
};
