export const commitmentOptions = [
  {
    value: 'FULL_TIME',
    title: 'Full Time',
  },
  {
    value: 'PART_TIME',
    title: 'Part Time',
  },
];
