import './index.scss';
import React from 'react';

import { PaddlLogo } from 'component-lib/components/paddl-logo';
import { NavbarBlogButton } from 'components/v2/navbar-button/blog-button';
import { NavbarLogInButton } from 'components/v2/navbar-button/log-in-button';
import { NavbarSignUpButton } from 'components/v2/navbar-button/sign-up-button';

export const V2NavbarLogin = () => (
  <nav className="v2-navbar-login">
    <div>
      <div className="v2-navbar-login__brand--desktop">
        <PaddlLogo hasLink hasName />
      </div>
      <div className="v2-navbar-login__brand--mobile">
        <PaddlLogo hasLink />
      </div>
    </div>

    <div className="v2-navbar-login__nav">
      <NavbarBlogButton />
      <NavbarLogInButton />
      <NavbarSignUpButton />
    </div>
  </nav>
);
