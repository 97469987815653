import { defined } from 'lib/helpers';
import React       from 'react';

import PanelFormHeadline                from 'components/v2/activities/generics/panel-form-headline';
import InputTextDropdown                from 'components/v2/input-fields/input-text-dropdown';
import DurationValueUnitInput           from 'components/v2/activities/generics/duration-value-unit/input';
import CommitmentInput                  from 'components/v2/activities/categories/education/fields/commitment/input';
import AttendanceInPersonAndOnlineInput from 'components/v2/activities/categories/education/fields/attendance/in-person-and-online/input';

class CourseDetailsPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValueAcceptedForTrainer: true,
      isValueAcceptedForCourse:  true,
    };

    this.isValueAcceptedForTrainer = () => {
      return this.state.isValueAcceptedForTrainer;
    };

    this.isValueAcceptedForCourse = () => {
      return this.state.isValueAcceptedForCourse;
    };

    this.setValueAcceptedForTrainer = (value) => {
      return this.setState({ isValueAcceptedForTrainer: value });
    };

    this.setValueAcceptedForCourse = (value) => {
      return this.setState({ isValueAcceptedForCourse: value });
    };
  }

  render() {
    const { data } = this.props;
    const { detailsData } = data;

    const renderTrainerInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'trainer',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForTrainer(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'trainer',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'trainer',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForTrainer(true);
      };

      const onAcceptSuggestion = (trainer) => {
        detailsData.setField({
          attrName: 'trainer',
          data:     trainer,
        });
        this.setValueAcceptedForTrainer(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { true }
          label         = { detailsData.getFieldLabel('trainer') }
          placeholder   = { detailsData.getFieldPlaceholder('trainer') }
          value         = { detailsData.getField('trainer').name }
          options       = { this.isValueAcceptedForTrainer() ? null : detailsData.getSuggestions('trainer') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCourseInput = () => {
      const onChangeValue = ({ name }) => {
        detailsData.setField({
          attrName: 'course',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCourse(false);
        detailsData.fetchSuggestionsFromApi({
          attrName:  'course',
          substring: defined(name),
        });
      };

      const onAcceptValue = ({ name }) => {
        detailsData.setField({
          attrName: 'course',
          data:     { id: null, name: name },
        });
        this.setValueAcceptedForCourse(true);
      };

      const onAcceptSuggestion = (course) => {
        detailsData.setField({
          attrName: 'course',
          data:     course,
        });
        this.setValueAcceptedForCourse(true);
      };

      return (
        <InputTextDropdown
          isAutoFocused = { false }
          label         = { detailsData.getFieldLabel('course') }
          placeholder   = { detailsData.getFieldPlaceholder('course') }
          value         = { detailsData.getField('course').name }
          options       = { this.isValueAcceptedForCourse() ? null : detailsData.getSuggestions('course') }
          isError       = { false }
          message       = { null }
          onInputChange = { (value) => { onChangeValue({ name: defined(value) }); } }
          onUseClick    = { (value) => { onAcceptValue({ name: defined(value) }); } }
          onOptionClick = { ({ id, name }) => { onAcceptSuggestion({ id: defined(id), name: defined(name) }); } }
        />
      );
    };

    const renderCourseDurationValueUnitInput = () => {
      const { value, unit } = detailsData.getField('courseDuration');

      const valueOnChange = (selectedValue) => {
        detailsData.setField({
          attrName: 'courseDuration',
          data: {
            value: selectedValue,
            unit:  unit,
          },
        });
      };

      const unitOnChange = (selectedUnit) => {
        detailsData.setField({
          attrName: 'courseDuration',
          data: {
            value: value,
            unit:  selectedUnit,
          },
        });
      };

      return (
        <DurationValueUnitInput
          label            = { detailsData.getFieldLabel('courseDuration') }
          valuePlaceholder = { detailsData.getFieldPlaceholder('courseDuration').value }
          unitPlaceholder  = { detailsData.getFieldPlaceholder('courseDuration').unit }
          value            = { value }
          unit             = { unit }
          valueOnChange    = { (selectedValue) => { valueOnChange(selectedValue); } }
          unitOnChange     = { (selectedUnit) => { unitOnChange(selectedUnit); } }
        />
      );
    };

    const renderCommitmentInput = () => {
      return (
        <CommitmentInput
          label       = { detailsData.getFieldLabel('commitment') }
          placeholder = { detailsData.getFieldPlaceholder('commitment') }
          value       = { detailsData.getField('commitment') }
          onChange    = { (commitment) => { detailsData.setField({ attrName: 'commitment', data: commitment }); } }
        />
      );
    };

    const renderAttendanceInput = () => {
      return (
        <AttendanceInPersonAndOnlineInput
          isInPersonChecked = { detailsData.getField('attendanceInPerson') }
          isOnlineChecked   = { detailsData.getField('attendanceOnline') }
          onInPersonChange  = { (value) => { detailsData.setField({ attrName: 'attendanceInPerson', data: value }); } }
          onOnlineChange    = { (value) => { detailsData.setField({ attrName: 'attendanceOnline', data: value }); } }
        />
      );
    };

    return (
      <div className = "v2-course-details-panel-form">
        <PanelFormHeadline
          iconSrc     = { require('images/v2/xp/activity-details.svg') }
          title       = 'Course Details'
          description = 'Search & Select your course details or add something new...'
        />

        <div className = "columns is-multiline">
          <div className = "column is-full">{ renderTrainerInput() }</div>
          <div className = "column is-full">{ renderCourseInput() }</div>
          <div className = "column is-half">{ renderCourseDurationValueUnitInput() }</div>
          <div className = "column is-half">{ renderCommitmentInput() }</div>
          <div className = "column is-full">{ renderAttendanceInput() }</div>
        </div>
      </div>
    );
  }
};

export default CourseDetailsPanelForm;
