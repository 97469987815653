import { defined } from 'lib/helpers';

class Level {
  constructor(value) {
    const expectedValues = [
      null,
      Level.GRADUATE,
      Level.JUNIOR,
      Level.MID_LEVEL,
      Level.SENIOR,
      Level.VETERAN,
    ];

    if (expectedValues.indexOf(defined(value)) === -1) {
      throw Error('Unexpected value');
    }

    this.value = value;
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new Level(null);
  }

  toApiFormat() {
    switch (this.value) {
      case Level.GRADUATE:  return 'GRADUATE';
      case Level.JUNIOR:    return 'JUNIOR';
      case Level.MID_LEVEL: return 'MID_LEVEL';
      case Level.SENIOR:    return 'SENIOR';
      case Level.VETERAN:   return 'VETERAN';
      default:              throw Error('Unexpected value');
    }
  }

  static fromApiFormat(data) {
    const value = (() => {
      switch (data) {
        case 'GRADUATE':  return Level.GRADUATE;
        case 'JUNIOR':    return Level.JUNIOR;
        case 'MID_LEVEL': return Level.MID_LEVEL;
        case 'SENIOR':    return Level.SENIOR;
        case 'VETERAN':   return Level.VETERAN;
        default:          throw Error('Unexpected value');
      }
    })();

    return new Level(value);
  }
}

Level.GRADUATE  = 0;
Level.JUNIOR    = 1;
Level.MID_LEVEL = 2;
Level.SENIOR    = 3;
Level.VETERAN   = 4;

export default Level;
