import './index.scss';
import React from 'react';

import { PaddlLogo } from 'component-lib/components/paddl-logo';

export const V2PublicNavbar = ({ children }) => (
  <nav className="v2-public-navbar">
    <div className="v2-public-navbar__content">
      <div className="v2-public-navbar__brand--desktop">
        <PaddlLogo hasLink hasName />
      </div>
      <div className="v2-public-navbar__brand--mobile">
        <PaddlLogo hasLink />
      </div>

      { children }
    </div>
  </nav>
);
