import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalProgressContent } from 'components/v2/modal-progress-content';

export class ModalProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: null
    };
  }

  componentDidMount() {
    const { appv2, activityId } = this.props;

    appv2.api.activities
      .fetch({ id: activityId, isLoggedIn: true })
      .then((value) => {
        this.setState({ activity: value });
      });
  }

  render() {
    const {
      appv2,
      basecampRefreshFunction,
    } = this.props;

    const closeFunction = () => { appv2.ui.closeModal(); };

    // this.state.activity has loaded into it via an API call, so
    // we need to wait for it to respond before rendering
    const modalContent = () => {
      if (this.state.activity === null) {
        return <div />;
      }

      return (
        <ModalProgressContent
          appv2 = { appv2 }
          activity  = { this.state.activity }
          basecampRefreshFunction = { basecampRefreshFunction }
        />
      );
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { true }
        windowWidthSize = "small"
      >
        { modalContent() }
      </Modal>
    );
  }
}
