import { DefinedMap } from 'lib/helpers';

const createDetailsClass = ({ attributes }) => {
  const theDetailsClass = class extends DefinedMap {
    constructor(data) {
      const attributeNames = Object.keys(attributes);

      super({ attributes: attributeNames, data });
    }

    toApiFormat() {
      const object = {};

      for (const attributeName in attributes) {
        object[attributeName] = this.get(attributeName).toApiFormat();
      };

      return object;
    }

    static fromApiFormat(data) {
      const object = {};

      for (const attributeName in attributes) {
        const dataType = attributes[attributeName].dataType;

        object[attributeName] = dataType.fromApiFormat(data[attributeName]);
      };

      return new theDetailsClass(object);
    }
  };

  return theDetailsClass;
};

export default createDetailsClass;
