import { defined } from 'lib/helpers';

const numericValue = (value) => {
  const intValue = parseInt(defined(value), 10);

  if (isNaN(intValue) || intValue < 1 || intValue > 30) {
    throw Error('Unexpected value');
  }

  return intValue;
};

class DurationValue {
  constructor(value) {
    this.value = (value === null ? null : numericValue(value));
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new DurationValue(data);
  }
}

export default DurationValue;
