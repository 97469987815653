import pluralize from 'pluralize';

export const courseDurationOptions =
  Array.from(Array(12).keys())
    .map((num) => {
      const value = num + 1;

      return {
        value: value,
        title: pluralize('Year', value, true),
      };
    });
