import './index.scss';

import React                 from 'react';
import Common                from 'pages/v2/profile/common';
import { defined }           from 'lib/helpers';
import { ACCESS_DENIED }     from 'lib/paddl-error';
import { STUDENT, EMPLOYER } from 'lib/api/profiles/profile';
import ProfileExtra          from 'lib/api/profiles/profile-extra';

import PublicStudentSummaryContent from 'components/v2/public-profile/public-student-summary-content';

import AccessAllExperience from 'components/v2/public-profile/access-all-experience';
import StartJourneyBanner  from 'components/v2/public-profile/start-journey-banner';

import PublicProfileEmptyPage from 'components/v2/public-profile/empty-page'

import Header, {
  SUMMARY_TAB,
  EXPERIENCE_TAB,
  CAMPAIGNS_TAB
} from 'components/v2/profile/header';

export class PublicSummaryPage extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this.state = {
      profile:            Common.createEmptyProfile(),
      profileExtra:       ProfileExtra.createEmpty(),
      visibilitySettings: {},
      isHidden:           false,
    };

    this._fetchProfile = (idOrSlug) => {
      appv2.api.profiles
        .fetchWithExtraByIdOrSlug({ idOrSlug, isLoggedIn: false })
        .then(({ profile, profileExtra, visibilitySettings }) => {
          if (profile.get('urlSlug') && idOrSlug !== profile.get('urlSlug')) {
            const publicSuffix = (window.location.search.indexOf('public=1') > -1 ? '?public=1' : '');

            const baseUrl = appv2.slugs.generateProfilePath({
              id:   profile.get('id'),
              slug: profile.get('urlSlug'),
            });

            return appv2.ui.redirect({ url: `${ baseUrl }/summary${ publicSuffix }` });
          }

          if (profile.get('role') === EMPLOYER) {
            this.setState({ isHidden: true });
          }

          if (visibilitySettings.master === false) {
            this.setState({ isHidden: true });
          }

          this.setState({ profile, profileExtra, visibilitySettings });
        })
        .catch((error) => {
          if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
            return appv2.ui.redirect({ url: `/not-found` });
          }

          throw error;
        });
    };
  }

  componentDidMount() {
    this._fetchProfile(defined(this.props.params.id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this._fetchProfile(defined(this.props.params.id));
  }

  render() {
    const { appv2 } = this.props;

    const { profile, profileExtra, visibilitySettings } = this.state;

    const onTabSwitch = (tab) => {
      const publicSuffix = (window.location.search.indexOf('public=1') > -1 ? '?public=1' : '');

      const baseUrl = appv2.slugs.generateProfilePath({
        id:   profile.get('id'),
        slug: profile.get('urlSlug'),
      });

      if (tab === EXPERIENCE_TAB) {
        return appv2.ui.redirect({ url: `${ baseUrl }/experience${ publicSuffix }` });
      } else if (tab === CAMPAIGNS_TAB) {
        return appv2.ui.redirect({ url: `${ baseUrl }/campaigns${ publicSuffix }` });
      }

      throw Error('Unexpected TAB');
    };

    const renderSummaryContent = () => {
      switch (profile.get('role')) {
        case STUDENT:  return <PublicStudentSummaryContent appv2 = { appv2 } profile = { profile } visibilitySettings = { visibilitySettings } canEdit = { false } />;
        case EMPLOYER: return null; // Fix this after we decide to show employer public summary page
        case null:     return null;
        default:       throw Error('Unexpected role');
      }
    };

    const renderVisibleContent = () => {
      return (
        <div>
          <Header
            appv2                    = { appv2 }
            profile                  = { profile }
            profileExtra             = { profileExtra }
            canEdit                  = { false }
            currentTab               = { SUMMARY_TAB }
            onTabSwitch              = { onTabSwitch }
            secondTabPlaceholderText = { 'Experience' }
          />

          <AccessAllExperience profile = { profile } />

          <div className = "v2-public-summary-page__page-wrapper">
            { renderSummaryContent() }
          </div>
        </div>
      );
    };

    if (Common.isProfileEmpty({ profile: profile })) {
      return null;
    }

    return (
      <div className = "v2-public-summary-page">
        { this.state.isHidden
          ? <PublicProfileEmptyPage />
          : renderVisibleContent()
        }

        <StartJourneyBanner />
      </div>
    );
  }
}
