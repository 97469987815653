import './index.scss';

import React from 'react';
import BezierEasing from 'bezier-easing';

const HORIZONTAL_PADDING = 42; // depends on a font
const HEIGHT = 64;
const VERTICAL_CENTER = 58; // depends on a font

const textNode = ({ key, x, y, className, contents }) => {
  return (
    <text
      key = { key }
      x = { x }
      y = { y }
      className = { className }>{ contents }</text>
  );
};

const calculateHorizontalOffset = (component, index, digitsCount) => {
  const svgNode = component.refs.svg;

  if (svgNode === undefined) {
    return 0;
  }

  const centerLine = svgNode.clientWidth / 2;
  const positionsCount = digitsCount + 2; // account for the "XP" postfix

  return centerLine + HORIZONTAL_PADDING * (index - positionsCount / 2);
};

/*********
 * This function converts a list of digits' states
 * to a list of <text> elements.
 *
 * Examples:
 * [ 1, 2, 3.5 ] => [ <100% of 1>, <100% of 2>, <lower 50% of 3>, <upper 50% of 4> ]
 * [ 1, 2.36, 9 ] => [ <100% of 1>, <lower 36% of 2>, <upper 64% of 3>, <100% of 9> ]
 * [ 9.7 ] => [ <lower 70% of 9>, <upper 30% of 0> ]
 *
 * Also, the incoming list may contain a sign as the first element.
 * E.g. [ "+", 1, 2, 3.5 ]
 * In this case, the sign should be rendered as a regular digit.
 *********/
const renderOneDigit = (component, dig, index, digitsCount, className) => {
  const horizontalOffset = calculateHorizontalOffset(component, index, digitsCount);

  if (typeof dig === 'string') {
    return textNode({
      key:       'sign',
      x:         horizontalOffset,
      y:         VERTICAL_CENTER,
      className: className,
      contents:  dig,
    });
  }

  const integer = Math.floor(dig);
  const fraction = (dig % 1);
  const verticalOffset = HEIGHT * fraction;

  const thisNode = textNode({
    key:       index,
    x:         horizontalOffset,
    y:         VERTICAL_CENTER - verticalOffset,
    className: className,
    contents:  integer,
  });

  if (fraction === 0) {
    return [thisNode];
  }

  const nextNode = textNode({
    key:       `next${ index }`,
    x:         horizontalOffset,
    y:         VERTICAL_CENTER + HEIGHT - verticalOffset,
    className: className,
    contents:  (integer + 1) % 10,
  });

  return [thisNode, nextNode];
};

/*********
 * This function converts a real number into a list of digits' states.
 *
 * Examples:
 * 123.5 => [ 1, 2, 3.5 ]
 * 129.36 => [ 1, 2.36, 9.36 ]
 * 199.7 => [ 1.7, 9.7, 9.7 ]
 *
 * Note that if a digit to the right is greater than 9
 * then the current digit will have the fractional part added to it.
 *
 * If shouldShowSign is true, then an extra element is added to the head of the list,
 * containing "+" or "-" as a string, e.g. [ "+", 1.7, 9.7 ]
 *********/
const convertValueToDigits = (value, sign) => {
  const fraction = (value % 1);
  const digits = [];

  if (value < 1) {
    digits.push(fraction);
  } else {
    // we always add a fraction to the last digit (if the fraction is present)
    let prevDigit = 9 + fraction;

    while (value >= 1) {
      prevDigit = Math.floor(value) % 10 + (prevDigit > 9 ? fraction : 0);
      digits.push(prevDigit);
      value /= 10;
    }
  }

  if (sign) {
    digits.push(sign);
  }

  return digits.reverse();
};

const getIntegerSign = (value) => {
  if (value > -1 && value < 1) {
    return null;
  }

  return (value < 0 ? '-' : '+');
};

const renderXP = (component, digitsCount) => {
  return textNode({
    key:       'xp',
    x:         calculateHorizontalOffset(component, digitsCount + 0.25, digitsCount),
    y:         VERTICAL_CENTER,
    className: 'v2-odometer__xp',
    contents:  'XP',
  });
};

const renderValue = (component, value, shouldShowSign) => {
  const sign = shouldShowSign ? getIntegerSign(value) : null;
  const digits = convertValueToDigits(Math.abs(value), sign);
  const digitsCount = digits.length;
  const className = `v2-odometer__digit v2-odometer__digit--${ value < 0 ? 'negative' : 'positive' }`;

  return digits
    .map((dig, index) => (renderOneDigit(component, dig, index, digitsCount, className)))
    .concat(renderXP(component, digitsCount))
    .reduce((allNodes, oneDigitNodes) => (allNodes.concat(oneDigitNodes)), []);
};

export class Odometer extends React.Component {
  constructor(props) {
    super(props);

    this._timeout = null;

    this._clearTimeoutIfSet = () => {
      if (!this._timeout) {
        return;
      }

      clearTimeout(this._timeout);
      this._timeout = null;
    };

    this._reRender = () => {
      this.forceUpdate();
    };

    this.state = {
      currentValue: this.props.startValue,
    };

    this._setupAnimationIfNecessary = (actualProps) => {
      if (!actualProps.isAnimated) {
        this.setState({
          currentValue: actualProps.endValue,
        });

        return;
      }

      const TICK_LENGTH = 10; // milliseconds
      const easing = BezierEasing(1.0, 0.0, 0.0, 1.0);
      const vector = actualProps.endValue - actualProps.startValue;

      const animate = (currentTime) => {
        this._clearTimeoutIfSet();

        if (currentTime > actualProps.animationLength) {
          this.setState({
            currentValue: actualProps.endValue,
          });

          actualProps.onFinish && actualProps.onFinish();
          return;
        }

        const delta = vector * easing(currentTime / actualProps.animationLength);

        this.setState({
          currentValue: actualProps.startValue + delta,
        });

        this._timeout = setTimeout(() => {
          animate(currentTime + TICK_LENGTH);
        }, TICK_LENGTH);
      };

      animate(0.0);
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._reRender);
    this._setupAnimationIfNecessary(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.isAnimated !== this.props.isAnimated) {
      this._clearTimeoutIfSet();
      this._setupAnimationIfNecessary(nextProps);
    }
  }

  componentWillUnmount() {
    this._clearTimeoutIfSet();
    window.removeEventListener('resize', this._reRender);
  }

  render() {
    return (
      <svg
        ref = "svg"
        width = "720"
        height = { HEIGHT }
        className = "v2-odometer"
      >
        { renderValue(this, this.state.currentValue, this.props.shouldShowSign) }
      </svg>
    );
  }
}
