import './index.scss';
import React from 'react';

export function TermsProviderContent() {
  return (
    <div className="terms-section-content">
      <header>
        Terms of Use for Providers
      </header>

      <div>
        <p>
          www.paddl.com (the <b>Platform</b>) is a web application wholly owned by Paddl Co. Pty Ltd (ACN 131 952 004) (<b>Paddl</b>, <b>we</b>, <b>our</b> or <b>us</b>).
        </p>
        <p>
          These are the terms on which Paddl will provide access to the Platform to a business or  organisation which is registered with Paddl (or is seeking to be registered with Paddl) (<b>you</b> or <b>your</b>) for the purpose of using the Platform to advertise, publicise or otherwise promote employment, training, internship, placement, conference, event, competition, social and other experiences and/or opportunities to users of the Platform.
        </p>
      </div>

      <ol className = "terms-provider-content__nested-number-list-layer-1">
        <li>
          <header>
            Definitions
          </header>
          <p>
            <b>Activity Cards</b> is a set of features available to Users in their Paddl Profile to add experiences including: education, employment, competition, personal and social experience.
          </p>
          <p>
            <b>Campaign</b> is an employment, training, internship, placement, conference, event, competition, social or other experience or opportunity advertised or otherwise published on the Platform by you.
          </p>
          <p>
            <b>Campaign Fees</b> are all fees and other taxes, charges and amounts payable in respect of your access to the Platform and/or to any Campaign, notice, proposal, document or other publication posted, lodged, published or otherwise displayed on the Platform or otherwise provided or made available to Users by us or on our behalf and <b>Campaign Fee</b> means any one such fee.
          </p>
          <p>
            <b>Claim</b> means any claim, action, suit, proceeding, demand, notice, litigation, investigation, judgement or entitlement to the payment of compensation, however arising, whether present, unascertained, immediate, future or contingent, whether based in contract, tort, statute or otherwise and whether involving you or a third party.
          </p>
          <p>
            <b>Employer Account</b> means:
          </p>
          <ol className = "terms-provider-content__lower-alpha-list">
            <li>
              the account in your name on the Platform (accessible via a username and password which allows your authorised user(s) to login into the Platform and utilise the features available to you on the Platform); or
            </li>
            <li>
              if access to the Platform is via a Managed Account, that account.
            </li>
          </ol>
          <p>
            <b>Intellectual Property Rights</b> means any Intellectual Property Rights in existence now or coming into existence in the future anywhere in the world and includes (but is not limited to) rights in respect of copyright, trade marks (whether registered or unregistered), inventions, patents, designs (whether registered or unregistered), confidential information and know how.
          </p>
          <p>
            <b>Liabilities</b> means, in relation to any Claim, all losses, costs, damages, expenses or other liabilities of any kind (including penalties, fines and interest) and however arising out of or in connection with the fact, matter or circumstance giving rise to the Claim including all legal and other professional expenses incurred in connection with investigating, disputing, defending or settling any claim, action, demand or proceeding relating to that fact, matter or circumstance (including any claim, action, demand or proceeding based on the Terms).
          </p>
          <p>
            <b>Managed Account</b> is an Employer Account where we manage access to the Platform (and the use of the features available on the Platform) for and on your behalf and report to you in respect of the use of that account on the Platform.
          </p>
          <p>
            <b>Paddl Profile</b> means the access granted to a User (via use of a username and password) which allows the User to login into Paddl, to create a personal profile, to use the features available on the Platform including, but not limited to, viewing and responding to Campaigns (e.g. by applying for employment or other work-related opportunities advertised on the Platform) and to add Activity Cards.
          </p>
          <p>
            <b>Terms</b> means the terms and conditions set out in this document for your access to, and use of, the Platform.
          </p>
          <p>
            <b>User</b> is any person (other than you) who uses the Platform and, includes, but is not limited to individuals (including students and graduates) who have activated a Paddl Profile for the purpose of building an experience profile and/or gaining employment or access to other work-related experiences and opportunities through use of the Platform.
          </p>
          <p>
            <b>Volunteer</b> means a person that engages in “Volunteering” as that term is defined by Volunteers Australia.
          </p>
        </li>

        <li>
          <header>
            Variation &amp; Acceptance of Terms
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>You will be required to accept the Terms before being granted an Employer Account.  If, at any time after you have been granted access to the Platform via an Employer Account, you determine that you cannot or do not wish to comply with any one or more of the Terms, you must immediately advise us in writing and we will cancel your Employer Account (support@paddljobs.com).</p>
            </li>
            <li>
              <p>Your access to the Platform and your Employer Account may be terminated by us, with immediate effect and with or without prior notice to you, for non-compliance with any provision of the Terms.</p>
            </li>
            <li>
              <p>We may change the Terms at our absolute discretion and at any time.  A change to the Terms will become effective immediately on publication of the change (by notice (in writing) detailing the change) on the Platform.  You accept that publication of the change on the Platform is sufficient notice to you of the change and that, on and from the date of publication of the change, you agree to be bound by the Terms (as amended).</p>
            </li>
            <li>
              <p>Should you have any questions concerning these terms please contact support@paddljobs.com.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Employer Account
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>Only persons with an Employer Account will be permitted to publish a Campaign on the Platform.  To open an Employer Account, you will be required to provide certain information, which may include your business name, contact name, details about your business and a valid email address (“<b>Account Information</b>”). You agree:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  to provide Account Information which is true, accurate and complete and to take all reasonable steps to ensure your Account Information remains true, accurate and complete; and
                </li>
                <li>
                  if you provide any information that is untrue, inaccurate, incomplete or inappropriate, or we have grounds to suspect that such information is untrue, inaccurate, incomplete or inappropriate, we have the right to suspend, terminate, lock or delete your Employer Account.
                </li>
              </ol>
            </li>
            <li>
              <p>We reserve the right to use your Account Information for any purpose for which it is reasonably required in the ordinary course of our business.  Its use will be in accordance with our policies and procedures, including our Privacy Policy which can be found <a href = "/terms#privacy">here</a>. In particular, we reserve the right to disclose your Account Information to third parties, including Users, in order for you to use the Platform and interact with Users.</p>
            </li>
            <li>
              <p>By providing your email address to us, you consent to our use of that email address to send you service-related notices, including among other things, notices required by law, in lieu of postal mail.  For so long as you hold an Employer Account, you may not opt out of service-related emails.  We may also use your email address (or, for subscribers to mobile notifications, your mobile number) to send you other notices and/or messages, including information about Paddl and special offers. You may opt out of such email or text messages by sending an email to support@paddljobs.com or using an unsubscribe link in any such email.</p>
            </li>
            <li>
              <p>We may refuse your request for an Employer Account at any time and for any reason (and without any obligation to provide you with a reason for any such refusal).</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Payment for Services
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>If you choose to post a Campaign on the Platform, you agree to pay all applicable Campaign Fees.  You are obliged to pay the Campaign Fees regardless of whether you utilise any particular service and regardless of whether your objective in undertaking the Campaign is achieved.  Similarly, if you do not provide us with the necessary materials or information for us to deliver the required services to you, you are still liable to us for full payment.</p>
            </li>
            <li>
              <p>In most cases, Campaign Fees will be payable in advance (by credit card or on-line (via Stripe).  If you are an approved customer and are invoiced for Campaign Fees, all amounts due and payable must be paid within 14 days of the date of invoice. We may charge you interest on late payments at our applicable bank overdraft interest rate and debit your Employer Account for all costs we incur in the collection from you of any overdue amount.</p>
            </li>
            <li>
              <p>All Campaign Fees are non-refundable.</p>
            </li>
            <li>
              <p>You are responsible for all Campaign Fees incurred by yourself or by any of your authorised users, in accordance with the billing provisions specified in an invoice, online form, on www.paddl.com or in a payment confirmation.</p>
            </li>
            <li>
              <p>All online payments must be made through our third-party payment processing provider, Stripe.  Access to Stripe’s can be found at the following <a href = "https://stripe.com/au/privacy">link</a>. To the extent required, Stripe's terms and conditions are incorporated into and form part of the Terms.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Trademarks &amp; Intellectual Property Rights
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>You acknowledge and agree that:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  all Intellectual Property Rights of Paddl (including but not limited to copyright in the software and codes underpinning the Platform), Paddl advertisements, Paddl content and Paddl logos (collectively Paddl IP) are the sole and exclusive property of Paddl;
                </li>
                <li>
                  you have no right, title or interest in or to any Paddl IP and your access to, or use of, the Platform, does not convey to, or result in, any transfer to you of any right, title or interest in or to any Paddl IP; and
                </li>
                <li>
                  we are authorised to use your Your IP (refer to clause 5.2 below) for the purpose of fulfilling our obligations to you under the Terms.
                </li>
              </ol>
            </li>
            <li>
              <p>We acknowledge and agree that:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  your name and all of your logos and advertisements (excluding content drafted or prepared by us) (collectively “<b>Your IP</b>”) are solely and exclusively your property;
                </li>
                <li>
                  subject to clause 6, we have no right, title or interest in any of Your IP and our use of Your IP for the purpose of fulfilling our obligations to you under the Terms does not convey to, or result in, any transfer to us of any right, title or interest in or to Your IP.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Links &amp; Publicity
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>You grant to Paddl a royalty free licence to use your name and logo for the express and sole purpose of identifying you as a client of Paddl and a Paddl Employer in its marketing material, both print and electronic.  Any other use by Paddl of Your IP will require your prior approval.</p>
            </li>
            <li>
              <p>You may, by notice in writing to Paddl, elect to withdraw your permission for the use of your name and logo in our marketing material.  If we receive such notice, we will cease using your name and/or logo within 30 days of receipt of that notice.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Termination
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>We may terminate or suspend your Employer Account at any time and for any reason whatsoever, including, without limitation, if you breach the Terms.  Termination may occur without prior notice to you and without need on our part to give you a reason for that termination.</p>
            </li>
            <li>
              <p>To the maximum extent permitted by law, we will have no liability whatsoever to you for or as a result of termination or suspension of your Employer Account, other than in respect of the repayment of all Campaign Fees paid for or in respect of access to the Platform denied by, or Campaigns which cannot be completed, by reason of the termination.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Changes to Paddl
          </header>
          <p>
            We reserve the right at all times, and without the need to provide any notice to you, to alter the functionality and/or appearance of the Platform, including but not limited to the presentation of Campaigns.
          </p>
        </li>

        <li>
          <header>
            Limitation of liability &amp; Disclaimer
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>Reliance on and use by you of:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  any information on the Platform; or
                </li>
                <li>
                  other information provided to you by us and/or by any other Employer or User,
                </li>
              </ol>
              <p>is solely at your own risk.</p>
            </li>
            <li>
              <p>We accept no responsibility or liability for any information in your Campaigns.  You must carefully check any and all Campaign materials (of any description) provided to us or uploaded by you onto the Platform.  Errors in any such materials are your responsibility.</p>
            </li>
            <li>
              <p>While we will use reasonable efforts to publish a Campaign in accordance with your instructions (if any), we accept no liability or responsibility for any delay in publication (whether caused by us or any other person).</p>
            </li>
            <li>
              <p>The Platform is a medium through which you and Users may create and/or publish materials relevant to users of the Platform.  While we use reasonable efforts to ensure the legitimacy of any material published on the Platform (for example, Campaign materials and/or Paddl Profiles), we neither vet, nor are we responsible for vetting, Users or responses and other materials published by them on the Platform (including but not limited to any facts or representations made by a User in a Paddl Profile, a response to an employment advertisement or any other document, whether oral or in writing).</p>
            </li>
            <li>
              <p>We do not recommend or endorse any User or guarantee that any or all materials published on the Platform by a User are genuine or that any or all information provided by a User is true, accurate and/or complete</p>
            </li>
            <li>
              <p>We expressly reserve the right to delete or otherwise remove from the Platform any material which we consider, in our absolute discretion, to be offensive, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, invasive of another’s privacy, racially or ethnically offensive or otherwise objectionable or damaging to us, our reputation, other Employers or Users and/or to any other person (or persons) generally.</p>
            </li>
            <li>
              <p>We provide no warranty to you that your access to the Platform and/or to the services or features generally available on the Platform or via Paddl will be uninterrupted, error free or free from any risk of exposure to you or your computer systems of malicious software (malware), including but not limited to software that may harm your computer system or software (in the form of worms, viruses, trojans, spyware, adware and rootkits, etc.) designed to compromise, destroy or steal protected data and/or documents or of software not approved by you being installed on your computer systems.</p>
            </li>
            <li>
              <p>We will take such steps as are reasonable to maintain the security, integrity and privacy of your Employer Account and protect it from misuse, interference, loss, unauthorised access, modification and/or disclosure (other than as expressly required by law, permitted under the Terms or authorised by you).  Except where we are unable to exclude our liability by law, we, our officers, employees, agents and contractors will not be liable in any way to you or to anyone else for any Liability (whether it be in contract, tort including negligence, or otherwise) arising out of or in connection with your access and use of the Platform and/or misuse, interference, loss, unauthorised access to, modification and/or disclosure of personal information or protected data in your Employer Account.</p>
            </li>
            <li>
              <p>To the maximum extent permitted at law, Paddl shall not be liable to you (or any person claiming through you or on your behalf) in respect of any Claim, regardless of whether the Claim is in contract, tort (includes negligence), under any statute or otherwise at law, for any special, indirect, incidental, punitive, or consequential loss or damages of any kind whatsoever, or for any loss of profit, use, content, data, opportunity, revenue or business, whether caused by Paddl, by any of its employees, agents or by any other persons acting on its behalf or by any other user of the Platform.</p>
            </li>
            <li>
              <p>Nothing in these terms purports to limit our liability under the Australian Consumer Law or otherwise required at law.</p>
            </li>
            <li>
              <p>In the event of a Claim by you (or by any person claiming through you or on your behalf) and regardless of whether the Claim is in contract, tort (includes negligence), under any statute or otherwise at law, to the maximum extent permitted by law, our Liability in respect of that claim shall be limited to:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  the replacement of the goods or services or the re-supply of equivalent goods or services;
                </li>
                <li>
                  payment of the cost of replacing the goods or acquiring equivalent goods or services;
                </li>
                <li>
                  reimbursement or refund of any charges paid for the goods or services or incorrectly charged or deducted; or
                </li>
                <li>
                  compensation for any loss or damage caused to you, subject at all times to a maximum liability cap of $##[amount],
                </li>
              </ol>
              <p>as determined by Paddl (in its absolute discretion). </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Your obligations
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>You represent, warrant and acknowledge that:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  you have the legal capacity and power to agree to be bound by the Terms and perform the obligations under them;
                </li>
                <li>
                  Campaigns and any Campaign materials or other content posted by you on the Platform do not breach any copyright or privacy laws or the Intellectual Property Rights of any third party;
                </li>
                <li>
                  the information provided in your Campaigns does not breach any law or the rights of any person (including legislation regarding consumer protection and anti-competitive conduct, equal opportunity and human rights);
                </li>
                <li>
                  in contracting and/or interacting with you, our relationship with you is as an independent contractor and, in particular, we do not act as your agent or employer;
                </li>
                <li>
                  you will not use the Platform for any purpose other than the express purpose for which access has been granted under the Terms;
                </li>
                <li>
                  you will not use the Platform or any features of the Platform or products offered on the Platform or by us to upload, download, transact, store or make available data that is unlawful, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, invasive of another’s privacy, racially or ethnically offensive or otherwise in our opinion objectionable or damaging to us, our reputation, other Employers and/or Users or to any other person (or persons) generally;
                </li>
                <li>
                  you will not assign or transfer any rights and obligations pursuant to the Terms to any other person or entity without our prior written approval (which approval will not be unreasonably withheld).  If you are a company, any change in your effective control shall be deemed an assignment for the purpose of this clause;
                </li>
                <li>
                  you will at all times deal with any information, products or services provided by us (or on our behalf) or accessed on the Platform in a manner which abides by all applicable laws of Australia and of any other relevant jurisdiction (including, without limitation, all privacy and copyright laws);
                </li>
                <li>
                  you will not ask or require any User to pay a fee, charge, cost or any money whatsoever in order to apply or respond to, or participate in any Campaign  (whether such fee, charge, cost or money is asked for or required of the User in the Campaign itself or in any communication with the User that takes place as a result of a Campaign) without our prior written approval (which approval may be withheld or, if approved, approved subject to subject to such conditions as we determine in our absolute discretion);
                </li>
                <li>
                  you must not make any public comment (includes news release, advertising and/or promotional material) naming us or otherwise relating to us without our prior written approval, which approval may be withheld by us in our sole discretion; and
                </li>
                <li>
                  you must not sell, transfer, hawk or otherwise distribute any Campaign materials supplied or prepared by us, or acquired by you from us, to any third parties without our prior written approval, which approval may be withheld by us in our sole discretion.
                </li>
              </ol>
            </li>
            <li>
              <p>You agree to indemnify (and to keep indemnified) Paddl, its officers, employees and agents against all Losses incurred by Paddl in connection with:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  any Claim arising from or relating to your listing or proposed listing of any Campaigns on the Platform;
                </li>
                <li>
                  any breach by you of the Terms;
                </li>
                <li>
                  all Claims to the extent that such Claims are a result, directly or indirectly, of any negligent, dishonest or fraudulent act or omission by you; or
                </li>
                <li>
                  any Claim arising from or relating to an actual or alleged breach by you of any law, legislation, regulations, by-laws, ordinances or codes of conduct consequent, directly or indirectly, on the publication of one or more of your Campaigns on the Platform.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Misuse of User data
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>Any personal information (within the meaning of that term in the Privacy Act 1988 (Cth)) of any User that you obtain through your use of the Platform must only be used by you for the particular purpose (Campaign) for and in relation to which that personal information was provided to you.  Any other use of the personal information of any User is strictly prohibited.</p>
            </li>
            <li>
              <p>Selling or offering any service or product not directly related to the purpose in respect of which that personal information was provided to you by a User is a misuse of User data and is strictly prohibited.</p>
            </li>
            <li>
              <p>You must not under any circumstances provide any User’s personal information you have obtained through your use of the Platform to any third party (which includes any of your affiliates and/or related parties).  This restriction on forwarding personal information applies irrespective of whether you receive direct financial benefit for doing so.</p>
            </li>
            <li>
              <p>If we believe you have misused User data for any reason, we reserve the right to:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  immediately suspend or terminate your Employer Account;
                </li>
                <li>
                  immediately suspend or terminate the Employer Account of any party that has received User personal information from you in breach of these terms;
                </li>
                <li>
                  report any potential contraventions of the Privacy Act 1988 (Cth) by you to the relevant authorities; and
                </li>
                <li>
                  take legal action against you for any Loss suffered by us (or the User) as a result of your unauthorised use of any User’s personal information, including by seeking injunctive relief or the award of monetary damages.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Placing Campaigns
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>You must ensure that all Campaigns comply with all applicable legislation, by-laws,  regulations, ordinances and codes of conduct, relevant and applicable to the country or location of the Campaign.  In Australia this includes, but is not limited to:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  the Competition and Consumer Act 2010 (Cth) including but not limited to Section 31 of Schedule 2 which requires that if you are a company you must not mislead persons seeking employment as to the availability, nature, terms or conditions or any other matter relating to the employment opportunity being offered;
                </li>
                <li>
                  Fair Trading Acts in all applicable States and Territories;
                </li>
                <li>
                  Privacy Act 1988 (Cth) including the Australian Privacy Principles;
                </li>
                <li>
                  Fair Work Act 2009 (Cth);
                </li>
                <li>
                  Equal Opportunity Act 2010 (Vic);
                </li>
                <li>
                  Sex Discrimination Act 1984 (Cth);
                </li>
                <li>
                  Age Discrimination Act 2004 (Cth);
                </li>
                <li>
                  Disability Discrimination Act 1992 (Cth);
                </li>
                <li>
                  Racial Discrimination Act 1975 (Cth); and
                </li>
                <li>
                  All other anti-discrimination and equal opportunity legislation applicable in the State or Territory in which you do business.
                </li>
              </ol>
            </li>
            <li>
              <p>We reserve the right to refuse you:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  an Employer Account; and/or;
                </li>
                <li>
                  <p>the use of the Platform and/or goods and services provided by Paddl;</p>
                </li>
              </ol>
              <p>and to reject or withdraw any Campaign from the Platform without notice or reason.</p>
              <p>The reasons for this may include (but are not limited to):</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  actual or suspected non-compliance with the Terms or any applicable law or regulation;
                </li>
                <li>
                  misleading, inaccurate, incorrect or inappropriate content or information provided as part of a Campaign; or
                </li>
                <li>
                  the receipt of complaints made about you.
                </li>
              </ol>
            </li>
            <li>
              <p>You are not permitted to insert links to an external website or an externally hosted application form in a Campaign (or from your Employer Account) without our express written approval (which may be granted, withheld or withdrawn at our discretion).</p>
            </li>
            <li>
              <p>You are not permitted to promote or refer to brands other than those associated with your business (or a business operated by one or more of your related parties) anywhere on the Platform.</p>
            </li>
            <li>
              <p>You must ensure that all of your Campaigns are tagged appropriately. It is your responsibility to ensure that you familiarise yourself with the available tags to ensure appropriate filtering of Users who apply for any Campaigns you post.</p>
            </li>
            <li>
              <p>We reserve the right, and you must accept as a condition of placing a Campaign on the Platform, our right to re-tag Campaigns.</p>
            </li>
            <li>
              <p>Unless extended by us, Campaigns are valid for 30 days (although you can choose to end the Campaign earlier). You will have access to details of all applicants for a Campaign for 30 days after the expiration of the Campaign.</p>
            </li>

            <div className = "terms-provider-content__subtitle-between-list-items">
              <b>Employment Campaigns</b>
            </div>
            <li>
              <p>You may only post Campaigns that are in respect of a genuine employment or experience opportunity that is current as at the time of posting the Campaign, and for which you are currently recruiting.  We reserve the right to request any information from you that we deem necessary to verify that a genuine employment opportunity exists.</p>
            </li>
            <li>
              <p>For any Campaign relating to an employment, training, internship or placement opportunity, you are responsible for ensuring that all Campaigns materials comply with the requirements of all applicable legislation (including, for employment in Australia, the Fair Work Act 2009 (Cth)) and, in particular, correctly detail all relevant salary and/or wage requirements applicable to the advertised position.</p>
            </li>
            <li>
              <p>If, in our sole discretion, we deem a Campaign to be unsuitable for Users, we reserve the right to not accept a Campaign or, before publication on the Platform, to require modification of the Campaign for any of the following positions:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  positions paid by commission only;
                </li>
                <li>
                  positions where payment is by profit share only;
                </li>
                <li>
                  positions that offer less than award wages;
                </li>
                <li>
                  positions that offer any form of 'board in exchange' arrangement where the User receives accommodation as payment for work performed;
                </li>
                <li>
                  positions that do not offer a genuine employment opportunity or that require applicants to register with an organisation for possible opportunities or pay a third-party;
                </li>
                <li>
                  positions where the employer is offering 'cash' or 'cash in hand';
                </li>
                <li>
                  positions offering payment by 'piece rates', i.e. where payment is per product produced, per delivery, per sale made etc.;
                </li>
                <li>
                  positions which are, in practical effect, franchise ownership opportunities and/or pyramid schemes;
                </li>
                <li>
                  modelling positions;
                </li>
                <li>
                  positions which involve unpaid or reduced pay for training/trial periods, except where a federal or state award makes allowances for such arrangements;
                </li>
                <li>
                  positions which involve any illegal activity or the performance of other activity which may expose the employee to a serious risk of injury or death;
                </li>
                <li>
                  positions which require the employee to purchase a product or service, or requires an application fee, registration fee, security deposit or similar as a condition of employment;
                </li>
                <li>
                  positions involving unpaid internships, except where the internship is undertaken as a vocational placement or is classified as voluntary work in a registered not-for-profit organisation;
                </li>
                <li>
                  overseas voluntary positions; and
                </li>
                <li>
                  scholarships that are used to pay an employee's (User's) wage.
                </li>
              </ol>
            </li>

            <div className = "terms-provider-content__subtitle-between-list-items">
              <b>Volunteering and Internship Campaigns</b>
            </div>
            <li>
              <p>The following terms and conditions apply to all Campaigns for volunteer positions:</p>
              <ol className = "terms-provider-content__lower-alpha-list">
                <li>
                  all Campaigns for volunteering opportunities must adhere to:
                  <ol className = "terms-provider-content__lower-roman-list">
                    <li>
                      the definition of “Volunteering” as defined by Volunteers Australia (as amended from time to time) and which definition can be found at Volunteering Australia’s website, which can be found at the following link: <a href = "https://www.volunteeringaustralia.org">https://www.volunteeringaustralia.org</a>; and
                    </li>
                    <li>
                      all policies and standards prescribed by Volunteers Australia (as amended from time to time) in relation to Volunteers, which can be found at the following link: <a href = "https://www.volunteeringaustralia.org">https://www.volunteeringaustralia.org</a>;
                    </li>
                  </ol>
                </li>
                <li>
                  <p>you must inform and afford all new volunteers their Volunteer Rights &amp; Responsibilities as stipulated by Volunteering Australia;</p>
                </li>
                <li>
                  <p>all new volunteers must be provided with adequate and appropriate induction, training, support and supervision;</p>
                </li>
                <li>
                  <p>all volunteer opportunities must be less than 16 hours per week, not replacing paid workers, nor constituting a threat to the job security of paid workers;</p>
                </li>
                <li>
                  <p>you must have an ABN and be registered as a not-for-profit entity in accordance with the Australian Charities and Not-for-profit Commission; and </p>
                </li>
                <li>
                  <p>you may be asked to submit certificates of currency for both Public Liability (to a minimum of $5,000,000 coverage) and/or Volunteer Personal Accident insurance.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>All terms and conditions applicable to all unpaid internship positions advertised by a Campaign must fully comply with the provisions of the Fair Work Act 2009 (Cth).</p>
            </li>
            <li>
              <p>You expressly authorise Paddl to store and retain all applications submitted by Users in response to any Campaign you publish on the Platform.</p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            General
          </header>
          <ol className = "terms-provider-content__nested-number-list-layer-2">
            <li>
              <p>If any part of the Terms is held invalid that part shall be severed from the Terms and the remainder of the Terms will continue to be valid and enforceable.</p>
            </li>
            <li>
              <p>Termination of your Employer Account will not end those provisions in the Terms that are capable of surviving the termination of your Employer Account. </p>
            </li>
            <li>
              <p>The laws governing the Terms will be the laws in Victoria, Australia and you submit to the exclusive jurisdiction of the courts of Victoria for the resolution of all disputes concerning or arising from or in connection with the Terms.</p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
