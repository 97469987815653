import './index.scss';
import React from 'react';

import ImgTag                     from 'component-lib/components/img-tag';
import Text                       from 'component-lib/type/text';
import { style16pxLightBluegrey } from 'component-lib/type/text/styles';

const LevelResponsibility = ({ level, responsibility }) => {
  if (!level || !responsibility) return null;

  const iconSrc = require('component-lib/icons/position.svg');

  return (
    <div className = "level-responsibility">
      <ImgTag
        src   = { iconSrc }
        width = '16px'
      />
      <Text { ...style16pxLightBluegrey } text = { [level, responsibility].join(" ") } />
    </div>
  );
}

export default LevelResponsibility;
