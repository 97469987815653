import React from 'react';
import { Modal } from 'components/v2/modal';

import { ModalSidenavSupportContent } from 'components/v2/modal-sidenav-support-content';

export class ModalSidenavSupport extends React.Component {
  render() {
    const {
      appv2,
      withLeftOffset,
    } = this.props;

    const closeFunction = () => {
      appv2.ui.closeModal();
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { withLeftOffset }
        windowWidthSize = "small"
      >
        <ModalSidenavSupportContent />
      </Modal>
    );
  }
}
