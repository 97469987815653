import './index.scss';
import React from 'react';


export function TermsPrivacyContent() {
  return (
    <div className="terms-section-content">
      <header>
        Paddl Privacy Policy
      </header>

      <div>
        <p>
          www.paddl.com (the <b>Platform</b>) is a web application wholly owned by Paddl Co. Pty Ltd (ACN 131 952 004) (<b>Paddl</b>, <b>we</b>, <b>our</b> or <b>us</b>).
        </p>
        <p>
          We appreciate that your privacy is important to you. This policy details how your personal information is handled, including how we collect, hold, use and disclose that information through any interaction you have with us and with any other persons using Paddl.
        </p>
        <p>
          We are committed to protecting your personal information and we comply with the Australian Privacy Principles (APPs) set out in the Privacy Act 1988 (Cth). The APPs regulate the way that organisations can collect, use, keep, secure and disclose personal information. It also gives individuals the right to know what information an organization holds about them and a right to correct that information if it is wrong. Personal information includes any information or opinion, whether true or not, about a person who is identified or whose identity can reasonably be established.
        </p>
        <p>
          This Privacy Statement should be read in conjunction with the Paddl Terms of Service (<a href = "/terms">https://paddl.com/terms</a>).
        </p>
      </div>

      <ol className = "terms-privacy-content__nested-number-list-layer-1">
        <li>
          <header>
            We may collect and hold personal information
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                When you visit Paddl, email Us, complete application/contact forms or respond to surveys, we collect personal information from you about yourself which we require in order to interact with you (your personal information). This may include your full name, contact details, email address, records of your use of Paddl (and the status of that use) and other publicly available information. If you provide us with personal information about another person we will take reasonable steps to ensure that person is aware of this Privacy Policy and may contact them for the purpose of providing a copy of our Privacy Policy to them or seek confirmation from you that this Privacy Policy has been provided to them.
              </p>
            </li>
            <li>
              <p>
                We also collect and hold information regarding activity on Paddl, such as the number of visitors, the date and time of those visits, the number of pages viewed and navigation patterns. This information may not personally identify you. It is obtained using 'cookies' (which are frequently used by websites to identify users' computers and track usage) and provides us with information and statistics that we can use to maintain your session, allow you to have your own personalized account with us, store user preferences and analyse and improve Paddl and our services. This information may be collected by us or on our behalf by third parties.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
              How we use Your Information
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                We will use any of your personal information collected for the purposes described below:
              </p>
              <ol className = "terms-privacy-content__lower-alpha-list">
                <li>
                  Verifying your identity;
                </li>
                <li>
                  Assisting you to use Paddl and our services;
                </li>
                <li>
                  Responding to feedback and complaints;
                </li>
                <li>
                  Sending you communication that you have opted in for (e.g. a newsletter for which you have subscribed);
                </li>
                <li>
                  Research and develop our services;
                </li>
                <li>
                  Enforcing our Terms of Service;
                </li>
                <li>
                  Addressing potentially prohibited or illegal activities;
                </li>
                <li>
                  Contacting you about the services;
                </li>
                <li>
                  When evaluating job applicants and personnel, including their contact details, employment history and educational qualifications;
                </li>
                <li>
                  To protect our legal interests and fulfil our regulatory and tax obligations (if and to the extent necessary);
                </li>
                <li>
                  If you are a student, to share information to prospective employers for the purpose of those employers assessing your eligibility for employment;
                </li>
                <li>
                  If you are student, to share information with your education provider for the purpose of that education provider assessing your academic performance; and any other purpose you would reasonably expect given the nature of our business.
                </li>
              </ol>
            </li>
            <li>
              <p>
                We may also use your personal information for any other purpose which you consent to.
              </p>
            </li>
            <li>
              <p>
                We may also use your personal information for planning, research, product development customization, strategic, financial and other business purposes in relation to our services (and for the purpose of improving our services) and for communicating with you for promotional and marketing purposes (including the promotion of new services that we or our related entities may seek to provide to you).
              </p>
            </li>
            <li>
              <p>
                If you do not wish us to use your personal information for marketing or promotional purposes, you may request at any time to opt out of receiving such communications by contacting us or by using the option to "unsubscribe" from receiving such further communications.
              </p>
            </li>
            <li>
              <p>
                Without limiting the above, in order to deliver the services you require, we may disclose your personal information to external organisations in relation to our provision of the services to you. These organisations may carry out any one or more of the following functions for us:
              </p>
              <ol className = "terms-privacy-content__lower-alpha-list">
                <li>
                  Customer enquiries;
                </li>
                <li>
                  Mailing operations;
                </li>
                <li>
                  Billing and debt-recovery functions;
                </li>
                <li>
                  Credit, fraud and other security checks;
                </li>
                <li>
                  Information technology services;
                </li>
                <li>
                  Installation, maintenance and repair services;
                </li>
                <li>
                  Marketing and telemarketing services;
                </li>
                <li>
                  Market research; and
                </li>
                <li>
                  Website usage analysis.
                </li>
              </ol>
            </li>
            <li>
              <p>
                We take reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.
              </p>
            </li>
            <li>
              <p>
                In addition, we may disclose your personal information to:
              </p>
              <ol className = "terms-privacy-content__lower-alpha-list">
                <li>
                  Your authorised representatives or your legal advisers (eg. when requested by you to do so);
                </li>
                <li>
                  Credit-reporting and fraud-checking agencies;
                </li>
                <li>
                  Credit providers (for credit related purposes such as credit-worthiness, credit rating, credit provision and financing);
                </li>
                <li>
                  Our related companies;
                </li>
                <li>
                  Our professional advisers, including its accountants, auditors and lawyers;
                </li>
                <li>
                  Other information technology service providers (for example, if you obtain services from other providers, we may need to disclose your personal information for billing purposes); and
                </li>
                <li>
                  Government and regulatory authorities and other organisations, as required or authorised by law.
                </li>
              </ol>
            </li>
            <li>
              <p>
                Paddl is a global digital platform and as such we will seek your consent to share your personal information with prospective employers from overseas if you submit an application via the Paddl Platform. Prospective employers may be located in any country in the world and it will not always be possible for us to verify in which country an employer is based. When you submit an application (and prior to us sharing your personal information with overseas employers), we will seek your explicit consent to permit us to disclose your personal information to prospective employers who are located overseas. The proposed disclosure will be in the form of a “pop-up” notification in the Paddl web application so that you can make an informed decision about providing your consent, at the time of seeking your consent to an overseas disclosure, we will inform you that:
              </p>
              <ol className = "terms-privacy-content__lower-alpha-list">
                <li>
                  by providing your consent, an overseas recipient will not be accountable for any breaches of the Australian Privacy Principles in connection with its handling of your personal information and you may not be able to seek redress for any such breaches;
                </li>
                <li>
                  you may not be able to access or retrieve your personal information from the overseas recipient;
                </li>
                <li>
                  we are unable to and will not be taking any steps to ensure that any overseas recipients will comply with the Australian Privacy Principles;
                </li>
                <li>
                  due to the global nature of the Paddl platform, if you do not provide your consent to us disclosing your personal information to overseas recipient, you may not be able to apply for a position using the Paddl platform.
                </li>
              </ol>
            </li>
          </ol>
          <p>
          We will only propose to share your personal information with overseas employers in circumstances where you would reasonably expect us to do so and if the disclosure is related to the purposes for which your information was collected.
        </p>
        </li>

        <li>
          <header>
            Other websites
          </header>
          <p>
          Paddl and our email communications may contain links to other websites that are not owned by us. We do not control these websites or any of their content and if you visit these websites they will be governed by their own terms (including privacy policies).
        </p>
        </li>

        <li>
          <header>
            How we disclose your information
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                We will disclose your personal information when you have consented or where you would reasonably expect us to do so and if the disclosure is for an activity related to the purposes for which your information was collected.
              </p>
            </li>
            <li>
              <p>
                Subject to the above information regarding overseas disclosure, we may disclose your personal information to related entities or third parties (for example, prospective employers) for the purpose of the services (and such other services and tools that we provide) or who provide services to us in connection with Paddl. In doing so, we will require such persons (if they are based in Australia) not to hold, use or disclose your personal information in a manner inconsistent with the APPs.
              </p>
            </li>
            <li>
              <p>
                There may also be some exceptional circumstances for disclosing your personal information to other third parties – for example, if disclosure is required or we are permitted or authorised to do so by law or if we consider it is reasonably necessary for the prevention, detection, investigation, prosecution or punishment of criminal offences, breaches of law or improper conduct or for the enforcement of law or the preparation and conduct of legal proceedings. Disclosure may also be necessary for investigating and resolving disputes or complaints concerning your use of the service or to enable the investigation and resolution of other disputes or complaints.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Data security and quality
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                We will take reasonable steps to protect the personal information we collect and hold from misuse, loss and from unauthorised access and modification, and to make sure it is accurate, complete and up-to-date when we collect, use or disclose it. The steps we take include internal and external security, restricting access to personal information to those who have a need to know, maintain technological products to prevent unauthorised computer access and regularly reviewing our technology to maintain security. We use secured IT systems.
              </p>
            </li>
            <li>
              <p>
                To assist us, please ensure you provide us with your correct details, and let us know if you believe the information we have about you is inaccurate, incomplete or out of date. We will take reasonable steps to correct information which is inaccurate, incomplete or out of date or give you reasons if we disagree (although we will take reasonable steps to note your claim that the information is inaccurate, incomplete or out of date if so requested).
              </p>
            </li>
            <li>
              <p>
                We may take steps to destroy or permanently de-identify information when it is no longer needed for any purpose for which it may be used or disclosed. We may retain and use de-identified information.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            General Data Protection Regulations
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                If you are a resident of the European Union (<b>EU</b>), you may contact us to exercise the following further rights:
              </p>
              <ol className = "terms-privacy-content__lower-alpha-list">
                <li>
                  request for the erasure of your personal information;
                </li>
                <li>
                  request for the restriction of processing of your personal information;
                </li>
                <li>
                  object to the processing of your personal information; and/or
                </li>
                <li>
                  request to transfer your personal information to you or a third-party.
                </li>
              </ol>
            </li>
            <li>
              <p>
                You also have the right to lodge a complaint with the relevant EU supervisory authority if we have contravened any applicable laws or regulations.
              </p>
            </li>
            <li>
              <p>
                All requests made to us in exercising the rights above will be processed within a reasonable time except where we refuse such requests in accordance with any applicable laws or regulations.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <header>
            Access, correction and complaints
          </header>
          <p>
            You can request, and we will provide you access to, any personal information we hold about you unless we consider a legal exception applies, in which case we will give you reasons for our denial of access. You can also let us know if you have any concerns or complaints about the way we are handling your personal information and we will do all we can to address them. You can do this by emailing us at support@paddljobs.com. We may charge you for our reasonable costs and expenses that are incurred in providing such access to you.
          </p>
        </li>

        <li>
          <header>
            Changes to this policy
          </header>
          <p>
            This Privacy Policy is current at 18th December, 2018. We reserve the right to amend or update it at any time (especially if our practices and/or procedures change). Any changes will take effect once they are included on Paddl.
          </p>
        </li>

        <li>
          <header>
            Contact Us
          </header>
          <ol className = "terms-privacy-content__nested-number-list-layer-2">
            <li>
              <p>
                If you believe that your privacy has been breached or have any questions in relation to this Privacy Policy, you can contact us by writing to us or contacting us by email on support@paddljobs.com or by mail at the following address:
              </p>
              <p>
                Paddl Co. Pty Ltd<br />
                Operations Office<br />
                c/- PlayLab, 196 High St<br />
                Shepparton VIC 3630<br />
              </p>
            </li>
            <li>
              <p>
                You can find further information on privacy and about the APPs at the Office of the Australian Information Commissioner – <a href = "http://www.privacy.gov.au/">http://www.privacy.gov.au/</a>
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
