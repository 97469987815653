import { defined } from 'lib/helpers';

const numericValue = (value) => {
  const intValue = parseInt(defined(value), 10);

  if (isNaN(intValue) || intValue < 0) {
    throw Error('Unexpected value');
  }

  return intValue;
};

class NaturalNumber {
  constructor(value) {
    this.value = (value === null ? null : numericValue(value));
  }

  isEmpty() {
    return (this.value === null);
  }

  isValid() {
    return (this.value !== null);
  }

  static createEmpty() {
    return new NaturalNumber(null);
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new NaturalNumber(data);
  }
}

export default NaturalNumber;
