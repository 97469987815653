import { defined } from 'lib/helpers';

import Hackathon from 'lib/api/var-competition/hackathon';

import PercentageComplete          from 'lib/api/activities/generics/duration-common/percentage-complete';
import DurationTimeboxDayMonthYear from 'lib/api/activities/generics/duration-timebox-day-month-year';
import Year                        from 'lib/api/activities/generics/duration-common/year';
import Month                       from 'lib/api/activities/generics/duration-common/month';
import Day                         from 'lib/api/activities/generics/duration-common/day';
import Bool                        from 'lib/api/activities/generics/fields/bool';

class HackathonDurationData {
  constructor({ state, appv2 }) {
    this._ourState = defined(state);
    this._appv2    = defined(appv2);

    let _alreadyInsideHook = false;
    let _hasBeenPopulated  = false;

    this._ourState.addHook('setAsSavePoint', () => {
      const detailsData = new (Hackathon.detailsDataClass())({ state, appv2 });
      const sourceData  = detailsData.getSourceData('eventLink');

      if (Object.keys(sourceData).length === 0) {
        return null;
      }

      if (_alreadyInsideHook || _hasBeenPopulated) {
        return null;
      }

      _alreadyInsideHook = true;

      this.populateDuration({
        startYear:  sourceData.startDate.year,
        startMonth: sourceData.startDate.month,
        startDay:   sourceData.startDate.day,
        endYear:    sourceData.endDate.year,
        endMonth:   sourceData.endDate.month,
        endDay:     sourceData.endDate.day,
      }, () => {
        _hasBeenPopulated = true;
        _alreadyInsideHook = false;
      });
    });

    this.isEqualToFields = ({ data }) => {
      const currentDuration = this._ourState.get({ id: 'duration' });

      if (data.duration !== currentDuration) {
        return false;
      }

      return true;
    };

    this.toActivityDuration = () => {
      return this._ourState.get({ id: 'duration' });
    };

    this.getDuration = () => {
      const duration = this._ourState.get({ id: 'duration' });
      const { startYear, startMonth, startDay, endYear, endMonth, endDay, percentageComplete, isPercentageCompleteSetManually } = duration;

      return {
        startDay:                        startDay                        && startDay.value,
        startMonth:                      startMonth                      && startMonth.value,
        startYear:                       startYear                       && startYear.value,
        endDay:                          endDay                          && endDay.value,
        endMonth:                        endMonth                        && endMonth.value,
        endYear:                         endYear                         && endYear.value,
        percentageComplete:              percentageComplete              && percentageComplete.value,
        isPercentageCompleteSetManually: isPercentageCompleteSetManually && isPercentageCompleteSetManually.value,
      };
    };

    this.setDuration = ({ startYear, startMonth, startDay, endYear, endMonth, endDay, percentageComplete, isPercentageCompleteSetManually }) => {
      this._ourState.change({
        duration: new DurationTimeboxDayMonthYear({
          startYear:                       defined(startYear)  && new Year(startYear),
          startMonth:                      defined(startMonth) && new Month(startMonth),
          startDay:                        defined(startDay)   && new Day(startDay),
          endYear:                         defined(endYear)    && new Year(endYear),
          endMonth:                        defined(endMonth)   && new Month(endMonth),
          endDay:                          defined(endDay)     && new Day(endDay),
          percentageComplete:              new PercentageComplete(defined(percentageComplete)),
          isPercentageCompleteSetManually: new Bool(defined(isPercentageCompleteSetManually)),
          // Use defined in new SomeDataType instead of `defined(value) && SOMETHING_TO_BE_RENDERED`
          // when the value can be null or false
        }),
      });
    };

    this.populateDuration = ({ startYear, startMonth, startDay, endYear, endMonth, endDay }, callback = () => {}) => {
      const calculatedPercentageComplete = PercentageComplete.calculate({
        startYear,
        startMonth,
        startDay,
        endYear,
        endMonth,
        endDay,
      });

      this._ourState.reset({
        duration: new DurationTimeboxDayMonthYear({
          startYear:                       defined(startYear)  && new Year(startYear),
          startMonth:                      defined(startMonth) && new Month(startMonth),
          startDay:                        defined(startDay)   && new Day(startDay),
          endYear:                         defined(endYear)    && new Year(endYear),
          endMonth:                        defined(endMonth)   && new Month(endMonth),
          endDay:                          defined(endDay)     && new Day(endDay),
          percentageComplete:              calculatedPercentageComplete,
          isPercentageCompleteSetManually: new Bool(false),
        }),
      }, callback);
    };

    this.isEverythingEmpty = () => {
      return this._ourState.get({ id: 'duration' }).isEmpty();
    };

    this.isValid = () => {
      return this._ourState.get({ id: 'duration' }).isValid();
    };
  }

  static createEmptyFields() {
    return {
      duration: DurationTimeboxDayMonthYear.createEmpty(),
    };
  }

  static fieldsFromActivityDuration({ activityDuration }) {
    return {
      duration: activityDuration,
    };
  }
}

export default HackathonDurationData;
