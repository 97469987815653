import './index.scss';
import React from 'react';

export const CancelButton = ({ onClick }) => {
  return (
    <button
      className = "cancel-button"
      onClick   = { () => { onClick(); } }
    >
      <span>Cancel</span>
    </button>
  );
};
