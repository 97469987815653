export const getAttendanceInPersonAndOnline = ({ attendanceInPerson, attendanceOnline }) => {
  const attendanceArray = [
    { value: attendanceInPerson, string: 'In Person' },
    { value: attendanceOnline,   string: 'Online'    },
  ];

  const filteredAttendance = attendanceArray.filter(attendance => (attendance.value));
  const mappedAttendance   = filteredAttendance.map(attendance => (attendance.string));

  return mappedAttendance.join(" / ");
};
