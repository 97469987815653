import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';

class ApiSkills {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetchSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/skills/suggestions',
      method: 'GET',
      params: { substring: substring },
    })
      .then((response) => {
        return response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });
      });
  }
}

export default ApiSkills;
