import { defined } from 'lib/helpers';

const dataPrefix = 'data_';
const dataRegexp = new RegExp(`^${dataPrefix}(.+)\$`);

class PrefixedKey {
  static data(str) {
    return `${dataPrefix}${str}`;
  }

  static isData(str) {
    const matched = str.match(dataRegexp);
    return (matched !== null);
  }

  static unprefixData(str) {
    const matched = str.match(dataRegexp);
    return (matched === null ? null : matched[1]);
  }
}

class State {
  constructor({ component }) {
    this._component = component;
    this._component.state = {};

    this.dataSet = (obj, callback = () => {}) => {
      const objWithPrefixedValues = Object.keys(obj).reduce((accum, id) => {
        const key = PrefixedKey.data(id);
        const value = defined(obj[id]);
        accum[key] = value;
        return accum;
      }, {});

      this._component.setState(objWithPrefixedValues, callback);
    };

    this.dataGet = ({ id }) => {
      const key = PrefixedKey.data(defined(id));
      return this._component.state[key];
    };

    this.dataGetAll = () => {
      const dataKeys = Object.keys(this._component.state).filter((key) => {
        return PrefixedKey.isData(key);
      });

      return dataKeys.reduce((obj, key) => {
        const keyWithoutPrefix = PrefixedKey.unprefixData(key);
        obj[keyWithoutPrefix] = this._component.state[key];
        return obj;
      }, {});
    };
  }
}

export default State;
