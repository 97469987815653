import { defined } from 'lib/helpers';

import { isEndDateBeforeStartDate } from 'lib/api/activities/generics/duration-common';

import Year               from 'lib/api/activities/generics/duration-common/year';
import Month              from 'lib/api/activities/generics/duration-common/month';
import Day                from 'lib/api/activities/generics/duration-common/day';
import PercentageComplete from 'lib/api/activities/generics/duration-common/percentage-complete';
import Bool               from 'lib/api/activities/generics/fields/bool';

class DurationTimeboxDayMonthYear {
  constructor(data) {
    this.startYear                       = defined(data.startYear);
    this.startMonth                      = defined(data.startMonth);
    this.startDay                        = defined(data.startDay);
    this.endYear                         = defined(data.endYear);
    this.endMonth                        = defined(data.endMonth);
    this.endDay                          = defined(data.endDay);
    this.percentageComplete              = defined(data.percentageComplete);
    this.isPercentageCompleteSetManually = defined(data.isPercentageCompleteSetManually);
  }

  toApiFormat() {
    const percentageComplete = this.isPercentageCompleteSetManually.value ? this.percentageComplete.toApiFormat() : null;

    return {
      startDate: {
        year:  this.startYear.toApiFormat(),
        month: this.startMonth.toApiFormat(),
        day:   this.startDay.toApiFormat(),
      },
      endDate: {
        year:  this.endYear.toApiFormat(),
        month: this.endMonth.toApiFormat(),
        day:   this.endDay.toApiFormat(),
      },
      percentageComplete: percentageComplete,
    };
  }

  isEmpty() {
    return (
      this.startYear     === null
      && this.startMonth === null
      && this.startDay   === null
      && this.endYear    === null
      && this.endMonth   === null
      && this.endDay     === null
    );
  }

  cloneWithNewFinishDate({ day, month, year }) {
    const newEndDay   = new Day(day);
    const newEndMonth = new Month(month);
    const newEndYear  = new Year(year);

    return new DurationTimeboxDayMonthYear({
      startYear:                       this.startYear,
      startMonth:                      this.startMonth,
      startDay:                        this.startDay,
      endYear:                         newEndYear,
      endMonth:                        newEndMonth,
      endDay:                          newEndDay,
      percentageComplete:              this.percentageComplete,
      isPercentageCompleteSetManually: this.isPercentageCompleteSetManually,
    });
  }

  isValid() {
    return (
      this.startYear     !== null
      && this.startMonth !== null
      && this.startDay   !== null
      && this.endYear    !== null
      && this.endMonth   !== null
      && this.endDay     !== null
      && !isEndDateBeforeStartDate({
        startYear:  this.startYear && this.startYear.value,
        startMonth: this.startMonth && this.startMonth.value,
        startDay:   this.startDay && this.startDay.value,
        endYear:    this.endYear && this.endYear.value,
        endMonth:   this.endMonth && this.endMonth.value,
        endDay:     this.endDay && this.endDay.value,
      })
    );
  }

  getProgressState() {
    if (this.isPercentageCompleteSetManually.value) return 'incomplete';
    if (this.percentageComplete.value === 100) return 'complete';
    return 'in-progress';
  }

  static createEmpty() {
    return new DurationTimeboxDayMonthYear({
      startYear:                       null,
      startMonth:                      null,
      startDay:                        null,
      endYear:                         null,
      endMonth:                        null,
      endDay:                          null,
      percentageComplete:              null,
      isPercentageCompleteSetManually: null,
    });
  }

  static fromApiFormat(data) {
    const startDate = defined(data.startDate);
    const endDate = defined(data.endDate);

    const isPercentageCompleteSetManually = (typeof data.percentageComplete === 'number')
      ? new Bool(true)
      : new Bool(false);

    const calculatedPercentageComplete = PercentageComplete.calculate({
      startYear:  startDate.year,
      startMonth: startDate.month,
      startDay:   startDate.day,
      endYear:    endDate.year,
      endMonth:   endDate.month,
      endDay:     endDate.day,
    });

    const percentageComplete = isPercentageCompleteSetManually.value
      ? new PercentageComplete(data.percentageComplete)
      : calculatedPercentageComplete;

    return new DurationTimeboxDayMonthYear({
      startYear:                       new Year(defined(startDate.year)),
      startMonth:                      new Month(defined(startDate.month)),
      startDay:                        new Day(defined(startDate.day)),
      endYear:                         new Year(defined(endDate.year)),
      endMonth:                        new Month(defined(endDate.month)),
      endDay:                          new Day(defined(endDate.day)),
      percentageComplete:              percentageComplete,
      isPercentageCompleteSetManually: isPercentageCompleteSetManually,
    });
  }
}

export default DurationTimeboxDayMonthYear;
