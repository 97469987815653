import './disabled.scss';
import React from 'react';

import PanelFormHeadline                from 'components/v2/activities/generics/panel-form-headline';
import Datepicker                       from 'components/v2/input-fields/datepicker';
import { ProgressBarRangeInputSection } from 'component-lib/components/progress-bar-range-input-section';
import { ProgressBarRangeInput }        from 'component-lib/components/progress-bar-range-input';

const DisabledDurationPanelForm = ({
  startDate,
  endDate,
  percentageComplete,
}) => {
  const titleCopy  = `${percentageComplete}% Complete`;
  const titleColor = (percentageComplete === 100) ? 'green' : 'blue';
  const fillColor  = (percentageComplete === 100) ? 'green' : 'blue';
  const trackColor = 'blue-xxl';

  return (
    <div className = "v2-disabled-duration-panel-form">
      <PanelFormHeadline
        iconSrc     = { require('images/v2/xp/activity-duration.svg') }
        title       = 'Progress'
        description = 'Enter your Start and Finish dates for this Activity...'
      />

      <div className = "v2-disabled-duration-panel-form__container">
        <div className = "columns v2-disabled-duration-panel-form__form">
          <div className = "column">
            <Datepicker
              isReadonly
              label       = "Start"
              placeholder = "Select Date"
              value       = { startDate }
            />
          </div>
          <div className = "column">
            <Datepicker
              isReadonly
              label       = "Finish"
              placeholder = "Select Date"
              value       = { endDate }
              message     = "Date finished or expected to finish"
            />
          </div>
        </div>
        <div className = "v2-disabled-duration-panel-form__progress-bar-range-input-section">
          <ProgressBarRangeInputSection
            titleCopy  = { titleCopy }
            titleColor = { titleColor }
          >
            <ProgressBarRangeInput
              isEditable         = { false }
              fillColor          = { fillColor }
              trackColor         = { trackColor }
              percentageComplete = { percentageComplete }
            />
          </ProgressBarRangeInputSection>
        </div>
      </div>
    </div>
  );
};

export default DisabledDurationPanelForm;
