import './index.scss';
import React from 'react';

export const FacebookCircle = (props) => {
  return (
    <a href = { props.url } target = "_blank" onClick = { props.onClick }>
      <svg className = "facebook-circle" width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path className = "facebook-circle__bg" d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z" />
        <path className = "facebook-circle__content" d="M29.6485 38.9043V27.2569H33.6192L34.2137 22.7176H29.6485V19.8194C29.6485 18.5052 30.0191 17.6096 31.9332 17.6096L34.3745 17.6085V13.5486C33.9522 13.4934 32.5031 13.3698 30.8172 13.3698C27.2973 13.3698 24.8877 15.4851 24.8877 19.37V22.7176H20.9069V27.2569H24.8877V38.9043H29.6485Z" />
      </svg>
    </a>
  );
};
