import { defined } from 'lib/helpers';

export const EDUCATION_ID   = 1;
export const COMPETITION_ID = 2;
export const EMPLOYMENT_ID  = 3;

export const VERIFIED_VARIANTS = [14, 15];

const RELATION = {
  [EDUCATION_ID]:   [5, 6, 3, 1, 2, 7, 14, 15],
  [COMPETITION_ID]: [13, 4],
  [EMPLOYMENT_ID]:  [9, 11, 10, 8, 12],
};

const filterOutVerifiedVariants = (categoryId) => {
  return defined(RELATION[categoryId]).filter(e => VERIFIED_VARIANTS.indexOf(e) === -1);
};

class Education {
  static icon()            { return require('component-lib/activity-artwork/category-education.svg'); }
  static title()           { return 'Education'; }
  static subTitle()        { return 'Degree, Course, Learning Activity.'; }
  static themeColor()      { return 'purple'; }
  static themeColorL()     { return 'purple-xl'; }
  static themeColorD()     { return 'purple-d'; }
  static visibleVariants() { return filterOutVerifiedVariants(EDUCATION_ID); }
}

class Competition {
  static icon()            { return require('component-lib/activity-artwork/category-competition.svg'); }
  static title()           { return 'Competition'; }
  static subTitle()        { return 'Tournament, Prize, Competition.'; }
  static themeColor()      { return 'yellow'; }
  static themeColorL()     { return 'yellow-l'; }
  static themeColorD()     { return 'yellow-d'; }
  static visibleVariants() { return filterOutVerifiedVariants(COMPETITION_ID); }
}

class Employment {
  static icon()            { return require('component-lib/activity-artwork/category-employment.svg'); }
  static title()           { return 'Employment'; }
  static subTitle()        { return 'Internship, Job, Business Venture.'; }
  static themeColor()      { return 'blue'; }
  static themeColorL()     { return 'blue-xl'; }
  static themeColorD()     { return 'blue-d'; }
  static visibleVariants() { return filterOutVerifiedVariants(EMPLOYMENT_ID); }
}

export const getCategoryData = ({ categoryId }) => {
  switch (parseInt(defined(categoryId), 10)) {
    case EDUCATION_ID:   return Education;
    case COMPETITION_ID: return Competition;
    case EMPLOYMENT_ID:  return Employment;
    default:             throw Error(`Unknown category ID: ${categoryId}`);
  }
};

export const findCategoryIdBy = ({ variantId }) => {
  const categoryIds = Object.keys(RELATION);

  return categoryIds.find((id) => {
    return (RELATION[id].indexOf(variantId) !== -1);
  });
};
