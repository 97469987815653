import './index.scss';
import React from 'react';

import { fetchStringOfYMWD } from 'lib/helpers';
import VariantMapping from 'lib/api/variant-mapping';

import ImgTag               from 'component-lib/components/img-tag';
import Text                 from 'component-lib/type/text';
import { ProgressModalBox } from 'components/v2/progress-modal-box';

import {
  style16pxRegularBluegreyD,
  style26pxMediumBluegreyD,
} from 'component-lib/type/text/styles';

const changeActivityEndDate = (activity, date) => {
  const updatedDurationHandler = activity.get('duration')
    .cloneWithNewFinishDate({
      day:   date.getDate(),
      month: date.getMonth() + 1,
      year:  date.getFullYear(),
    });

  return activity.cloneWithChanges({ duration: updatedDurationHandler });
};

export const ModalProgressContent = ({ appv2, activity, basecampRefreshFunction }) => {
  const activityId                    = activity.get('id');
  const variantId                     = activity.get('variantId');
  const variantHandler                = VariantMapping.getFor({ variantId: variantId });
  const activityHasPercentageComplete = activity.get('duration').hasOwnProperty('percentageComplete');
  const activityDuration              = activity.get('duration');

  const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

  const renderTitle = () => {
    if (activityHasPercentageComplete) {
      const returnString = `Finishing: ` +
        `${activityDuration.endDay.value}/` +
        `${activityDuration.endMonth.value}/` +
        `${activityDuration.endYear.value}`;
      return (
        <div className = "modal-progress-content__title">
          <Text { ...style26pxMediumBluegreyD } text = { returnString } />
        </div>
      );
    }

    const ymwdString = fetchStringOfYMWD({
      startDate: new Date(
        activityDuration.startYear.value,
        activityDuration.startMonth.value - 1,
        activityDuration.startDay.value
      ),
      endDate: new Date(Date.now()),
    });

    return (
      <div className = "modal-progress-content__title">
        <Text { ...style26pxMediumBluegreyD } text = { ymwdString } />
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div
        className = "modal-progress-content__footer"
        onClick   = { () => {
          appv2.ui.openExistingActivityModal({
            activityId: activityId,
            variantId: variantId,
          });
        } }
      >
        <div className = "modal-progress-content__footer-content">
          <ImgTag width = "48px" src = { variantHandler.icon() } />
          <Text { ...style16pxRegularBluegreyD } text = "Go to Card" />
        </div>
        <div className = "modal-progress-content__footer-link" />
      </div>
    );
  };

  const renderAddWeekButton = () => {
    const updatedDate = new Date(
      activityDuration.endYear.value,
      activityDuration.endMonth.value - 1,
      activityDuration.endDay.value + 7
    );

    return (
      <ProgressModalBox
        title      = "Add a Week"
        subtitle   = { `Extend to: ` +
          `${updatedDate.getDate()}/` +
          `${updatedDate.getMonth() + 1}/` +
          `${updatedDate.getFullYear()}`
        }
        imageClass = "date-new"
        onClick    = { () => {
          const newActivity = changeActivityEndDate(activity, updatedDate);

          appv2.api.activities
            .update({ id: activityId, activity: newActivity, isXpFeatureEnabled })
            .then(({ xpNumber, events }) => {
              basecampRefreshFunction();

              if (isXpFeatureEnabled) {
                appv2.ui.openAfterActivityUpdatesModal({
                  xpNumber:          xpNumber,
                  onClick:           () => { appv2.ui.closeModal(); },
                  events:            events,
                  activityId:        activityId,
                  isVariantUnlocked: false,
                });
              } else {
                appv2.ui.closeModal();
              }

              appv2.ui.displaySnackBar({
                style:    'green',
                text:     'Week added',
                callback: () => {},
              });
            });
        } }
      />
    );
  };

  const renderAddMonthButton = () => {
    const updatedDate = new Date(
      activityDuration.endYear.value,
      (activityDuration.endMonth.value - 1) + 1, // -1 for backend-to-frontend date representation conversion, + 1 to add a month
      activityDuration.endDay.value
    );

    return (
      <ProgressModalBox
        title      = "Add a Month"
        subtitle   = { `Extend to: ` +
          `${updatedDate.getDate()}/` +
          `${updatedDate.getMonth() + 1}/` +
          `${updatedDate.getFullYear()}`
        }
        imageClass = "date-new"
        onClick    = { () => {
          const newActivity = changeActivityEndDate(activity, updatedDate);

          appv2.api.activities
            .update({ id: activityId, activity: newActivity, isXpFeatureEnabled })
            .then(({ xpNumber, events }) => {
              basecampRefreshFunction();

              if (isXpFeatureEnabled) {
                appv2.ui.openAfterActivityUpdatesModal({
                  xpNumber:          xpNumber,
                  onClick:           () => { appv2.ui.closeModal(); },
                  events:            events,
                  activityId:        activityId,
                  isVariantUnlocked: false,
                });
              } else {
                appv2.ui.closeModal();
              }

              appv2.ui.displaySnackBar({
                style:    'green',
                text:     'Month added',
                callback: () => {},
              });
            });
        } }
      />
    );
  };

  const renderEditProgressButton = () => {
    return (
      <ProgressModalBox
        title      = "Edit Progress"
        subtitle   = "Go To Card"
        imageClass = "clock"
        onClick    = { () => {
          appv2.ui.openExistingActivityModalWithDuration({
            activityId: activityId,
            variantId: variantId,
          });
        } }
      />
    );
  };

  const renderCompleteActivityButton = () => {
    const subTitle = (activityHasPercentageComplete)
      ? `Card ${activity.get('duration').percentageComplete.value}% Complete`
      : 'End Today';

    return (
      <ProgressModalBox
        title      = "Complete Activity"
        subtitle   = { subTitle }
        imageClass = "completed"
        onClick    = { () => {
          // Why do we make the endDate yesterday's date?
          //   We work on dates with (day, month, year) resolution, but as the
          //   calculation of of "percentageComplete" works at the same resolution,
          //   if we set date as "completed" today, it will still have a small
          //   percentage still to go (you'll end up at 98-99% complete), so we
          //   set it to yesterday to ensure you get 100% complete.
          const todayDate = new Date();
          const endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1);
          const newActivity = changeActivityEndDate(activity, endDate);

          appv2.api.activities
            .update({ id: activityId, activity: newActivity, isXpFeatureEnabled })
            .then(({ xpNumber, events }) => {
              basecampRefreshFunction();

              if (isXpFeatureEnabled) {
                appv2.ui.openAfterActivityUpdatesModal({
                  xpNumber:          xpNumber,
                  onClick:           () => { appv2.ui.closeModal(); },
                  events:            events,
                  activityId:        activityId,
                  isVariantUnlocked: false,
                });
              } else {
                appv2.ui.closeModal();
              }

              appv2.ui.displaySnackBar({
                style:    'green',
                text:     'Activity Completed',
                callback: () => {},
              });
            });
        } }
      />
    );
  };

  const renderBoxes = () => {
    if (activityHasPercentageComplete) {
      return (
        <div className = "modal-progress-content__box_grid">
          { renderAddWeekButton() }
          { renderAddMonthButton() }
          { renderEditProgressButton() }
          { renderCompleteActivityButton() }
        </div>
      );
    }

    return (
      <div className = "modal-progress-content__box_grid">
        { renderEditProgressButton() }
        { renderCompleteActivityButton() }
      </div>
    );
  };

  return (
    <div className = "modal-progress-content">
      <div className = "modal-progress-content__body">
        { renderTitle() }
        { renderBoxes() }
      </div>
      { renderFooter() }
    </div>
  );
};
