import React from 'react';
import Pikaday from 'pikaday';
import Moment from 'lib/helpers/moment';
import './index.scss';

class Datepicker extends React.Component {
  constructor(props) {
    super(props);

    this.pikadayNode = React.createRef();
    this.pikadayRef = React.createRef();
  }

  componentDidMount() {

    const date = this.props.value;

    if (date) {
      this.formatAndSetDate(date);
    }

    this.pikadayRef.current = new Pikaday({
      field: this.pikadayNode.current,
      format: 'D/M/YYYY',
      toString(date, format) {
        // https://github.com/Pikaday/Pikaday#formatting
        // you should do formatting based on the passed format,
        // but we will just return 'D/M/YYYY' for simplicity
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      parse(dateString, format) {
        // dateString is the result of `toString` method
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      },
      onSelect: this.props.onChange,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      reposition: false,
      onOpen: () => {
        const pikadayCalendar = document.querySelector(`.v2-datepicker__wrapper-${this.props.label} > .pika-single`);
        const { style } = pikadayCalendar;

        // Override the position of the calendar to keep it at the bottom of the input field
        Object.assign(style, { position: 'absolute', left: '0', top: '48px' });
      }
    })

    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.pikadayNode.current.focus();
      }, 0);
    }

    document.querySelector(`.v2-datepicker__wrapper-${this.props.label}`).append(
      document.querySelector('body > .pika-single')
    );

  }

  componentDidUpdate(prevProps) {
    const date = this.props.value;
    const previouseDate = prevProps.value;

    if (date && date !== previouseDate) {
      this.formatAndSetDate(date);
    }
  }

  formatAndSetDate(date) {
    this.pikadayNode.current.value = Moment.format_D_MMMM_YYYY(date)
  }


  componentWillUnmount () {
    this.pikadayRef.current.destroy()
  }

  render() {
    const {
      isError,
      placeholder,
      inputFontSize,
      label,
      message,
      isReadonly,
    } = this.props;

    const blockClassName         = 'v2-datepicker__date';
    const isErrorClassName       = isError                   ? `${blockClassName}--error`           : '';
    const inputFontSizeClassName = inputFontSize === "small" ? `${blockClassName}--small-font-size` : `${blockClassName}--default-font-size`;
    const className              = `${blockClassName} ${isErrorClassName} ${inputFontSizeClassName}`;

    return (
      <div className = "v2-datepicker-day-month-year-input">
        <div className = "v2-datepicker-day-month-year-input__container">
          <label className = "v2-datepicker__label" htmlFor = {`pikaday-${label}`}>
            { label }
          </label>
          <div className = {`v2-datepicker__wrapper v2-datepicker__wrapper-${label}`}>
            <input
              type="text"
              ref={this.pikadayNode}
              placeholder={placeholder}
              disabled={isReadonly}
              className={className}
              id={`pikaday-${label}`}
              autocomplete="off"
            />
          </div>
          {
            message &&
            <span className = "v2-datepicker-day-month-year-input__subtitle">
              { message }
            </span>
          }
        </div>
      </div>
    );
  }
}

export default Datepicker;
