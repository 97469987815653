import React from 'react';

import { defined } from 'lib/helpers';

import VariantMapping from 'lib/api/variant-mapping';
import StateWithSavePoints from 'lib/helpers/state-with-save-points';

import Data from 'components/v2/activities/generics/card/data';
import UI from 'components/v2/activities/generics/card/ui';
import UIBehaviourExisting from 'components/v2/activities/generics/card/ui-behaviour-existing';

import { FormFooter } from 'components/v2/form-footer';
import CardFooterExisting from 'components/v2/activities/generics/card-footer/existing';

import DetailsSection from 'components/v2/activities/generics/card/details-section';
import SkillsSection from 'components/v2/activities/generics/skills-section';
import DurationSection from 'components/v2/activities/generics/duration-section';

import './index.scss';

export const DETAILS = 'details';
export const SKILLS = 'skills';
export const DURATION = 'duration';

class CardExisting extends React.Component {
  constructor(props) {
    const { appv2, variantId, modalObject } = props;
    super(props);

    this._variantHandler = VariantMapping.getFor({ variantId });

    // This `state` is SHARED across all the "data" objects.
    // It utilizes (hijacks) the React component's internal state!
    const state = new StateWithSavePoints({ component: this });

    const detailsData = new (this._variantHandler.detailsDataClass())({ state, appv2 });
    const skillsetData = new (this._variantHandler.skillsetDataClass())({ state, appv2 });
    const durationData = new (this._variantHandler.durationDataClass())({ state, appv2 });

    this._data = new Data({
      state,
      appv2,
      variantId,
      detailsData,
      skillsetData,
      durationData,
    });

    const ui = new UI({ state });
    this._uiBehaviour = new UIBehaviourExisting({ ui });

    modalObject.shouldAlertOnLeave = () => {
      return !this._data.isDataPersisted();
    };
  }

  UNSAFE_componentWillMount() {
    const activityId = defined(this.props.activityId);
    const openSection = this.props.openSection;
    const currentProfileId = this.props.appv2.auth.currentProfileId();

    this._data.initializeStateWithEmptyFields();
    this._uiBehaviour.reset();

    if (currentProfileId) {
      return this._data.fetchFromApi({ activityId, isLoggedIn: true })
        .then(() => {
          switch (openSection) {
            case DETAILS: this._uiBehaviour.clickDetailsSection(); break;
            case SKILLS: this._uiBehaviour.clickSkillsSection(); break;
            case DURATION: this._uiBehaviour.clickDurationSection(); break;
            case undefined: break;
            default: throw new Error('Unknown section specifier');
          }
        });
    }

    return this._data.fetchFromApi({ activityId, isLoggedIn: false });
  }

  render() {
    const { appv2, activityId, variantId } = this.props;

    const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

    const onSubmit = () => {
      this._data
        .updateViaApi({ activityId })
        .then(({ xpNumber, events }) => {
          if (!isXpFeatureEnabled) {
            return this.props.onSubmit();
          }

          return appv2.ui.openAfterActivityUpdatesModal({
            xpNumber,
            onClick: () => { this.props.onSubmit(); },
            events,
            activityId,
            isVariantUnlocked: false,
          });
        });
    };

    const onRemove = () => {
      this._data
        .deleteViaApi({ activityId })
        .then(({ xpNumber, events }) => {
          if (!isXpFeatureEnabled) {
            return this.props.onRemove();
          }

          return appv2.ui.openAfterActivityUpdatesModal({
            xpNumber,
            onClick: () => { this.props.onRemove(); },
            events,
            activityId: null,
            isVariantUnlocked: false,
          });
        });
    };

    const renderFooter = () => {
      if (this._data.isDataPersisted() && this._data.isValid()) {
        return (
          <CardFooterExisting
            variantId={variantId}
            xpNumber={this._data.getXpNumber()}
            onRemove={onRemove}
            canEdit={this._data.canEdit()}
          />
        );
      }

      return (
        <div className="v2-generics-card__footer--form-view">
          <div className="v2-generics-card__footer-container--form-view">
            <FormFooter
              onSubmit={onSubmit}
              onCancel={() => { this._data.resetToPersistedState(); }}
              isSubmitDisabled={this._data.isDataChanged() || !this._data.isValid()}
            />
          </div>
        </div>
      );
    };

    const headingClass = this._variantHandler.categoryCssClassPrefix() + '--heading';

    return (
      <div className="v2-generics-card">
        <div className="v2-generics-card__container">
          <div className="v2-generics-card__header">
            <div className={'v2-generics-card__header-heading ' + headingClass} >
              <img src={this._variantHandler.icon()} alt="" />
              <span>{ this._variantHandler.title() }</span>
            </div>
          </div>

          <div className="v2-generics-card__body">
            <div className="v2-generics-card__body-content">
              <div className="v2-generics-card__section">
                <DetailsSection
                  uiBehaviour={this._uiBehaviour}
                  data={this._data}
                  formComponent={this._variantHandler.detailsPanelFormComponent()}
                  viewComponent={this._variantHandler.detailsPanelViewComponent()}
                />
              </div>

              <div className="v2-generics-card__section">
                <SkillsSection uiBehaviour={this._uiBehaviour} data={this._data} />
              </div>

              <div className="v2-generics-card__section">
                <DurationSection
                  uiBehaviour={this._uiBehaviour}
                  data={this._data}
                  formComponent={this._variantHandler.durationPanelFormComponent()}
                />
              </div>
            </div>
          </div>

          { renderFooter() }
        </div>
      </div>
    );
  }
}

export default CardExisting;
