import './index.scss';
import React from 'react';

export const WhiteOutlineButton = ({ text, onClick }) => {
  return (
    <a
      className = "white-outline-button"
      onClick   = { onClick }
    >
      <div className = "white-outline-button__text">
        { text }
      </div>
    </a>
  );
};
