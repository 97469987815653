import './index.scss';
import React from 'react';

import { PaddlLogo } from 'component-lib/components/paddl-logo';

export const PublicNavFooterCopyright = () => (
  <div className="public-nav-footer-copyright">
    <PaddlLogo hasLink customColor="new-gray" customWidthInPx={24} />
    <div className="public-nav-footer-copyright__text">
      &copy; Paddl Co. { new Date().getFullYear() }
    </div>
  </div>
);
