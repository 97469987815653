import { defined }  from 'lib/helpers';
import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';

class Skillset {
  constructor({ skills }) {
    this.skills = defined(skills);
  }

  toApiFormat() {
    return this.skills.map((skill) => {
      return {
        id:   skill.id,
        name: skill.name,
      };
    });
  }

  isEmpty() {
    return (this.skills.length <= 0);
  }

  cloneWithNewSkills({ skills }) {
    return new Skillset({ skills });
  }

  isValid() {
    return (this.skills.length > 0);
  }

  static createEmpty() {
    return new Skillset({ skills: [] });
  }

  static fromApiFormat(data) {
    const skills = data.map((skill) => {
      return IdNameObject.fromApiFormat({
        id:   defined(skill.id),
        name: defined(skill.name),
      });
    });

    return new Skillset({ skills });
  }
}

export default Skillset;
