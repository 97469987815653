import './index.scss';
import React from 'react';

const XpAmountXl = ({ xpNumber }) => {
  return (
    <div className = "v2-xp-amount-xl">
      <span className = "v2-xp-amount-xl__number">{ xpNumber }</span>
      <span className = "v2-xp-amount-xl__unit">XP</span>
    </div>
  );
}

export default XpAmountXl;
