import './index.scss';
import React from 'react';

const StepGuide = ({ categoryTitle }) => {
  return (
    <div className = "v2-step-guide">
      <div className = "v2-step-guide__container">
        <div className = "v2-step-guide__icon">
          <img src = { require('./icon--details.svg') } />
        </div>
        <div className = "v2-step-guide__subtitle">
          Complete your { categoryTitle } details above to progress.
        </div>
      </div>
    </div>
  );
};

export default StepGuide;
