import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import GenericsProgressSavedView     from 'component-lib/components/generics-progress-saved-view';

const DurationPanelView = ({
  progressState,
  percentageComplete,
  title,
  subtitle,
}) => {
  return (
    <div className = "v2-duration-panel-view">
      <Text
        { ...style20pxRegularBluegreyD }
        text = "Progress"
      />
      <GenericsProgressSavedView
        progressState = { progressState }
        percentageComplete = { percentageComplete }
        title = { title }
        subtitle = { subtitle }
      />
    </div>
  );
};

export default DurationPanelView;
