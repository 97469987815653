import { defined }  from 'lib/helpers';
import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';
import Employer     from 'lib/api/activities/categories/employment/fields/employer';

class ApiCompetition {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetchHackathonEventSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/competition/hackathon-events/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const eventsSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return eventsSuggestions;
      });
  }

  fetchHackathonOrganiserSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/competition/hackathon/organisers/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const organisersSuggestions = response.map((item) => {
          return Employer.fromApiFormat(item);
        });

        return organisersSuggestions;
      });
  }

  fetchSourceData({ link }) {
    return this._transport
      .makeRequest({
        endpoint: '/competition/variants/hackathon/data-source',
        method:   'GET',
        params:   { link: link },
      })
      .then((data) => {
        return {
          sourceIcon:  defined(data.sourceIcon),
          sourceName:  defined(data.sourceName),
          title:       defined(data.title),
          description: defined(data.description),
          image:       defined(data.image),
          startDate: {
            day:   defined(data.startDate.day),
            month: defined(data.startDate.month),
            year:  defined(data.startDate.year),
          },
          endDate: {
            day:   defined(data.endDate.day),
            month: defined(data.endDate.month),
            year:  defined(data.endDate.year),
          },
          location: defined(data.location),
        };
      });
  }
}

export default ApiCompetition;
