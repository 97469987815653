import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style16pxRegularBluegreyD } from 'component-lib/type/text/styles';

const PaddlGamesNameAndLocationReadonly = ({ name, location }) => {
  return (
    <div className = "v2-paddl-games-name-and-location-readonly">
      <Text { ...style20pxRegularBluegreyD } text = { name } />
      <Text { ...style16pxRegularBluegreyD } text = { location } />
    </div>
  );
}

export default PaddlGamesNameAndLocationReadonly;
// TODO: Similar structure to XPEntryDetailsTitle, check if they should be the same.
