import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import EventId       from 'lib/api/activities/categories/competition/fields/event-id';
import Text          from 'lib/api/activities/generics/fields/text';
import NaturalNumber from 'lib/api/activities/generics/fields/natural-number';
import Bool          from 'lib/api/activities/generics/fields/bool';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/competition/variants/paddl-games/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/competition/variants/paddl-games/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/competition/variants/paddl-games/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/competition/variants/paddl-games/card/duration-panel/form';

import { getEventDataFor } from 'lib/api/var-competition/paddl-games-events';

class PaddlGames {
  static categoryCssClassPrefix() {
    return 'v2-categories-competition-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-competition-paddl-games.svg');
  }

  static title() {
    return 'Paddl Games';
  }

  static subtitle() {
    return 'Competitive business innovation challenge.';
  }

  static detailsAttributes() {
    return {
      eventId:          { dataType: EventId,       required: true  },
      teamName:         { dataType: Text,          required: true  },
      competitionPlace: { dataType: NaturalNumber, required: true  },
      roleTeamLeader:   { dataType: Bool,          required: false },
      rolePresenter:    { dataType: Bool,          required: false },
      roleDemonstrator: { dataType: Bool,          required: false },
      roleCreative:     { dataType: Bool,          required: false },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');
    const eventData = getEventDataFor({ eventId: details.get('eventId').value });

    return {
      activityName: eventData.name,
      activityProviderName: eventData.hostCompany.title,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({ attributes: this.detailsAttributes() });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default PaddlGames;
