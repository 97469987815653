import './index.scss';
import React  from 'react';
import config from 'config';

const StartJourneyBanner = () => {
  return(
    <div className = "v2-start-journey-banner">
      <div className = "v2-start-journey-banner__img-wrapper">
        <img
          className = "v2-start-journey-banner__img"
          src       = { require('./icon-person-footer-banner.svg') }
          alt       = ""
        />
      </div>
      <div className = "v2-start-journey-banner__content">
        <div className = "v2-start-journey-banner__text">
          <h3 className = "v2-start-journey-banner__heading">
            Start your Paddl Journey today.
          </h3>
          <div className = "v2-start-journey-banner__subheading">
            Gather experience and apply for new Opportunities.
          </div>
          <div className = "v2-start-journey-banner__row">
            <div className = "v2-start-journey-banner__col">
              <a className = "v2-start-journey-banner__btn v2-start-journey-banner__btn--outline" href = { config.LANDING_PAGE_URL } target = "_blank" rel="noopener noreferrer">
                Learn more
              </a>
            </div>
            <div className = "v2-start-journey-banner__col">
              <a className = "v2-start-journey-banner__btn v2-start-journey-banner__btn--solid" href = { config.SIGN_UP_PAGE_URL }>
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartJourneyBanner;
