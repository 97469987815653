import './index.scss';
import React from 'react';

import Text                      from 'component-lib/type/text';
import { DottedUnderlineButton } from 'component-lib/components/dotted-underline-button';

export class DismissiblePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dismissed: null,
    };
  }

  render() {
    const { title, titleStyle } = this.props;

    const onDisplayClick = () => {
      this.setState({ dismissed: false });
    };

    const onDismissClick = () => {
      this.setState({ dismissed: true });
    };

    if (this.state.dismissed === true) {
      return (
        <div className = "dismissible-panel dismissible-panel--dismissed">
          <DottedUnderlineButton
            copy    = { `Show ${title}` }
            color   = "bluegrey-l"
            onClick = { onDisplayClick }
          />
        </div>
      );
    }

    return (
      <div className = "dismissible-panel">
        <div className = "dismissible-panel__texts">
          <Text { ...titleStyle } text = { title } />
          <DottedUnderlineButton
            copy    = "Dismiss"
            color   = "bluegrey"
            onClick = { onDismissClick }
          />
        </div>
        { this.props.children }
      </div>
    );
  }
}
