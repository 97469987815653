import { defined } from 'lib/helpers';

class Bool {
  constructor(value) {
    if (value !== null && typeof defined(value) !== 'boolean') {
      throw Error('Unexpected value');
    }

    this.value = !!value;
  }

  toApiFormat() {
    return this.value;
  }

  static fromApiFormat(data) {
    return new Bool(data);
  }
}

export default Bool;
