import React from 'react';

// https://www.npmjs.com/package/directory-metagen
// ./node_modules/.bin/metagen --filter=svg src/components/svg-icon/svgs commonJS
import allIcons from './svgs/__all.js'
import './svg-icon.scss';

const SvgIcon = ({
  id,
  className = '',
  style,
}) => {

  const SvgComponent = allIcons[id].default;

  return (
    <SvgComponent
      className={`icon icon-${id} ${className}`}
      width="1em"
      height="1em"
      style={style}
    />
  )
};

export default SvgIcon;
