import './index.scss';
import { defined } from 'lib/helpers';
import React       from 'react';

import SkillsPanelForm  from 'components/v2/activities/generics/skills-panel/form';
import SkillsPanelView  from 'components/v2/activities/generics/skills-panel/view';
import SkillsPanelEmpty from 'components/v2/activities/generics/skills-panel/empty';
import { FormFooter }   from 'components/v2/form-footer';

const renderFormView = ({ uiBehaviour, data }) => {
  const onCancel = () => {
    data.backToSavePoint();
    uiBehaviour.skillsPanelCancel();
  };

  const onSubmit = () => {
    data.markAsSavePoint();
    uiBehaviour.skillsPanelContinue();
  };

  return (
    <div className = "v2-skills-section__form-view">
      <div className = "v2-skills-section__container">
        <SkillsPanelForm data = { data } />
        <FormFooter
          customSubmitCopy = 'Continue'
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          isSubmitDisabled = { !data.isDataChanged() || !data.skillsetData.isValid() }
        />
      </div>
    </div>
  );
};

const onOpenSection = ({ uiBehaviour, data }) => {
  data.backToSavePoint();
  uiBehaviour.clickSkillsSection();
};

const renderSavedView = ({ uiBehaviour, data }) => {
  if (data.canEdit()) {
    return (
      <div className = "v2-skills-section__saved-view v2-skills-section__saved-view--can-edit" onClick = { () => { onOpenSection({ uiBehaviour, data }); } }>
        <div className = "v2-skills-section__container">
          <SkillsPanelView data = { data } />
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-skills-section__saved-view">
      <div className = "v2-skills-section__container">
        <SkillsPanelView data = { data } />
      </div>
    </div>
  );
};

const renderEmptyView = ({ uiBehaviour, data }) => {
  if (data.canEdit()) {
    return (
      <div className = "v2-skills-section__empty-view v2-skills-section__empty-view--can-edit" onClick = { () => { onOpenSection({ uiBehaviour, data }); } }>
        <div className = "v2-skills-section__container">
          <SkillsPanelEmpty />
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-skills-section__empty-view">
      <div className = "v2-skills-section__container">
        <SkillsPanelEmpty />
      </div>
    </div>
  );
};

const renderPanel = ({ uiBehaviour, data }) => {
  if (data.canEdit() && uiBehaviour.isSkillsPanelOpen()) {
    return renderFormView({ uiBehaviour, data });
  }

  if (data.skillsetData.isEverythingEmpty()) {
    return renderEmptyView({ uiBehaviour, data });
  }

  if (data.skillsetData.isValid()) {
    return renderSavedView({ uiBehaviour, data });
  }

  throw Error('Should not happen, invalid Skillset data');
};

const SkillsSection = ({ uiBehaviour, data }) => {
  return (
    <div className = "v2-skills-section">
      { renderPanel({ uiBehaviour, data }) }
    </div>
  );
};

export default SkillsSection;
