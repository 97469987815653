import './index.scss';
import React from 'react';

import DetailsPanelEmpty from 'components/v2/activities/generics/card/details-panel/empty';
import { FormFooter }    from 'components/v2/form-footer';

const renderFormView = ({ appv2, uiBehaviour, data, formComponent }) => {
  const onCancel = () => {
    data.backToSavePoint();
    uiBehaviour.detailsPanelCancel();
  };

  const onSubmit = () => {
    data.markAsSavePoint();
    uiBehaviour.detailsPanelContinue();
  };

  const Component = formComponent;

  return (
    <div className = "v2-details-section__form-view">
      <div className = "v2-details-section__container">
        <Component data = { data } />
        <FormFooter
          customSubmitCopy = 'Continue'
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          isSubmitDisabled = { !data.isDataChanged() || !data.detailsData.isValid() }
        />
      </div>
    </div>
  );
};

const onOpenSection = ({ uiBehaviour, data }) => {
  data.backToSavePoint();
  uiBehaviour.clickDetailsSection();
};

const renderSavedView = ({ uiBehaviour, data, viewComponent }) => {
  const Component = viewComponent;

  const wrappedOnOpenSection = ({ target }) => {
    if (target.closest('.link-wrapper')) {
      return; // ignore clicks on LinkWrapper link
    }

    return onOpenSection({ uiBehaviour, data });
  };

  if (data.canEdit()) {
    return (
      <div className = "v2-details-section__saved-view v2-details-section__saved-view--can-edit" onClick = { wrappedOnOpenSection }>
        <div className = "v2-details-section__container">
          <Component data = { data } />
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-details-section__saved-view">
      <div className = "v2-details-section__container">
        <Component data = { data } />
      </div>
    </div>
  );
};

const renderEmptyView = ({ uiBehaviour, data }) => {
  if (data.canEdit()) {
    return (
      <div className = "v2-details-section__empty-view v2-details-section__empty-view--can-edit" onClick = { () => { onOpenSection({ uiBehaviour, data }); } }>
        <div className = "v2-details-section__container">
          <DetailsPanelEmpty />
        </div>
      </div>
    );
  }

  return (
    <div className = "v2-details-section__empty-view">
      <div className = "v2-details-section__container">
        <DetailsPanelEmpty />
      </div>
    </div>
  );
};

const renderPanel = ({ appv2, uiBehaviour, data, formComponent, viewComponent }) => {
  if (data.canEdit() && uiBehaviour.isDetailsPanelOpen()) {
    return renderFormView({ appv2, uiBehaviour, data, formComponent });
  }

  if (data.detailsData.isEverythingEmpty()) {
    return renderEmptyView({ uiBehaviour, data });
  }

  if (data.detailsData.isValid()) {
    return renderSavedView({ uiBehaviour, data, viewComponent });
  }

  throw Error('Should not happen, invalid Details data');
};

const DetailsSection = ({ appv2, uiBehaviour, data, formComponent, viewComponent }) => {
  return (
    <div className = "v2-details-section">
      { renderPanel({ appv2, uiBehaviour, data, formComponent, viewComponent }) }
    </div>
  );
};

export default DetailsSection;
