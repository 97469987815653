import './index.scss';
import React from 'react';

export const LinkedinCircle = (props) => {
  return (
    <a href = { props.url } target = "_blank" onClick = { props.onClick }>
      <svg className = "linkedin-circle" width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path className = "linkedin-circle__bg" d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z" />
        <path className = "linkedin-circle__content" d="M16.1494 12.5133C17.6134 12.5133 18.8031 13.703 18.8031 15.1653C18.8031 16.631 17.6134 17.8207 16.1494 17.8207C14.6802 17.8207 13.494 16.631 13.494 15.1653C13.494 13.703 14.6802 12.5133 16.1494 12.5133ZM13.8574 34.5607H18.4397V19.835H13.8574V34.5607Z" />
        <path className = "linkedin-circle__content" d="M21.3106 19.8342H25.6975V21.8451H25.7609C26.3712 20.6879 27.8643 19.4673 30.0912 19.4673C34.7215 19.4673 35.5769 22.5171 35.5769 26.4839V34.5599H31.0049V27.3993C31.0049 25.6902 30.9723 23.4942 28.6272 23.4942C26.246 23.4942 25.8826 25.3542 25.8826 27.2742V34.5599H21.3106V19.8342Z" />
      </svg>
    </a>
  );
};
