import './index.scss';
import React     from 'react';
import pluralize from 'pluralize';

import TagReadonly from 'components/v2/tag-readonly';

const renderSkills = (skills) => {
  if (skills === null || skills.length <= 0) {
    return null;
  }

  return (
    <div className = "v2-skills-panel-view__skills">
      {
        skills.map((skill, i) => (
          <TagReadonly
            key = { i }
            label = { skill.name }
          />
        ))
      }
    </div>
  );
};

const SkillsPanelView = ({
  data
}) => {
  const skills = data.skillsetData.getSkillsList();

  return (
    <div className = "v2-skills-panel-view">
      <div className = "v2-skills-panel-view__title">
        { pluralize('Skill', skills.length, true) }
      </div>
      { renderSkills(skills) }
    </div>
  );
};

export default SkillsPanelView;
