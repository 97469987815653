import './input-text.scss';

import React from 'react';

const handleFocus = ({ onFocus, onComplete }) => {
  if (!onComplete) {
    return onFocus;
  }

  return () => {
    onComplete();
    return onFocus();
  };
};

export default ({ addon, options, attrs, setRef, ...props }) => {
  if (addon && (addon.prefix || addon.suffix)) {
    const { prefix, postfix } = addon;

    return (
      <div className = "input__text__addon">
        { prefix && <div className = "input__text__prefix">{ prefix }</div> }
        <input { ...props } { ...attrs } className = "input__text is--short" />
        { postfix && <div className = "input__text__postfix">{ postfix }</div> }
      </div>
    );
  }

  return (
    <input
      ref = { setRef && (el => setRef(el)) }
      { ...props }
      { ...attrs }
      onFocus = { handleFocus(props) }
      // className has to be placed after `{ ...props }` to avoid className being inexplicitly overwritten
      className = {`input__text ${props.className}`}
    />
  );
};
