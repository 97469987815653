import React from 'react';

import './index.scss';

export const TermsContentSideMenu = ({ sectionLinks }) => (
  <div className="terms-content-side-menu">
    {
      sectionLinks.map(({ text, href }, i) => (
        <a
          className="terms-content-side-menu__item"
          href={href}
          key={text + i}
        >
          {text}
        </a>
      ))
    }
  </div>
);
