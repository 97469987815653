import { defined, urlToBeShared } from 'lib/helpers';
import { STUDENT, EMPLOYER, UNKNOWN_ROLE } from 'lib/auth';

class Analytics {
  constructor({ auth }) {
    this._auth = defined(auth);
  }

  identify({ profileId }) {
    window.analytics.identify(profileId, {
      hitType: 'identify',
      userId:  profileId,
    });
  }

  track({ event, metaData = {} }) {
    const role = this._auth.currentProfileRole();

    const mapProfileType = (role) => {
      switch (role) {
        case STUDENT:      return 'Standard';
        case EMPLOYER:     return 'Company';
        case UNKNOWN_ROLE: return 'Unknown';
        default:           throw Error('Unexpected role');
      }
    };

    window.analytics.track(event, {
      ...metaData,
      hitType:     'event',
      profileType: mapProfileType(role),
    });
  }

  sendSharedXpProfileEvent({ shareType }) {
    return this.track({
      event:        'Shared XP Profile',
      metaData: {
        profileUrl: urlToBeShared(),
        shareType:  shareType,
      }
    });
  }

  sendSharedAPageEvent({ pageType, shareType }) {
    return this.track({
      event:       'Shared a Page',
      metaData: {
        pageType:  pageType,
        pageUrl:   urlToBeShared(),
        shareType: shareType,
      }
    });
  }
}

export default Analytics;
