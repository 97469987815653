import './index.scss';
import React from 'react';

import Text                     from 'component-lib/type/text';
import { style16pxMediumWhite } from 'component-lib/type/text/styles';

export class ImagePreviewSection extends React.Component {
  constructor(props) {
    super(props);

    this._setUrlToStyle = () => {
      this.bgImageDiv.style.setProperty('--bg-image', `url('${this.props.url}')`);
    }
  }

  componentDidMount() {
    this._setUrlToStyle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url === this.props.url) { return; }

    this._setUrlToStyle();
  }

  render() {
    const { url, onRemove } = this.props;

    if (url === null) {
      return null;
    }

    return (
      <div
        className = "v2-image-preview-section"
        ref = { (element) => { this.bgImageDiv = element; } }
      >
        <div className = "v2-image-preview-section__remove-button" onClick = { onRemove }>
          <Text { ...style16pxMediumWhite } text = 'Remove Banner' />
        </div>
      </div>
    );
  }
}
