import './index.scss';
import React from 'react';

import ImgTag                        from 'component-lib/components/img-tag';
import { style16pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style16pxLightBluegrey }    from 'component-lib/type/text/styles';
import Text                          from 'component-lib/type/text';
import SvgIconClose                  from 'components/v2/svg-icons/svg-icon-close';

export const ActivityCardSuggestionListItemSmall = ({
  onClick,
  variantIcon,
  variantTitle,
  variantSubtitle,
  footerBorderTopColor,
  categoryTitle,
  categoryTitleColor,
  onHide
}) => {
  const renderImg = () => {
    return (
      <ImgTag
        src    = { variantIcon }
        alt    = ""
        width  = '56px'
        height = '56px'
      />
    );
  };

  return (
    <div
      className = "activity-card-suggestion-list-item-small activity-card-suggestion-list-item-small--active"
      onClick   = { onClick }
    >
      <div className = "activity-card-suggestion-list-item-small__content-wrapper">
        <a className = "activity-card-suggestion-list-item-small__close-link" onClick = { onHide }>
          <SvgIconClose />
        </a>
        <div className = "activity-card-suggestion-list-item-small__body">
          { renderImg() }
          <Text { ...style16pxRegularBluegreyD } text = { variantTitle } />
          <Text { ...style16pxLightBluegrey } text = { variantSubtitle } />
        </div>

        <div className = { `
          activity-card-suggestion-list-item-small__footer
          activity-card-suggestion-list-item-small__footer--${footerBorderTopColor}
        ` }>
          <Text
            text       = { categoryTitle }
            color      = { categoryTitleColor }
            fontSize   = '16px'
            fontWeight = 'regular'
          />
        </div>
      </div>
      <div className = "activity-card-suggestion-list-item-small__shadow">
      </div>
    </div>
  );
};
