import Skillset    from 'lib/api/activities/generics/skillset';
import SkillsCount from 'lib/api/activities/generics/skills-count';
import Duration    from 'lib/api/activities/generics/duration-timebox-day-month-year';

import IdNameObject   from 'lib/api/activities/generics/fields/id-name-object';
import CourseDuration from 'lib/api/activities/categories/education/fields/course-duration';
import Commitment     from 'lib/api/activities/categories/education/fields/commitment';
import Bool           from 'lib/api/activities/generics/fields/bool';

import createDetailsDataClass from 'components/v2/activities/generics/details-panel/create-details-data-class';
import DetailsPanelForm       from 'components/v2/activities/categories/education/variants/common/card/details-panel/form';
import DetailsPanelView       from 'components/v2/activities/categories/education/variants/common/card/details-panel/view';

import SkillsetData from 'components/v2/activities/generics/skills-panel/data';

import DurationData      from 'components/v2/activities/categories/education/variants/common/card/duration-panel/data';
import DurationPanelForm from 'components/v2/activities/categories/education/variants/common/card/duration-panel/form';

import DurationUnit from 'lib/api/activities/generics/duration-value-unit/duration-unit';

class Diploma {
  static categoryCssClassPrefix() {
    return 'v2-categories-education-common';
  }

  static icon() {
    return require('component-lib/activity-artwork/variant-education-diploma.svg');
  }

  static title() {
    return 'Diploma';
  }

  static subtitle() {
    return 'Official, issued certificate of study.';
  }

  static detailsAttributes() {
    const fetchEducatorsSuggestions      = ({ appv2 }) => { return appv2.api.education.fetchEducatorsSuggestions.bind(appv2.api.education);      };
    const fetchCampusesSuggestions       = ({ appv2 }) => { return appv2.api.education.fetchCampusesSuggestions.bind(appv2.api.education);       };
    const fetchDiplomaCoursesSuggestions = ({ appv2 }) => { return appv2.api.education.fetchDiplomaCoursesSuggestions.bind(appv2.api.education); };

    return {
      educator:           { required: true,  dataType: IdNameObject,   fieldLabel: 'Educator Name',   fieldPlaceholder: 'e.g. University of Technology',  suggestionsFunction: fetchEducatorsSuggestions      },
      campus:             { required: true,  dataType: IdNameObject,   fieldLabel: 'Campus',          fieldPlaceholder: 'e.g. Melbourne',                 suggestionsFunction: fetchCampusesSuggestions       },
      course:             { required: true,  dataType: IdNameObject,   fieldLabel: 'Course Name',     fieldPlaceholder: 'e.g. Diploma of Administration', suggestionsFunction: fetchDiplomaCoursesSuggestions },
      courseDuration:     { required: true,  dataType: CourseDuration, fieldLabel: 'Course Duration', fieldPlaceholder: 'Select Years' },
      commitment:         { required: true,  dataType: Commitment,     fieldLabel: 'Commitment',      fieldPlaceholder: 'Select Type'  },
      attendanceOnline:   { required: false, dataType: Bool,           multiSelectGroup: 'attendance' },
      attendanceOnCampus: { required: false, dataType: Bool,           multiSelectGroup: 'attendance' },
    };
  }

  static activityCardViewData(activity) {
    const details = activity.get('details');

    return {
      activityName: details.get('course').name,
      activityProviderName: details.get('educator').name,
    };
  }

  static detailsDataClass() {
    return createDetailsDataClass({
      attributes: this.detailsAttributes(),
      getDurationObjectFn: (detailsData) => {
        return {
          value: detailsData.getField('courseDuration'),
          unit:  DurationUnit.YEAR,
        };
      },
    });
  }

  static skillsetDataClass() {
    return SkillsetData;
  }

  static durationDataClass() {
    return DurationData;
  }

  static detailsPanelFormComponent() {
    return DetailsPanelForm;
  }

  static detailsPanelViewComponent() {
    return DetailsPanelView;
  }

  static durationPanelFormComponent() {
    return DurationPanelForm;
  }

  static skillsetHandler() {
    return Skillset;
  }

  static skillsCountHandler() {
    return SkillsCount;
  }

  static durationHandler() {
    return Duration;
  }
}

export default Diploma;
