import VisaStatus from 'lib/api/profiles/profile-extra/visa-status';

export const VISA_STATUSES = {
  [VisaStatus.CITIZEN]:  'Citizen',
  [VisaStatus.RESIDENT]: 'Resident',
  [VisaStatus.WORKER]:   'Worker Visa',
  [VisaStatus.STUDENT]:  'Student Visa',
  [VisaStatus.TOURIST]:  'Tourist Visa',
  [VisaStatus.SPOUSE]:   'Spouse Visa',
  [VisaStatus.OTHER]:    'Other',
};
