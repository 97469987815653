import { defined, regexpWithUrlHead } from 'lib/helpers';

import Profile from './profile';
import axios   from 'axios';
import config  from 'config';

class ApiProfiles {
  constructor({ transport, analytics }) {
    this._transport = transport;
    this._analytics = analytics;
  }

  fetch({ id, isLoggedIn = false }) {
    const endpoint = isLoggedIn
      ? `/profiles/${ encodeURIComponent(id) }`
      : `/public-profiles/${ encodeURIComponent(id) }`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET', useCache: true })
      .then((data) => {
        if (isLoggedIn) {
          return Profile.fromApiFormat(data);
        }

        return {
          profile: Profile.fromApiFormat(data.profile),
          visibilitySettings: data.visibilitySettings,
        };
      });
  }

  fetchWithExtraByIdOrSlug({ idOrSlug, isLoggedIn = false }) {
    const params = idOrSlug.toString().match(/^\d+$/)
      ? 'extra=1'
      : 'extra=1&bySlug=1';

    const endpoint = isLoggedIn
      ? `/profiles/${ encodeURIComponent(defined(idOrSlug)) }?${params}`
      : `/public-profiles/${ encodeURIComponent(defined(idOrSlug)) }?${params}`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET', useCache: true })
      .then((data) => {
        if (isLoggedIn) {
          return {
            profile:      Profile.fromApiFormat(data),
            profileExtra: Profile.getExtraFromApiFormat(data),
          };
        }

        return {
          profile:            Profile.fromApiFormat(data.profile),
          profileExtra:       Profile.getExtraFromApiFormat(data.profile),
          visibilitySettings: data.visibilitySettings,
        };
      });
  }

  fetchBasecampStatistics({ id, cleanTransportCache }) {
    const endpoint = `/basecamp/stats/${ encodeURIComponent(id) }`;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'GET',
        useCache: true,
        invalidateCachePatterns: cleanTransportCache ? [regexpWithUrlHead(endpoint)] : undefined,
      }).then((data) => {
        return {
          totalCollectedXp:           defined(data.totalCollectedXp),
          totalActivities:            defined(data.totalActivities),
          totalSkills:                defined(data.totalSkills),
          dailyAverageXp:             defined(data.dailyAverageXp),
          committedHoursPerWeek:      defined(data.committedHoursPerWeek),
          lastCollectionDateObject: { // day, month, year can be nulls!
            day:                      defined(data.lastCollectionDateObject.day),
            month:                    defined(data.lastCollectionDateObject.month),
            year:                     defined(data.lastCollectionDateObject.year),
          },
        };
      });
  }

  update({ id, profile }) {
    const urlHead  = `/profiles/${ encodeURIComponent(id) }`;
    const endpoint = `${urlHead}`;
    const data     = profile.toApiFormat();

    data.id = null;

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }

  updateSummary({ id, summary }) {
    const urlHead  = `/profiles/${ encodeURIComponent(id) }`;
    const endpoint = `${urlHead}/summary`;
    const data     = { summary: summary.toApiFormat() };

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }

  updateStrapline({ id, strapline }) {
    const urlHead  = `/profiles/${ encodeURIComponent(id) }`;
    const endpoint = `${urlHead}/strapline`;
    const data     = { strapline: strapline.toApiFormat() };

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }

  updateExtra({ id, extra }) {
    const urlHead  = `/profiles/${ encodeURIComponent(id) }`;
    const endpoint = `${urlHead}/extra`;
    const data     = extra.toApiFormat();

    return this._transport
      .makeRequest({
        endpoint,
        method: 'POST',
        data,
        invalidateCachePatterns: [regexpWithUrlHead('/profiles')],
      })
      .then((data) => {
        return true;
      });
  }

  registerEmail({ email }) {
    const endpoint = `/profiles/register-email`;
    const data     = { email: email };

    return this._transport
      .makeRequest({ endpoint, method: 'POST', data })
      .then((data) => {
        this._analytics.identify({ profileId: data.profileId });

        this._analytics.track({
          event: 'Registered',
          metaData: {},
        });

        return data.registeredSuccessfully;
      });
  }

  registerFeedback({ email, heardFrom }) {
    const endpoint = `/profiles/register-feedback`;
    const data     = {
      email:     defined(email),
      heardFrom: defined(heardFrom),
    };

    return this._transport
      .makeRequest({ endpoint, method: 'POST', data });
  }

  activateWithPassword({ email, token, password }) {
    const endpoint = `/onboarding/password`;
    const data = {
      email,
      token,
      password,
    };

    return axios({
      url: [config.API_URL, endpoint].join(''),
      method: 'POST',
      headers: {},
      data,
      params: {},
      transformResponse: [(data) => (data)], // getting rid of all the magical transformations
    })
    .catch((error) => {
      if (typeof error.response == 'undefined') {
        throw error;
      }

      throw ({
        data:   error.response.data,
        status: error.response.status,
      });
    })
    .then(({ data }) => {
      this._analytics.track({
        event: 'Activated',
        metaData: {},
      });

      return JSON.parse(data);
    });
  }

  initWithBasics({ id, fullName, dob, identity }) {
    const endpoint = '/onboarding/student/basics';
    const data     = {
      name:        fullName,
      dateOfBirth: dob,
      identity:    identity,
    };

    return this._transport
      .makeRequest({ endpoint, method: 'POST', data })
      .then((data) => {
        this._analytics.track({
          event: 'Created Explorer Profile',
          metaData: {},
        });

        return true;
      });
  }

  finishOnboarding() {
    const endpoint = '/onboarding/success';
    const data     = {};

    return this._transport
      .makeRequest({ endpoint, method: 'POST', data })
      .then((data) => {
        return true;
      })
      .catch((err) => {
        return true;
      });
  }

  fetchIsOnboarded({ id }) {
    const endpoint = `/profiles/${ encodeURIComponent(id) }/v2-onboarded`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET' })
      .then((data) => {
        return data;
      });
  }

  fetchTotalXpNumber({ id }) {
    const endpoint = `/profiles/${ encodeURIComponent(id) }/total-xp-number`;

    return this._transport
      .makeRequest({ endpoint, method: 'GET', useCache: true })
      .then((data) => {
        return data;
      });
  }
}

export default ApiProfiles;
