import './index.scss';
import React from 'react';

import { QualificationDetailsPanelContent } from 'components/v2/qualification-details-panel-content';
import { VerifiedActivityFooter }           from 'components/v2/verified-activity-footer';

export const QualificationDetailsPanelView = ({
  recipientName,
  qualificationName,
  issuerLogo,
  signatures,
  verificationDate,
  courseCode,
  issuerProfileLink,
  issuerProfileImage,
  sealImage,
  issuer,
  location,
}) => {
  return (
    <div className = "v2-qualification-details-panel-view">
      <div className = "v2-qualification-details-panel-view__content">
        <QualificationDetailsPanelContent
          issuer            = { issuer }
          recipientName     = { recipientName }
          qualificationName = { qualificationName }
          issuerLogo        = { issuerLogo }
          signatures        = { signatures }
          verificationDate  = { verificationDate }
          courseCode        = { courseCode }
        />
      </div>
      <div className = "v2-qualification-details-panel-view__footer">
        <VerifiedActivityFooter
          issuerProfileLink  = { issuerProfileLink }
          issuerProfileImage = { issuerProfileImage }
          sealImage          = { sealImage }
          issuer             = { issuer }
          location           = { location }
        />
      </div>
    </div>
  );
};
