import { defined } from 'lib/helpers';

import PaddlGames          from 'lib/api/var-competition/paddl-games';
import { getEventDataFor } from 'lib/api/var-competition/paddl-games-events';

import PercentageComplete          from 'lib/api/activities/generics/duration-common/percentage-complete';
import DurationTimeboxDayMonthYear from 'lib/api/activities/generics/duration-timebox-day-month-year';
import Year                        from 'lib/api/activities/generics/duration-common/year';
import Month                       from 'lib/api/activities/generics/duration-common/month';
import Day                         from 'lib/api/activities/generics/duration-common/day';
import Bool                        from 'lib/api/activities/generics/fields/bool';

class PaddlGamesDurationData {
  constructor({ state, appv2 }) {
    this._ourState = defined(state);
    this._appv2    = defined(appv2);

    let _alreadyInsideHook = false;

    this._ourState.addHook('setAsSavePoint', () => {
      const detailsData = new (PaddlGames.detailsDataClass())({ state, appv2 });
      const eventId     = detailsData.getField('eventId');
      const eventData   = eventId && getEventDataFor({ eventId });

      if (_alreadyInsideHook) {
        return null;
      }

      _alreadyInsideHook = true;

      this.populateDuration({
        startYear:  eventData && eventData.duration.startDate.getFullYear(),
        startMonth: eventData && eventData.duration.startDate.getMonth() + 1,
        startDay:   eventData && eventData.duration.startDate.getDate(),
        endYear:    eventData && eventData.duration.endDate.getFullYear(),
        endMonth:   eventData && eventData.duration.endDate.getMonth() + 1,
        endDay:     eventData && eventData.duration.endDate.getDate(),
      }, () => {
        _alreadyInsideHook = false;
      });
    });

    this.isEqualToFields = ({ data }) => {
      const currentDuration = this._ourState.get({ id: 'duration' });

      if (data.duration === currentDuration) {
        return true;
      }

      // Comparing actual values here, as one of the sets may be coming from API
      // and another may be set up via hook in Details section.

      if (data.duration === null || currentDuration === null) {
        return false;
      }

      if ([
        data.duration.startYear, currentDuration.startYear,
        data.duration.startMonth, currentDuration.startMonth,
        data.duration.startDay, currentDuration.startDay,
        data.duration.endYear, currentDuration.endYear,
        data.duration.endMonth, currentDuration.endMonth,
        data.duration.endDay, currentDuration.endDay,
      ].some((e) => (e === null))) {
        return false;
      }

      return (
        data.duration.startYear.value == currentDuration.startYear.value
        && data.duration.startMonth.value == currentDuration.startMonth.value
        && data.duration.startDay.value == currentDuration.startDay.value
        && data.duration.endYear.value == currentDuration.endYear.value
        && data.duration.endMonth.value == currentDuration.endMonth.value
        && data.duration.endDay.value == currentDuration.endDay.value
      );
    };

    this.toActivityDuration = () => {
      return this._ourState.get({ id: 'duration' });
    };

    this.getDuration = () => {
      const duration = this._ourState.get({ id: 'duration' });
      const { startYear, startMonth, startDay, endYear, endMonth, endDay, percentageComplete, isPercentageCompleteSetManually } = duration;

      return {
        startDay:                        startDay                        && startDay.value,
        startMonth:                      startMonth                      && startMonth.value,
        startYear:                       startYear                       && startYear.value,
        endDay:                          endDay                          && endDay.value,
        endMonth:                        endMonth                        && endMonth.value,
        endYear:                         endYear                         && endYear.value,
        percentageComplete:              percentageComplete              && percentageComplete.value,
        isPercentageCompleteSetManually: isPercentageCompleteSetManually && isPercentageCompleteSetManually.value,
      };
    };

    this.populateDuration = ({ startYear, startMonth, startDay, endYear, endMonth, endDay }, callback = () => {}) => {
      const calculatedPercentageComplete = PercentageComplete.calculate({
        startYear,
        startMonth,
        startDay,
        endYear,
        endMonth,
        endDay,
      });

      this._ourState.reset({
        duration: new DurationTimeboxDayMonthYear({
          startYear:                       defined(startYear)  && new Year(startYear),
          startMonth:                      defined(startMonth) && new Month(startMonth),
          startDay:                        defined(startDay)   && new Day(startDay),
          endYear:                         defined(endYear)    && new Year(endYear),
          endMonth:                        defined(endMonth)   && new Month(endMonth),
          endDay:                          defined(endDay)     && new Day(endDay),
          percentageComplete:              calculatedPercentageComplete,
          isPercentageCompleteSetManually: new Bool(false),
        }),
      }, callback);
    };

    this.isEverythingEmpty = () => {
      return this._ourState.get({ id: 'duration' }).isEmpty();
    };

    this.isValid = () => {
      return this._ourState.get({ id: 'duration' }).isValid();
    };
  }

  static createEmptyFields() {
    return {
      duration: DurationTimeboxDayMonthYear.createEmpty(),
    };
  }

  static fieldsFromActivityDuration({ activityDuration }) {
    return {
      duration: activityDuration,
    };
  }
}

export default PaddlGamesDurationData;
