import React from 'react';

import './index.scss';

// tried this, but need to be able to change colour of svg, so it has to be inline
// import arrow from 'components/svg-icon/svgs/arrow-down.svg'

export class TermsMobileDropDown extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      transformerHeight: null,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleToggle() {
    const transformerHeight = this.ulRef.offsetHeight;

    this.setState({
      isOpen: !this.state.isOpen,
      transformerHeight,
    });
  }

  handleClick(e) {
    e.preventDefault();

    if (!e.target.hash) {
      console.warn('The clicked anchor does not contain a href hash');
      return;
    }

    const targetEl = document.querySelector(e.target.hash);
    if (!targetEl) {
      console.warn('Target ID doesnt exist on page');
      return;
    }

    this.setState({ isOpen: false });

    // wait till the dropdown has closed before scrolling
    setTimeout(() => {
      const scrollTop = targetEl.offsetTop;

      // https://stackoverflow.com/a/55221484
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

      if (supportsNativeSmoothScroll) {
        window.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo(0, scrollTop);
      }
    }, 600); // matches css transition timing
  }

  render() {
    const { sectionLinks } = this.props;
    const { isOpen, transformerHeight } = this.state;

    return (
      <div className="terms-mobile-dropdown">
        <button onClick={this.handleToggle} className="terms-mobile-dropdown__toggle">
          Terms &amp; Conditions

          <svg width="32" height="32" viewBox="0 0 32 32" className="terms-mobile-dropdown__carat" style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}>
            <path fill="currentColor" d="M26.5 12.594c0.208-0.208 0.313-0.458 0.313-0.75 0-0.271-0.104-0.51-0.313-0.719s-0.448-0.313-0.719-0.313-0.51 0.104-0.719 0.313l-9.719 9.719-9.719-9.719c-0.167-0.167-0.406-0.25-0.719-0.25-0.354 0-0.583 0.083-0.687 0.25-0.208 0.208-0.313 0.448-0.313 0.719 0 0.292 0.104 0.542 0.313 0.75l10.406 10.406c0.229 0.188 0.479 0.281 0.75 0.281s0.51-0.094 0.719-0.281z" />
          </svg>
        </button>

        <div
          className="terms-mobile-dropdown__transformer"
          style={{
            height: isOpen ? transformerHeight : 0,
          }}
        >
          <ul className="terms-mobile-dropdown__ul" ref={elem => this.ulRef = elem}>
            {sectionLinks.map(({ text, href }, i) => (
              <li key={text + i} className="terms-mobile-dropdown__li">
                <a
                  href={href}
                  className="terms-mobile-dropdown__item terms-mobile-dropdown__item--title"
                  onClick={this.handleClick}
                >
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
