import React       from 'react';
import { defined } from 'lib/helpers';
import Dropdown    from 'components/v2/input-fields/dropdown';

const DropdownVariant = ({
  value,
  onPlaceholderClick,
  onOptionClick,
  options,
  onPlaceholderBlur,
}) => {
  const isError = (value) => {
    return false;
  };
  const hasError = isError(value);
  const message = hasError ? 'error!' : '';

  const sanitizeOptions = (options) => {
    return options.map(
      ({ value, title }) => ({
        value: defined(value),
        title: defined(title),
      })
    );
  };

  return (
    <Dropdown
      value = { value }
      name = "dropdown-variant"
      label = { null }
      placeholder = "Select an Activity"
      onPlaceholderClick = { () => { onPlaceholderClick(); } }
      onOptionClick = { (item) => { onOptionClick(item); } }
      onPlaceholderBlur = { () => { onPlaceholderBlur(); } }
      options = { sanitizeOptions(options) }
      message = { message }
      isError = { hasError } />
  );
};

export default DropdownVariant;
