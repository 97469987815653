import { defined } from 'lib/helpers';

import VarEduBachelor              from 'lib/api/var-edu/bachelor';
import VarEduMaster                from 'lib/api/var-edu/master';
import VarEduDiploma               from 'lib/api/var-edu/diploma';
import VarCompetitionPaddlGames    from 'lib/api/var-competition/paddl-games';
import VarEduCourse                from 'lib/api/var-edu/course';
import VarEduCertificate           from 'lib/api/var-edu/certificate';
import VarEduHighSchool            from 'lib/api/var-edu/high-school';
import VarEmploymentInternship     from 'lib/api/var-employment/internship';
import VarEmploymentCasualWork     from 'lib/api/var-employment/casual-work';
import VarEmploymentFullTime       from 'lib/api/var-employment/full-time';
import VarEmploymentPartTime       from 'lib/api/var-employment/part-time';
import VarEmploymentContract       from 'lib/api/var-employment/contract';
import VarCompetitionHackathon     from 'lib/api/var-competition/hackathon';
import VarEduVerifiedCourseUnit    from 'lib/api/var-edu/verified/course-unit';
import VarEduVerifiedQualification from 'lib/api/var-edu/verified/qualification';

const MAPPING = {
  1:  VarEduBachelor,
  2:  VarEduMaster,
  3:  VarEduDiploma,
  4:  VarCompetitionPaddlGames,
  5:  VarEduCourse,
  6:  VarEduCertificate,
  7:  VarEduHighSchool,
  8:  VarEmploymentInternship,
  9:  VarEmploymentCasualWork,
  10: VarEmploymentFullTime,
  11: VarEmploymentPartTime,
  12: VarEmploymentContract,
  13: VarCompetitionHackathon,
  14: VarEduVerifiedCourseUnit,
  15: VarEduVerifiedQualification,
};

const validate = (variantId) => {
  if (!MAPPING.hasOwnProperty(variantId)) {
    throw Error('Unexpected variantId');
  }
};

class VariantMapping {
  static getFor({ variantId }) {
    validate(variantId);
    return defined(MAPPING[variantId]);
  }
}

export default VariantMapping;
