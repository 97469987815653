import { defined } from 'lib/helpers';

const DETAILS  = 'details';
const SKILLS   = 'skills';
const DURATION = 'duration';

class UIBehaviourExisting {
  constructor({ ui }) {
    this._ui = defined(ui);

    this.reset = () => {
      this._ui.initializeState();
      this._ui.closePanel();

      // for existing activities the UX sequences are always broken
      this._ui.breakPanelSequence();
    };

    this.detailsPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
    };

    this.detailsPanelContinue = () => {
      this._ui.closePanel();
    };

    this.isDetailsPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === DETAILS;
    };

    this.clickDetailsSection = () => {
      this._ui.openPanel({ id: DETAILS });
    };

    this.skillsPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
    };

    this.skillsPanelContinue = () => {
      this._ui.closePanel();
    };

    this.isSkillsPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === SKILLS;
    };

    this.clickSkillsSection = () => {
      this._ui.openPanel({ id: SKILLS });
    };

    this.durationPanelCancel  = () => {
      this._ui.initializeState();
      this._ui.closePanel();
    };

    this.durationPanelContinue = () => {
      this._ui.closePanel();
    };

    this.isDurationPanelOpen = () => {
      return this._ui.getCurrentlyOpenPanel() === DURATION;
    };

    this.clickDurationSection = () => {
      this._ui.openPanel({ id: DURATION });
    };
  }
}

export default UIBehaviourExisting;
