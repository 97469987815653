import React from 'react';

import Text from 'component-lib/type/text';
import { DismissiblePanel } from 'component-lib/components/dismissible-panel';
import { ActivityCardSuggestionListNew } from 'component-lib/components/activity-card-suggestion-list-new';
import { ChooseFromCategorySection } from 'components/v2/profile/choose-from-category-section';
import { VoteForNewCard } from 'components/v2/profile/vote-for-new-card';
import { VerifiedActivitiesCardRedeem } from 'components/v2/profile/verified-activities-card-redeem';

import {
  style20pxRegularWhite,
  style16pxLightBluegreyL,
} from 'component-lib/type/text/styles';

import './index.scss';

export const AddAnActivityCardView = ({ appv2, onSelectCategory }) => {
  const isRedemptionEnabled = appv2.featureFlags.isEnabled({ featureName: 'redemption' });

  const redirectToUrl = (url) => {
    appv2.ui.redirect({ url });
  };

  return (
    <div className="v2-add-an-activity-card-view">
      <div className="v2-add-an-activity-card-view__header">
        <Text
          {...style20pxRegularWhite}
          text="Add an Activity Card"
        />
        <Text
          {...style16pxLightBluegreyL}
          text="Capture all of your professional experience by adding Activity Cards!"
        />
      </div>
      <DismissiblePanel
        title="Suggestions"
        titleStyle={style20pxRegularWhite}
      >
        <ActivityCardSuggestionListNew
          appv2={appv2}
        />
      </DismissiblePanel>
      <ChooseFromCategorySection
        onSelectCategory={onSelectCategory}
      />
      {
        isRedemptionEnabled &&
        <VerifiedActivitiesCardRedeem
          redirectToUrl={redirectToUrl}
        />
      }
      <VoteForNewCard />
    </div>
  );
};
