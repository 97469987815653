import VariantMapping                        from 'lib/api/variant-mapping';
import { findCategoryIdBy, getCategoryData } from 'lib/api/categories';
import { IssuerData }                        from 'lib/api/var-edu/verified/issuer-data';

export const getNewCardUnlockedViewProps = ({ activity, issuerProfile }) => {
  const variantId = activity.get('variantId');
  const details   = activity.get('details');

  const variantHandler = VariantMapping.getFor({ variantId });

  const variantIcon     = variantHandler.icon();
  const variantTitle    = variantHandler.title();
  const variantSubtitle = variantHandler.subtitle();

  const categoryId   = findCategoryIdBy({ variantId });
  const categoryData = getCategoryData({ categoryId });

  const categoryTitle       = categoryData.title();
  const categoryThemeColor  = categoryData.themeColor();
  const categoryThemeColorL = categoryData.themeColorL();

  const issuerCode = details.get('issuerCode');

  if (!issuerCode) {
    return {
      variantIcon,
      variantIconShape: null,
      variantSubtitle,
      categoryTitleColor: categoryThemeColor,
      footerBorderTopColor: categoryThemeColorL,
      variantTitle,
      categoryTitle,
    };
  }

  const issuerProfileImage = (issuerProfile && issuerProfile.get('avatarUrl')) || require('images/placeholder--avatar-employer.svg');

  const issuerData = IssuerData.getFor(issuerCode.value);

  return {
    variantIcon:          issuerProfileImage,
    variantIconShape:     'rounded',
    variantSubtitle:      `Issued by ${issuerData.issuer}`,
    categoryTitleColor:   issuerData.themeColor,
    footerBorderTopColor: issuerData.themeColorL,
    variantTitle,
    categoryTitle,
  };
};
