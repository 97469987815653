import React from 'react';
import { Modal } from 'components/v2/modal';

import CardNew                   from 'components/v2/activities/generics/card/new';
import { AddAnActivityCardView } from 'components/v2/activities/add-an-activity-card-view';
import SelectVariantForm         from 'components/v2/activities/select-variant-form';

export class ModalNewActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: null,
      variantId:  null,
    };

    const ALERT_MESSAGE = `There is unsaved information on this card, changes will not be saved.\n\nAre you sure you want to leave this page?`;

    this.shouldAlertOnLeave = () => (false);
    // this will be overridden by the internal component
    // modalObject.shouldAlertOnLeave in CardNew
    // src/components/v2/activities/generics/card/new.jsx:52
    // FIXME: Refactor to untangle ModalNewActivity & CardNew

    this._confirmPageClose = (event) => {
      if (this.shouldAlertOnLeave()) {
        event.preventDefault();
        event.returnValue = ALERT_MESSAGE;
        return event.returnValue;
      }
    };

    this._confirmModalClose = () => {
      if (this.shouldAlertOnLeave()) {
        return window.confirm(ALERT_MESSAGE);
      }

      return true;
    };
  }

  UNSAFE_componentWillMount() {
    const { variantId } = this.props;

    this.setState({
      categoryId: null,
      variantId: null,
    });

    if (typeof variantId !== 'undefined') {
      this.setState({ variantId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.variantId !== nextProps.variantId) {
      this.setState({ variantId: nextProps.variantId });
    }
  }

  componentDidMount() {
    window.onbeforeunload = this._confirmPageClose;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  renderCategorySelectForm({ appv2 }) {
    return (
      <AddAnActivityCardView
        onSelectCategory = { (categoryId) => { this.setState({ categoryId }); } }
        appv2 = { appv2 }
      />
    );
  }

  renderVariantSelectForm({ appv2 }) {
    return (
      <SelectVariantForm
        appv2 = { appv2 }
        categoryId = { this.state.categoryId }
        onContinue = { ({ variantId }) => { this.setState({ variantId }); } }
      />
    );
  }

  renderActivityForm({ appv2 }) {
    return (
      <CardNew
        appv2 = { appv2 }
        variantId = { this.state.variantId }
        onCancel = { () => { this.setState({ variantId: null }); } }
        onSubmit = { () => {
          appv2.ui.closeModal();

          appv2.ui.displaySnackBar({
            style:    'green',
            text:     'Activity saved!',
            callback: () => { appv2.ui.refreshPage(); },
          });
        } }
        modalObject = { this }
      />
    );
  }

  renderForm({ appv2 }) {
    if (this.state.variantId !== null) {
      return this.renderActivityForm({ appv2 });
    }

    if (this.state.categoryId !== null) {
      return this.renderVariantSelectForm({ appv2 });
    }

    return this.renderCategorySelectForm({ appv2 });
  }

  render() {
    const {
      appv2,
      withLeftOffset,
    } = this.props;

    const closeFunction = () => {
      if (this._confirmModalClose()) {
        appv2.ui.closeModal();
      }
    };

    return (
      <Modal
        closeFunction = { closeFunction }
        withLeftOffset = { withLeftOffset }
        windowWidthSize = "large"
      >
        { this.renderForm({ appv2 }) }
      </Modal>
    );
  }
}
