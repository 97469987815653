import IdNameObject from 'lib/api/activities/generics/fields/id-name-object';
import Employer     from 'lib/api/activities/categories/employment/fields/employer';

class ApiEmployment {
  constructor({ transport }) {
    this._transport = transport;
  }

  fetchInternshipRolesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/internship/roles/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const rolesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return rolesSuggestions;
      });
  }

  fetchCasualWorkRolesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/casual-work/roles/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const rolesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return rolesSuggestions;
      });
  }

  fetchFullTimeRolesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/fulltime/roles/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const rolesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return rolesSuggestions;
      });
  }

  fetchPartTimeRolesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/parttime/roles/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const rolesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return rolesSuggestions;
      });
  }

  fetchContractRolesSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/contract/roles/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const rolesSuggestions = response.map((item) => {
          return IdNameObject.fromApiFormat(item);
        });

        return rolesSuggestions;
      });
  }

  fetchEmployersSuggestions({ substring }) {
    return this._transport.makeRequest({
      endpoint: '/employment/employers/suggestions',
      method:   'GET',
      params:   { substring: substring },
    })
      .then((response) => {
        const employersSuggestions = response.map((item) => {
          return Employer.fromApiFormat(item);
        });

        return employersSuggestions;
      });
  }
}

export default ApiEmployment;
