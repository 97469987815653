import { defined } from 'lib/helpers';

import Location from 'lib/api/location';

class Employer {
  constructor({ id, name, profileId, icon, location }) {
    if (id        !== null && typeof defined(id)        !== 'number') { throw Error('Unexpected id');        }
    if (                      typeof defined(name)      !== 'string') { throw Error('Unexpected name');      }
    if (profileId !== null && typeof defined(profileId) !== 'number') { throw Error('Unexpected profileId'); }
    if (icon      !== null && typeof defined(icon)      !== 'string') { throw Error('Unexpected icon');      }
    if (location  !== null && typeof defined(location)  !== 'object') { throw Error('Unexpected location');  }

    this.id        = id;
    this.name      = name;
    this.profileId = profileId;
    this.icon      = icon;
    this.location  = location;
  }

  toApiFormat() {
    return {
      id:        this.id,
      name:      this.name,
      profileId: this.profileId,
      icon:      this.icon,
      location:  this.location.toApiFormat(),
    };
  }

  isEmpty() {
    return (this.name === '');
  }

  isValid() {
    return (this.name !== '');
  }

  static createEmpty() {
    return new Employer({
      id:        null,
      name:      '',
      profileId: null,
      icon:      '',
      location:  Location.createEmpty(),
    });
  }

  static fromApiFormat(data) {
    return new Employer({
      ...data,
      location: Location.fromApiFormat(data.location),
    });
  }
}

export default Employer;
