import './index.scss';
import React from 'react';

import Text                              from 'component-lib/type/text';
import { style22pxRegularWhite }         from 'component-lib/type/text/styles';
import { ActivityCardWrapper }           from 'component-lib/components/activity-card-wrapper';
import { ActivityCardContent, TIMELINE } from 'component-lib/components/activity-card-content';
import { getActivityCardContentProps }   from 'component-lib/components/activity-card-content/get-props';
import { ActivityEvents }                from 'components/v2/activity-events';

export const CardUpdatesView = ({ events, activity, issuerProfile, onClick }) => {
  return (
    <div className = "card-updates-view">
      <Text { ...style22pxRegularWhite } text = "Card Updates" />
      {
        activity &&
        <div className = "card-updates-view__activity-card">
          <ActivityCardWrapper onClick = { onClick }>
            <ActivityCardContent
              { ...getActivityCardContentProps({ activity, issuerProfile }) }
              state = { TIMELINE }
            />
          </ActivityCardWrapper>
        </div>
      }
      <ActivityEvents events = { events } />
    </div>
  );
};
